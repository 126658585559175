import React, { useState } from 'react';
import FileGalleryContainer from '../../container/FileGalleryContainer';
import { WorkModal } from '../../components/WorkModal/WorkModal';
import AdminNavbar from '../../components/Navbars/AdminNavbar';
import { BreadcrumbHeader } from '../../components/Breadcrumb/Breadcrumb';
import { Button } from 'reactstrap';
import { getImagesInspection } from '../../core/database/inspections';
import FileContainer from '../../container/File/FileContainer';

export const WorkView = ({ router, history, location }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [saveState, setSaveState] = useState(true);
    const [links, setLinks] = useState([])
    // const [linked, setLinked] = useState(false);
    // const [isReport, setIsReport] = useState(false);

    const onSelectionChange = (files) => {
        setSelectedFiles(files);
    }

    const downloadAll = async () => {
        const images = await getImagesInspection(location.state.workId, location.state.id);

        let downloadLinks = []
        let data = [];
        images.filter(item => !item.url.includes("file://")).map((item, index) => {
            data.push(item)
            if (data.length === 20) {
                downloadLinks.push(`https://process.filestackapi.com/Az5mHPLnsSbGGf00M8CaAz/zip/[${data.map(item => item.url).join(",")}] `)
                data = [];
            }
        })
        if (data.length > 0) {
            downloadLinks.push(`https://process.filestackapi.com/Az5mHPLnsSbGGf00M8CaAz/zip/[${data.map(item => item.url).join(",")}] `)
        }
        setLinks(downloadLinks);
    }

    return (
        <>
            <div className="content skills-screen skills">
                <AdminNavbar goBack={() => history.goBack()} />
                {/* <BreadcrumbHeader data={router} />
                </AdminNavbar> */}
                {/* <Button onClick={() => setLinked(!linked)}>{linked ? "Ocultar imagens vinculadas" : "Mostrar imagens vinculadas"}</Button> */}
                {/* <Button onClick={downloadAll}>{"Baixar todas as imagens"}</Button> */}
                <div>
                    {links.map(url => {
                        return <p><a target="_blank" href={url}>{url}</a></p>
                    })}
                </div>
                <div className="category editable" style={{ marginInline: 30, marginTop: 30 }}>
                    {/* <FileContainer
                        useCrop={true}
                        dataReference={`Works/${location.state.workId}/inpections/${location.state.id}/images`}
                        canSelect={true}
                        isMultipleSelect={true}
                        showFiles={false}
                        style={{ minHeight: "auto" }}
                    /> */}
                    <FileGalleryContainer
                        saveState={saveState}
                        filter={{}}
                        canSelect={true}
                        isMultipleSelect={true}
                        accept="image/*"
                        onSelectionChange={onSelectionChange}
                        useCrop={true}
                        dataReference={`Works/${location.state.workId}/inpections/${location.state.id}/images`}
                        largeDropzone={true}
                    />
                </div>
                <WorkModal location={location} selection={selectedFiles} saveState={saveState} setSaveState={setSaveState} />
            </div>
        </>
    );
};