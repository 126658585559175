import { getItem } from './firebaseFunctions';
import { storage } from "./firebaseConfig";

export const getSize = async (forestRef) => {
    // const folderRef = storage.ref();
    // var forestRef = folderRef.child(path);
    return forestRef.getMetadata ? forestRef.getMetadata().then(function (metadata) {
        return metadata.size
    }).catch(function (error) {
        console.log({ error })
        return 0
    }) : 0
}

export const getAllFiles = async (reference) => {
    const items = await reference.listAll().then(async response => {
        const foldersChildrens = await Promise.all(response.prefixes.map(reference => getAllFiles(reference)));

        return [...response.items, ...foldersChildrens]
    })
    return items.flat();
}

export const getSizeWork = async (idWork, nameWork) => {
    const folderRef = storage.ref();

    if (idWork && nameWork) {
        const files = await getAllFiles(folderRef.child(`Works/${idWork}`))
        const filesMore = await getAllFiles(folderRef.child(`Images/${idWork}`))

        const allFiles = [...files, ...filesMore].flat();

        const sizeArray = await Promise.all(allFiles.map(reference => getSize(reference)));
        let size = sizeArray.reduce((partial_sum, a) => partial_sum + a, 0);

        return [{
            idWork: idWork,
            nameWork: nameWork,
            size
        }]
    }
}

export const getFullSize = async (idWork, nameWork) => {
    const data = await getSizeWork(idWork, nameWork)
    if (data) {
        const sizeMap = data.map(row => row.size)
        let soma = 0;
        for (let i = 0; i < sizeMap.length; i++) {
            soma += sizeMap[i];
        }
        return getSizeInMB(soma)
    }

}

export const getSizeInMB = async (soma) => {
    const sizeInMB = (soma / (1024 * 1024)).toFixed(2);
    return sizeInMB.replace(".", ",")
}