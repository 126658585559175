import { getGroup, getAllGroup, addGroup, deleteGroup, updateGroup } from "core/database/group";
import ActionCreator from 'core/utils/redux/ActionCreator';

const { actions, defaultReducer, get, add, remove, update } = new ActionCreator("group");
export { actions, defaultReducer };

export const getGroupData = get(getGroup);
export const getAllGroupData = get(getAllGroup)
export const addGroupData = add(addGroup);
export const updateGroupData = update(updateGroup);
export const deleteGroupData = remove(deleteGroup);
