import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label } from 'reactstrap';
import avatar from "assets/img/faces/perfil.png";
import FileSimpleContainer from '../container/FileSimpleContainer';
import { SelectInput, TextInput } from '../layoutV2/components/Form';
import { MdClose } from "react-icons/md";
import { SaveButton } from '../layoutV2/components/Button';
import { useSelector } from 'react-redux';
import { splitFullName } from '../container/UserProfileContainer';
import { NewModal } from '../layoutV2/components/Modal/Modal';

const permissionOptions = [{ value: true, label: "Admin" }, { value: false, label: "Membro" }, { value: 0, label: "Convidado" }]
export const UserProfile = (props) => {
    const { open, setOpen, OpenButton, toggle, avatar, onFileChange, onChange, values, onClickButton, error, title, data } = props
    const user = useSelector(state => state.login)
    console.log({ values })

    return (
        <>
            <NewModal
                open={open}
                toggle={toggle}
                OpenButton={OpenButton}
                onClickConfirm={onClickButton}
                onClick={() => setOpen(true)}
                title={title}
            >
                <FileSimpleContainer
                    disabled={values && values.id && !values.id.includes(user.id)}
                    circle={true}
                    file={avatar || values && values.name && { url: `https://ui-avatars.com/api/?name=${splitFullName(values.name)}&background=random&size=200` }}
                    mini
                    onFileChange={onFileChange}
                    single={true}
                    dataReference={`Images/Users/data`}
                />
                <TextInput
                    id="user-name"
                    placeholder="Nome"
                    type="text"
                    value={values.name}
                    onChange={(event) => onChange("name", event.target.value)}
                    disabled={values && values.id && !values.id.includes(user.id)}
                />
                <TextInput
                    id="user-email"
                    placeholder="E-Mail"
                    type="text"
                    value={values.email}
                    onChange={(event) => onChange("email", event.target.value)}
                    disabled={values && values.id}
                />
                {/*  <TextInput
                            id="user-permission"
                            placeholder="Permissão"
                            type="text"
                            value={values.permission}
                            onChange={(event) => onChange("permission", event.target.value)}
                        /> */}
                <SelectInput
                    options={permissionOptions}
                    title="Permissão"
                    labelledBy={"Permissão"}
                    value={permissionOptions.find(item => item.value === values.hasPower)}
                    onChange={data => onChange("hasPower", data.value)}
                    isSingle={true}
                />
                <TextInput
                    id="work-regProf"
                    placeholder="Registro Profissional"
                    type="text"
                    value={values.regProf}
                    onChange={(event) => onChange("regProf", event.target.value)}
                />
                <Label className="label-error">{error}</Label>
            </NewModal>
        </>
    )
}