import React, { useEffect } from 'react';
import { SelectInput, TextInput } from '../../layoutV2/components/Form';
import { NewModal } from '../../layoutV2/components/Modal/Modal';
import { normalizeCombo } from '../../core/utils/Utils';
import I18n from '../../core/I18n/I18n';
import keyEnum from '../../core/I18n/KeyEnum';
import Alert from '../../components/Alert/Alert';

export const SkillsModal = ({ data = undefined, isOpen, setIsOpen, onRowAdd, onRowUpdate, cancelEditing, loggedUser, allGroups, values, setValues, realData }) => {

    useEffect(() => {
        setValues(data)
    }, [data])

    const onChange = (field, value) => {
        setValues({ ...values, [field]: value })
    }

    const onSave = () => {
        try {

            if (!loggedUser.isAdmin) {
                if (!values.group) {
                    Alert.error(I18n[keyEnum.settingsGroup])
                    throw new Error(I18n[keyEnum.settingsGroup]);
                }
            }

            if (isOpen === "add") {
                onRowAdd(values)
            } else if (isOpen === "edit") {
                onRowUpdate(values, data)
            }
            setIsOpen(false)

        } catch (e) {
            console.log({ e })
        }
    }

    const isDisabled = (value, data) => {
        if (value && data) {
            const isNew = data.filter(item => value.parentId === '' && !value.isNew ? item && item.group : null)[0]
            const isNewChild = data.filter(item => item.id === value.parentId)[0]
            return isNew || isNewChild ? true : false
        }
    }

    return (
        <NewModal
            open={isOpen !== false}
            toggle={cancelEditing}
            onClickConfirm={onSave}
            title={isOpen === "add" ? "Adicionar" : "Editar"}
            onClickCancel={cancelEditing}
            className="datepicker-modal"
        >
            {/* <Input placeholder="Nome" type="text" defaultValue={values && values.name} onChange={(event) => onChange("name", event.target.value)} /> */}
            <TextInput
                id="skills-name"
                placeholder="Nome"
                type="text"
                value={values && values.name}
                onChange={(event) => onChange("name", event.target.value)}
            />
            <SelectInput
                options={normalizeCombo('id', 'name', allGroups)}
                title="Grupo"
                labelledBy={"Selecione um Grupo"}
                value={values && values.group}
                onChange={data => onChange("group", data)}
                isDisabled={isDisabled(values, realData)}
                isSingle={true}
            />
        </NewModal>
    )
}

{/* <Modal isOpen={isOpen !== false} toggle={cancelEditing} backdrop={true}>
        <ModalHeader toggle={cancelEditing}>{isOpen === "add" ? "Adicionar" : "Editar"}</ModalHeader>
        <ModalBody style={{ maxHeight: "100%", overflowY: "auto" }}>
            <Input placeholder="Nome" type="text" defaultValue={values && values.name} onChange={(event) => onChange("name", event.target.value)} />
        </ModalBody>
        <ModalFooter>
            <Button onClick={cancelEditing}>Cancelar</Button>
            <Button color="success" onClick={onSave}>Salvar</Button>{' '}
        </ModalFooter>
    </Modal> */}