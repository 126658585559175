import React from 'react';
import { IconButton } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';

export const DeleteConfirmLabel = ({ openConfirm, setOpenConfirm, removeEvent }) => {
	return openConfirm && (
		<div className="confirm-delete">
			<h4>Deseja mesmo remover esse item?</h4>
			<div>
				<IconButton onClick={removeEvent}>
					<Check fontSize="small" />
				</IconButton>
				<IconButton onClick={() => setOpenConfirm(false)}>
					<Close fontSize="small" />
				</IconButton>
			</div>
		</div>
	)
}