import { actions, defaultReducer } from "./Actions";
import { success } from "core/utils/redux/Reducers";

export const initialState = {
	isLoading: true,
	error: null,
	items: [],
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actions.ADD_TAG_SUCCESS:
			state = success(state, { items: state.items.concat([action.payload.item]) });
			break;
		case actions.DELETE_TAG_SUCCESS:
			state = success(state, { items: state.items.filter(row => row.id !== action.payload.id) });
			break;
		case actions.UPDATE_TAG_SUCCESS:
			const index = state.items.findIndex(row => row.id === action.payload.item.id);
			state.items[index] = {
				...action.payload.item
			};
			state = success(state, { items: state.items.concat([]) });
			break;
		default:
			state = defaultReducer(state, action);
			break;
	}
	return state; 
}