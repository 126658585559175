import React from 'react';
import { IoSendSharp, IoFileTrayFullOutline } from 'react-icons/io5';
import Dropzone from 'react-dropzone';
import { imageNameUtils } from '../../core/utils/Utils';
import storage from 'redux-persist/lib/storage';

export const SendInput = ({ placeholder = 'Enviar mensagem', onClick, onChange, value, uploadFiles, onKeyDown, }) => {

    return (
        <div className='div-input-send'>
            <div className='div-input-margin'>
            
                <Dropzone onDrop={uploadFiles} maxSize={52428800} noDrag={true} maxFiles={1}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <IoFileTrayFullOutline className='send-file' />
                        </div>
                    )}
                </Dropzone>
                <input placeholder={placeholder} className='send-input' onChange={onChange} value={value} onKeyDown={onKeyDown} />
                <IoSendSharp className='send-message' onClick={onClick} />
            </div>
        </div>
    )
};