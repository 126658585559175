import React from 'react';
import PubNub from "pubnub";
import { SendMessage, MessageChanelList } from '../components/ChatComponent';
import { Helmet } from 'react-helmet';

const currentChannel = "Default";
const theme = "light";

const pubnubData = new PubNub({
    publishKey: "pub-c-522c83d7-f96e-44fd-8152-e19184ca296a",
    subscribeKey: "sub-c-02d0ce14-bf0b-11ec-bd2a-8ab19e3fdcf0",
    uuid: "sec-c-MWQ2NjMwMjgtN2I2Zi00ZWFkLTkzNTgtMGZiMjkyZjEyMjcw",
});

const onClickFilter = (openFilter, setOpenFilter, setValues) => {
    if (openFilter) {
        setOpenFilter(false)
        setValues({ dates: {}, search: '' })
    } else {
        setOpenFilter(true)
    }
}

export const ChatView = ({ chat, onChange, values, loggedUser, onChannel, channel, messages, onSend, loading, isOpen, setIsOpen, onDeleteMessage, onReplayMessage, respondent,reload, setRespondent, setChangeMessage, changeMessage, setLoading,uploadFiles, setOpenFilter, openFilter, setValues, onRespondent, scrollRespondent, newForum, setNewForum, newGroup, setNewGroup, onSaveNewForum, onSaveNewGroup, setOpenConfig, openConfig, removeForum, onEditConfig, editGroup, unreadMessage, markedUnread, setOpenShareGroup, openShareGroup ,setOpenShareUsers,openShareUsers,setReload,loadingMessages,setChatMessage,}) => {
    return (
        <div className="content">
            <Helmet>
                {unreadMessage() === 0 ? <title>ike</title> : <title>{`(${unreadMessage()}) ike`}</title>}
            </Helmet>
            <div className="chat-row">
                <MessageChanelList
                    data={chat}
                    onChangeSearch={(event) => onChange('search', event.target.value)}
                    value={values.search}
                    loggedUser={loggedUser}
                    channel={channel}
                    onChannelSwitched={onChannel}
                    newForum={newForum}
                    setNewForum={setNewForum}
                    newGroup={newGroup}
                    setNewGroup={setNewGroup}
                    onSaveNewForum={onSaveNewForum}
                    onSaveNewGroup={onSaveNewGroup}
                    values={values}
                    setValues={setValues}
                    setOpenConfig={setOpenConfig}
                    openConfig={openConfig}
                    removeForum={removeForum}
                    onEditConfig={onEditConfig}
                    editGroup={editGroup}
                    markedUnread={markedUnread}
                    onChange={onChange}
                    loading={loading}
                    setChatMessage={setChatMessage}

                />
                <div className="chat-collumn">
                    <SendMessage
                        loading={loading}
                        onSend={(event) => onSend(event)}
                        pubnub={pubnubData}
                        loggedUser={loggedUser}
                        currentChannel={currentChannel}
                        channel={channel}
                        theme={theme}
                        users={messages}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        onDeleteMessage={onDeleteMessage}
                        onReplayMessage={onReplayMessage}
                        respondent={respondent}
                        respondentClose={() => setRespondent(false)}
                        setChangeMessage={setChangeMessage}
                        changeMessage={changeMessage}
                        uploadFiles={uploadFiles}
                        onChangeCalendary={(field, value) => onChange(field, value)}
                        values={values}
                        onClickFilter={() => onClickFilter(openFilter, setOpenFilter, setValues)}
                        openFilter={openFilter}
                        onRespondent={onRespondent}
                        scrollRespondent={scrollRespondent}
                        setOpenShareGroup={setOpenShareGroup}
                        openShareGroup={openShareGroup}
                        setOpenShareUsers={setOpenShareUsers}
                        openShareUsers={openShareUsers}
                        onChange={onChange}
                        setLoading={setLoading}
                        reload={reload}
                        setReload={setReload}
                        loadingMessages={loadingMessages}
                      
 
                    />
                </div>
            </div>
        </div>
    )
};