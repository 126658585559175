import React from "react";
import MaterialTable, { MTableAction, MTableEditRow, MTableToolbar } from "material-table";
import I18n from "core/I18n/I18n";
import keyEnum from "core/I18n/KeyEnum";
import { Button } from 'reactstrap';
import { ConfirmButton, CancelButton, DeleteButton, EditButton } from "../Button/Button";

let action;

class CustomTable extends React.Component {

	static defaultProps = {
		showToolbar: true,
		canAdd: true,
	}

	constructor(props) {
		super(props);

		this.state = {
			pageSize: 10
		}

		this.onRowAdd = this.onRowAdd.bind(this);
		this.onRowDelete = this.onRowDelete.bind(this);
		this.onRowUpdate = this.onRowUpdate.bind(this);
		this.setPageSize = this.setPageSize.bind(this);
	}

	componentWillMount() {
		const value = parseInt(localStorage.getItem('ike/pageSize')) || 10;
		this.setState({ pageSize: value })
	}

	setPageSize(value) {
		this.setState({ pageSize: value })
		localStorage.setItem('ike/pageSize', value);
	}

	onRowAdd(newData) {
		return new Promise(resolve => {
			resolve(newData);
		});
	}

	onRowDelete(oldData) {
		return new Promise(resolve => {
			resolve(oldData);
		});
	}

	onRowUpdate(newData, oldData) {
		return new Promise(resolve => {
			resolve({ oldData, newData });
		});
	}

	render() {
		return (
			<div className="custom-table">
				<div className="custom-table-toolbar" style={{ marginBottom: 20 }}>
					{this.props.filterComponent && this.props.filterComponent()}
					{this.props.canAdd && (this.props.onAddClick || this.props.onRowAdd) && (
						<>
							<Button className="button-add" outline color="primary" onClick={(e) => this.props.onAddClick ? this.props.onAddClick() : action.onClick(e, {})}>{this.props.isSchedule ? "Importar" : "Novo"}</Button>
							<Button className="button-add-fixed btn-round btn-icon" color="primary" onClick={(e) => this.props.onAddClick ? this.props.onAddClick() : action.onClick(e, {})}>
								<i className="nc-icon nc-simple-add" />
							</Button>
						</>
					)}
				</div>
				<MaterialTable
					ref={this.props.ref}
					editable={{
						onRowAdd: this.props.onRowAdd,
						onRowUpdate: this.props.onRowUpdate,
						onRowDelete: this.props.onRowDelete
					}}
					onChangeRowsPerPage={(value) => this.setPageSize(value)}
					localization={I18n[keyEnum.tableTranslate]}
					{...this.props}
					options={{
						filtering: this.props.filtering,
						actionsColumnIndex: -1,
						paging: false,
						maxBodyHeight: `calc(100vh - 295px)`,
						...this.props.options,
						pageSize: this.state.pageSize,
					}}
					components={{
						Toolbar: (item) => {
							action = item.actions.filter(item => item.tooltip === "Adicionar")[0];
							if (!this.props.showToolbar) {
								return <div className="custom-table-toolbar"></div>
							} else {
								return (
									<></>
								)
							}
						},
						EditRow: (item) => {
							const newColumns = [];
							item.columns.map(column => {
								if (column.editable) {
									newColumns.push({
										...column,
										title: column.placeholder ? column.placeholder : column.title
									})
								}
							})

							const editProps = {
								...item,
								columns: newColumns
							}

							return this.props.editableRow ? this.props.editableRow(editProps) : <MTableEditRow {...editProps} />;
						},
						Action: item => {
							const { data } = item;
							const action = item && typeof item.action === "function" ? item.action(data) : item.action;
							if (action) {
								if (action.isFreeAction && action.icon.name === "Add") {
									return (
										<>
											<Button className="button-add" outline color="primary" onClick={(e) => this.props.onAddClick ? this.props.onAddClick() : action.onClick(e, data)}>Novo</Button>
											<Button className="button-add-fixed btn-round btn-icon" color="primary" onClick={(e) => this.props.onAddClick ? this.props.onAddClick() : action.onClick(e, data)}>
												<i className="nc-icon nc-simple-add" />
											</Button>
										</>
									)
								} else if (data) {
									if (action.icon && action.icon.name === "Delete") {
										return <DeleteButton onClick={(e) => action.onClick(e, data)} />;
									} else if (action.icon && action.icon.name === "Edit") {
										return <EditButton onClick={(e) => action.onClick(e, data)} />;
									} else if (action.icon && action.icon.name === "Check") {
										return <ConfirmButton onClick={(e) => {
											this.props.cancelEditing && this.props.cancelEditing();
											action.onClick(e, data);
										}} />;
									} else if (action.icon && action.icon.name === "Clear") {
										return <CancelButton onClick={(e) => {
											this.props.cancelEditing && this.props.cancelEditing();
											action.onClick(e, data);
										}} />;
									}
								}
							}
							return <MTableAction {...item} />;
						},
						...this.props.components
					}}
				/>
			</div>
		);
	}
}

export default CustomTable;
