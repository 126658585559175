import { db, firebaseAuth, fireSQL } from './firebaseConfig';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import EntityEnum from './EntityEnum';
import { getItem, addItem, deleteItem, updateItem, execute, getItemById } from './firebaseFunctions';
import { emailRegex } from './validator/Regex';
import { getScopeHasPower } from 'core/utils/Utils';
import { VerifyErroCode } from './firebaseError';

/** Register User actions */
export const registerUser = async ({ id, name, email, password }) => {
    const invite = await getInvite(id);
    const userEmail = await getItem("Users", { email });

    if (invite) {
        if (userEmail && userEmail[0].id === id) {
            try {
                await firebaseAuth().createUserWithEmailAndPassword(email, password);
                // await firebaseAuth().currentUser.sendEmailVerification();

                return db.collection('Users').doc(id).set({ name, email, invite: false }, { merge: true });
            } catch (e) {
                throw new Error(I18n[keyEnum.emailAlreadyExists]);
            }
        } else {
            throw new Error(I18n[keyEnum.emailAlreadyExists]);
        }
    } else {
        throw new Error(I18n[keyEnum.loginAlreadyExists]);
    }
}

/** Do login with Registered User */
export const doLogin = async (email, password) => {
    try {
        return firebaseAuth().setPersistence(firebaseAuth.Auth.Persistence.LOCAL).then(async () => {
            const loggedUser = await firebaseAuth().signInWithEmailAndPassword(email, password).catch(e => VerifyErroCode(e.code));

            if (loggedUser.user) {
                return verifyUser(loggedUser.user);
            } else {
                throw new Error(loggedUser);
            }
        })
    } catch (e) {
        throw new Error(VerifyErroCode(e.code));
    }
}

export const verifyUser = async (loggedUser) => {
    if (loggedUser) {
        const arrUser = await getItem("Users", { email: loggedUser.email || {} });
        if (arrUser && arrUser.length > 0) {
            const user = arrUser[0];
            const ids = user.groups.map(item => `"${item.details.id}"`)
            if (!ids || ids.length === 0) {
                throw new Error(I18n[keyEnum.groupNotExists]);
            }
            let arrGroup = await fireSQL.query(`
                SELECT workGroup from Company where id in (${ids.join(",")})
            `)
            arrGroup = [...new Set(arrGroup.map(item => item.workGroup).filter(item => item))];
            if (arrGroup && (arrGroup.length < 2)) {
                const group = user && (arrGroup.length === 1) ? await getItemById("Groups", arrGroup[0]) : undefined
                if (group || user.isAdmin) {
                    return { ...user, groupData: group, network: group.id, workGroup: arrGroup };
                } else {
                    throw new Error(I18n[keyEnum.groupNotExists]);
                }
            } else {
                return { ...user, workGroup: arrGroup }
            }

            /* const groups = [];

            if (user.hasFullPower) {
                groups.push(getItem("Groups"));
            } else {
                user.groups.map(group => {
                    if (group && group.details) {
                        groups.push(execute(group.details, group));
                    }
                })
            }

            return Promise.all(groups).then(arrGroups => {
                const groups = [].concat(...arrGroups).filter(item => item.active === true);
                if (groups.length > 0) {
                    return { ...user, groups };
                } else {
                }
            }); */
        } else {
            throw new Error(I18n[keyEnum.loginNotExists]);
        }
    } else {
        throw new Error(I18n[keyEnum.loginNotExists]);
    }
}

/** Do login with Registered User */
export const userRecovery = async (email) => {
    try {
        await firebaseAuth().sendPasswordResetEmail(email);
    } catch (e) {
        throw new Error(I18n[keyEnum.loginNotExists]);
    }
}

export const getInvite = async (id) => {
    const users = db.collection(EntityEnum.user).doc(id);

    return users.get().then(document => {
        if (document.exists) {
            const user = document.data();
            return (user && user.invite) && user;
        } else {
            throw new Error(I18n[keyEnum.inviteExpired]);
        }
    })
}


export const addInvite = async (item) => {
    return;
}

export const deleteInvite = async (id) => {
    return;
}

export const updateInvite = async (item) => {
    return;
}

export const getUsers = (scope) => {
    console.log({ scope })
    if (scope && scope.value) {
        const table = db.collection("Users").where("active", "==", true);
        const clauseHasPower = table.where("groups", 'array-contains', { hasPower: true, details: groupReference(scope) });
        const clauseNormalPower = table.where("groups", 'array-contains', { hasPower: false, details: groupReference(scope) });
        const clauseHasInvitedPower = table.where("groups", 'array-contains', { hasPower: 0, details: groupReference(scope) });

        const usersHasPower = execute(clauseHasPower);
        const usersNormal = execute(clauseNormalPower);
        const usersInvite = execute(clauseHasInvitedPower);

        return Promise.all([usersHasPower, usersNormal, usersInvite]).then((arrUsers) => {
            // console.log({ arrUsers });
            const users = [].concat(...arrUsers);
            return users;
        });
    } else {
        throw new Error(I18n[keyEnum.groupFieldRequired]);
    }
}

export const getAllUsers = () => {
    const table = db.collection("Users").where("active", "==", true);

    return execute(table);
}

export const groupReference = (item) => {
    return item.value ? db.collection("Company").doc(item.value) : {};
}

const authGroup = (scope, hasPower = false) => {
    return [{ hasPower, details: groupReference(scope) }]
}

export const addUser = async (item) => {
    const { newData, scope, hasPower = false } = item;
    if (newData.email && emailRegex.test(newData.email)) {
        const arrUser = await getItem("Users", { email: newData.email });
        if (!arrUser || arrUser.length === 0) {
            const newUser = await addItem("Users", { ...newData, /* name: newData.email.split("@")[0], */ invite: true, groups: authGroup(scope, hasPower) });
            sendEmailInvite(newUser.id, newUser.email);
            return newUser;
        } else {
            const user = arrUser[0];
            const newGroup = authGroup(scope, hasPower);
            const groups = user.groups || [];

            // const workGroup = user.workGroup || [];
            const alreadyExists = groups.filter(item => item.details.id === groupReference(scope).id);
            // const extraData = {}
            // if (!workGroup.includes(newData.workGroup)) {
            //     extraData.workGroup = workGroup.concat(newData.workGroup)
            // }

            if (alreadyExists && alreadyExists.length > 0) {
                throw new Error(`${newData.email}: ${I18n[keyEnum.emailAlreadyExists]}`);
            } else {
                return updateItem("Users", { ...user, ...newData, groups: groups.concat(newGroup) });
            }
        }
    } else {
        throw new Error(`${newData.email}: ${I18n[keyEnum.invalidEmail]}`);
    }
}

export const sendEmailInvite = async (id, email) => {
    //http://localhost:5000/app-ike/us-central1/api3/
    //https://us-central1-app-ike.cloudfunctions.net/api3/
    return fetch(`https://us-central1-app-ike.cloudfunctions.net/api3/sendInvite?invite=${id}&email=${email}`);
}

export const deleteUser = async ({ id, groups }) => {
    return updateItem("Users", { id, groups });
    // return deleteItem("Users", id);
}

export const updateUser = async (item) => {
    const { newData, scope, hasPower } = item;
    const groupIndex = newData.groups.findIndex(item => item.details.id === groupReference(scope).id);
    // const hasPower = getScopeHasPower(newData, scope);
    // console.log({ hasPower, newData, scope, groupIndex })
    /* if (hasPower) {
        newData.groups[groupIndex] = authGroup(scope, 0)[0];
    } else {
        if (hasPower === 0) {
            newData.groups[groupIndex] = authGroup(scope, false)[0];
        } else {
            newData.groups[groupIndex] = authGroup(scope, true)[0];
        }
    } */

    if (hasPower || hasPower === false || hasPower === 0) {
        newData.groups[groupIndex] = authGroup(scope, hasPower)[0];
    }

    // const { id, groups } = newData;
    return updateItem("Users", { ...newData });
}


/**
 * const users = db.collection(EntityEnum.user);
    const query = users.where('invite', '==', true);

    return query.get().then(snapshot => {
        if (snapshot.empty) {
            return;
        } else {
            snapshot.docs.forEach(document => {
                if (document.exists && document.id === id) {
                    return document;
                } else {
                    throw new Error(I18n[keyEnum.inviteExpired]);
                }
            });
            return;
        }
    })
 */