export const DefaultImpact = (isFilter = true) => [
    {
        value: 4,
        label: "Positivo",
        color: "#20a74c"
    },
    {
        value: 1,
        label: isFilter ? "Negativo Baixo" : "Baixo impacto",
        color: "#f5af19"
    },
    {
        value: 2,
        label: isFilter ? "Negativo Médio" : "Médio impacto",
        color: "#ef6615"
    },
    {
        value: 3,
        label: isFilter ? "Negativo Alto" : "Alto impacto",
        color: "#ef6d2e"
    },
    /* {
        value: 0,
        label: "Neutro",
        color: "gray"
    },
    {
        value: -3,
        label: "Alto impacto",
        color: "#20a74c"
    },
    {
        value: -2,
        label: "Médio impacto",
        color: "#15ef66"
    },
    {
        value: -1,
        label: "Baixo impacto",
        color: "#19f5af"
    } */
]

export const DefaultState = [
    {
        value: undefined,
        label: "Pendente"
    },
    {
        value: 1,
        label: "Finalizado",
    },
]

export const DefaultOrdination = [
    {
        value: 1,
        label: "Etapa"
    },
    {
        value: 2,
        label: "Data",
    },
    {
        value: 3,
        label: "Inspeção",
    },
]

export const DefaultSignal = [
    {
        value: 1,
        label: "Melhoria"
    },
    {
        value: 3,
        label: "Custo"
    },
    {
        value: 4,
        label: "Prazo"
    },
    {
        value: 2,
        label: "As Built"
    },
]