import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'
import { EditGalleryButton } from '../Button/Button';
import { NewModal } from '../../layoutV2/components/Modal/Modal';

const disabledIcons = require('tui-image-editor/dist/svg/icon-a.svg')
const activeIcons = require('tui-image-editor/dist/svg/icon-b.svg')
const hoverIcons = require('tui-image-editor/dist/svg/icon-c.svg')
const normalIcons = require('tui-image-editor/dist/svg/icon-d.svg')
//const bg = require('tui-image-editor/examples/img/bg.png')

export const ModalEditImage = (props) => {
    const { onChange, open, hideButton, onToggle, vertical } = props
    const { url, filename } = props.file;
    const editor = useRef(null);
    const [isOpenModal, setIsOpenModal] = useState();

    useEffect(() => {
        setIsOpenModal(open !== undefined);
    }, [open])

    const toggle = () => {
        if (!hideButton) {
            setIsOpenModal(!isOpenModal)
        } else {
            onToggle({});
        }
    };
    const onSave = () => {
        if (editor.current) {
            const editorInstance = editor.current.getInstance();
            const dataUrl = editorInstance.toDataURL();
            onChange({ ...props.file, url: dataUrl });
        }
        toggle();
    }

    const onDuplicate = () => {
        if (editor.current) {
            const editorInstance = editor.current.getInstance();
            const dataUrl = editorInstance.toDataURL();
            onChange({ ...props.file, url: dataUrl }, true);
        }
        toggle();
    }

    return (
        <>
            {!hideButton && <div className={`button-edit-gallery ${vertical && "vertical"}`}>
                <EditGalleryButton onClick={toggle} />
            </div>}
            {/* <Button
                onClick={toggle}
                className="btn-icon " //button-edit-image
                color="warning"
                size="sm"
                type="button"
                style={{
                    zIndex: 999,
                    margin: 0,
                    pointerEvents: "auto",
                    width: 23,
                    height: 23,
                    position: "absolute",
                    top: 8,
                    right: 38,
                    minWidth: 23,
                    borderRadius: 20,
                }}
            >
                <i className="fa fa-edit" style={{ fontSize: 14, margin: 0 }} />
            </Button> */}
            {/* <Modal isOpen={isOpenModal} toggle={toggle} className="modal-edit-image" backdrop={true}>
                <ModalHeader toggle={toggle}>Editor de imagem</ModalHeader>
                <ModalBody> */}

            <NewModal
                open={isOpenModal}
                toggle={toggle}
                onClickConfirm={onSave}
                title={"Editor de imagem"}
                outlineLabel={"Salvar em cópia"}
                onClickCancel={onDuplicate}
                className="report-modal modal-edit-image"
            >
                {isOpenModal && <ImageEditor
                    ref={editor}
                    onClick={(e) => e.stopPropagation()}
                    includeUI={{
                        loadImage: {
                            path: url, //`https://aelyjcyado.cloudimg.io/v7/${url}&h=600&org_if_sml=1`,
                            name: filename || "file.png"
                        },
                        locale: localePtBR,
                        theme: blackTheme,
                        menu: [
                            'crop', 'flip', 'rotate',
                            'draw', 'shape', 'icon', 'text'],
                        initMenu: '',
                        uiSize: {
                            height: "800px"
                        },
                        menuBarPosition: 'bottom'
                    }}
                    cssMaxHeight={800}
                    usageStatistics={false}
                />}
            </NewModal>
            {/* </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={onDuplicate}>Salvar em cópia</Button>{' '}
                    <Button color="success" onClick={onSave}>Salvar</Button>{' '}
                </ModalFooter>
            </Modal> */}
        </>
    )
}

const blackTheme = { // or white
    // main icons
    'menu.normalIcon.path': normalIcons,
    'menu.activeIcon.path': activeIcons,
    'menu.disabledIcon.path': disabledIcons,
    'menu.hoverIcon.path': hoverIcons,
    'submenu.normalIcon.path': normalIcons,
    'submenu.activeIcon.path': hoverIcons,
}
const localePtBR = { // override default English locale to your custom
    'Crop': 'Cortar',
    'Delete-all': 'Remover todos',
    'Delete': 'Remover',
    'Rectangle': 'Retângulo',
    'Circle': 'Círculo',
    'Triangle': 'Triângulo',
    'Apply': 'Aplicar',
    'Cancel': 'Cancelar',
    'Undo': 'Desfazer',
    'Redo': 'Refazer',
    'Reset': 'Resetar',
    'Fill': 'Preenchimento',
    'Stroke': 'Linha',
    'Flip': 'Inverter',
    'Rotate': 'Girar',
    'Draw': 'Desenhar',
    'Shape': 'Formas',
    'Icon': 'Ícones',
    'Text': 'Texto',
    'Mask': 'Máscara',
    'Custom': 'Customizado',
    'Square': 'Quadrado',
    'Flip X': 'Inverter X',
    'Flip Y': 'Inverter Y',
    'Range': 'Valor',
    'Text size': 'Tamanho',
    'Bold': 'Negrito',
    'Italic': 'Itálico',
    'Underline': 'Sublinhado',
    'Left': 'Esquerda',
    'Center': 'Centro',
    'Right': 'Direita',
    'Color': 'Cor',
    'Arrow': 'Seta',
    'Arrow-2': 'Seta',
    'Arrow-3': 'Seta',
    'Star-1': 'Estrela',
    'Star-2': 'Estrela',
    'Polygon': 'Polígono',
    'Location': 'Marcação',
    'Hearth': 'Coração',
    'Bubble': 'Diálogo',
    'Custom icon': 'Customizado',
    'Free': 'Livre',
    'Straight': 'Reta',
    'Double Click': 'Duplo clique'
};