import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import LoginRequiredRoute from 'components/LoginRequiredRoute/LoginRequiredRoute';
import AuthLayout from "layouts/Auth/Auth.jsx";
import AdminLayout from "layouts/Admin/Admin.jsx";
import Loader from 'views/pages/Loader';
import { logout, markAsLoaded, silentSignIn } from './core/redux-store/login/Actions';


const App = () => {
    const dispatch = useDispatch()
    const loaded = useSelector(state => state.login.loaded);
    const rehydrated = useSelector(state => state.login.rehydrated);
    const email = useSelector(state => state.login.email);

    useEffect(() => {
        if(rehydrated){
            if(email){
                dispatch(silentSignIn(email))
            } else {
                dispatch(logout());
            }
        }
	}, [rehydrated, email])
    
    if (!rehydrated || !loaded) {
        return <Loader/>
    }

    return (
        <Switch>
            <Route path="/auth" render={props => <AuthLayout {...props} />} />
            <LoginRequiredRoute component={AdminLayout} />
        </Switch>
    )
}

export default App;