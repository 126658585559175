import React from "react";
import avatar from "assets/img/faces/perfil.png";
import {
  Row,
  Col,
  Badge,
} from "reactstrap";
import CustomTable from "../components/CustomTable/CustomTable";
import { defaultColor } from "../variables/theme";
import { MTableEditRow } from 'material-table';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import GenericMaterialTable from "../components/GenericMaterialTable";
import { EditButton, EmailButton } from "../components/Button/Button";
import { AddNavButton } from "../layoutV2/components/Button";
import AddUserContainer from "../container/UserProfileContainer";
import UserProfileContainer, { splitFullName } from "../container/UserProfileContainer";

class Users extends React.Component {
  render() {
    const { hasPower, resendInvite, scope } = this.props;

    const columns = [
      {
        title: "Nome", field: "name", defaultSort: 'asc', filtered: true, defaultSearch: true, render: rowData => (
          <div className="row-user">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={rowData && rowData.avatar && rowData.avatar.url || `https://ui-avatars.com/api/?name=${splitFullName(rowData.name)}&background=random&size=200`}
              />
            </div>
            <Col className="v2-details truncated">
              <h5>{rowData && rowData.name}</h5>
              <p>{rowData && rowData.email}</p>
            </Col>
          </div>
        )
      },
      {
        title: "Type", field: "type", render: rowData => {
          console.log({ rowData })
          if (rowData === undefined) {
            return;
          }

          const hasPowerItem = this.props.getScopeHasPower(rowData)

          if (hasPowerItem === 0) {
            return (
              <Badge className="badge-text member" pill /* onClick={() => hasPower && this.props.onHasPowerUpdate(rowData)} */>Convidado</Badge>
            )
          } else if (hasPowerItem) {
            return (
              <Badge className="badge-text admin" pill /* onClick={() => hasPower && this.props.onHasPowerUpdate(rowData)} */>Admin</Badge>

            )
          } else {
            return (
              <Badge className="badge-text member" pill /* onClick={() => hasPower && this.props.onHasPowerUpdate(rowData)} */>Membro</Badge>
            )
          }
        }
      },
    ]

    return (
      <>
        {/* <div className="content users-screen"> */}
        <GenericMaterialTable
          title="Usuários"
          idTable={"users-list"}
          columns={columns}
          rightComponent={<UserProfileContainer scope={scope} title="Novo usuário" OpenButton={(props) => <><h2 className="user-label-title">Usuários</h2><AddNavButton label="Novo usuário" {...props} /><div className="flex1" /></>} />}
          {...this.props}
          editableRow={item => {
            let newItem = item;
            if (item && item.mode === "delete") {
              const { active, email, id, invite, name, tableData } = item.data;
              newItem = {
                ...item,
                data: { active, email, id, invite, name, tableData }
              };
            }
            return <tr><td colspan="3"><table className="edit-row"><MTableEditRow {...newItem} /></table></td></tr>
          }}
          actions={[
            rowData => (hasPower && rowData.invite) && ({
              icon: (props) => <EmailButton {...props} marginZero />,//'mail_outline',
              tooltip: I18n[keyEnum.resendInvite],
              onClick: (_event, rowData) => {
                resendInvite(rowData);
              },
              iconProps: {
                style: {
                  fontSize: 24,
                  color: defaultColor
                }
              }
            }),
            rowData => ({
              icon: (props) => <UserProfileContainer data={rowData} scope={scope} title="Editar usuário" OpenButton={(props) => <EditButton {...props} marginZero />} />,
              iconProps: {
                style: {
                  fontSize: 24,
                  color: defaultColor
                }
              },
              // onClick: (_event, rowData) => props.onEditClick(rowData)
            }),

          ]}
        />
        {/* </div> */}
      </>
    );
  }
}

export default Users;

