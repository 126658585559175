import React, { useState, useMemo } from "react";
import CustomTable from "../components/CustomTable/CustomTable";
import { Row, Col, Input, Badge } from 'reactstrap';
import { defaultColor } from "../variables/theme";
import { MTableEditRow } from 'material-table';
import { FilterSearch, FilterTag, FilterPicker } from "../components/Filter/FilterHooks";
import { CommentContainer } from "../container/CommentContainer";
import AdminNavbar from '../components/Navbars/AdminNavbar';
import { BreadcrumbHeader } from '../components/Breadcrumb/Breadcrumb';
import { normalizeCombo } from "../core/utils/Utils";
import GenericMaterialTable from "../components/GenericMaterialTable";

const dataRoute = [
    {
        name: "Início",
        active: true,
        route: "/admin/dashboard"
    },
    {
        name: "Apontamentos"
    }
]

const doFilter = (rowData, values, dsTerm) => {
    let hasTags = true;
    if (values.tags) {
        const arrTags = values.tags.map(item => {
            return item.value
        })
        hasTags = values.tags.length == 0 || (rowData.linkSteps && rowData.linkSteps.filter(tag => {
            console.log({ rowData, value: arrTags.includes(tag.value), arrTags, tag })
            return arrTags.includes(tag.value)
        })).length > 0;
    }

    let hasWorkType = true;
    if (values.workType) {
        const arrWorkTypes = values.workType.map(item => {
            return item.value
        })
        hasWorkType = values.workType.length == 0 || (rowData.workType && rowData.workType.filter(row => {
            return arrWorkTypes.includes(row.value)
        })).length > 0;
    }

    let hasTerm = true;
    if (dsTerm) {
        hasTerm = rowData.name.toLowerCase().includes(dsTerm.toLowerCase());
    }

    return hasTags && hasWorkType && hasTerm;
}

const getFilteredData = (data, values, dsSearch) => {
    return data.filter(item => doFilter(item, values, dsSearch))
}

export const ListComment = (props) => {
    const { data, report } = props;
    const [dsSearch, setDsSearch] = useState(defaultDsSearch);

    const onFilterSearch = (_name, value) => {
        setDsSearch(value);
        defaultDsSearch = value;
    }

    return (
        <>
            <div className="content lib-list">
                {!report && <AdminNavbar>
                    <BreadcrumbHeader data={dataRoute} />
                </AdminNavbar>}
                <CommentListTable
                    report={report}
                    dsSearch={dsSearch}
                    data={data}
                    onFilterSearch={onFilterSearch}
                    {...props}
                />
            </div>
        </>
    )
};

let defaultDsSearch;
let defaultPage = 0;
let defaultValues = {}

export const CommentListTable = (props) => {
    const { report, data, onSelectedChange, comboTags, comboWorkTypes } = props;
    const [dsSearch, setDsSearch] = useState(defaultDsSearch);
    const [values, setValues] = useState(defaultValues);
    const [selected, setSelected] = useState(null)

    const onSelectedChangeHandler = (rowData) => {
        const newData = selected && selected.id && rowData.id === selected.id ? null : rowData
        setSelected(newData);
        onSelectedChange(newData);
    }

    const onFilterSearch = (_name, value) => {
        setDsSearch(value);
        defaultDsSearch = value;
    }

    const onFilter = (field, value) => {
        const newValues = { ...values, [field]: value }
        setValues(newValues);
        defaultValues = newValues;
    }

    const formatTags = () => {
        const tagsFiltered = (values.workType && values.workType.length > 0) ?
            comboTags.filter(tag => values.workType.find(type => tag.parentId === type.value))
            : comboTags
        return normalizeCombo("id", "name", tagsFiltered)
    }

    const columns = [
        {
            title: 'Nome',
            field: 'name',
            editable: 'always',
            cellStyle: {
                maxWidth: 230,
                minWidth: 230
            },
            defaultSort: 'asc',
            filtered: true,
            defaultSearch: true,
            editComponent: props => {
                return (
                    <Row className="edit-custom-row">
                        <input
                            level="text"
                            placeholder="Nome"
                            className="editing-input-custom"
                            value={props.value}
                            onChange={e => props.onChange(e.target.value)}
                        />
                    </Row>
                )
            },
            render: rowData => (
                <Row className="row-user" style={{ maxWidth: "100%" }}>
                    {report && <Input type="checkbox" onChange={() => onSelectedChangeHandler(rowData)} checked={selected && selected.id === rowData.id} />}
                    <Col className="details truncated">{rowData.name}</Col>
                </Row>
            )
        },
        {
            title: 'Público',
            field: 'isPublic',
            cellStyle: {
                width: 100
            },
            render: rowData => (
                <Row className="row-user">
                    <Col className="details truncated-badge">
                        {rowData.isPublic ? <Badge color="info">Público</Badge> : <Badge color="success">Grupo</Badge>}
                    </Col>
                </Row>
            )
        },
    ]

    const setPage = (page) => {
        defaultPage = page
    }

    const extraData = !report ? {
        actions: [
            {
                icon: 'info_outlined',
                iconProps: {
                    style: {
                        fontSize: 24,
                        color: defaultColor
                    }
                },
                tooltip: 'Editar Documento',
                onClick: (_event, rowData) => props.onEditClick(rowData)
            },
            rowData => !props.isAdmin && (rowData.workGroup === props.network) && (props.groupData.responsible === props.email) && {
                icon: 'delete_outlined',
                iconProps: {
                    style: {
                        fontSize: 24,
                        color: defaultColor
                    }
                },
                tooltip: 'Excluir Documento',
                onClick: (_event, rowData) => props.onDeleteItem(rowData)
            }
        ],
        editableRow: item => {
            return <tr><td colspan="4"><table className="edit-row"><MTableEditRow {...item} /></table></td></tr>
        },
        filterComponent: () => {
            return <div className="filter-container">
                <div className="actions">
                    <FilterSearch onFilterSearch={onFilterSearch} value={dsSearch} />
                    {/* <FilterTag onFilter={onFilterTags} value={tags} comboOptions={comboTags} /> */}
                    <div className="filter-lib">
                        <FilterPicker onFilter={onFilter} field="workType" value={values.workType} comboOptions={comboWorkTypes} placeholder="Filtrar por tipo de obra" />
                    </div>
                    <div style={{ marginRight: 0 }} className="filter-lib">
                        <FilterPicker onFilter={onFilter} field="tags" value={values.tags} comboOptions={formatTags()} placeholder="Filtrar por etapa" disabled={!(values.workType && values.workType.length > 0)} />
                    </div>
                </div>
            </div>
        },
        ...props,
    } : {
        showToolbar: false,
        canAdd: false,
    }

    return (
        <GenericMaterialTable
            title="Biblioteca"
            columns={columns.filter(item => item !== false)}
            options={{ search: true, paging: true, initialPage: defaultPage }}
            {...extraData}
            onChangePage={page => setPage(page)}
            data={getFilteredData(data, values, dsSearch)}
            detailPanel={[
                {
                    tooltip: 'Mostrar detalhes',
                    render: rowData => {
                        return (
                            <div className="list-comment" >
                                <CommentContainer id={rowData.id} onSave={{}} />
                            </div>
                        )
                    },
                },
            ]}
        />
    )
}