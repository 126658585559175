import { getWork, getWorks, addWorks, deleteWorks, updateWorks} from "core/database/works";
import ActionCreator from 'core/utils/redux/ActionCreator';

const { actions, defaultReducer, get, add, remove, update, getSimple } = new ActionCreator("works");
export { actions, defaultReducer };

export const getWorksByIdData = getSimple(getWork)
export const getWorksData = get(getWorks);
export const addWorksData = add(addWorks);
export const updateWorksData = update(updateWorks);
export const deleteWorksData = remove(deleteWorks);
