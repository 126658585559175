import React from "react";
import { connect } from "react-redux";
import { signIn, setErrorMessage, verifyUserData } from "core/redux-store/login/Actions";
import Login from "views/pages/Login";

class LoginContainer extends React.Component {
	constructor(props) {
		super(props);

		this.doLogin = this.doLogin.bind(this);
		this.onChange = this.onChange.bind(this);

		this.state = {
			email: undefined,
			password: undefined
		};
	}

	componentWillMount() {
		const { email } = this.props;
		if (email) {
			this.props.dispatch(verifyUserData(email));
		}
	}

	doLogin() {
		const { email, password } = this.state;

		if (email && password) {
			this.props.dispatch(signIn(email, password));
		} else {
			this.props.dispatch(setErrorMessage("Preencha os campos!"));
		}
	}

	onChange(field, value) {
		this.setState({ [field]: value });
	}

	render() {
		return (
			<Login
				loggedIn={this.props.loggedIn}
				isLoading={this.props.isLoading}
				errorMessage={this.props.errorMessage}
				doLogin={this.doLogin}
				onChange={this.onChange}
				history={this.props.history}
				{...this.state}
			/>
		);
	}
}

export default connect(state => ({
	isLoading: state.login.isLoading,
	email: state.login.email,
	loggedIn: state.login.loggedIn,
	errorMessage: state.login.error
}))(LoginContainer);
