import React from 'react';
import toast from 'react-hot-toast';
import { VscWarning } from 'react-icons/vsc';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { RiCloseFill } from 'react-icons/ri';

export const notifyError = (props) => toast.error(
  <div className="toastDiv">
    <div className="divTitle">
      <VscWarning className="icon" />
      <text>{props}</text>
    </div>
    <button className="closedButton" onClick={() => toast.dismiss()} >
      <RiCloseFill />
    </button>
  </div>
  , {
    style: {
      minWidth: '100%',
      width: '100%',
      minHeight: '70px',
      background: "#d85854",
      color: '#fff',
      margin: 0,
      borderRadius: 0,
    },
    icon: false,
  });

export const notifySuccess = (props) => toast.success(
  <div className="toastDiv">
    <div className="divTitle">
      <AiOutlineCheckCircle className="icon" />
      <text>{props}</text>
      <button className="closedButton" onClick={() => toast.dismiss()}>
      <RiCloseFill />
    </button>
    </div>
  </div>
  , {
    style: {
      minWidth: '100%',
      minHeight: '70px',
      background: "#58b48f",
      color: '#fff',
      margin: 0,
      borderRadius: 0,
    },
    icon: false
  });