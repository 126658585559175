import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap';
import { ListGroup, ListGroupItem } from 'reactstrap';
import moment from 'moment';
import { toDate } from './InspectionView';
import { getItem, deleteItem } from '../../core/database/firebaseFunctions';
import { NewModal } from '../../layoutV2/components/Modal/Modal';
import { DeleteButton } from '../../components/Button/Button';
import { useSelector } from 'react-redux';
import { getConfirmRead } from '../../core/database/confirmRead';
import sort from 'fast-sort';

const listEmails = (confirmed, alert) => {
    return alert.emails.map(email => {
        const hasConfirmed = confirmed.find(row => row.email === email && row.idItem === alert.id)
        return <div>
            <div className={`${hasConfirmed ? 'alert-text-confirmed' : 'alert-text'}`}>{email}</div>
        </div>
    })
}

const sendBagde = (date) => {
    var partesData = date.split("/");
    var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
    if (data > new Date()) {
        return
    } else {
        return <Badge color="danger" style={{ cursor: "default", height: "max-content", margin: "auto", marginLeft: 10 }}>Enviado</Badge>
    }

}

export const NextAlerts = ({ location, reload, setReload }) => {
    const [alerts, setAlerts] = useState([]);
    const [confirmed, setConfirmed] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const toggle = () => setIsOpenModal(!isOpenModal);
    const user = useSelector(state => state.login)

    useEffect(() => {
        loadAlert()
    }, [reload])

    const loadAlert = async () => {
        const alert = await getItem(`Alert`, { parentId: location.state.workId }).then((items) => {
            return items
        })
        sort(alert).desc([u => u.date]);
        const promises = alert.map(item => { return getConfirmRead({ idItem: item.id }) })
        const confirm = await Promise.all(promises).then(item => item)
        let allConfirm = []

        for (let j = 0; j < confirm.length; j++) {
            const data = confirm[j]
            for (let i = 0; i < data.length; i++) {
                allConfirm.push(data[i])
            }
        }
        setConfirmed(allConfirm)
        setAlerts(alert)
    }


    const remove = async (alert) => {
        await deleteItem(`Alert`, alert.id);
        setReload(!reload);
    }

    return (
        <div>
            {!user.groupData.isModel && <Button className="button-schedule-screen" style={{ minWidth: 163 }} onClick={() => setIsOpenModal(true)}>
                Agendamentos
            </Button>}
            <NewModal
                open={isOpenModal}
                toggle={toggle}
                title={"Alertas"}
                onClickCancel={toggle}
                className="alert-modal"
            >
                {/* <Modal isOpen={isOpenModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Alertas</ModalHeader>
                <ModalBody> */}
                <ListGroup className="alert-list">
                    {(!alerts || alerts.length === 0) && <span className="items-without">Não há itens para mostrar</span>}
                    {alerts && alerts.map(alert => {
                        let dateFormated = alert.date ? moment(toDate(alert.date)).format("DD/MM/YYYY") : ""
                        console.log({ alert })
                        return <div className="alert-item">
                            <div className="alert-header">
                                <div className="alert-header-details">
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div className="alert-title">{alert.Name}</div>
                                        {sendBagde(dateFormated)}
                                    </div>
                                    {listEmails(confirmed, alert)}
                                    {/* <div className={ 'alert-text'}>{alert.emails ? alert.email.join(', ') : ''}</div> */}
                                </div>
                                <div>
                                    {/* <a style={{ cursor: "pointer" }} onClick={() => remove(alert)}>Remover</a> */}
                                    <DeleteButton onClick={() => remove(alert)} />
                                </div>
                            </div>
                            <span className="alert-text">{dateFormated}</span>
                            {/* <span style={{ color: "#B0B0B0" }}>{alert.description}</span> */}
                            <div dangerouslySetInnerHTML={{ __html: alert.description }} className="alert-description" />

                        </div>
                    })}
                </ListGroup>
                {/* </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setIsOpenModal(false)}>Cancelar</Button>
                </ModalFooter>
            </Modal> */}
            </NewModal>
        </div>
    )
}