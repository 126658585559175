import React, { useEffect, useState } from "react";
import { NewModal } from "../../layoutV2/components/Modal/Modal";
import { SelectInput, TextInput } from "../../layoutV2/components/Form";
import { useSelector } from 'react-redux';
import { getWorks } from "../../core/database/works";
import sort from 'fast-sort';

export const ModalForum = ({ open, setOpen, onSave, title, onChange, values, setValues, openConfig, setOpenConfig }) => {
    const [users, setUsers] = useState([])
    const [works, setWorks] = useState([])
    const groups = useSelector(state => state.login.groups);
    const user = useSelector(state => state.login);

    const toggle = () => {
        setOpen(false)
        setValues({ search: '' })

    }

    useEffect(() => {
        loadWorks()
    }, [])

    useEffect(() => {
        loadUsers()
    }, [values && values.work])

    const loadUsers = async () => {
        if (values && values.work) {
            const work = works.find(work => work.id === values.work.id)
            if (work && work.users && work.users.length > 0) {
                setUsers(work.users.filter(item => item.value !== user.email))
            }

        }
    }

    const loadWorks = async () => {
        let works = await getWorks({ filter: {}, groups, isAdmin: user.isAdmin, email: user.email })
        sort(works).asc(u => u.name)
        setWorks(works)
    }

    const onSaveData = () => {
        const work = works.find(work => work.id === values.work.id)
        onSave({ work })
    }

    return (
        <>
            <NewModal
                open={open}
                toggle={toggle}
                onClickConfirm={onSaveData}
                title={title}
            >
                <SelectInput
                    options={works.map(item => ({ ...item, value: item.id, label: item.name }))}
                    title="Projetos"
                    labelledBy={"Projetos"}
                    value={values && values.work}
                    onChange={value => onChange("work", value)}
                    isSingle={true}
                />
                <SelectInput
                    options={users}
                    title="Usuários"
                    labelledBy={"Usuários"}
                    value={values && values.user}
                    onChange={value => onChange("user", value[0])}
                    isDisabled={!values.work ? true : false}
                />
            </NewModal>
        </>
    )
}

