import React, { useEffect, useMemo } from "react";
import MaterialTable, { MTableAction, MTableBodyRow, MTableEditRow, MTableHeader } from "material-table";
import I18n from "core/I18n/I18n";
import keyEnum from "core/I18n/KeyEnum";
import { Button, Row, Col } from 'reactstrap';
import { ConfirmButton, CancelButton, DeleteButton, EditButton } from "../Button/Button";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { useState } from "react";
import { MaterialFilter } from "../../layoutV2/components/MaterialFilter";
import sort from 'fast-sort';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { setLogin, setLoginData } from "../../core/redux-store/login/Actions";
let action;

const render = ({ field, type }) => {
	return rowData => {
		return rowData && (
			<Row className="row-user">
				<Col className="details truncated">{rowData[field]}</Col>
			</Row>
		)
	}
}

const getFormattedData = (filter, data, columns) => {
	// console.log("formatted", data)
	// console.log({ filter })
	const newData = data && data.filter(item => {
		const keys = Object.keys(filter);
		let isValid = true;

		const defaultColumns = columns.filter(item => item.defaultSearch);

		// console.log({ keys, columns })
		keys.map(field => {
			if (isValid) {//duas verificações em seguida
				if (isValid) {// troca e verifica
					// console.log({ field })
					const column = columns.find(item => item.field === field) || {};
					const { startDate, endDate } = filter[field] || {}

					if (column.customSearch) {
						isValid = column.customSearch(item, field, filter[field])
					} else if (startDate && !endDate) {
						const date = moment(`${item[field]}`, column.mask);
						const filterStart = moment(`${startDate}`, column.mask);

						isValid = filterStart.isSameOrBefore(date);
					} else if (!startDate && endDate) {
						const date = moment(`${item[field]}`, column.mask);
						const filterEnd = moment(`${endDate}`, column.mask);

						isValid = filterEnd.isSameOrAfter(date);
					} else if (startDate && endDate && startDate !== undefined && endDate !== undefined) {
						// const date = moment(`${item[field]}`, column.mask);
						const filterStart = moment(`${startDate}`, column.mask || "DD-MM-YYYY");
						const filterEnd = moment(`${endDate}`, column.mask || "DD-MM-YYYY");

						const criteriaDates = () => moment(item.Start, column.mask).diff(filterStart, 'days') >= 0 &&
							moment(item.Finish, column.mask).diff(filterEnd, 'days') <= 0

						const criteriaEnd = () => moment(item.Finish, column.mask).diff(filterStart, 'days') >= 0 &&
							moment(item.Finish, column.mask).diff(filterEnd, 'days') <= 0

						const criteriaStart = () => moment(item.Start, column.mask).diff(filterStart, 'days') >= 0 &&
							moment(item.Start, column.mask).diff(filterEnd, 'days') <= 0

						isValid = criteriaEnd() || criteriaStart() || criteriaDates();

						// console.log(`${date.toString()} | ${filterStart.toString()} | ${filterEnd.toString()}`)

						// isValid = filterEnd.isSameOrAfter(date) && filterStart.isSameOrBefore(date);
						// console.log({ isValid })
					} else if (field === "term") {
						defaultColumns.map(({ field }) => {
							if (isValid) {
								isValid = !filter.term || `${item[field]}`.toLowerCase().includes(`${filter.term}`.toLowerCase())
							}
						})
					} else {
						isValid = !filter[field] || filter[field].length === 0 || startDate === undefined || endDate === undefined || (Array.isArray(filter[field]) && filter[field].find(a => a.value === item[field]))
					}
				}

			}
			return field;
		})

		return isValid;
	})
	return newData
}

export const GenericMaterialTable = (props) => {
	const {
		canAdd = true,
		filterComponent, onAddClick, onRowAdd, onRowUpdate, onRowDelete,
		onFilter, ref, options, editableRow, cancelEditing, components,
		filtering, columns, data, rightComponent, headerAdd, titleList, marginZero, wordWrap, leftComponent, idTable, moreModalFields,
	} = props;

	const dispatch = useDispatch()
	const [filter, setFilter] = useState({});
	const [pageSize, setPageSize] = useState(parseInt(localStorage.getItem('ike/pageSize')) || 10)
	const [dataRedux, setDataRedux] = useState([]);
	// const dataRedux = useSelector(state => state.login[titleList] || [])
	// console.log({ expanded })
	const [expandedStates, setExpandedStates] = useState({})
	const filterList = useSelector(state => state.login[idTable])

	const filterOptions = {};

	// console.log({ idTable })
	useEffect(() => {
		// console.log({ filter, onFilter })
		if (onFilter) {
			onFilter(filter)
		}
	}, [filter, onFilter])

	// useEffect(() => {
	// 	//dispatch(setLoginData({ network: undefined, groupData: {} }))
	// 	if (idTable) {
	// 		dispatch(setLoginData({ [idTable]: filter }))
	// 	}
	// }, [filter])

	// const onChangeFilter = (field, value) => {
	// 	console.log({ field, value })
	// 	setFilter(selected => ({ ...selected, [field]: value }))
	// 	if (idTable) {
	// 		dispatch(setLoginData({ [idTable]: filter }))
	// 	}
	// }

	const onChangeFilter = (field, value) => {
		setFilter(selected => {
			const newFilter = { ...selected, [field]: value };
			if (idTable) {
				dispatch(setLoginData({ [idTable]: newFilter }))
			}
			return newFilter
		})
	}

	useEffect(() => {
		if (filterList && ((Object.keys(filter).length === 0 && filter.constructor === Object) || idTable === "inspections-list" || idTable === "quality-list")) {
			setFilter(filterList)
		}
	}, [JSON.stringify(filterList), idTable])

	// useEffect(() => {
	// 	const newData = data.map(item => ({ ...item, tableData: dataRedux.find(row => row.id === item.id) && dataRedux.find(row => row.id === item.id).tableData ? dataRedux.find(row => row.id === item.id).tableData : item.tableData }))
	// 	console.log({ data, dataRedux, newData })
	// 	dispatch(setLoginData({ [titleList]: newData || [] }))
	// }, [data])

	columns.filter(item => item.filtered).map(itemField => {
		if (itemField.options) {
			filterOptions[itemField.field] = itemField.options || [];
		} else if (itemField.type === "multiple") {
			const values = data.map(item => item[itemField.field]).flat();
			const descriptions = [...new Set(values.map(item => item.label ? item.label : item))]
			sort(descriptions).asc()
			filterOptions[itemField.field] = descriptions.map(description => values.find(item => item.label === description));
		} else {
			const values = data.map(item => item[itemField.field]).filter(item => item);
			sort(values).asc()
			filterOptions[itemField.field] = [...new Set(values)].map(value => ({ label: value, value }))
		}
		return itemField;
	})

	// console.log(columns.map(field => ({
	// 	...field,
	// 	render: field.render ? field.render : render(field)
	// })))

	// const saveExpanded = (value) => {
	// const newData = dataRedux.find(item => item.id === value.id)
	// console.log({ data: dataRedux.map(item => item.id === value.id ? value : item) })
	// 	dispatch(setLoginData({ [titleList]: dataRedux.map(item => item.id === value.id ? value : item) }))
	// }

	// console.log({ dataList: data.map(row => ({ ...row, tableData: { isTreeExpanded: expandedStates[row.id] } })), expandedStates, test: expandedStates["oq4jdYBJeSKDDmmpGQ3T"] })

	return (
		<div className={`custom-table ${marginZero && "marginZero"} ${wordWrap && "wordWrap"}`}>
			<MuiThemeProvider theme={theme}>
				{/* <div className="custom-table-toolbar" style={{ marginBottom: 20 }}>
					{filterComponent && filterComponent()}
					{canAdd && (onAddClick || onRowAdd) && (
						<>
							<Button className="button-add" outline color="primary" onClick={(e) => onAddClick ? onAddClick() : action.onClick(e, {})}>{isSchedule ? "Importar" : "Novo"}</Button>
							<Button className="button-add-fixed btn-round btn-icon" color="primary" onClick={(e) => onAddClick ? onAddClick() : action.onClick(e, {})}>
								<i className="nc-icon nc-simple-add" />
							</Button>
						</>
					)}
				</div> */}
				<MaterialFilter
					filter={filter}
					onFilter={onChangeFilter}
					fields={columns.filter(item => item.filtered || item.moreFiltered)}
					options={filterOptions}
					rightComponent={rightComponent}
					onAddClick={(e) => onAddClick ? onAddClick() : action.onClick(e, {})}
					headerAdd={headerAdd}
					leftComponent={leftComponent}
					moreModalFields={moreModalFields}
				/>
				<MaterialTable
					editable={{
						onRowAdd: onRowAdd,
						onRowUpdate: onRowUpdate,
						onRowDelete: onRowDelete
					}}
					onChangeRowsPerPage={(value) => setPageSize(value)}
					localization={I18n[keyEnum.tableTranslate]}
					{...props}
					data={getFormattedData(filter, data, columns)} // data.map(row => ({ ...row, tableData: { isTreeExpanded: expandedStates[row.id] } }))
					columns={columns.map(field => ({
						...field,
						render: field.render ? field.render : render(field)
					}))}
					// onTreeExpandChange={(value) => {
					// 	console.log({ value })
					// }}
					// onTreeExpandChange={(row, state) => setExpandedStates({ ...expandedStates, [row.id]: state })}
					options={{
						filtering,
						actionsColumnIndex: -1,
						paging: false,
						...options,
						pageSize: pageSize,
						addRowPosition: 'first',
						// rowStyle: rowData => ({
						//     backgroundColor: (selectedRow.includes(rowData.id)) ? '#2A3F92' : '#FFF',
						//     color: (selectedRow.includes(rowData.id)) ? '#FFF' : '#2A3F92'
						// })
					}}
					components={{
						Toolbar: (item) => {
							action = item.actions.filter(item => item.tooltip === "Adicionar")[0];
							if (!props.showToolbar) {
								return <div className="custom-table-toolbar"></div>
							} else {
								return (
									<></>
								)
							}
						},
						Header: () => <div />,
						EditRow: (item) => {
							const newColumns = [];
							item.columns.map(column => {
								if (column.editable) {
									newColumns.push({
										...column,
										title: column.placeholder ? column.placeholder : column.title
									})
								}
							})

							const editProps = {
								...item,
								columns: newColumns
							}

							return editableRow ? editableRow(editProps) : <MTableEditRow {...editProps} />;
						},
						Action: item => {

							const { data } = item;
							const action = item && typeof item.action === "function" ? item.action(data) : item.action;

							if (action) {
								if (action.isFreeAction && action.icon.name === "Add") {
									return (
										<>
											<Button className="button-add" outline color="primary" onClick={(e) => onAddClick ? onAddClick() : action.onClick(e, data)}>Novo</Button>
											<Button className="button-add-fixed btn-round btn-icon" color="primary" onClick={(e) => onAddClick ? onAddClick() : action.onClick(e, data)}>
												<i className="nc-icon nc-simple-add" />
											</Button>
										</>
									)
								} else if (data) {
									if (action.icon && action.icon.name === "Delete") {
										return <DeleteButton onClick={(e) => action.onClick(e, data)} />;
									} else if (action.icon && action.icon.name === "Edit") {
										return <EditButton onClick={(e) => action.onClick(e, data)} />;
									} else if (action.icon && action.icon.name === "Check") {
										return <ConfirmButton onClick={(e) => {
											cancelEditing && cancelEditing();
											action.onClick(e, data);
										}} />;
									} else if (action.icon && action.icon.name === "Clear") {
										return <CancelButton onClick={(e) => {
											cancelEditing && cancelEditing();
											action.onClick(e, data);
										}} />;
									}
								}
							}
							return <MTableAction {...item} />;
						},
						...components
					}}
				/>
			</MuiThemeProvider>
		</div>
	);
}

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#2A3F92",
		},
		secondary: {
			main: "#B6C2D6",
		},
	},
	overrides: {
		MuiPaper: {
			root: {
				boxShadow: "none !important",
				elevation: 0
			}
		},
		MuiToolbar: {
			root: {
				backgroundColor: "transparent !important"
			}
		},
		MuiTableSortLabel: {
			root: {
				fontFamily: 'Manrope',
				fontStyle: 'normal',
				fontWeight: '600',
				fontSize: '14px',
				lineHeight: '19px',
				color: ' #404040 !important'
			}
		},
		MuiTable: {
			root: {
				borderCollapse: 'separate',
				borderSpacing: '0px 15px',
				paddingLeft: '30px',
				paddingRight: '30px'
			}
		},
		MuiTableRow: {
			root: {
				height: 'initial',
				display: 'flow-root',
				background: '#FFFFFF',
				boxShadow: '0px 12px 20px rgba(31, 77, 155, 0.07)',
				borderRadius: '8px',
				marginBottom: '10px',
				paddingLeft: '15px',
				paddingRight: '15px',
				borderBottomWidth: '0px !important'
			},
			head: {
				boxShadow: 'none',
			}
		},
		MuiIcon: {
			root: {
				color: `${"#677DA3"} !important`,
				fontWeight: "bold"
			}
		},
		MuiInputBase: {
			root: {
				backgroundColor: "white",
				borderRadius: 5,
				// border: `1px solid ${"#2A3F92"}`,
				paddingLeft: 10,
				height: 40,
			}
		},
		MuiInput: {
			underline: {
				'&:before': {
					borderBottom: `1px solid ${"#2A3F92"}`,
					// content: ""
				},
				'&:hover:before': {
					borderBottom: `1px solid ${"#2A3F92"} !important`,
					// content: ""
				},
				'&:after': {
					transform: 'scaleX(0) !important',
				}
			},
		},
		MuiTablePagination: {
			root: {
				border: '0px solid transparent'
			}
		},
		MuiTableCell: {
			head: {
				padding: 10,
			},
			body: {
				padding: 8,
				color: 'inherit',
				fontFamily: 'Manrope',
				fontWeight: 'bold',
				fontSize: '16px'
			},
			alignLeft: {
				padding: 4,
				width: "100%"
			},
			root: {
				// background:'#FFFFFF',
				// boxShadow: '0px 12px 20px rgba(31, 77, 155, 0.07)',
				// borderRadius:'8px',
				borderBottom: '0px'
			}
		},
		MuiCheckbox: {
			root: {
				color: '#B6C2D6'
			}
		},
		MuiIconButton: {
			colorInherit: {
				backgroundColor: 'transparent !important'
			},
			root: {
				height: 42,
				width: 42,
				marginRight: 10,
				'&:hover': {
					color: "transparent !important"
				},
				'&:focus': {
					outline: "none"
				}
			},
		},
		MuiFormControl: {
			root: {
				width: '100%'
			}
		}
	}
});

export default GenericMaterialTable;
