import { actions } from "./Actions";
import { failure, begin, success } from "core/utils/redux/Reducers";

export const initialState = {
	isLoading: true,
	error: null,
	items: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actions.GET_EVALUATION_SUCCESS:
			state = success(state, { ...action.payload });
			break;
		case actions.GET_EVALUATION_BEGIN:
			state = begin(state);
			break;
		case actions.GET_EVALUATION_ERROR:
			state = failure(state, action.payload.error);
			break;
		case actions.ADD_EVALUATION_SUCCESS:
			state = success(state, { ...action.payload });
			break;
		case actions.ADD_EVALUATION_BEGIN:
			state = begin(state);
			break;
		case actions.ADD_EVALUATION_ERROR:
			state = failure(state, action.payload.error);
			break;
			case actions.DELETE_EVALUATION_SUCCESS:
			state = success(state, { ...action.payload });
			break;
		case actions.DELETE_EVALUATION_BEGIN:
			state = begin(state);
			break;
		case actions.DELETE_EVALUATION_ERROR:
			state = failure(state, action.payload.error);
			break;
			case actions.UPDATE_EVALUATION_SUCCESS:
			state = success(state, { ...action.payload });
			break;
		case actions.UPDATE_EVALUATION_BEGIN:
			state = begin(state);
			break;
		case actions.UPDATE_EVALUATION_ERROR:
			state = failure(state, action.payload.error);
			break;
		default:
			break;
	}
	return state;
}