import React from 'react';
import { IconButton } from '@material-ui/core';
import { Button } from 'reactstrap';
import { Close, Check, DeleteOutlined } from '@material-ui/icons';

class DeleteLabel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openConfirm: false
        }
    }

    setOpenConfirm(openConfirm) {
        this.setState({ openConfirm })
    }

    render() {
        const { openConfirm } = this.state;

        if(!openConfirm){
            return (
                <div className="delete-controls">
                    <Button color="danger" onClick={() => this.setOpenConfirm(true)}>
                        <DeleteOutlined />
                    </Button>
                </div>
            )
        }

        return (
            <div className="confirm-delete">
                <h4>{this.props.label || "Deseja mesmo remover esse item?"}</h4>
                <div>
                    <IconButton onClick={this.props.removeEvent}>
                        <Check fontSize="small" />
                    </IconButton>
                    <IconButton onClick={() => this.setOpenConfirm(false)}>
                        <Close fontSize="small" />
                    </IconButton>
                </div>
		    </div>
        )
    }
}

export default DeleteLabel;