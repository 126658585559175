import React, { Component } from 'react';
import { connect } from 'react-redux';
import WorksToolbarView from '../../views/works/WorksToolbarView';
import { withRouter } from 'react-router-dom';

function mapStateToProps(state) {
    return {
        user: state.login,
        allGroups: state.group.items
    };
}

class WorksToolbarContainer extends Component {

    constructor(props) {
        super(props);
        this.onCompanyClick = this.onCompanyClick.bind(this);
    }

    onCompanyClick() {
        const { history } = this.props;
		history.push("/admin/company-details");
    }

    render() {
        return (
            <WorksToolbarView
                onFilter={this.props.onFilter}
                onCompanyClick={this.onCompanyClick}
                user={this.props.user}
                allGroups={this.props.allGroups}
                adminWorkGroup={this.props.adminWorkGroup}
            />
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
)(WorksToolbarContainer));