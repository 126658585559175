import React from 'react';
import { AdvDetails } from '../../components/AdvDetails/AdvDetails';
import AdminNavbar from '../../components/Navbars/AdminNavbar';
import { BreadcrumbHeader } from '../../components/Breadcrumb/Breadcrumb';
import { Row } from 'reactstrap';

const data = [
    {
        name: "Início",
        active: true,
        route: "/admin/dashboard"
    },
    {
        name: "Configurações",
        active: true,
        route: "/admin/settings"
    },
    {
        name: "Anúncios"
    }
]

export const AdvDetailsView = (props) => {
    return (
        <div className="content">
            {/* <AdminNavbar>
                <BreadcrumbHeader data={data} />
            </AdminNavbar> */}
            <AdminNavbar goBack={props.onBack} />
            <Row style={{ paddingTop: 30 }}>
                <AdvDetails
                    {...props}
                />
            </Row>
        </div>
    );
};