import ActionCreator from 'core/utils/redux/ActionCreator';
import { getProjectLib, getProjectLibs, addProjectLib, updateProjectLib, deleteProjectLib } from "../../database/projectLib";

const { actions, defaultReducer, get, add, remove, update, getSimple } = new ActionCreator("projectLib");
export { actions, defaultReducer };

export const getProjectLibByIdData = getSimple(getProjectLib);
// export const getLibTagsData = getSimple(getLibTags);
export const getProjectLibData = get(getProjectLibs);
export const addProjectLibData = add(addProjectLib);
export const updateProjectLibData = update(updateProjectLib);
export const deleteProjectLibData = remove(deleteProjectLib);
