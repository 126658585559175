import React, { useState, useEffect } from "react";
import sort from 'fast-sort';
import { Card } from "reactstrap";
import { Col } from "reactstrap";
import { NivoBarChart } from "../Charts/NivoBarChart";
import { useTheme } from '@nivo/core'

const StepTick = tick => {
    const theme = useTheme()

    return (
        <g transform={`translate(${tick.x},${tick.y + 22})`}>
            <rect x={tick.xNumber} y={-6} rx={3} ry={3} width={tick.widthNumber} height={24} fill="rgba(0, 0, 0, .05)" />
            <rect x={tick.xNumber} y={-12} rx={2} ry={2} width={tick.widthNumber} height={24} fill="rgb(232, 193, 160)" />
            <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} />
            <text
                textAnchor="middle"
                dominantBaseline="middle"
                style={{
                    ...theme.axis.ticks.text,
                    fill: '#333',
                    fontSize: 10,
                }}
            >
                {`${tick.value}`.split(" ")[0]}{tick.percent ? ` (${tick.percent}%)` : ``}
            </text>
        </g>
    )
}

const allCount = (data, inNumber = false) => {
    let count = 0;
    data.map(row => {
        count = row.allCount + count //asbuilt + row.melhoria + row.normal + count
    })
    return inNumber ? count : `${count} ${count > 1 ? "apontamentos" : "apontamento"}`
}

const getPercent = (value, data) => {
    const step = data.find(row => row.step === value.value)
    const totalValue = step ? step.allCount : 0
    return ((totalValue / allCount(data, true)) * 100).toFixed(0)
}

const StepsCard = ({ data, keyField = "subject", title, lg = "8", md = "12", xNumber, widthNumber }) => {
    const [dataFormatted, setDataFormatted] = useState([])

    const formatData = async () => {
        let stepFilter = [...new Set(data.filter(item => item[keyField]).map(item => item[keyField].id))];
        let steps = stepFilter.map(idStep => data.find(item => item[keyField] ? idStep === item[keyField].id : false)[keyField])
        sort(steps).asc(step => step.name);

        const dataFormatted = steps.map(step => {
            const asbuilt = data.filter(item => item[keyField] && item[keyField].id === step.id && item.signal && Array.isArray(item.signal) && item.signal.filter(number => number === 2).length > 0).length;
            const melhoria_custo = data.filter(item => item[keyField] && item[keyField].id === step.id && item.signal && ((Array.isArray(item.signal) && item.signal.filter(number => number === 1).length > 0 && item.typeImprovement && item.typeImprovement.filter(number => number === 1).length > 0) || (Array.isArray(item.signal) && item.signal.filter(number => number === 3).length > 0))).length;
            const melhoria_prazo = data.filter(item => item[keyField] && item[keyField].id === step.id && item.signal && ((Array.isArray(item.signal) && item.signal.filter(number => number === 1).length > 0 && item.typeImprovement && item.typeImprovement.filter(number => number === 2).length > 0) || (Array.isArray(item.signal) && item.signal.filter(number => number === 4).length > 0))).length;
            const normal = data.filter(item => item[keyField] && item[keyField].id === step.id).length - data.filter(item => item[keyField] && item[keyField].id === step.id && item.signal && item.signal.length > 0).length //asbuilt - melhoria
            const melhoria = data.filter(item => item[keyField] && item[keyField].id === step.id && item.signal && Array.isArray(item.signal) && item.signal.filter(number => number === 1).length > 0).length;
            return {
                step: step.name,
                melhoria: melhoria > 0 ? melhoria : 0,
                // melhoria_custo,
                // melhoria_prazo,
                // melhoriaColor: "hsl(81, 70%, 50%)",
                asbuilt,
                // asbuiltColor: "hsl(117, 70%, 50%)",
                normal: normal > 0 ? normal : 0,
                allCount: data.filter(item => item[keyField] && item[keyField].id === step.id).length
                // totalColor: "hsl(38, 70%, 50%)",
            }
        })
        setDataFormatted(dataFormatted);
    }

    useEffect(() => {
        formatData()
    }, [data])

    if (dataFormatted && dataFormatted.length === 0) {
        return <div />
    }

    const Subtitle = dataFormatted.filter(item => item && item.step).map(item => <div style={{ display: 'flex', alignItems: 'center', margin: 5 }}> <div style={{ width: 10, height: 10, borderRadius: 100, backgroundColor: '#eac4a4', marginBottom: 3 }} /> <text style={{ marginInline: 5 }}> {item.step}</text></div>)

    return (
        <Col lg={lg} md={md} style={{ marginTop: 10 }}>
            <Card style={{ flex: 1, padding: 20, width: "100%", height: 500 }} className='recurrency-card'>
                <div style={{ display: "flex", fontWeight: "bold", fontSize: 20, justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
                    {`${title} - ${allCount(dataFormatted)}`}
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {Subtitle}
                </div>
                <NivoBarChart
                    data={dataFormatted}
                    keys={['melhoria', 'melhoria_prazo', 'melhoria_custo', 'asbuilt', 'normal']}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                        renderTick: (value) => <StepTick {...value} widthNumber={widthNumber} xNumber={xNumber} percent={getPercent(value, dataFormatted)} />
                    }}
                    // label={d => {
                    //     const { data } = d;
                    //     const totalValue = data.asbuilt + data.melhoria + data.normal;
                    //     console.log({ data, totalValue })
                    //     return `${d.value}`
                    // }}
                    legends={[
                        {
                            dataFrom: "keys",
                            anchor: "bottom",
                            direction: "row",
                            translateY: 65,
                            translateX: 30,
                            itemWidth: 120,
                            itemHeight: 20,
                            itemDirection: "left-to-right",
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </Card>
        </Col>
    )
}

export default StepsCard