import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Toast } from 'reactstrap';
// import { generateUniqueId } from '../../core/utils/Utils';
// import Alert from '../../components/Alert/Alert';
// import { updateItem, addItem, getItem } from '../../core/database/firebaseFunctions';
// import FileGalleryContainer from '../../container/FileGalleryContainer';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getInspectionModelData } from '../../core/redux-store/inspectionModel/Actions';
import { normalizeCombo } from '../../core/utils/Utils';
import { addActivity, deleteActivity, getActivity, updateActivity } from '../../core/database/activity';
import Alert from '../../components/Alert/Alert';
import sort from 'fast-sort'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { NewModal } from '../../layoutV2/components/Modal/Modal';
import { SelectInput } from '../../layoutV2/components/Form';
import Checkbox from 'rc-checkbox';
import ModalAlert from '../../layoutV2/components/Modal/ModalAlert';
import { SettingsButton } from '../../components/Button/Button';

// const Question = ({ deleteItem, item }) => {
//     const { id, name } = item;
//     return (
//         <div style={{ display: "flex", alignItems: "center" }}>
//             <span style={{ display: "flex" }} className='details-description'>
//                 <span style={{ fontSize: 18, marginRight: 5 }} class="material-icons">
//                     check_box
//                     </span>
//                 {name}
//             </span>
//             {/* <Button style={{ marginLeft: 10, height: 24, width: 24, minWidth: 24 }}
//                 onClick={() => updateData(id)}
//                 className="btn-icon"
//                 color="warning"
//                 size="sm"
//                 type="button"
//             >
//                 <span style={{ display: "flex", justifyContent: "center", fontSize: 20 }} class="material-icons">edit</span>
//             </Button> */}
//             <Button style={{ marginLeft: 10, height: 24, width: 24, minWidth: 24 }}
//                 onClick={() => deleteItem(id)}
//                 className="btn-icon"
//                 color="primary"
//                 size="sm"
//                 type="button"
//             >
//                 <span style={{ display: "flex", justifyContent: "center", fontSize: 20 }} class="material-icons">delete</span>
//             </Button>{" "}
//         </div>
//     )
// }

// const hasValue = item => item.name;

const allData = [

]

export const QualityConfiguration = (props) => {
    const { UID, Finish, Name, OutlineNumber, Start, tableData, status, state, history } = props
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [model, setModel] = useState();
    const items = useSelector(state => state.inspectionModel.items)
    const network = useSelector(state => state.login.network)
    const [activity, setActivity] = useState();
    const [selected, setSelected] = useState([{ UID, Finish, Name, OutlineNumber, Start, tableData, status, replaceTemplate: true }])
    const [similarItems, setSimilarItems] = useState([])
    sort(items).asc(u => u.name)

    const getAllChildrens = (row) => {
        // console.log({ row })
        let all = [];
        if (row.childRows && row.childRows !== null && row.childRows.length > 0) {
            row.childRows.filter(item => item && !item.model).map(newRow => {
                all.push(newRow);
                // console.log({ newRow })
                if (newRow.tableData.childRows && newRow.tableData.childRows !== null && newRow.tableData.childRows.length > 0) {
                    all = all.concat(getAllChildrens(newRow.tableData))
                }
            })
        }
        return all;
    }

    const loadChilds = () => {
        // setAllChildrens(allChildrens) && allChildrens.filter(children => children.UID === item.UID).length === 0
        const childrensItemOpen = getAllChildrens(props.tableData)
        // console.log({ childrensItemOpen })
        const similar = props.allData.filter(item => (`${item.Name.toLowerCase()}`.startsWith(props.Name.toLowerCase()) || `${item.Name.toLowerCase()}`.endsWith(props.Name.toLowerCase())) && item.UID !== props.UID)
            .filter(item => !childrensItemOpen.find(a => a.UID === item.UID))
        // console.log({ similar })
        setSimilarItems(similar)
    }

    const saveSelected = async ({ UID, status, replaceTemplate = false }) => {
        if (replaceTemplate) {
            // console.log({ UID, status, replaceTemplate, activity })
            if (activity.length > 0) {
                await updateActivity({ ...activity[0], model })
            } else {
                await addActivity({ UID, status: status || 1, workId: state.workId, companyId: state.company.id, model: model })
            }
        } else {
            // console.log({ UID, status })
            await addActivity({ UID, status: status || 1, workId: state.workId, companyId: state.company.id, model: model })
        }
    }

    const save = async () => {
        // console.log({ model, UID, state })
        if (model && model.length > 0 && UID && state && state.workId && state.company && state.company.id) {

            const childrens = []
            for (let i = 0; i < selected.length; i++) {
                childrens.push(getAllChildrens(selected[i].tableData))
            }
            const formattedChildrens = childrens.reduce((list, sub) => list.concat(sub), [])
            const itemsSave = selected.concat(formattedChildrens)
            for (let i = 0; i < itemsSave.length; i++) {
                await saveSelected(itemsSave[i])
            }
            Alert.success("Salvo com sucesso!")
            toggle()
            props.setReload(!props.reload)
        } else {
            Alert.error("Não foi possível salvar este item! Tente novamente mais tarde.")
        }
    }

    const deleteButton = async () => {
        /* confirmAlert({
            title: 'Deseja remover esta configuração?',
            message: 'Ao remover a configuração da atividade, o progresso atual será perdido, deseja mesmo remover?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: deleteQualityInspection
                },
                {
                    label: 'Não'
                }
            ]
        }); */
        ModalAlert.danger({
            title: "Deseja remover esta configuração?",
            text: "Ao remover a configuração da atividade, o progresso atual será perdido, deseja mesmo remover?",
            onConfirm: deleteQualityInspection
        })
    }

    const deleteQualityInspection = async () => {
        // console.log({ props })
        try {
            await deleteActivity(props.id)
            Alert.success("Removido com sucesso!")
            toggle()
            history.goBack()
            props.setReload(!props.reload)
        } catch (e) {
            console.log({ e })
        }
        /* if (model && model.value && UID && state && state.workId && state.company && state.company.id) {
            const childrens = []
            for (let i = 0; i < selected.length; i++) {
                childrens.push(getAllChildrens(selected[i].tableData))
            }
            const formattedChildrens = childrens.reduce((list, sub) => list.concat(sub), [])
            const itemsSave = selected.concat(formattedChildrens)
            // console.log({ itemsSave, formattedChildrens })
            for (let i = 0; i < itemsSave.length; i++) {
                // console.log({ itemSave: itemsSave[i] })
                await saveSelected(itemsSave[i])
            }
            Alert.success("Salvo com sucesso!")
            toggle()
            props.setReload(!props.reload)
        } else {
           Alert.error("Não foi possível salvar este item! Tente novamente mais tarde.")
        } */
    }

    // const deleteItem = (id) => {
    //     setAsks(asks.filter(item => item.id !== id));
    // }

    // const updateData = (item) => {
    //     setUpItem(item);
    // }

    // const getChecklist = async () => {
    //     const checklist = await getItem(`Checklist`, {
    //         workId, companyId, UID
    //     })

    //     setChecklist(checklist[0])
    // }

    // useEffect(() => {
    //     getChecklist();
    // }, [workId, companyId])

    // useEffect(() => {
    //     setAsks(checklist ? checklist.asks : []);
    // }, [checklist])

    // const addAsk = (values, type) => {
    //     if (values) {
    //         const names = values.split(";").map((name, index) => ({
    //             id: generateUniqueId(`Checklist-ask-${index}`),
    //             name,
    //             type
    //         })).filter(hasValue)

    //         setAsks(asks.concat(names));
    //         if (type === 1) {
    //             setQuestion("");
    //         } else if (type === 2) {
    //             setService("");
    //         }
    //     } else {
    //         Alert.error("Insira um item para o seu checklist")
    //     }
    // }

    useEffect(() => {
        dispatch(getInspectionModelData({ fields: { workGroup: network, workTypeId: state.workType }, standard: network !== "FvoPZCcXbTskLPIQjltC" && { workGroup: "FvoPZCcXbTskLPIQjltC" } }));
        loadActivity()
        loadChilds()
    }, [open])

    const loadActivity = async () => {
        // console.log({ props })
        const activity = await getActivity({ UID: props.UID, workId: props.state.workId });
        setActivity(activity)
        if (activity && activity[0] && activity[0].model) {
            // const model = normalizeCombo("id", "name", items).find(item => item.value === activity[0].model)
            setModel(activity[0].model)
        }
    }

    const toggle = () => {
        setOpen(!open)
    }

    const onChangeSelected = (value) => {
        const exists = selected.find(item => item.UID === value.UID)
        if (exists && exists.UID) {
            // const newSelected = selected.filter(item => item.UID !== value.UID)
            setSelected(selected => (selected.filter(item => item.UID !== value.UID)))
        } else {
            // const newSelected = selected.concat(value)
            setSelected(selected => (selected.concat(value)))
        }
    }

    return (
        <>
            <div className="btn-quality-settigns" style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
                {/* <a onClick={toggle} style={{ color: "#66615b", cursor: "pointer" }} class="material-icons">
                    miscellaneous_services
                </a> */}
                <SettingsButton onClick={toggle} className="blue" />
            </div>
            <NewModal
                open={open}
                toggle={toggle}
                onClickConfirm={save}
                onClick={() => setOpen(true)}
                title={"Configurações"}
                outlineLabel={"Remover"}
                onClickCancel={deleteButton}
                className="quality-modal"
            >
                {/* <Modal isOpen={open} toggle={toggle} style={{ maxWidth: '60%' }}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <Label className='details-title-modal'>Configurações</Label>
                <ModalBody className='details-wizard'> */}
                {/* <Select
                    noOptionsMessage={() => "Não há opções para mostrar"}
                    className="react-select dark-gray"
                    classNamePrefix="react-select"
                    placeholder="Modelo"
                    name="multipleSelect"
                    // closeMenuOnSelect={true}
                    options={normalizeCombo("id", "name", items)}
                    defaultValue={model}
                    onChange={data => setModel(data)}
                    isMulti
                /> */}
                <SelectInput
                    options={normalizeCombo("id", "name", items)}
                    title="Modelo"
                    labelledBy={"Modelo"}
                    value={model}
                    onChange={data => setModel(data)}
                    isSingle={false}
                />
                {similarItems.length > 0 ? <div style={{ marginTop: 10 }}>
                    <Label style={{ fontWeight: "bold" }}>Aplicar nos itens semelhantes</Label>
                    {similarItems.map(item => {
                        return (
                            // <div style={{ paddingRight: 20, paddingLeft: 20 }}>
                            //     <Input type="checkbox" onChange={() => onChangeSelected(item)} />
                            //     <div style={{ display: "flex" }}>
                            //         <Label style={{ flex: 0, paddingRight: 5 }}>{item.OutlineNumber}</Label><Label style={{ flex: 1 }}>{item.Name}</Label>
                            //     </div>
                            // </div> 
                            <p>
                                <label>
                                    <Checkbox
                                        checked={selected.find(row => row.UID === item.UID)}
                                        onChange={() => onChangeSelected(item)}
                                    />
                                    &nbsp; {item.OutlineNumber} {item.Name}
                                </label>
                                &nbsp;&nbsp;
                            </p>
                        )
                    })}
                </div> : <></>}
            </NewModal>
            {/* <Label className='details-title' style={{ display: "flex" }}>Criar critério para checklist de início de inspeção</Label>
                    <div style={{ display: "flex" }}>
                        <Input
                            placeholder="Insira uma ou mais perguntas separadas por ';'"
                            type="textarea"
                            value={question}
                            onChange={(event) => setQuestion(event.target.value)}
                            className='details-input-quality'
                        />
                        <Button className="btn-add-quality" onClick={() => addAsk(question, 1)}>
                            Adicionar
                        </Button>
                    </div>
                    <Label className='details-title' style={{ display: "flex" }}>Critérios do checklist</Label>
                    {asks.filter(item => item.type === 1).map(item => <Question deleteItem={deleteItem} key={item.id} item={item} />)}
                    <Label className='details-title' style={{ display: "flex" }}>Criar critério para o checklist de serviço</Label>
                    <div style={{ display: "flex" }}>
                        <Input
                            placeholder="Insira uma ou mais perguntas separadas por ';'"
                            type="textarea"
                            value={service}
                            onChange={(event) => setService(event.target.value)}
                            className='details-input-quality'
                        />
                        <Button className="btn-add-quality" onClick={() => addAsk(service, 2)}>
                            Adicionar
                        </Button>
                    </div>
                    <Label className='details-title' style={{ display: "flex" }}>Critérios do checklist</Label>
                    {asks.filter(item => item.type === 2).map(item => <Question deleteItem={deleteItem} key={item.id} item={item} />)} */}
            {/* </ModalBody>
                <ModalFooter>
                    <Button style={{ marginRight: 24 }} className="button-save-company" outline color="danger" onClick={deleteButton}>
                        {"Remover"}
                    </Button>
                    <Button style={{ marginRight: 24 }} className="button-save-company" outline color="success" onClick={save}>
                        {"Salvar"}
                    </Button>
                </ModalFooter> */}
            {/* {checklist && checklist.id ? <div style={{ paddingRight: 30, paddingLeft: 30, paddingBottom: 30 }}>
                    <Label className='details-title' style={{ display: "flex" }}>Anexos</Label>
                    <div className="category editable">
                        <FileGalleryContainer
                            useCrop={true}
                            dataReference={`ChecklistImages/${workId}-${checklist.id}/images`}
                            canSelect={true}
                            isMultipleSelect={true}
                        />
                    </div>
                </div> : <div />} */}
            {/* </Modal> */}
        </>
    );
};