import React, { useMemo, useEffect } from 'react';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css'; // Include CSS
import { useState } from 'react';

const TagPicker = ({ value, comboOptions, placeholder, multiple, onFilter, hasSort = true, disabled = false }) => {
    const [values, setValues] = useState(value || [])

    useEffect(() => {
        setValues(value || [])
    }, [value])
    // console.log({values});

    if (comboOptions && hasSort) {
        comboOptions = comboOptions.sort((a, b) => {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
    }

    // console.log({values, comboOptions})
    return useMemo(() => {
        return (
            <Picky
                options={comboOptions}
                placeholder={placeholder}
                filterPlaceholder="Buscar"
                numberDisplayed="1"
                manySelectedPlaceholder="%s Selecionadas"
                allSelectedPlaceholder="%s Selecionadas"
                valueKey="value"
                labelKey="label"
                multiple={multiple}
                value={values}
                includeFilter={true}
                onChange={values => {
                    setValues(values)
                }}
                onClose={() => {
                    // console.log(values)
                    onFilter(values)
                }}
                dropdownHeight={200}
                includeSelectAll={true}
                selectAllText={"Selecionar todas"}
                disabled={disabled}
                buttonProps={disabled && {
                    style: {
                        backgroundColor: "#e3e3e3"
                    }
                }}
            />
        )
    }, [values, comboOptions, disabled])
}

export default TagPicker;