import { db, firebaseAuth, fireSQL } from './firebaseConfig';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import EntityEnum from './EntityEnum';
import { getItem, addItem, deleteItem, updateItem } from './firebaseFunctions';
import { useSelector } from 'react-redux';


export const getGroup = async ({ workGroup, email }) => {
    if (workGroup && workGroup.length > 0) {
        const arrWorkGroups = workGroup.map(item => `"${item}"`).filter(item => item)
        return await fireSQL.query(`
            SELECT * from Groups where id in (${arrWorkGroups.join(",")}) OR responsible = '${email}'
        `)
    }
    // return getItem("Groups", fields);
}

export const getAllGroup = async () => {
    return await fireSQL.query(`
        SELECT * from Groups where active = true
    `)
}

export const addGroup = async (item) => {
    return addItem("Groups", item);
}

export const deleteGroup = async (id) => {
    return deleteItem("Groups", id);
}

export const updateGroup = async (item) => {
    return updateItem("Groups", item);
}



/**
 * const users = db.collection(EntityEnum.user);
    const query = users.where('invite', '==', true);

    return query.get().then(snapshot => {
        if (snapshot.empty) {
            return;
        } else {
            snapshot.docs.forEach(document => {
                if (document.exists && document.id === id) {
                    return document;
                } else {
                    throw new Error(I18n[keyEnum.inviteExpired]);
                }
            });
            return;
        }
    })
 */