import React, { useEffect, useState } from 'react';
import { getCountRecurrencyCompany } from '../../core/database/inspections';
import { Row, Col, Alert, UncontrolledTooltip } from 'reactstrap';

export default function ProblemRecurrency({ idCompany, data, onRecurrencies }) {
    const [comments, setComments] = useState([]);

    useEffect(() => {
        getCountRecurrencyCompany(idCompany, data).then(items => {
            setComments(items);
            onRecurrencies(items);
        })
    }, [idCompany, data])

    return (comments && comments.length > 0) && (
        <>
            <h2 className="alert-task-title">Problemas recorrentes</h2>
            <Row style={{ marginBottom: 20 }}>
                {comments.map(comment => {
                    return (
                        <Col lg="4" md="6" sm="12">
                            <Alert id={comment.id} className="alert-task" color={"warning"}>
                                {`(${comment.count}/${comment.countCompany}) ${comment.name}`}
                            </Alert>
                            <UncontrolledTooltip placement="top" target={comment.id}>
                                {comment.name}
                            </UncontrolledTooltip>
                        </Col>
                    )
                })}
            </Row>
        </>
    );
}
