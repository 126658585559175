import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Row, Col, FormGroup, Input } from 'reactstrap';
import RichEditorQuill from "../components/RichEditor/RichEditorQuill";
import ListCommentContainer from "../container/ListCommentContainer"
import Select from 'react-select';
import { NewModal } from '../layoutV2/components/Modal/Modal';
import { SelectInput, TextInput } from '../layoutV2/components/Form';
import { AddButton, RemoveButton } from '../components/Button/Button';
import { SaveButton } from '../layoutV2/components/Button';
import ModalAlert from '../layoutV2/components/Modal/ModalAlert';

//<CommentModal isOpenModal={isOpenModal} toggle={() => setIsOpenModal(0)}>
export const CommentReport = ({ values, data, onChange, saveData, setIsOpenModal, isOpenModal, onSaveSelected, skills }) => {
    const [selected, setSelected] = useState(null)

    const onSelectedChange = (data) => {
        setSelected(data)
    }

    return (
        <>
            <div className="list-margin headerText hide-mobile">
                {`Selecione o que deseja fazer:`}
            </div>
            <SaveButton className="hide-mobile" onClick={() => setIsOpenModal(1)} label="Novo apontamento" />
            <SaveButton className="hide-mobile" onClick={() => setIsOpenModal(2)} label="Vincular apontamento existente" />
            {isOpenModal !== 0 && <NewModal
                open={isOpenModal}
                toggle={() => setIsOpenModal(0)}
                title={isOpenModal === 1 ? `Novo Apontamento` : isOpenModal === 2 ? `Vincular apontamento existente` : ``}
                className={`report-modal ${isOpenModal === 2 && "comment-report"}`}
                onClickConfirm={isOpenModal === 1 ? saveData : isOpenModal === 2 ? () => onSaveSelected(selected) : () => { }}
            >
                {isOpenModal === 1 ? <NewComment values={values} onChange={onChange} saveData={saveData} skills={skills} />
                    : isOpenModal === 2 ? <LinkComment onSelectedChange={onSelectedChange} data={data} /> : ""}</NewModal>}
        </>
    );
};

const CommentModal = ({ children, isOpenModal, toggle }) => {
    return (
        <Modal className="modal-comment" isOpen={isOpenModal} toggle={toggle} style={{ width: '90%', maxWidth: '960px' }}>
            <ModalHeader toggle={toggle}>{isOpenModal === 1 ? `Novo Apontamento` : isOpenModal === 2 ? `Vincular apontamento existente` : ``}</ModalHeader>
            <ModalBody style={{ height: "100%", paddingTop: 0, marginTop: 0 }}>
                <div className="overflow-body" style={{ maxHeight: "100%", paddingTop: 0, marginTop: 0 }}>
                    {children}
                </div>
            </ModalBody>
        </Modal>
    )
}

const NewComment = ({ values, onChange, saveData, skills }) => {

    const addSection = () => {
        if (values.section === 2) {
            ModalAlert.warn({
                title: "Número máximo de seções",
                text: "Você pode acrescentar no máximo 2 seções que equivalem ao tamanho de uma página!",
                hideConfirm: true
            })
        } else {
            onChange("section", values.section + 1)
        }
    }

    const removeSection = () => {
        if (values.section === 1) {
            ModalAlert.warn({
                title: "Número mínimo de seções",
                text: "Deve conter no mínimo 1 seção, para que possa ser apresentado na página!",
                hideConfirm: true
            })
        } else {
            onChange("section", values.section === 1 ? 1 : values.section - 1)
        }
    }

    return (
        <>
            <Row>
                <Col sm="12">
                    {/* <FormGroup>
                        <Input placeholder="Nome" type="text" defaultValue={values.name} onChange={(event) => onChange("name", event.target.value)} />
                    </FormGroup> */}
                    <TextInput
                        id="comment-name"
                        placeholder="Nome"
                        type="text"
                        value={values.name}
                        onChange={(event) => onChange("name", event.target.value)}
                    />
                </Col>
            </Row>
            <Row className="comment-details-container">
                <Col md="12">
                    {/*  <FormGroup>
                        <span>Etapas</span>
                        <Select
                            noOptionsMessage={() => "Não há opções para mostrar"}
                            className="react-select dark-gray"
                            classNamePrefix="react-select"
                            placeholder="Selecione as etapas"
                            name="multipleSelect"
                            closeMenuOnSelect={false}
                            options={skills}
                            value={values.linkSteps}
                            isMulti
                            onChange={(value) => onChange("linkSteps", value)}
                            formatCreateLabel={label => `Criar ${label}`}
                        />
                    </FormGroup> */}
                    <SelectInput
                        options={skills}
                        title="Etapas"
                        labelledBy={"Etapas"}
                        value={values.linkSteps}
                        onChange={(value) => onChange("linkSteps", value)}
                        isSingle={false}
                    />
                </Col>
                <Col sm="12">
                    <FormGroup>
                        <RichEditorQuill
                            defaultValue={values.description}
                            onChange={(value) => onChange("description", value)}
                            placeholder={'Insira aqui suas observações'}
                            height={490 * values.section}
                        // width={891.48}
                        />
                    </FormGroup>
                </Col>
                <Col sm="12">
                    <FormGroup className="section-buttons">
                        {/* <Button color="link" className="section-button" onClick={() => onChange("section", values.section + 1)}>
                            <i class="material-icons">
                                add
                            </i>
                        </Button> */}
                        {/* <Button color="link" className="section-button" onClick={() => onChange("section", values.section === 1 ? 1 : values.section - 1)}>
                            <i class="material-icons">
                            remove
                            </i>
                        </Button> */}
                        <span className="section-span">Seções</span>
                        <div className="section-button">
                            <AddButton onClick={addSection} />
                            <RemoveButton onClick={removeSection} />
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            {/* <Row>
                <Button color="success" className="comment-save" outline onClick={saveData}>{"Salvar"}</Button>
            </Row> */}
        </>
    )
}

const LinkComment = ({ data, onSelectedChange }) => {
    return (
        <>
            <ListCommentContainer idStep={data.step && data.step.id} report={true} onSelectedChange={onSelectedChange} />
            {/* <Button color="success" className="comment-save" outline onClick={() => onSaveSelected(selected)}>{"Salvar"}</Button> */}
        </>
    )
}