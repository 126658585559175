import React, { useState, useEffect } from "react";
import { InspectionModelDetails } from "../views/InspectionModelDetails";
import { useDispatch, useSelector } from "react-redux";
import { updateInspectionModelData, addInspectionModelData } from "../core/redux-store/inspectionModel/Actions";
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import Alert from "../components/Alert/Alert.js";
import { getWorkTypes } from "../core/database/skill";
import { generateUniqueId, normalizeCombo } from "../core/utils/Utils";
import { getSkillData } from "../core/redux-store/skill/Actions";

const hasValue = item => item.name;

const InspectionModelDetailsContainer = (props) => {
    const dispatch = useDispatch()
    const network = useSelector(state => state.login.network)
    // const [open, setOpen] = useState(false)
    const [values, setValues] = useState({
        asks: []
    })
    const [question, setQuestion] = useState();
    const [service, setService] = useState();
    const [comboWorkTypes, setComboWorkTypes] = useState([])
    const skills = useSelector(state => state.skill.items)
    const user = useSelector(state => state.login)
    console.log({ values })

    const onSave = () => {
        let extraData = {}
        try {
            if (!values.name) {
                throw new Error(I18n[keyEnum.nameRequired]);
            }

            // if (!values.asks || values.asks.length < 1) {
            //     throw new Error(I18n[keyEnum.checklistRequired]);
            // }

            if (!values.workType || !values.workType.value) {
                throw new Error(I18n[keyEnum.workTypeRequired])
            } else {
                extraData['workTypeId'] = values.workType.value
            }

            if (!values.linkStep || !values.linkStep.value) {
                throw new Error(I18n[keyEnum.stepRequired])
            } else {
                extraData['linkStepId'] = values.linkStep.value
            }

            // console.log({ ...values, ...extraData })
            const onDataCompleted = async (data = undefined) => {
                Alert.success("Modelo de inspeção salvo!")
                // setOpen(data)
            }

            // console.log({ ...values, ...extraData, network })
            if (values.id) {
                delete values.tableData
                dispatch(updateInspectionModelData({ ...values, ...extraData, workGroup: network })).then(onDataCompleted);
            } else {
                dispatch(addInspectionModelData({ ...values, ...extraData, workGroup: network })).then(onDataCompleted)
            }
        } catch (error) {
            Alert.error(error.message);
        }
    }

    const loadWorks = async () => {
        // console.log({ props, user })
        const works = await getWorkTypes({ network: user.network })
        const newWorks = user.groupData && user.groupData.workTypeIds ? works.filter(work => user.groupData.workTypeIds.includes(work.value)) : works
        // console.log({ works, newWorks })
        setComboWorkTypes(newWorks);
    }

    const formatSkills = () => {
        const skillsFiltered = values.workType ?
            skills.filter(skill => values.workType.value === skill.parentId)
            : skills
        return normalizeCombo("id", "name", skillsFiltered).sort((a, b) => a.label && b.label && a.label.localeCompare(b.label))
    }

    useEffect(() => {
        const { state } = props.location
        dispatch(getSkillData({ level: 1 }));
        setValues({ asks: [], ...state })
        loadWorks()
    }, [])

    const deleteItem = (id) => {
        setValues({ ...values, asks: values.asks.filter(item => item.id !== id) });
    }

    const addAsk = (asks, type) => {
        if (asks) {
            const names = asks.split(";").map((name, index) => ({
                id: generateUniqueId(`Checklist-ask-${index}`),
                name,
                type
            })).filter(hasValue)

            setValues({ ...values, asks: values.asks.concat(names) });
            if (type === 1) {
                setQuestion("");
            } else if (type === 2) {
                setService("");
            }
        } else {
            Alert.error("Insira um item para o seu checklist")
        }
    }

    const onBack = () => {
        props.history.goBack()
        setValues({
            asks: []
        })
    }

    // console.log({ props })
    return (
        <InspectionModelDetails
            {...props}
            onBack={onBack}
            values={values}
            setValues={setValues}
            comboWorkTypes={comboWorkTypes}
            formatSkills={formatSkills}
            question={question}
            setQuestion={setQuestion}
            addAsk={addAsk}
            deleteItem={deleteItem}
            service={service}
            setService={setService}
            onSave={onSave}
        />
    );
}

export default InspectionModelDetailsContainer;
