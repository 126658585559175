import React, { useEffect, useState } from 'react';
import { ChatView } from '../views/ChatView';
import { database, db, fireSQL } from '../core/database/firebaseConfig';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getItem, updateItem, deleteItem } from '../core/database/firebaseFunctions';
import sort from 'fast-sort'
import ModalAlert from '../layoutV2/components/Modal/ModalAlert';
import { storage } from '../core/database/firebaseConfig'
import { imageNameUtils } from '../core/utils/Utils';
import { observeChat, readMessage, sendNotification, verifyAndAddChat } from '../core/communication/PushNotification';
import Alert from '../components/Alert/Alert';
import { addItem } from '../core/database/firebaseFunctions';
import { isUnreadMessage } from '../core/communication/PushNotification';
import { getAllUsers } from '../core/database/user';

const filteredData = (data, values) => {
    return data.filter(item => item && item.name && item.name.toLowerCase() && item.name.toLowerCase().includes(values && values.search && values.search.toLowerCase()))
}

const filteredMessages = (data, values) => {
    if (values && values.dates && values.dates.startDate) {
        return data.filter(item => moment(item.createdAt).format("DD-MM-YYYY") === values.dates.startDate || moment(item.createdAt).format("DD-MM-YYYY") === values.dates.endDate)
    }
    return data
}

const parse = snapshot => {
    if (snapshot.val()) {
        const val = snapshot.val();
        const { createdAt, sendAt, text = "", user } = val;
        const { key: _id } = snapshot;
        const timestamp = moment(typeof createdAt == "string" && moment(createdAt).isValid() ? createdAt : sendAt).toDate();
        const message = { ...val, createdAt: timestamp, _id, timestamp, text, user };
        return message;
    }
    return;
};

export const notReadMessage = async (id, text, sender, type = `Chats`) => {
    await updateItem(type, {
        id,
        lastMessage: {
            text,
            sender,
            read: false,
            date: new Date()
        }
    })
}

const ChatContainer = () => {
    const dispatch = useDispatch();
    const loggedUser = useSelector(state => state.login)
    const [chat, setChat] = useState([])
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingMessages, setLoadingMessages] = useState(false)
    const [reload, setReload] = useState(false)
    const [chatMessage, setChatMessage] = useState(false)
    const [channel, setChannel] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [respondent, setRespondent] = useState(false)
    const [openFilter, setOpenFilter] = useState(false)
    const [scrollRespondent, setScrollRespondent] = useState(false)
    const [changeMessage, setChangeMessage] = useState({})
    const [newForum, setNewForum] = useState(false)
    const [newGroup, setNewGroup] = useState(false)
    const [openConfig, setOpenConfig] = useState(false)
    const [openShareGroup, setOpenShareGroup] = useState(false)
    const [openShareUsers, setOpenShareUsers] = useState(false)
    const [values, setValues] = useState({
        search: '',
    })
   
    useEffect(() => {
        verifyRead();
    }, [channel, chat, reload])

    useEffect(() => {
        loadData()
    }, [channel, chatMessage])

    useEffect(() => {
        load()
    }, [reload,])

    const load = () => {
        let allMessages = []
        loadMessages(newMessages => {

            if (newMessages) {
                allMessages.push({
                    ...newMessages,
                    idChat: channel.id,
                    group: channel.nmGroup ? true : false,
                })
            }
        })
        if (channel) {
            if (allMessages.find(item => item.idChat === channel.id)) {
                readChannel(allMessages)
            }
        }
        setReload(false)
        setMessages(allMessages)
    }

    const loadMessages = (callback) => {
        let id = channel.id
        let ref = database.ref(`Messages/${id}`);
        let refFilter = ref.limitToLast(500);
        refFilter.on('child_added', snapshot => {
            setReload(true)
            callback(parse(snapshot))

        });
    }

    const loadData = async () => {

        let newChats
        const promises = []
        promises && promises.push(fireSQL.query(`
            SELECT *
            FROM Chats
            where active = true and (idUser = '${loggedUser.id}' OR idSender = '${loggedUser.id}')
            order by \`lastMessage.date\` desc
        `))

        promises && promises.push(fireSQL.query(`
            SELECT *
            FROM ChatGroups
            where active = true and usersGroup CONTAINS '${loggedUser.id}'
        `))

        newChats = await Promise.all(promises).then((items) => items.flat())
        const users = await getAllUsers()



        const chat = newChats.map(item => {
            const name = item.idUser === loggedUser.id ? item.nmSender : item.nmUser
            const nmGroup = item.nmGroup ? item.nmGroup : name
            const senderUser = users.filter(row => row.id === item.lastMessage.sender)[0] && users.filter(row => row.id === item.lastMessage.sender)[0].name ? users.filter(row => row.id === item.lastMessage.sender)[0].name : ''
            const emailUserProject = users.filter(row => item.idUser ? row.id === item.idUser : item)[0] && users.filter(row => item.idUser ? row.id === item.idUser : item)[0].email ? users.filter(row => item.idUser ? row.id === item.idUser : item)[0].email : ''

            return {
                name: nmGroup,
                id: item.id,
                description: item.lastMessage.text,
                custom: {
                    thumb: item.piGroup ? item.piGroup : `https://ui-avatars.com/api/?background=random&name=${nmGroup}`
                },
                emailUserProject,
                senderLastMessage: senderUser,
                updated: item && item.lastMessage && item.lastMessage.date && item.lastMessage.date.seconds ? new Date(item && item.lastMessage && item.lastMessage.date && item.lastMessage.date.seconds * 1000) : item && item.date,
                ...item
            }
        }).filter(item => item.name)
        sort(chat).asc(u => u && u.lastMessage && u.lastMessage.date && u.lastMessage.date.seconds ? new Date(u.lastMessage.date.seconds * 1000) : u && u.lastMessage && u.lastMessage.date)




        setReload(!reload)
        setChat(chat)
    }

    const onChange = (field, value) => {
        setValues({ ...values, [field]: value })
    }

    const onChannel = (channel) => {
        setChannel(channel)
    }

    const onSend = async (value) => {
        if (value.text) {
            const id = channel.id
            const userLogged = {
                name: loggedUser.name,
                email: loggedUser.email,
                avatar: loggedUser.piUser ? loggedUser.piUser : `https://ui-avatars.com/api/?background=random&name=${loggedUser.name}`,
                _id: loggedUser.id,
            }

            let sendRef = database.ref(`Messages/${id}`);
            if (respondent) {
                const image = respondent.image
                const work = respondent.work
                const forward = respondent.forward
                const replyTo = { text: respondent.text, forward: forward ? forward : false, image: image ? image : false, work: work ? work : false, _id: respondent._id, user: userLogged, sendAt: moment(new Date()).format("YYYY-MM-DD"), type: "text" }

                const messageData = { ...value, replyTo, user: userLogged, sendAt: moment(new Date()).format("YYYY-MM-DD"), createdAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") }
                sendRef.push(messageData);
                await notReadMessage(id, value.text, loggedUser.id, channel.nmGroup ? "ChatGroups" : undefined);
                setRespondent(false)
                setChangeMessage({ text: '' })

            } else {
                const messageData = { ...value, user: userLogged, sendAt: moment(new Date()).format("YYYY-MM-DD"), createdAt: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), }
                sendRef.push(messageData);
                await notReadMessage(id, value.text, loggedUser.id, channel.nmGroup ? "ChatGroups" : undefined);
                const idUser = channel.usersGroup ? channel.usersGroup : channel.idSender !== loggedUser.id ? [channel.idSender] : [channel.idUser]
                sendNotification(idUser, id, loggedUser.name, value.text, channel.nmGroup ? { nmGroup: channel.nmGroup } : undefined)
                setChangeMessage({ text: '' })
            }
        }
    }

    const uploadFiles = async (files) => {
        setLoading(true)

        const type = files[0].type.split('/')
        const dataReference = type[0] === 'image' ? `Images/${channel.nmGroup ? "ChatGroups" : "Chats"}/${loggedUser.id}${new Date().getTime()}` : `Files/${channel.nmGroup ? "ChatGroups" : "Chats"}/${loggedUser.id}${new Date().getTime()}`;

        files.forEach(file => {
            const filename = file.name;
            const uniqueFilename = imageNameUtils(filename);
            const storageRef = storage.ref(dataReference).child(uniqueFilename);
            const task = storageRef.put(file);
            task.then(() => {
                storageRef.getDownloadURL().then((url) => {
                    sendFiles({ url, filename, uniqueFilename, type: type[0] });
                });
            })
        })
    }

    const sendFiles = async (files) => {
        if (channel) {
            const id = channel.id
            const userLogged = {
                name: loggedUser.name,
                email: loggedUser.email,
                avatar: loggedUser.piUser ? loggedUser.piUser : `https://ui-avatars.com/api/?background=random&name=${loggedUser.name}`,
                _id: loggedUser.id,
            }
            let sendRef = database.ref(`Messages/${id}`);

            if (files.type === 'image') {
                const message = { text: "", user: userLogged, createdAt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS"), sendAt: moment(new Date()).format("YYYY-MM-DD"), image: files.url };
                sendRef.push(message);
                await notReadMessage(id, "Foto", loggedUser.id, channel.group ? "ChatGroups" : undefined);
                const idUser = channel.usersGroup ? channel.usersGroup : channel.idSender !== loggedUser.id ? [channel.idSender] : [channel.idUser]
                sendNotification(idUser, id, loggedUser.name, "Foto", channel.nmGroup ? { nmGroup: channel.nmGroup } : undefined)
                setReload(!reload)
                setLoading(false)
            } else {
                const message = { text: "", filename: files.filename, user: userLogged, createdAt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS"), sendAt: moment(new Date()).format("YYYY-MM-DD"), file: files.url };
                sendRef.push(message);
                await notReadMessage(id, "Arquivo", loggedUser.id, channel.nmGroup ? "ChatGroups" : undefined);
                const idUser = channel.usersGroup ? channel.usersGroup : channel.idSender !== loggedUser.id ? [channel.idSender] : [channel.idUser]
                sendNotification(idUser, id, loggedUser.name, "Arquivo", channel.nmGroup ? { nmGroup: channel.nmGroup } : undefined)
                setReload(!reload)
                setLoading(false)
            }
        }
    }

    const onDeleteMessage = (value) => {
        ModalAlert.danger({
            title: "Excluir",
            text: "Você deseja mesmo excluir esta mensagem",
            onConfirm: () => deleteMessage(value._id)
        })
    }

    const deleteMessage = async (message) => {
        const idChat = channel.id
        if (message && idChat) {
            await database.ref(`Messages/${idChat}/${message}`).remove()
            setIsOpen(false)
            setReload(!reload)
        }
    }

    const onReplayMessage = (value) => {
        setRespondent(value)
        setIsOpen(false)
    }

    const onRespondent = (value) => {
        setScrollRespondent(value)
        setTimeout(() => {
            setScrollRespondent(false)
        }, 2000)
    }

    const onSaveNewForum = async ({ work }) => {
        if (chat.filter(item => item.idWork === work.id && item.emailUserProject === values.user.label).length > 0) {
            Alert.error("Você já possui um chat com esse usuário sobre este projeto.")
        } else {

            if (values && values.user) {
                const userReceived = await getItem("Users", { email: values.user.value })
                if (userReceived && userReceived.length > 0 && work && work.id) {
                    await verifyAndAddChat({ idWork: work.id, nmWork: work.name }, loggedUser, userReceived[0])
                    setNewForum(false)
                    setReload(!reload)
                    setValues({ ...values, work: null, user: null })
                }
            }
        }
    }

    const onSaveNewGroup = async () => {
        if (values) {
            if (!values.nmGroup) {
                Alert.error("Nome é obrigatório");
            } else if (!values.usersGroup || values.usersGroup.length === 0) {
                Alert.error("Selecione usuários!");
            } else {
                const users = values.usersGroup.map(item => item.id)
                const usersGroup = users.push(loggedUser.id)

                await addItem("ChatGroups", { nmGroup: values.nmGroup, usersGroup: users, piGroup: values.piGroup ? values.piGroup : null, ...usersGroup, lastMessage: { read: true, date: new Date(), sender: loggedUser.id, text: "Você foi adicionado ao grupo!" } })
                setReload(!reload)
                setNewGroup(false)
                setValues({ ...values, piGroup: null, nmGroup: null, usersGroup: null })
            }
        }
    }

    const removeForum = (item) => {
        ModalAlert.danger({
            title: "Excluir",
            text: "Você deseja mesmo excluir esta mensagem",
            onConfirm: () => confirmRemove(item)
        })
    }

    const confirmRemove = async (item) => {
        if (item.nmGroup) {
            await deleteItem(`ChatGroups`, item.id)
            setChannel(false)
            setReload(!reload)
        } else {
            await deleteItem(`Chats`, item.id)
            setChannel(false)
            setReload(!reload)
        }
    }

    const onEditConfig = async () => {
        if (openConfig && openConfig.nmGroup) {
            setNewGroup(true)
            const promises = openConfig.usersGroup.map(id => getItem("Users", { id }))
            const usersGroup = await Promise.all(promises).then(items => items.flat())
            const formatedUsers = usersGroup.map(row => {
                return {
                    ...row,
                    label: row.name,
                    value: row.id
                }
            })
            setValues({ ...values, nmGroup: openConfig.nmGroup, usersGroup: formatedUsers, piGroup: openConfig && openConfig.piGroup ? openConfig.piGroup : openConfig && openConfig.custom && openConfig.custom.thumb, custom: openConfig.custom })
        }
    }

    const editGroup = async () => {
        if (openConfig && openConfig.nmGroup) {
            if (values) {
                if (!values.nmGroup) {

                } else if (!values.usersGroup) {

                } else {
                    const users = values.usersGroup.map(item => item.id)
                    const newValues = { ...openConfig, nmGroup: values.nmGroup, name: values.nmGroup, usersGroup: users, piGroup: values.piGroup, custom: { thumb: values.piGroup } }
                    await updateItem("ChatGroups", { ...newValues })
                    setNewGroup(false)
                    setReload(!reload)
                    setOpenConfig(false)
                }
            }
        }
    }

    const verifyRead = () => {
        setReload(!reload)
        const dataItem = {
            id: channel.id, lastMessage: channel.lastMessage
        }
        if (channel.idSender || channel.idUser) {
            dataItem['idSender'] = channel.idSender
            dataItem['idUser'] = channel.idUser
        }
        readMessage(dataItem, loggedUser, dispatch, channel.nmGroup ? `ChatGroups` : `Chats`);
        setReload(false)
    }

    const unreadMessage = () => {
        const unRead = chat.map(item => {
            const isUnread = isUnreadMessage(item, loggedUser, item.nmGroup ? "ChatGroups" : "Chats",);
            return isUnread
        }).filter(row => row === true)
        return unRead && unRead.length
    }

    const readChannel = async (messages) => {
        const messageFormatted = messages[messages.length - 1];
        const sender = messageFormatted.user._id;
        const senderGroup = messageFormatted.user._id
        setReload(!reload)
        if (sender !== loggedUser.id) {

            await updateItem(channel.nmGroup ? `ChatGroups` : `Chats`, { id: messageFormatted.idChat, lastMessage: { text: messageFormatted.text, sender: messageFormatted.user._id, date: { seconds: Math.floor(new Date(messageFormatted.timestamp).getTime() / 1000), nanoseconds: new Date(messageFormatted.timestamp).getTime(), }, read: sender === loggedUser.id ? false : true, sender: channel.nmGroup ? senderGroup : sender } });
        }
    }

    const markedUnread = async (item) => {
        const sender = item.idSender === loggedUser.id ? item.idUser : item.idSender;
        const senderGroup = item.lastMessage.sender === loggedUser.id ? null : item.idSender;
        await updateItem(item.nmGroup ? `ChatGroups` : `Chats`, { id: item.id, lastMessage: { ...item.lastMessage, read: false, sender: item.nmGroup ? senderGroup : sender } });
        setReload(!reload)
        setOpenConfig(false)
        setChannel(false)
    }

    return <ChatView
        messages={filteredMessages(messages.filter(item => item.idChat === channel.id), values)}
        chat={filteredData(chat, values)}
        values={values}
        onChange={onChange}
        loggedUser={loggedUser}
        onChannel={onChannel}
        onSend={onSend}
        loading={loading}
        channel={channel}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onDeleteMessage={onDeleteMessage}
        onReplayMessage={onReplayMessage}
        respondent={respondent}
        setRespondent={setRespondent}
        setChangeMessage={setChangeMessage}
        changeMessage={changeMessage}
        uploadFiles={uploadFiles}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        setValues={setValues}
        onRespondent={onRespondent}
        scrollRespondent={scrollRespondent}
        newForum={newForum}
        setNewForum={setNewForum}
        newGroup={newGroup}
        setNewGroup={setNewGroup}
        onSaveNewForum={onSaveNewForum}
        onSaveNewGroup={onSaveNewGroup}
        setOpenConfig={setOpenConfig}
        openConfig={openConfig}
        removeForum={removeForum}
        onEditConfig={onEditConfig}
        editGroup={editGroup}
        unreadMessage={unreadMessage}
        markedUnread={markedUnread}
        setOpenShareGroup={setOpenShareGroup}
        openShareGroup={openShareGroup}
        setOpenShareUsers={setOpenShareUsers}
        openShareUsers={openShareUsers}
        setLoading={setLoading}
        reload={reload}
        setReload={setReload}
        loadingMessages={loadingMessages}
        setLoadingMessages={setLoadingMessages}
        setChatMessage={setChatMessage}


    />
};
export default ChatContainer;