import React from 'react';

export const PickerList = ({ arrayBtn, loggedUser, isOpen, className = 'edit', data = undefined }) => {

    const verificationFiltered = (row) => {
        if (isOpen && isOpen.user && isOpen.user._id !== loggedUser.id) {
            return row && row.name !== 'Excluir'
        } else if(data && !data.nmGroup){
            return row && row.name !== "Editar"
        }
        else {
            return row && row.name
        }
    }

    return (
        <div className={className}>
            {arrayBtn.filter(row => verificationFiltered(row)).map(item => {
                return <div onClick={item.onClick} className="div-Btn">
                    {item.name}
                </div>
            })}
        </div>


    )
};