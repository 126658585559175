import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Row, Col } from 'reactstrap';
import { normalizeCombo } from '../../core/utils/Utils';
import Creatable from 'react-select/lib/Creatable';
import { toDateFormat } from './InspectionView';
import { getItem } from '../../core/database/firebaseFunctions';
import { getActivity } from '../../core/database/activity';
import { getInspectionModel } from '../../core/database/inspectionModel';
import { QualityInspectionDetails } from './QualityInspectionDetails';
import { OutlineButton, SaveButton } from '../../layoutV2/components/Button';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { NewModal } from '../../layoutV2/components/Modal/Modal';
import { getQualityFileHistory } from '../../core/database/qualityFileHistory';

const AttachmentItem = ({ item }) => {
    return (
        <a className="pdf-quality" href={item} target="_blank">
            <div style={{ width: 48, backgroundColor: "#e03d3d", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <span style={{ fontSize: 28, color: "white" }} className="material-icons">
                    picture_as_pdf
                </span>
            </div>
            <div style={{ flex: 1, border: "1px solid #ddd", paddingLeft: 8, display: "flex", alignItems: "center", fontWeight: "bold", fontSize: 12 }}>
                Arquivo.pdf
            </div>
        </a>
    )
}

const checkIcon = (value) => {
    let icon = <RiCheckboxBlankCircleLine size={20} color={"black"} style={{ marginRight: 5 }} />
    // <span style={{ fontSize: 18, marginRight: 5, color: "black" }} class="material-icons">
    //     radio_button_unchecked
    // </span>
    if (value === true) {
        icon = <RiCheckboxCircleLine size={20} color={"#6bd098"} style={{ marginRight: 5 }} />
        // <span style={{ fontSize: 18, marginRight: 5, color: "#6bd098" }} class="material-icons">
        //     check_circle
        //     </span>
    } else if (value === false) {
        icon = <RiCloseCircleLine size={20} color={"red"} style={{ marginRight: 5 }} />
        // <span style={{ fontSize: 18, marginRight: 5, color: "red" }} class="material-icons">
        //     cancel
        // </span>
    }
    else if (value === -1) {
        icon = <AiOutlineMinusCircle size={20} color={"#62B1F6"} style={{ marginRight: 5 }} />
        // <span style={{ fontSize: 18, marginRight: 5, color: "red" }} class="material-icons">
        //     cancel
        // </span>
    }
    return icon
}

export const QualityDetails = (props) => {
    const { workId, company } = props && props.state
    const [open, setOpen] = useState(false);
    const [checklist, setChecklist] = useState([])
    const [history, setHistory] = useState([])
    console.log({ props })
    const toggle = () => {
        setOpen(!open)
    }

    const loadChecklist = async () => {
        // console.log({ props })
        // const activity = await getActivity({ activityUID: props.activityUID, workId: props.workId });
        // console.log({ activity })
        if (props.modelId) {
            const checklist = await getInspectionModel({ fields: { id: props.modelId } })
            console.log({ aaa: checklist })
            if (checklist && checklist[0] && checklist[0].asks.length > 0) {
                let checklistConfig = checklist[0].asks.filter(item => item.type === props.type)
                checklistConfig = checklistConfig.map(item => props.asks.find(row => row.id === item.id) ? ({ ...props.asks.find(row => row.id === item.id), ...item }) : item)
                // console.log({ checklistConfig })
                setChecklist(checklistConfig)
            }
        }
        // const checklist = await getItem(`Checklist`, {
        //     workId, companyId: company.id, UID: props.UID
        // })
    }

    const loadHistory = async () => {
        if (props.modelId && props.workId && props.idActivity) {
            const history = await getQualityFileHistory({ idModel: props.modelId, idWork: props.workId, idActivity: props.idActivity })
            setHistory(history)
        }
    }

    const onRemove = async () => {
        props.removeInspection(props.id)
        toggle()
    }

    useEffect(() => {
        loadChecklist()
    }, [workId, company.id, props.UID, props.reload])

    useEffect(() => {
        if (open) {
            loadHistory()
        }
    }, [open])

    return (
        <>
            {checklist.map((item, index) => {
                return item.value === false && (
                    <span style={{ display: "flex", marginTop: 5 }} className="subtitle-step">
                        {checkIcon(item.value)}
                        {item.name}
                        {((item.obsIds && item.obsIds.length > 0) || item.obsIdDate) && <QualityInspectionDetails {...props} item={item} />}
                    </span>
                )
            })}
            {/* <Button
                disabled={false}
                onClick={toggle}
                className={"btn-see-step"}
                style={{ padding: " 5px 10px 5px 10px" }}
            >
                Ver
            </Button> */}
            {/* <OutlineButton onClick={toggle} label="Ver" style={{ height: 35, padding: "0px 12px", width: "max-content" }} /> */}
            <SaveButton onClick={toggle} label="Ver" style={{ height: 35, padding: "0px 12px", width: "max-content", margin: 0 }} />
            <NewModal
                open={open}
                toggle={toggle}
                title={"Detalhes"}
                onClickCancel={props.isResponsible ? onRemove : undefined}
                outlineLabel={"Remover"}
                className="report-modal"
                onClickConfirm={props.saveData}
            >
                <div className='details-wizard'>
                    <Row style={{ marginBottom: 10 }}>
                        <Col>
                            {props.model && props.model.label && <Label className='details-subtitle-modal' style={{ marginTop: 0 }}>{props.model.label}</Label>}
                            {props.referenceLocal && props.referenceLocal.name && <Label className='details-subtitle-modal two' style={{ color: "#6c757d", marginTop: 0 }}>{props.referenceLocal.name}</Label>}
                        </Col>
                    </Row>
                    {(props.quantity || props.unity) && <Row>
                        {props.unity && <Col>
                            <Label className='details-title'>Unidade de produção</Label>
                            <span className='details-description'>{props.unity}</span>
                        </Col>}
                        {props.quantity && <Col>
                            <Label className='details-title'>{props.type === 1 ? "Quantidade" : "Quantidade real"}</Label>
                            <span className='details-description'>{props.quantity}</span>
                        </Col>}
                    </Row>}
                    {(props.team.length > 0 || props.step && props.step.label) && <Row>
                        {props.step && props.step.label && <Col>
                            <Label className='details-title'>Local de atividade</Label>
                            <span className='details-description'>{props.step && props.step.label}</span>
                        </Col>}
                        {props.team.length > 0 && <Col>
                            <Label className='details-title'>{props.type === 1 ? "Equipe alocada prevista" : "Equipe alocada realizada"}</Label>
                            {props.team.length > 0 && props.team.map(item => <span className='details-description'>{item.function.label}{item.quantity ? ` (${item.quantity})` : ""}{item.names ? ` - ${item.names}` : ""}</span>)}
                        </Col>}
                    </Row>}
                    {(props.real_date_start || props.date_start) && <Row>
                        {props.date_start && <Col>
                            <Label className='details-title'> Data de início planejada</Label>
                            <span className='details-description'>{props.date_start}</span>
                        </Col>}
                        {(props.type === 2 && props.real_date_start) && <Col>
                            <Label className='details-title'>Data liberada para início</Label>
                            <span className='details-description'>{props.real_date_start}</span>
                        </Col>}
                    </Row>}
                    {checklist && checklist.length > 0 && <div style={{ display: "flex", flexDirection: "column" }}>
                        <Label className='details-title'> Critérios para liberação</Label>
                        {checklist.map((item, index) => {
                            return (
                                <span style={{ display: "flex" }} className='details-description'>
                                    {checkIcon(item.value)}
                                    {item.name}
                                    {item.obs && <span style={{ marginLeft: 5, textOverflow: "ellipsis", overflow: "hidden", height: 20, color: "#a3a3a3", alignSelf: "flex-end", fontSize: 12 }}>{item.obs}</span>}

                                    {((item.obsIds && item.obsIds.length > 0) || item.obsIdDate) && <QualityInspectionDetails {...props} item={item} />}
                                </span>
                            )
                        })}
                        {/* <span style={{ display: "flex" }} className='details-description'>
                            <span style={{ fontSize: 18, marginRight: 5, color: "#6bd098" }} class="material-icons">
                                check_circle
                            </span>
                            Critério A
                        </span>
                        <span style={{ display: "flex" }} className='details-description'>
                            <span style={{ fontSize: 18, marginRight: 5, color: "red" }} class="material-icons">
                                cancel
                        </span>
                            Critério B <a style={{ marginLeft: 5 }} href="123456">#123456</a>
                        </span>
                        {/* <Label className='details-title'>Arquivos</Label>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {props.files.map(item => <AttachmentItem item={item} />)}
                        </div> */}
                    </div>}
                    {history && history.length > 0 && <div style={{ display: "flex", flexDirection: "column" }}>
                        <Label className='details-title'> Histórico de visualização de arquivos</Label>
                        {history.map((item, index) => {
                            return (
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <span style={{ flex: 5 }} className='details-history'>
                                        {item.nameFile}
                                    </span>
                                    <span style={{ flex: 2, marginLeft: 15 }} className='details-history'>
                                        {/* {item.obs && <span style={{ marginLeft: 5, textOverflow: "ellipsis", overflow: "hidden", height: 20, color: "#a3a3a3", alignSelf: "flex-end", fontSize: 12 }}>{item.obs}</span>} */}
                                        {item.nmUser}
                                    </span>
                                    <span style={{ flex: 1, marginLeft: 15, minWidth: 150 }} className='details-history'>
                                        {toDateFormat(item.date, "DD/MM/YYYY HH:mm")}
                                    </span>
                                </div>
                            )
                        })}
                    </div>}
                </div>
            </NewModal>
        </>
    );
};

{/* <Modal isOpen={open} toggle={toggle} style={{ maxWidth: '60%' }}>
            <ModalHeader toggle={toggle}></ModalHeader>
            <Label className='details-title-modal'>Detalhes</Label>
            {props.model && props.model.label && <Label className='details-subtitle-modal' style={{ marginTop: 0 }}>{props.model.label}</Label>}
            {props.referenceLocal && props.referenceLocal.name && <Label className='details-subtitle-modal two' style={{ color: "#6c757d", marginTop: 0 }}>{props.referenceLocal.name}</Label>}
            <ModalBody className='details-wizard'> */}

{/* </ModalBody>
                <ModalFooter>
                    <Button style={{ marginRight: 24 }} className="button-save-company" outline color="success" onClick={props.saveData}>
                        {"Salvar"}
                    </Button>
                </ModalFooter>
            </Modal>
            </> */}