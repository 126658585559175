import React from "react";
import { connect } from "react-redux";
import { signIn, setErrorMessage, verifyUserData } from "core/redux-store/login/Actions";
import Recovery from "views/pages/Recovery";
import { withRouter, Redirect } from 'react-router-dom';
import Alert from '../components/Alert/Alert';
import { userRecovery } from "core/database/user";

class RecoveryContainer extends React.Component {
	constructor(props) {
		super(props);

		this.submit = this.submit.bind(this);
		this.onChange = this.onChange.bind(this);

		this.state = {
			email: undefined
		};
	}

	submit() {
		const { email } = this.state;

		if (email) {
			userRecovery(email).then(() => {
				this.setState({success: email})
			}).catch(e => this.props.dispatch(setErrorMessage(e.message)));
		} else {
			this.props.dispatch(setErrorMessage("Preencha o email!"));
		}
	}

	onChange(field, value) {
		this.setState({ [field]: value });
	}

	render() {
		const { state } = this.props.location;
		return (
			<Recovery
				isLoading={this.props.isLoading}
				errorMessage={this.props.errorMessage}
				submit={this.submit}
				onChange={this.onChange}
				{...this.state}
			/>
		);
	}
}

export default withRouter(connect(state => ({
	isLoading: state.login.isLoading,
	email: state.login.email,
	loggedIn: state.login.loggedIn,
	errorMessage: state.login.error
}))(RecoveryContainer));
