import React, { useState } from "react";
import CustomTable from "../components/CustomTable/CustomTable";
import { Row, Col } from 'reactstrap';
import AdminNavbar from "../components/Navbars/AdminNavbar";
import { BreadcrumbHeader } from "../components/Breadcrumb/Breadcrumb";
import { defaultColor } from "../variables/theme";
import ModalNewInspectionModel from "../components/InspectionModel/ModalNewInspectionModel";
import GenericMaterialTable from "../components/GenericMaterialTable";
import { DeleteButton, EditButton } from "../components/Button/Button";
import { AddNavButton } from "../layoutV2/components/Button";
import { SelectInput } from '../layoutV2/components/Form';

const columns = [
    { title: "Nome", field: "name", defaultSort: 'asc', filtered: true, defaultSearch: true }
]

export const InspectionModel = (props) => {
    const [myItens, setMyItens] = useState();
    const { goToNewModel, network, onDeleteItem } = props;
    const [selectedRow, setSelectedRow] = useState([]);

    console.log({ data: props.data, myItens });

    const selectedFilter = myItens ? [...new Set(myItens.map(item => item.value))] : []

    return (
        <div className="content">
            <AdminNavbar>
                <AddNavButton label="Nova Ficha" onClick={goToNewModel} />
            </AdminNavbar>
            {/* <ModalNewInspectionModel open={open} setOpen={setOpen} onSave={onSave} /> */}
            <GenericMaterialTable
                title="Modelo de Inspeção"
                idTable={"inspection-model-list"}
                columns={columns}
                rightComponent={
                    <Row className="filter-report" style={{marginRight: 220}}>
                        <Col lg="3" sm="12">
                            <SelectInput
                                onChange={(values) => setMyItens(values)}
                                value={myItens}
                                options={[
                                    { label: "Minhas fichas", value: 1 },
                                    { label: "Fichas públicas", value: 2 },
                                ]}
                                labelledBy={"Selecionar tipo"}
                                title={"Selecionar tipo"}
                            />
                        </Col>
                    </Row>
                }
                actions={[
                    rowData => rowData.workGroup === network && {
                        icon: (props) => <EditButton {...props} />,
                        iconProps: {
                            style: {
                                fontSize: 24,
                                color: defaultColor
                            }
                        },
                        onClick: (_event, rowData) => props.onEditClick(rowData)
                    },
                    rowData => rowData.workGroup === network && {
                        icon: (props) => <DeleteButton {...props} />,
                        iconProps: {
                            style: {
                                fontSize: 24,
                                color: defaultColor
                            }
                        },
                        onClick: (_event, rowData) => props.onDeleteItem(rowData)
                    }
                ]}
                onSelectionChange={(rows) => setSelectedRow(rows.map(item => item.id))}
                // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
                options={{
                    paging: false,
                    search: true,
                    rowStyle: rowData => ({
                        backgroundColor: (selectedRow.includes(rowData.id)) ? '#2A3F92' : '#FFF',
                        color: (selectedRow.includes(rowData.id)) ? '#FFF' : '#2A3F92'
                    })
                }}
                {...props}
                data={props.data.filter(item => selectedFilter.length === 0 || selectedFilter.length === 2 || (selectedFilter.includes(1) && item.workGroup === network) || (selectedFilter.includes(2) && item.workGroup !== network))}
            />
        </div>
    );
};