import React, { useEffect, useState } from "react";
import { NewModal } from "../../layoutV2/components/Modal/Modal";
import { SelectInput, TextInput } from "../../layoutV2/components/Form";
import { useSelector } from 'react-redux';
import { getUsers } from '../../core/database/user'
import FileSimpleContainer from "../../container/FileSimpleContainer";

export const ModalGroup = ({ open, setOpen, onSave, title, onChange, values, setValues, openConfig, setOpenConfig }) => {
    const [users, setUsers] = useState([])
    const groups = useSelector(state => state.login.groups);
    const user = useSelector(state => state.login);
    const piGroup = { url: values && values.piGroup }
  
    const toggle = () => {
        setOpen(false)
        setValues({ search: '' })
        if (openConfig) {
            setOpenConfig(false)
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const groupsIds = groups.map(group => group.details && group.details.id).filter(item => item)
        const promises = []
        let newUsers = []

        groupsIds.map(idCompany => {
            promises.push(getUsers({ value: idCompany }))
        })

        newUsers = await Promise.all(promises).then(items => items.flat())
        newUsers = newUsers.filter(item => item.id !== user.id)
        setUsers(newUsers.filter(function (a) {
            return !this[JSON.stringify(a.id)] && (this[JSON.stringify(a.id)] = true);
        }, Object.create(null)))
    }

    return (
        <>
            <NewModal
                open={open}
                toggle={toggle}
                onClickConfirm={onSave}
                title={title}
            >
                <FileSimpleContainer
                    file={openConfig ? piGroup ? piGroup : null : null}
                    onFileChange={(file) => onChange("piGroup", file.url)}
                    single={true}
                    dataReference={`Groups`}
                    mini
                    circle={true}
                />
                <TextInput
                    id="group-name"
                    placeholder="Nome"
                    type="text"
                    value={values && values.nmGroup}
                    onChange={(event) => onChange("nmGroup", event.target.value)}
                />
                <SelectInput
                    options={users.map(item => ({ ...item, value: item.id, label: item.name }))}
                    title="Usuários"
                    labelledBy={"Usuários"}
                    value={values && values.usersGroup}
                    onChange={value => onChange("usersGroup", value)}
                />
            </NewModal>
        </>
    )
}

