import React, { useState, useEffect } from 'react';
import { AlertView } from '../../views/works/AlertView';
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyByIdData } from '../../core/redux-store/company/Actions';
import { getLibData } from "../../core/redux-store/lib/Actions";
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import moment from 'moment';
import { addAlert, updateAlert } from '../../core/database/alert';
import { normalizeCombo } from '../../core/utils/Utils';
import { getUserData } from "core/redux-store/user/Actions";
import sort from 'fast-sort';
import Alert from '../../components/Alert/Alert';

async function onUsersLoad(location, dispatch, setWorkUsers) {
    const { state } = location;
    if (state) {
        if (state.company) {
            dispatch(getUserData({ value: state.company.id })).then(user => {
                setWorkUsers(normalizeCombo("email", "email", user));
            });
        }
    }
}

export const AlertContainer = (props) => {
    const user = useSelector(state => state.login);
    const libs = useSelector(state => state.lib.items);
    const [values, setValues] = useState(props.data && props.data.alert ? props.data.alert : {});
    const [workUsers, setWorkUsers] = useState();
    const { company } = props.location.state
    const dispatch = useDispatch();

    // console.log({ props })
    useEffect(() => {
        onUsersLoad(props.location, dispatch, setWorkUsers)
        dispatch(getLibData({ isAdmin: user.isAdmin, workGroup: user.network, tag: "HqBCrWpMof6pCP5E2DWD" }))
    }, []);

    function onChange(field, value) {
        setValues(values => ({ ...values, [field]: value }))
    }

    function saveData() {
        try {
            const { days, lib, users, description } = values;

            // console.log({ days, lib, users, description })

            if (!days || !lib || !users || !description) {
                throw new Error(I18n[keyEnum.requiredAll]);
            } else {
                const { alert, Start, UID, Name } = props.data;

                const date = moment(Start).add(`-${values.days}`, 'days').toDate();

                // console.log({ alert, location: props.location, company, UID: UID || "", Name, date, days, lib, libIds: lib.map(item => item.value), users, description, emails: users.map(item => item.value) })
                if (alert) {
                    updateAlert(props.location, props.company, { id: alert.id, location: props.location, UID: UID || "", Name, date, days, lib, libIds: lib.map(item => item.value), users, description, emails: users.map(item => item.value) }).then(() => {
                        Alert.success("Atualizado com sucesso");
                        setValues({})
                        props.toggle();
                    })
                } else {
                    addAlert(props.location, props.company, { UID: UID || "", Name, date, days, lib, libIds: lib.map(item => item.value), users, description, emails: users.map(item => item.value) }).then(() => {
                        Alert.success("Salvo com sucesso");
                        setValues({})
                        props.toggle();
                    })
                }
            }
        } catch (error) {
            Alert.error(error.message);
        }
    }

    sort(libs).asc('name');

    const filteredLibs = (allLibs) => {
        const libs = allLibs.filter(item => {
            let data = []
            item.workType && item.workType.map(row => data.push({ value: row.value }))
            const dataFiltered = data.filter(item => item.value === props.location.state.workType)
            return dataFiltered.map(row => row.value).toString() === props.location.state.workType
        })
        return libs
    }
    console.log({ libs, aaaaa: filteredLibs(libs) })
    
    return (
        <AlertView
            onChange={onChange}
            values={values}
            saveData={saveData}
            workUsers={workUsers}
            libs={filteredLibs(libs)}
            {...props}
        />
    );

};