import React, { useState } from 'react';
import CustomTable from '../../components/CustomTable/CustomTable';
import { Row, Col } from 'reactstrap';
import { FilterSearch } from '../../components/Filter/FilterHooks';
import GenericMaterialTable from '../../components/GenericMaterialTable';
import { AddNavButton } from '../../layoutV2/components/Button';

let defaultSearch;
let filterTimeout;

const getFilteredData = (data, tagSearch) => {
    return data.filter(item => !tagSearch || (item.label && tagSearch && item.label.toLowerCase().includes(tagSearch.toLowerCase())))
}

const TagList = (props) => {
    const [tagSearch, setTagSearch] = useState(defaultSearch);

    function onFilterSearch(_field, tagSearch) {
        setTagSearch(tagSearch);
        defaultSearch = tagSearch;
    }

    return (
        <>
            <div className='content'>
                <GenericMaterialTable
                    title='Tags'
                    idTable={"tag-list"}
                    columns={[
                        {
                            title: 'Nome',
                            field: 'label',
                            editable: 'always',
                            defaultSearch: true,
                            cellStyle: {
                                maxWidth: 230,
                                minWidth: 230
                            },
                            defaultSort: 'asc',
                            render: rowData => (
                                <Row className='row-user label-primary'>
                                    <Col className='details truncated'>{rowData.label}</Col>
                                </Row>
                            )
                        },
                    ]}
                    {...props}
                    marginZero={true}
                    filterComponent={() => {
                        return (
                            <div className="filter-container">
                                <div className="actions">
                                    {/* <AddNavButton label="Novo" /> */}
                                    <FilterSearch onFilterSearch={onFilterSearch} value={tagSearch} />
                                </div>
                            </div>
                        )
                    }}
                    data={getFilteredData(props.data, tagSearch)}
                    headerAdd
                />
            </div>
        </>
    );
}

export default TagList;