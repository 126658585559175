import React from "react";
import DateYear from "../DateYear/DateYear";
import { HiOutlineArrowRight } from 'react-icons/hi'
import { FiCalendar } from 'react-icons/fi';

class DateYearSelect extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      dates: props.dates
    };

    this.onDateChange = this.onDateChange.bind(this);
  }

  onDateChange(key, value){
    this.dates = {...this.dates, [key]: value};

    if(this.props.onDateChange){
      this.props.onDateChange(this.dates)
    }

    this.setState({dates: this.dates})
  }

  render() {
    const { dates } = this.state;
    return (
        <div className="year-container">
            <DateYear className="first" value={dates && dates.startDate} placeholder="Início" onChange={(value) => this.onDateChange('startDate', value)} />
            <DateYear className="last" value={dates && dates.endDate} placeholder="Fim" onChange={(value) => this.onDateChange('endDate', value)} />
            <HiOutlineArrowRight className="arrow-icon-calendar"/>
            <FiCalendar className="icon-calendar"/>
        </div>   
    )
  }
}
export default DateYearSelect