import React, { useEffect, useState } from "react";
import { Col, Row } from 'reactstrap';
import { normalizeCombo } from "../core/utils/Utils";
import { DateRange, SelectInput } from "../layoutV2/components/Form";

export const getWorks = (works, company) => {
    let worksFilter = works;
    if (company && company.length > 0) {
        worksFilter = works.filter(work => Array.isArray(company) ? company.map(item => item.value).includes(work.idCompany) : work.idCompany === company.value)
    }
    return worksFilter
}

const getSteps = (data) => {
    let steps = []
    let stepFilter = [...new Set(data.filter(item => item.step).map(item => item.step.value))];
    steps = stepFilter.map(idStep => data.find(item => item.step ? idStep === item.step.value : false).step)
    return steps
}

const FilterDashboardQualityContainer = ({ filter, setFilter, data, companies, works, allTasks, allLocal, allModel }) => {
    const onFilter = (field, value) => {
        setFilter(oldFilter => ({ ...oldFilter, [field]: value }))
    }
    const disabledWork = !filter.company || filter.company.length === 0;
    const disabledActivity = !filter.activity || filter.activity.length === 0;
    const disabled = (!filter.work || filter.work.length === 0) || disabledWork;

    return (
        <div id="filter-dashboard" >
            <Row className="filter-report">
                <Col lg="3" sm="12" style={{ marginBottom: 10 }}>
                    <DateRange
                        onDateChange={onFilter}
                        startDate={filter.dates.startDate}
                        endDate={filter.dates.endDate}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <SelectInput
                        options={normalizeCombo("id", "name", companies)}
                        title="Filtrar por empresa"
                        labelledBy={"Filtrar por empresa"}
                        value={filter.company}
                        onChange={data => onFilter("company", data)}
                        isSingle={false}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <SelectInput
                        options={normalizeCombo("id", "name", getWorks(works, filter.company))}
                        title="Filtrar por obra"
                        labelledBy={"Filtrar por obra"}
                        value={filter.work}
                        onChange={data => onFilter("work", data)}
                        isSingle={false}
                        isDisabled={disabledWork}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <SelectInput
                        options={getSteps(data)}
                        title="Filtrar por etapa"
                        labelledBy={"Filtrar por etapa"}
                        value={filter.step}
                        onChange={data => onFilter("step", data)}
                        isSingle={false}
                        isDisabled={disabled}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <SelectInput
                        options={normalizeCombo("UID", "labelShow", allTasks.map(task => ({ ...task, labelShow: `${task.UID} ${task.Name}` })))}
                        title="Filtrar por atividade"
                        labelledBy={"Filtrar por atividade"}
                        value={filter.activity}
                        onChange={data => onFilter("activity", data)}
                        isSingle={false}
                        isDisabled={disabled}
                        hasSort={false}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <SelectInput
                        options={normalizeCombo("id", "name", allModel)}
                        title="Filtrar por ficha"
                        labelledBy={"Filtrar por ficha"}
                        value={filter.model}
                        onChange={data => onFilter("model", data)}
                        isSingle={false}
                        isDisabled={disabled}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <SelectInput
                        options={normalizeCombo("id", "name", allLocal)}
                        title="Filtrar por local"
                        labelledBy={"Filtrar por local"}
                        value={filter.local}
                        onChange={data => onFilter("local", data)}
                        isSingle={false}
                        isDisabled={disabledActivity}
                    />
                </Col>
            </Row>
        </div >
    )
}

export default FilterDashboardQualityContainer