import React, { useEffect, useState } from "react";
import BarChart from "../Charts/BarChart";
import { Card, Col } from "reactstrap";
import { getLibData } from "../../core/redux-store/lib/Actions";
import { useSelector, useDispatch } from "react-redux";
import { NivoBarChart } from "../Charts/NivoBarChart";
import { useTheme } from '@nivo/core'
import sort from 'fast-sort'

const RecurrencyTick = tick => {
    const theme = useTheme()

    return (
        <g transform={`translate(${tick.x},${tick.y + 22})`}>
            <rect x={-20} y={-6} rx={3} ry={3} width={40} height={24} fill="rgba(0, 0, 0, .05)" />
            <rect x={-20} y={-12} rx={2} ry={2} width={40} height={24} fill="rgb(232, 193, 160)" />
            <line stroke="rgb(232, 193, 160)" strokeWidth={1.5} y1={-22} y2={-12} />
            <text
                textAnchor="middle"
                dominantBaseline="middle"
                style={{
                    ...theme.axis.ticks.text,
                    fill: '#333',
                    fontSize: 10,
                }}
            >
                {`${tick.value}`.split(" ")[0]}{tick.percent ? ` (${tick.percent}%)` : ``}
            </text>
        </g>
    )
}

const allCount = (dataFormatted, keys, inNumber = false) => {
    let totalValue = 0;
    dataFormatted.map(row => {
        keys.map(key => {
            if (row && row[key]) {
                totalValue += row[key]
            }
        })
    })
    return inNumber ? totalValue : `${totalValue} ${totalValue > 1 ? "apontamentos" : "apontamento"}`
}

const getPercent = (value, dataFormatted, keys) => {
    const count = dataFormatted.find(row => row.step === value.value)
    let totalValue = 0;
    keys.map(key => {
        if (count && count[key]) {
            totalValue += count[key]
        }
    })
    return ((totalValue / allCount(dataFormatted, keys, true)) * 100).toFixed(0)
}

const RecurrencyCard = ({ data }) => {
    const [dataFormatted, setDataFormatted] = useState([]);
    const [keys, setKeys] = useState([]);
    const dispatch = useDispatch();
    const comments = useSelector(state => state.lib.items);

    useEffect(() => {
        dispatch(getLibData())
    }, [])

    const formatData = async (comments) => {
        let stepFilter = [...new Set(data.filter(item => item.step).map(item => item.step.id))];
        let steps = stepFilter.map(idStep => data.find(item => item.step ? idStep === item.step.id : false).step)
        sort(steps).asc(step => step.name);

        const keys = [];

        const dataFormatted = steps.map(step => {
            const stepData = data.filter(item => item.step && item.step.id === step.id);
            const commentsData = [...new Set(stepData.map(item => item.commentId))].map(commentId => comments.find(item => item.id === commentId));

            const dataItem = {
                step: step.name
            }

            let hasRecurrency = false;
            commentsData.map(comment => {
                if (comment) {
                    const count = stepData.filter(item => item.commentId === comment.id).length;
                    if (count > 1) {
                        hasRecurrency = true;
                        dataItem[comment.name] = count

                        if (!keys.includes(comment.name)) {
                            keys.push(comment.name)
                        }
                    }
                }
            })

            return hasRecurrency && dataItem
        }).filter(item => item);

        setKeys(keys);
        setDataFormatted(dataFormatted);
    }

    useEffect(() => {
        if (comments && comments.length > 0) {
            formatData(comments)
        }
    }, [data, comments])

    const calcHeight = 500 + (22 * keys.length)
    const calcBottom = (22 * keys.length) + 50
    const calcY = (22 * keys.length) + 50

    if (keys && keys.length === 0) {
        return <div />
    }

    const Subtitle = dataFormatted.filter(item => item && item.step).map(item => <div style={{ display: 'flex', alignItems: 'center', margin: 5 }}> <div style={{ width: 10, height: 10, borderRadius: 100, backgroundColor: '#eac4a4', marginBottom: 3 }} /> <text style={{ marginInline: 5 }}> {item.step}</text></div>)

    return (
        <Col lg="12" style={{ marginTop: 10 }}>
            <Card style={{ padding: 20, width: "100%", height: calcHeight }} className='recurrency-card'>
                <div style={{ display: "flex", paddingTop: 5, fontWeight: "bold", fontSize: 20, justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
                    {`Recorrência por Etapa - ${allCount(dataFormatted, keys)}`}
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center' }}>
                    {(Subtitle)}
                </div>
                <NivoBarChart
                    data={dataFormatted}
                    keys={keys}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendPosition: 'middle',
                        legendOffset: 32,
                        renderTick: (value) => <RecurrencyTick {...value} percent={getPercent(value, dataFormatted, keys)} />
                    }}
                    bottom={calcBottom}
                    label={d => {
                        const { data } = d;
                        let totalValue = 0;
                        keys.map(key => {
                            if (data[key]) {
                                totalValue += data[key]
                            }
                        })
                        return `${((d.value / totalValue) * 100).toFixed(0)} %`
                    }}
                    legends={[
                        {
                            dataFrom: "keys",
                            anchor: "bottom",
                            direction: "column",
                            translateY: calcY,
                            translateX: -150,
                            itemWidth: 200,
                            itemHeight: 22,
                            itemDirection: "left-to-right",
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </Card>
        </Col >
    )
}

export default RecurrencyCard