import React, { useRef, useState, useEffect, useMemo } from 'react';
// import ReactQuill from 'react-quill'; // ES6
// import 'react-quill/dist/quill.snow.css';
// import SunEditor, { buttonList } from "suneditor-react";
// import "suneditor/dist/css/suneditor.min.css";
// import { lineHeight } from 'suneditor/src/plugins';
// import Lang from './Lang';
// import JoditEditor from "jodit-react";
import { storage } from 'core/database/firebaseConfig'
import isEmpty from "lodash/isEmpty";

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'froala-editor/js/plugins.pkgd.min.js';

// Import all Froala Editor plugins;
import 'froala-editor/js/plugins.pkgd.min.js';

// Import a single Froala Editor plugin.
import 'froala-editor/js/plugins/align.min.js';

// Import a language file.
import 'froala-editor/js/languages/de.js';

// Import a third-party plugin.
import 'froala-editor/js/third_party/image_tui.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/js/third_party/spell_checker.min.js';

import FroalaEditor from 'react-froala-wysiwyg';
import $ from 'jquery';

import { isSafari } from 'react-device-detect';
// import FroalaEditor from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditor from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditor from 'react-froala-wysiwyg/FroalaEditorInput';

let editableTimeout;

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
        // 
    ],
}
const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

let filterTimeout;

const onChangeAction = (action) => {
    if (filterTimeout) {
        clearTimeout(filterTimeout);
    }

    filterTimeout = setTimeout(() => {
        action();
    }, 500)
}

const RichEditorQuill = (props) => {
    const froala = useRef(null);
    const [text, setText] = useState(props.defaultValue)
    const [saved, setSaved] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if (!isEmpty(froala.current) && froala.current.editor.html && !froala.current.editor.loaded) {
                froala.current.editor.html.set(text);
                froala.current.editor.loaded = true;
            }
        }, 500)
    }, [froala, text, froala.current && froala.current.editor && froala.current.editor.html]);

    useEffect(() => {
        if (editableTimeout) { clearTimeout(editableTimeout) }
        editableTimeout = setTimeout(() => {
            if (froala && froala.current && froala.current.editor && froala.current.editor.edit) {
                if (props.disabled) {
                    froala.current.editor.edit.off();
                } else {
                    froala.current.editor.edit.on();
                }

            }
        }, 400)
    }, [props.disabled])

    const saveData = async () => {
        await props.onSave(text)
        setSaved(true);
    }

    const handleChange = (value) => {
        setText(value);
        setSaved(false);

        if (!props.onSave && props.onChange && value !== props.defaultValue) {
            onChangeAction(() => props.onChange(value))
        }
    }

    const styleReport = !props.commentItem || props.reportModal ? `
        color: #5f5f5f;
        border: 1px solid #35A8E0;
        border-radius: 10px;
    ` : ``

    const styleComment = props.commentItem ? `
        overflow: auto;
        overflow-x: hidden;
        height: ${props.height - 30}px !important;
    ` : `
        min-height: ${props.height - 20}px !important;
    `

    const styleParagraph = !props.commentItem || props.reportModal ? `
        margin: 20px 5px 4px;
        font-size: 14px;
        color: #5F5F5F !important;
    ` : ``    
    
    let extraProps = props.toolbarInline ? {
        iframeDefaultStyle: `
        html{
            margin: 0px;
        }
        body{
            padding: ${props.toolbarInline ? "5" : "10"}px;
            background:transparent;
            position:relative;
            z-index: 2;
            ${styleReport}
            -webkit-user-select:auto;margin:0px;
            font-size: 12px;
            font-family: Arial, sans-serif;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            overflow: hidden;
            ${styleComment}
        }

        p {
            ${styleParagraph}
        }
        body:after{content:"";clear:both;display:block}
        `,
    } : {
            iframeDefaultStyle: `
            #document {
                overflow-y: hidden;
            }
            .fr-disabled {
                overflow-y: auto;
            }
        body{
            font-size: 12px;
            font-family: Arial, sans-serif;
            ${styleComment}
                overflow-y: hidden;
        }

        ::-webkit-scrollbar {
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
            width: 12px;
            background-color: transparent;
        }
    
        ::-webkit-scrollbar-thumb {
            border-radius: 10px !important;
            //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #b6c2d6;
        }
        `,
        }

    if (props.height) {
        let height = props.commentItem ? props.height - 20 : props.height
        if (isSafari) {
            // extraProps = {...extraProps, minHeight: height}
        } else {
            extraProps = { ...extraProps, height }
        }
    }

    // if (props.maxHeight) {
    //     extraProps = { ...extraProps, maxHeight: props.maxHeight + 250 }
    // }

    if (props.width) {
        extraProps = { ...extraProps, width: props.width }
    }

    let count = 0;
    let hasInit = false;

    // const initilized = () => {
    //     setTimeout(() => {
    //         if(hasInit && count < 20){
    //             if (froala.current && froala.current.editor && froala.current.editor.html) {
    //                 hasInit = true;
    //                 froala.current.editor.html.set(text);
    //             } else {
    //                 count+=1;
    //                 initilized();
    //             }
    //         }
    //     }, 300)
    // }

    const config = {
        key: "bMA6aD7D6E2F2E2F2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2C6D6B3C3E4B2B2==",//0BA3jB11A8C7A5D4F4aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3D3A1B8B5D4A3G4==
        placeholderText: props.placeholder,
        // zIndex: 999999,
        language: 'pt_br',
        fullPage: props.fullPageDisabled ? false : true,
        useClasses: false,
        attribution: false,
        imageUpload: true,
        imageDefaultAlign: 'left',
        imageDefaultDisplay: 'inline-block',
        fontFamilySelection: true,
        toolbarInline: props.toolbarInline,
        events: {
            'blur': function () {
                if (props.onSave) {
                    saveData();
                }
            },
            'initialized': function (init) {
                // initilized();
            },
            'focus': function (e, editor) {
                if (props.setNeedRefreshCount) {
                    props.setNeedRefreshCount(props.needRefreshCount + 1)
                }
            },
            'image.beforeUpload': function (files, images, b, c, d, e) {
                var editor = this;
                files.map((file, index) => {
                    const reader = new FileReader();
                    const storageRef = storage.ref(props.dataReference ? props.dataReference : "Editor/images").child(new Date().getTime().toString());
                    const task = storageRef.put(file);
                    task.then(() => {
                        storageRef.getDownloadURL().then((url) => {
                            images[index].src = url;
                            // editor.image.insert(url, null, null, editor.image.get());
                            editor.popups.hideAll();
                        });
                    })
                    reader.readAsDataURL(file);
                })

                return false;
            }
        },
        ...extraProps
    };

    const styles = props.commentItem ? {
        color: "#2c2c2c",
        border: "1px solid #B6C2D6",
        borderRadius: 10,
        height: props.height
    } : {
            height: props.height
        }


    try {
        return (
            <div className={`editor-container ${isSafari && !props.toolbarInline ? "safari" : ""} isEditing ${props.className}`} style={{ ...styles }}>
                    <FroalaEditor
                        ref={froala}
                        tag='textarea'
                        config={config}
                        model={text}
                        onModelChange={handleChange}
                        data-header-content={props['data-header-content']}
                    />
                    {saved && <i class="material-icons save-icon">
                        check
                </i>}
                </div>
        );
    } catch (e) {
        console.log(e);
        return <div className={`editor-container isEditing ${props.className}`} style={{ height: props.height }} />;
    }

}

export default RichEditorQuill;