import React from "react";

// reactstrap components
import {
  Form,
  Container,
  Col,
  Row,
  Spinner
} from "reactstrap";
import { CardRecovery } from 'components/Login/Login';

class Recovery extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto col-login" lg="5" md="6" sm="10">
              <Form action="" className="form" method="">
                <CardRecovery {...this.props} />
              </Form>
            </Col>
          </Row>
        </Container>
        <Col className="img-login-container" lg="5" md="6" sm="10" style={{
          background: `url("${require("../../assets/img/img-login.png")}")`
        }}/>
      </div>
    );
  }
}

export default Recovery;
