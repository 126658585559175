import React from "react";
// import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";
import Footer from "components/Footer/Footer.jsx";

import routes, { homeRoute } from "routes.jsx";
import { connect } from 'react-redux';
import { Toaster } from "react-hot-toast";

var ps;

class Pages extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      // ps = new PerfectScrollbar(this.refs.fullPages);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      // ps.destroy();
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    if (this.props.loggedIn) {
      // console.log({ location: this.props.location })
      // console.log(this.props.location)
      // const { from } = this.props.location.state || { from: { pathname: '/' } }
      if(!this.props.location.pathname.includes("confirm-read")){
        const { from } = { from: { pathname: homeRoute } }
        return <Redirect to={from} />
      }
    }
    return (
      <div>
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className="full-page section-image">
            <Switch>{this.getRoutes(routes)}</Switch>
            <Footer fluid />
            <Toaster
              position="top-center"
              reverseOrder={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    loggedIn: state.login.loggedIn
  })
)(Pages);
