import React from "react";
import PieChart from "../Charts/PieChart";
import { Card, CardTitle, Col } from "reactstrap";
import { NivoPieChart } from "../Charts/NivoPieChart";

const getNumber = (data, type, count) => {
	let number = data.filter(item => (type && item.state === type) || (!type && !item.state)).length
	if (count) {
		return parseFloat(((number / data.length) * 100 || 0).toFixed(2))
	}
	return number > 1 ? `${number} apontamentos` : `${number} apontamento`
}

const StatusCard = ({ data }) => {

	const dataFormatted = [
		{
			"id": "Finalizado",
			"label": `Finalizado (${getNumber(data, 1)})`,
			"value": getNumber(data, 1, true),
			"color": "hsl(39, 99%, 54%)"
		},
		{
			"id": "Pendente",
			"label": `Pendente (${getNumber(data, 0)})`,
			"value": getNumber(data, 0, true),
			"color": "hsl(22, 87%, 51%)"
		},
	]

	return (
		<Col lg="6" md="12">
			<Card style={{ padding: 20, height: 500, width: "100%" }}>
				<div style={{ display: "flex", fontWeight: "bold", fontSize: 20, justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
					{`Estado - ${data.length} ${data.length > 1 ? "apontamentos" : "apontamento"}`}
				</div>
				{/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Nº de Apontamentos</div> */}
				{/* <PieChart height={300} data={dataChart()} options={options} height={"100%"} /> */}
				<NivoPieChart data={dataFormatted} />
			</Card>
		</Col>
	)
}

export default StatusCard;

{/* <div style={{ paddingTop: 5 }}>
	<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>% de Apontamentos</div>
	<PieChart data={dataChart(true)} />
</div> */}

{/* <div>{`Apontamentos sem gravidade: ${getNumber(data)}`}</div>
<div>{`Apontamentos com gravidade baixa: ${getNumber(data, 1)}`}</div>
<div>{`Apontamentos com gravidade média: ${getNumber(data, 2)}`}</div>
<div>{`Apontamentos com gravidade alta: ${getNumber(data, 3)}`}</div> */}

{/* <div>{`% Apontamentos sem gravidade: ${getNumber(data, undefined, true)}%`}</div>
<div>{`% Apontamentos com gravidade baixa: ${getNumber(data, 1, true)}%`}</div>
<div>{`% Apontamentos com gravidade média: ${getNumber(data, 2, true)}%`}</div>
<div>{`% Apontamentos com gravidade alta: ${getNumber(data, 3, true)}%`}</div> */}