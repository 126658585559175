import React, { useEffect, useState } from 'react';
import {
    Button, Label, Input, Row, Col
} from 'reactstrap';
import { FormGroup } from 'react-bootstrap';
import Select from 'react-select';
import { normalizeCombo } from '../../core/utils/Utils';
import RichEditorQuill from '../RichEditor/RichEditorQuill';
import { getItem } from '../../core/database/firebaseFunctions';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { DefaultImpact } from '../../views/DefaultFilters';
import { SelectInput, TextInput } from '../../layoutV2/components/Form';
import { ButtonGroup } from '../../layoutV2/components/Button';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';

const ScaleButton = ({ scaleValue, setValue, defaultValue, title, backgroundColor, styleButton = {}, styleLabel = {}, positive = '' }) => {
    var activeStyle = Array.isArray(defaultValue) ? defaultValue.includes(scaleValue) ? 'active' : '' : defaultValue === scaleValue ? 'active' : '';
    // console.log(defaultValue === scaleValue ? "" : scaleValue)
    return (
        <Button
            onClick={() => defaultValue === scaleValue ? setValue("") : setValue(scaleValue)}
            className={`scale-button ${positive} ${activeStyle} ${backgroundColor}`}
            active={defaultValue === scaleValue}
            outline
            color="primary"
            style={styleButton}
        >
            <Label className='scale-label' style={styleLabel}>{title}</Label>
        </Button>
    )
}

const GroupButton = ({ defaultValue, setValue, group }) => {
    return (
        <div>
            <ButtonGroup className='scale-group'>
                {group.map(item => <ScaleButton defaultValue={defaultValue} scaleValue={item.value} setValue={setValue} title={item.label} backgroundColor={item.color} />)}
            </ButtonGroup>
        </div>
    )
}

const GroupScaleButton = ({ defaultValue, setValue, group }) => {
    return (
        <div>
            <ButtonGroup className='scale-group'>
                <div style={{ flexDirection: "column" }}>
                    {/* <Label style={{ color: "#f12711", textAlign: "center", width: "100%", marginBottom: 0 }}>Negativo</Label> */}
                    {/* <div style={{ width: 2, backgroundColor: "#f12711", margin: "0 auto", height: 5 }} /> */}
                    {/* <div style={{ height: 2, backgroundColor: "#f12711" }} /> */}
                    {/* <Text style={{ color: "#f12711", textAlign: "center", borderBottomColor: "#f12711", borderBottomWidth: 3, marginHorizontal: 2 }}>|</Text> */}
                    <div style={{ flexDirection: "row", marginTop: 2 }}>
                        {group.map(item => item.value < 4 && <ScaleButton defaultValue={defaultValue} scaleValue={item.value} setValue={setValue} title={item.label} backgroundColor={item.color} />)}
                    </div>
                </div>
                <div style={{ flexDirection: "column" }}>
                    {/* <Label style={{ color: "#20a74c", textAlign: "center", width: "100%", marginBottom: 0 }}>Positivo</Label>
                    <div style={{ width: 2, backgroundColor: "#20a74c", margin: "0 auto", height: 5 }} />
                    <div style={{ height: 2, backgroundColor: "#20a74c" }} /> */}
                    <div style={{ flexDirection: "row", marginTop: 2 }}>
                        <ScaleButton defaultValue={defaultValue} scaleValue={4} setValue={setValue} title={<span class="material-icons">thumb_up</span>} backgroundColor={"positive"} positive={"positive"}
                            styleButton={{ padding: "1px 6px", width: "100%", borderColor: "#20a74c" }} styleLabel={{ display: "flex", justifyContent: "center", color: "#20a74c" }} />
                    </div>
                </div>
            </ButtonGroup>
        </div>
    )
}

const groupScale = [
    {
        id: 3,
        label: "Alto",
        color: "high"
    },
    {
        id: 2,
        label: "Médio",
        color: "medium"
    },
    {
        id: 1,
        label: "Baixo",
        color: "low"
    },
    {
        id: 4,
        label: "Positivo",
        color: "positive"
    },
]

/* const SliderName = ({ value }) => {
    const impact = DefaultImpact.find(item => item.value === value)
    return impact ? <span style={{ display: "flex", justifyContent: "center", color: impact.color }}>{impact.label}</span> : <div />
}
 */
export const groupSignal = [
    {
        id: 1,
        label: "Melhoria",
        description: "Melhoria"
    },
    {
        id: 3,
        // subvalue: 1,
        label: "Custo",
        // description: <AttachMoneyOutlinedIcon style={{marginLeft: 2, color: "white"}}/>
    },
    {
        id: 4,
        // subvalue: 2,
        label: "Prazo",
        // description: <ScheduleOutlinedIcon style={{marginLeft: 2, color: "white"}}/>
    },
    {
        id: 2,
        label: "As Built"
    },
]

export const WorkDetails = ({ onFormChange, formValues, data, hasButton, allLibs, setNeedRefreshCount, needRefreshCount }) => {
    const [subject, setSubject] = useState([])

    const loadData = async () => {
        if (formValues && formValues.step && formValues.step.value) {
            getItem(`Skills`, { parentId: formValues.step.value }).then(items => {
                setSubject(items)
            })
        }
    }

    useEffect(() => {
        loadData();
    }, [formValues.step])

    const libsFormatted = allLibs.filter(
        item => (formValues.comment && formValues.comment.value === item.id) || (item.linkSteps && formValues.step && item.linkSteps.find(item => item.value === formValues.step.value))
    ).sort((a, b) => a.label !== b.label ? a.label < b.label ? -1 : 1 : 0)

    // const impact = DefaultImpact.find(item => item.value === formValues.scale)
    const changeSignal = (value, subvalue) => {
        if (value === 2) {
            if (formValues.signal.includes(value)) {
                onFormChange("signal", formValues.signal.filter(item => item !== value))
            } else {
                onFormChange("signal", formValues.signal.concat(value))
            }
        } else {
            if (formValues.signal.includes(value) && formValues.typeImprovement.length === 0 && !subvalue) { //formValues.typeImprovement.includes(subvalue)
                onFormChange("signal", formValues.signal.filter(item => item !== value))
            } else if (!formValues.signal.includes(value)) {
                onFormChange("signal", formValues.signal.concat(value))
            }
            if (formValues.typeImprovement.includes(subvalue)) {
                onFormChange("typeImprovement", formValues.typeImprovement.filter(item => item !== subvalue))
            } else if (subvalue) {
                onFormChange("typeImprovement", formValues.typeImprovement.concat(subvalue))
            }
        }
        // onFormChange("signal", formValues.signal && formValues.signal.includes(value) ? formValues.signal.filter(item => item != value) : formValues.signal.concat(value))
    }

    const verificationActive = (value, subvalue) => {
        return value === 1 && subvalue ?
            formValues && formValues.typeImprovement && formValues.signal.includes(value) && formValues.typeImprovement.includes(subvalue) :
            Array.isArray(formValues.signal) ? formValues.signal.includes(value) : formValues.signal === value
    }

    return (
        <div className="report-data">
            {!hasButton &&
                <>
                    {/* <Row>
                        <Col>
                            <div className="title-center">Vincular</div>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col>
                            <div className="subtitle-center">Vincule imagens de problemas encontrados a etapa da obra</div>
                        </Col>
                    </Row>
                </>
            }
            <Row>
                <Col>
                    {/* <FormGroup>
                        <span>Etapa</span>
                        <Select
                            noOptionsMessage={() => "Não há opções para mostrar"}
                            className="react-select dark-gray"
                            classNamePrefix="react-select"
                            placeholder="Selecione a etapa"
                            name="multipleSelect"
                            closeMenuOnSelect={true}
                            options={normalizeCombo("id", "name", data).sort((a, b) => a.label !== b.label ? a.label < b.label ? -1 : 1 : 0)}
                            value={formValues.step}
                            onChange={value => onFormChange("step", value)}
                            formatCreateLabel={label => `Criar ${label}`}
                        />
                    </FormGroup> */}
                    <SelectInput
                        options={normalizeCombo("id", "name", data).sort((a, b) => a.label !== b.label ? a.label < b.label ? -1 : 1 : 0)}
                        title="Etapa"
                        labelledBy={"Etapa"}
                        value={formValues.step}
                        onChange={value => onFormChange("step", value)}
                        isSingle={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {/* <FormGroup>
                        <span>Categoria</span>
                        <Select
                            noOptionsMessage={() => "Não há opções para mostrar"}
                            className="react-select dark-gray"
                            classNamePrefix="react-select"
                            placeholder="Selecione a categoria"
                            name="multipleSelect"
                            closeMenuOnSelect={true}
                            options={normalizeCombo("id", "name", subject).sort((a, b) => a.label !== b.label ? a.label < b.label ? -1 : 1 : 0)}
                            value={formValues.subject}
                            onChange={value => onFormChange("subject", value)}
                            formatCreateLabel={label => `Criar ${label}`}
                        />
                    </FormGroup> */}
                    <SelectInput
                        options={normalizeCombo("id", "name", subject).sort((a, b) => a.label !== b.label ? a.label < b.label ? -1 : 1 : 0)}
                        title="Categoria"
                        labelledBy={"Categoria"}
                        value={formValues.subject}
                        onChange={value => onFormChange("subject", value)}
                        isSingle={true}
                        isDisabled={!subject || subject && subject.length === 0}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {/* <FormGroup>
                        <Input onChange={(e) => onFormChange("localization", e.target.value)} type="text" name="text" id="localizationText" style={{ padding: 5, height: 40 }} />
                    </FormGroup> */}
                    {/* <span>Localização</span> */}
                    <RichEditorQuill
                        className="localization"
                        defaultValue={formValues.localization}
                        toolbarInline={true}
                        onChange={(value) => onFormChange("localization", value)}
                        // onSave={(value) => onFormChange(data, "localization", value)}
                        placeholder={'Localização'}
                        // reportModal
                        needRefreshCount={needRefreshCount}
                        setNeedRefreshCount={setNeedRefreshCount}
                    />
                    {/* <TextInput
                        id="image-localization"
                        placeholder="Localização"
                        type="text"
                        value={formValues.localization}
                        onChange={(value) => onFormChange("localization", value)}
                    /> */}
                </Col>
            </Row>
            <Row style={{ marginTop: 10, marginBottom: 10 }}>
                <Col>
                    {/* <FormGroup>
                        <Input onChange={(e) => onFormChange("description", e.target.value)} type="textarea" name="text" id="exampleText" style={{ padding: 5 }} />
                    </FormGroup> */}
                    {/* <span>Observações</span> */}
                    <RichEditorQuill
                        className="noAddButton apoint"
                        defaultValue={formValues.description}
                        toolbarInline={true}
                        onChange={(value) => onFormChange("description", value)}
                        // onSave={(value) => onFormChange(data, "description", value)}
                        placeholder={'Insira aqui suas observações'}
                        reportModal
                        needRefreshCount={needRefreshCount}
                        setNeedRefreshCount={setNeedRefreshCount}
                    />
                    {/* <TextInput
                        id="image-description"
                        placeholder="Observações"
                        type="text"
                        value={formValues.description}
                        onChange={(value) => onFormChange("description", value)}
                    /> */}
                </Col>
            </Row>
            <Row>
                <Col>
                    {/* <FormGroup>
                        <span>Vincular a um apontamento existente</span>
                        <Select
                            noOptionsMessage={() => "Não há opções para mostrar"}
                            className="react-select dark-gray"
                            classNamePrefix="react-select"
                            placeholder="Selecione o apontamento"
                            name="multipleSelect"
                            closeMenuOnSelect={true}
                            options={normalizeCombo("id", "name", libsFormatted)}
                            value={formValues.comment}
                            onChange={value => onFormChange("comment", value)}
                            formatCreateLabel={label => `Criar ${label}`}
                            isClearable={true}
                        />
                    </FormGroup> */}
                    <SelectInput
                        options={normalizeCombo("id", "name", libsFormatted)}
                        title="Vincular a um apontamento existente"
                        labelledBy={"Vincular a um apontamento existente"}
                        value={formValues.comment}
                        onChange={value => onFormChange("comment", value)}
                        isSingle={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm="3" style={{ flex: 1, minWidth: "50%" }}>
                    <span>Impacto</span>
                    {/* <GroupScaleButton
                        defaultValue={formValues.scale}
                        setValue={(value) => onFormChange("scale", value)}
                        group={groupScale}
                    /> */}
                    <ButtonGroup
                        buttons={groupScale}
                        onClick={value => onFormChange("scale", value)}
                        active={value => formValues.scale === value}
                        className={"link-group"}
                    />
                    {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <span style={{ fontSize: 11, color: "#ef6d2e" }}>Negativo</span>
                        <span style={{ fontSize: 11, color: "#20a74c" }}>Positivo</span>
                    </div>
                    <Slider
                        reverse
                        dots
                        onChange={(value) => onFormChange("scale", value)}
                        min={-3}
                        max={3}
                        startPoint={0}
                        defaultValue={formValues.scale}
                        dotStyle={{ borderColor: 'gray' }}
                        activeDotStyle={{ borderColor: 'gray' }}
                        handleStyle={{
                            borderColor: impact.color,
                        }}
                        trackStyle={{ backgroundColor: impact.color }}
                    />
                    <SliderName value={formValues.scale} /> */}
                </Col>
                <Col sm="3" style={{ flex: 1, minWidth: "50%" }}>
                    <span>Sinalizar</span>
                    {/* <GroupButton
                        defaultValue={formValues.signal}
                        setValue={(value) => onFormChange("signal", formValues.signal && formValues.signal.includes(value) ? formValues.signal.filter(item => item != value) : formValues.signal.concat(value))}
                        group={groupSignal}
                    /> */}
                    <ButtonGroup
                        buttons={groupSignal}
                        onClick={changeSignal}
                        active={verificationActive}
                        className={"link-group"}
                    />
                </Col>
            </Row>
        </div>
    )
};