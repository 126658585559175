import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { StyleSheet } from '@react-pdf/renderer';
import { pdfjs } from 'react-pdf';
import { toDate, toDateFormat } from '../../views/works/InspectionView';
import { getLibByIdData } from 'core/redux-store/lib/Actions';
import { useDispatch } from 'react-redux';
import {
    Spinner
} from "reactstrap";
import { ModalMessageContainer } from '../../container/Message/ModalMessageContainer';
import { OutlineButton, SaveButton } from '../../layoutV2/components/Button';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});

const toDataUrl = async (url) => {
    return new Promise((resolve) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                resolve(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    })
}


const getComment = async (dispatch, sections) => {
    const commentIds = [];
    const promises = [];
    const promisesImages = [];
    sections.map(section => {
        return section.content.map(content => {
            if ((content.commentId || (content.comment && content.comment.value))) {
                const commentId = content.comment ? content.comment.value : content.commentId;
                if (!commentIds.includes(commentId)) {
                    commentIds.push(commentId);
                    promises.push(dispatch(getLibByIdData({ id: commentId })).then(item => ({ isComment: true, ...item[0] })));
                    // promisesImages.push(getItem(`Libs/${commentId}/images`).then(images => ({
                    //     id: commentId,
                    //     images: images.filter((image) => !image.isHiding)
                    // })));
                }
            }
        })
    })

    return Promise.all(promises.concat(promisesImages)).then(items => {
        // const commentImages = items.filter(item => !item.isComment);
        const comments = items.filter(item => item.isComment);

        return { comments }
    });
}

/* const prepareSections = async (sections) => {   
    const promises = []; 
    sections.map(section => {
        return section.content.map(content => {
            return content.images.map(image => {
                promises.push(toDataUrl(image.url).then((myBase64) => {
                    return {id: image.id, url: myBase64}; // myBase64 is the base64 string
                }));
            })
        })
    })

    return Promise.all(promises);
} */


export const onPDFGenerate = async (dispatch, setDownloadUrl, state, sections, allData, setLoadingPdf, setIsOpen, showBlankSpaces, steps, showSteps, showRecurrency, recurrencies, user) => {
    setLoadingPdf(true);
    setIsOpen(true);
    const { comments } = await getComment(dispatch, sections);
    let url = "https://us-central1-app-ike.cloudfunctions.net/api3/report-pdf"
    // let url = "http://localhost:5000/app-ike/us-central1/api3/report-pdf"


    const dates = [];

    sections.map(section => {
        if (section.content) {
            section.content.map(content => {
                dates.push(toDate(content.date))
            })
        }
    })

    const maxDate = dates.reduce(function (a, b) { return a > b ? a : b; });
    const minDate = dates.reduce(function (a, b) { return a < b ? a : b; });

    fetch(url, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            company: state.company,
            work: { ...state.work, regProf: user && user.regProf, responsible: user && user.name },
            date: toDateFormat(new Date),
            sections,
            comments,
            showBlankSpaces,
            showSteps, steps,
            showRecurrency, recurrencies,
            // inspection,
            maxDate: toDateFormat(maxDate),
            minDate: toDateFormat(minDate),
            allData
        })
    }).then(response => {
        return response.json();
    }).then((data) => {
        const { downloadUrl } = data;

        // window.open(downloadUrl, "_blank");
        setLoadingPdf(false);
        setDownloadUrl(downloadUrl);
    }).catch(error => {
        setLoadingPdf(false);
    });
}

export const PDFModal = ({ downloadUrl, setDownloadUrl, isOpen, setIsOpen, sections, location, loadingPdf, allData, setLoadingPdf, showBlankSpaces, steps, showSteps, showRecurrency, recurrencies, user }) => {
    const dispatch = useDispatch();
    return (sections && sections.length > 0) && (
        <div className="button-pdf">
            {/* <Button disabled={loadingPdf} size="sm" className="button-filter" outline color="primary" onClick={() => onPDFGenerate(dispatch, setDownloadUrl, location.state, sections, allData, setLoadingPdf, setIsOpen, showBlankSpaces, steps, showSteps, showRecurrency, recurrencies)}>
                {"Gerar relatório"}
            </Button> */}
            <SaveButton disabled={loadingPdf} label="Gerar relatório" onClick={() => onPDFGenerate(dispatch, setDownloadUrl, location.state, sections, allData, setLoadingPdf, setIsOpen, showBlankSpaces, steps, showSteps, showRecurrency, recurrencies, user)} />
            <ModalMessageContainer
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                company={location.state.company}
                downloadUrl={downloadUrl}
                loadingPdf={loadingPdf}
            />
        </div>
    );
};