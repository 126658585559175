import { toast } from 'react-toastify';
import { notifyError, notifySuccess } from '../../layoutV2/components/Toast';

const options = {
	position: toast.POSITION.BOTTOM_LEFT,
	hideProgressBar: true
}

class Alert {
	static success(text) {
		// toast.success(text, options);
		notifySuccess(text)
	}

	static warn(text) {
		// toast.warn(text, options);
		notifyError(text)
	}

	static error(text) {
		// toast.error(text, options);
		notifyError(text)
	}
}

export default Alert;