import swal from 'sweetalert';

class ModalAlert {
    static success({ title = "", text = "", confirmLabel = "Sim", onConfirm = () => { } }) {
        swal({
            title,
            text,
            icon: "success",
            buttons: {
                cancel: {
                    text: "Cancelar",
                    visible: true,
                },
                confirm: {
                    text: confirmLabel,
                    value: true,
                    visible: true,
                    className: "confirm",
                }
            }
        }).then((value) => {
            // console.log(value)
            if (value) {
                // console.log("passou")
                onConfirm()
            }
        });
    }

    static warn({ title = "", text = "", confirmLabel = "Sim", hideConfirm = false, onConfirm = () => { } }) {
        swal({
            title,
            text,
            icon: "warning",
            buttons: {
                cancel: {
                    text: "Cancelar",
                    visible: true,
                },
                confirm: {
                    text: confirmLabel,
                    value: true,
                    visible: !hideConfirm,
                    className: "warning"
                }
            }
        }).then((value) => {
            // console.log(value)
            if (value) {
                // console.log("passou")
                onConfirm()
            }
        });
    }

    static danger({ title = "", text = "", confirmLabel = "Sim", onConfirm = () => { } }) {
        swal({
            title,
            text,
            dangerMode: true,
            icon: "error",
            buttons: {
                cancel: {
                    text: "Cancelar",
                    visible: true,
                },
                confirm: {
                    text: confirmLabel,
                    value: true,
                    visible: true,
                    className: "danger"
                }

            }
        }).then((value) => {
            // console.log(value)
            if (value) {
                // console.log("passou")
                onConfirm()
            }
        });
    }

    static default({ title = "", text = "", confirmLabel = "Sim", onConfirm = () => { } }) {
        swal({
            title,
            text,
            buttons: {
                cancel: {
                    text: "Cancelar",
                    visible: true,
                },
                confirm: {
                    text: confirmLabel,
                    value: true,
                    visible: true,
                    className: "warning"
                }

            }
        }).then((value) => {
            if (value) {
                onConfirm()
            }
        });
    }
}

export default ModalAlert;