import React from 'react';
import { ResponsiveBar } from '@nivo/bar'

export const NivoBarChart = ({ data, axisBottom, keys, label, legends, bottom = 70 }) => (
    <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="step"
        margin={{ top: 50, right: 60, bottom, left: 60 }}
        padding={0.3}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={axisBottom}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Ocorrências',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        // groupMode={"grouped"}
        // layout={"horizontal"}
        label={label}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        /* legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]} */
        legends={legends}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
    />
)