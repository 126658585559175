import React, { useState, useRef } from "react";
import {
	Row,
	Col,
	Button,
	Card,
	CardHeader,
	CardTitle,
	FormGroup,
	Input,
} from "reactstrap";
import Creatable from 'react-select/lib/Creatable';
import FileSimpleContainer from "../../container/FileSimpleContainer";
import { emailRegex } from '../../core/database/validator/Regex';
import Alert from '../../components/Alert/Alert';
import Dialog from 'react-bootstrap-dialog';
import { ButtonGroup } from "react-bootstrap";
import { ScheduleContainer } from "../../container/Works/ScheduleContainer";
import UserContainer from "../../container/UserContainer"
import { useSelector } from 'react-redux'
import { FilterPicker } from "../../components/Filter/FilterHooks";
import { normalizeCombo } from "../../core/utils/Utils";
import Select from 'react-select';
import { useEffect } from "react";
import { SelectInput, TextInput } from "../../layoutV2/components/Form";
import { OutlineButton, SaveButton } from "../../layoutV2/components/Button";
import ModalAlert from "../../layoutV2/components/Modal/ModalAlert";

let defaultView = 0;

const CompanyDetailsCard = (props) => {
	const [isView, setIsView] = useState(defaultView);
	// const dialog = createRef();
	// const dialog = useRef();

	// useEffect(() => {
	// 	Dialog.setOptions({
	// 		defaultOkLabel: 'Sim',
	// 		defaultCancelLabel: 'Cancelar',
	// 		primaryClassName: 'btn-success'
	// 	})
	// }, [])

	const confirmDelete = () => {
		/* dialog.current.show({
			title: 'Empresa',
			body: 'Deseja mesmo remover esta empresa? Ao realizar esta ação o item e suas obras não poderão mais ser acessados e a ação não poderá ser revertida.',
			defaultOkLabel: 'Sim',
			defaultCancelLabel: 'Cancelar',
			actions: [
				Dialog.CancelAction(),
				Dialog.OKAction(() => {
					props.onDelete();
				})
			],
			bsSize: 'small',
		}) */
		ModalAlert.danger({
			title: "Empresa",
			text: "Deseja mesmo remover esta empresa? Ao realizar esta ação o item e suas obras não poderão mais ser acessados e a ação não poderá ser revertida.",
			onConfirm: props.onDelete
		})
	}

	const validateEmail = (emails) => {
		if (emails) {
			return emails.map(email => {
				if (email.value) {
					if (emailRegex.test(email.value)) {
						return email;
					} else {
						Alert.error(`Formato de email inválido: ${email.value}`);
					}
				}
			}).filter(item => item);
		}

		return [];
	}

	const onViewChange = (view) => {
		setIsView(view);
		defaultView = view;
	}


	const { id, onChange, saveData, name, onFileChange, file, workGroup, isAdmin, allGroups } = props;
	return (
		<>
			<div className="v2-company-details">
				<div className="header-company">
					<Col className="col-img">
						<FileSimpleContainer circle={true} file={file} mini onFileChange={onFileChange} single={true} dataReference={`Images/Companies/data`} />
					</Col>
					<Col className="col-info">
						<TextInput
							id="company-name"
							placeholder="Nome"
							type="text"
							value={name}
							onChange={(event) => onChange("name", event.target.value)}
						/>
						{isAdmin && (
							<>
								<SelectInput
									options={allGroups}
									title="Grupo de empresa"
									labelledBy={"Grupo de empresa"}
									value={allGroups.find(group => group.value === workGroup)}
									onChange={data => onChange("workGroup", data.value)}
									isSingle={true}
								/>
							</>
						)}
					</Col>
				</div>
				<div>
					<Col sm="12" className="company-footer padding">
						{id && <OutlineButton label="Remover empresa" onClick={confirmDelete} />}
					</Col>
				</div>
				{id && <UserContainer {...props} scope={{ value: id }} workGroup={workGroup} />}
				<div>
					<Col sm="12" className="company-footer padding">
						<SaveButton onClick={saveData} />
					</Col>
				</div>
			</div>
			{/* <Dialog ref={dialog} /> */}
		</>
	);
}

export default CompanyDetailsCard;