import React, { useState, useEffect, useRef } from "react";
import CustomTable from "../components/CustomTable/CustomTable";
import { Row, Col, Badge, Button } from 'reactstrap';
import { defaultColor } from "../variables/theme";
import { MTableActions, MTableFilterRow, MTableEditRow, MTableToolbar } from 'material-table';
import { FilterSearch, FilterTag, FilterYear, FilterPicker } from "../components/Filter/FilterHooks";
import AdminNavbar from '../components/Navbars/AdminNavbar';
import { BreadcrumbHeader } from '../components/Breadcrumb/Breadcrumb';
import sort from 'fast-sort';
import { getWorkTypes } from "../core/database/skill";
import { normalizeCombo } from "../core/utils/Utils";
import GenericMaterialTable from "../components/GenericMaterialTable";
import { useHasPowerInGroup } from "../core/user/hooks";
import { EditButton, ViewButton } from "../components/Button/Button";
import { AddNavButton } from "../layoutV2/components/Button";
import { CommentContainer } from "../container/CommentContainer";
import { useSelector } from "react-redux";

let defaultValues = {}
let defaultPage = 0;

const getFilteredData = (data, comboWorks, isProject) => {
  let newData = data
  if (!isProject) {
    newData = newData.filter(item => !item.workType || item.workType.length === 0 || item.workType.filter(type => comboWorks.find(row => row.value === type.value)).length > 0)
  }
  return newData
}

const filterWorkType = (rowData, _field, workType) => {
  const arrWorkTypes = workType && workType.map(item => {
    return item.value
  })
  // console.log({ rowData, _field, workType, arrWorkTypes })
  return !workType || workType.length == 0 || (rowData.workType && rowData.workType.findIndex(row => arrWorkTypes.includes(row.value)) > -1);
}

const filterSteps = (rowData, _field, steps) => {
  const arrSteps = steps && steps.map(item => {
    return item.value
  })
  return !steps || steps.length == 0 || (rowData.linkSteps && rowData.linkSteps.findIndex(tag => arrSteps.includes(tag.value)) > -1);
}


const filterTags = (rowData, _field, tags) => {
  const arrTags = tags && tags.map(item => {
    return item.value
  })
  return !tags || tags.length == 0 || (rowData.tags && rowData.tags.findIndex(tag => arrTags.includes(tag.value)) > -1);
}

const publicSearch = (item, _field, myItens, network) => {
  const selectedFilter = myItens ? [...new Set(myItens.map(item => item.value))] : []
  return selectedFilter.length === 0 || selectedFilter.length === 2 || (selectedFilter.includes(1) && item.workGroup === network) || (selectedFilter.includes(2) && item.workGroup !== network);
}

const LibRow = ({ rowData, isComment }) => {
  return (
    <div className="lib-row">
      <h5>{rowData.name}</h5>
      {!isComment && <div className="lib-row-info">
        {rowData.year && <p>{`Ano ${rowData.year}`}</p>}
        {
          rowData.tags && rowData.tags.map(tag => {
            return <Badge color="primary" className="badge-tag">{tag.label}</Badge>
          })
        }
      </div>}
    </div>
  )
}

const Libs = (props) => {
  const { data, isDetails, skills, hideHistory, isComment, isProject } = props;
  const [filter, setFilter] = useState(defaultValues);
  const [comboWorkTypes, setComboWorkTypes] = useState([])
  const hasPowerInGroup = useHasPowerInGroup()
  const user = useSelector(state => state.login)

  useEffect(() => {
    loadWorks()
  }, [])

  const loadWorks = async () => {
    // console.log({ props, user })
    const works = await getWorkTypes({ network: user.network })
    const newWorks = user.groupData && user.groupData.workTypeIds ? works.filter(work => user.groupData.workTypeIds.includes(work.value)) : works
    // console.log({ works, newWorks })
    setComboWorkTypes(newWorks);
  }

  const setPage = (page) => {
    defaultPage = page
  }

  sort(data).asc(u => {
    return u.linkSteps && u.linkSteps[0] && u.linkSteps[0].label
  });

  const formatSkills = () => {
    // console.log({ filter, workType: filter.workType, skills })
    const skillsFiltered = (filter && filter.workType && filter.workType.length > 0 && skills) ?
      skills.filter(tag => filter.workType.find(type => tag.parentId === type.value))
      : [];

    // console.log({ skillsFiltered })
    const combo = normalizeCombo("id", "name", skillsFiltered);
    sort(combo).asc(u => u.label);

    // console.log({ combo })
    return combo
  }

  const columns = [
    { title: "Nome", field: "name", defaultSort: 'asc', filtered: true, defaultSearch: true, render: (rowData) => <LibRow rowData={rowData} isComment={isComment} /> },
    !isProject && { title: "Tipo de obra", field: "workType", type: "multiple", options: comboWorkTypes, hidden: true, filtered: true, customSearch: filterWorkType },
    !isProject && { title: "Filtrar por etapa", field: "steps", type: "multiple", options: formatSkills(), hidden: true, filtered: true, isDisabled: !(filter.workType && filter.workType.length > 0), customSearch: filterSteps },
    isComment || !isProject && { title: "Tags", field: "tags", type: "multiple", hidden: true, filtered: true, customSearch: filterTags },
    !isProject && { title: "Ano", field: "year", type: "year", hidden: true, mask: "YYYY", filtered: true },
    { title: "Público", field: "isPublic", customSearch: (rowData, _field, myItens) => publicSearch(rowData, _field, myItens, props.network), options: [{value: 1, label: "Grupo"}, {value: 2, label: "Público"}], filtered: true, render: (rowData) => rowData.isPublic ? <span className="badge-public info">Público</span> : <span className="badge-public success">Grupo</span> },
  ].filter(item => item)

  const onFilter = (filter) => {
    setFilter(filter)
  }

  console.log({ data })
  console.log({ items: data.filter(item => item.isComment) })
  return (
    <>
      <div className="content lib-list">
        {!hideHistory && <AdminNavbar>
          {/* aqui nova lib */}
          {hasPowerInGroup && <AddNavButton onClick={props.onAddClick} label={isComment ? " Novo Apontamento" : "Novo Documento"} />}
        </AdminNavbar>}
        <GenericMaterialTable
          title="Biblioteca"
          idTable={`${isComment ? "comment" : "library"}-list`}
          options={{ selection: isDetails, search: true, paging: true, initialPage: defaultPage }}
          columns={columns.filter(item => item !== false)}
          {...props}
          onAddClick={hasPowerInGroup && props.onAddClick}
          onChangePage={page => setPage(page)}
          onFilter={onFilter}
          data={getFilteredData(data, comboWorkTypes, isProject)}
          // marginZero={true}
          wordWrap={true}
          // data={getFilteredData(data, tags, values, dates, dsSearch)}
          actions={[
            rowData => ({
              icon: (props) => isComment ? <ViewButton {...props} /> : <EditButton {...props} />,
              iconProps: {
                style: {
                  fontSize: 24,
                  color: defaultColor
                }
              },
              tooltip: isComment ? 'Ver Documento' : 'Editar Documento',
              onClick: (_event, rowData) => props.onEditClick({
                ...rowData, isComment
              })
            }),
            rowData => !props.isAdmin && (rowData.workGroup === props.network) && (props.groupData && props.groupData.responsible === props.email) && {
              icon: 'delete_outlined',
              iconProps: {
                style: {
                  fontSize: 24,
                  color: defaultColor
                }
              },
              tooltip: 'Excluir Documento',
              onClick: (_event, rowData) => props.onDeleteItem(rowData)
            }
          ]}
          detailPanel={isComment && [
            {
              tooltip: 'Mostrar detalhes',
              render: rowData => {
                return (
                  <div className="list-comment table-embeded" >
                    <CommentContainer id={rowData.id} onSave={{}} defaultDetails={rowData} />
                  </div>
                )
              },
            },
          ]}
        // editableRow={item => {
        //   return <tr><td colspan="4"><table className="edit-row"><MTableEditRow {...item} /></table></td></tr>
        // }}
        />
      </div>
    </>
  )
}

export default Libs;
