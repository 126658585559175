import { defaultReducer } from "./Actions";
import { failure } from "core/utils/redux/Reducers";

export const initialState = {
	isLoading: true,
	error: null,
	email: undefined,
	items: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case 'SET_INVITE_ERROR':
			state = failure(state, action.payload.error);
			break;
		default:
			state = defaultReducer(state, action);
			break;
	}
	return state;
}