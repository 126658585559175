import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Skills from "views/Skills.jsx";
import { getSkillData, updateSkillData, deleteSkillData, addSkillData } from "core/redux-store/skill/Actions";
import { getLibData } from 'core/redux-store/lib/Actions';
import { SkillsModal } from "../components/Skills/SkillsModal";
import { useSelector } from 'react-redux'
import { getGroup } from '../core/database/group'
import ModalAlert from "../layoutV2/components/Modal/ModalAlert";

const SkillContainer = ({ dispatch, history, items, isLoading, titleList }) => {
	const [newItem, setNewItem] = useState();
	const [isOpen, setIsOpen] = useState(false)
	const [data, setData] = useState([])
	const [myItems, setMyItems] = useState()
	const [allGroups, setAllGroups] = useState([])
	const loggedUser = useSelector(state => state.login)
	const [values, setValues] = useState({
		name: "",

	})
	
	function getChildren(oldData) {
		let newItems = [];
		newItems.push(oldData);
		if (oldData.id) {
			const childrens = data.filter(item => item.parentId === oldData.id);
			childrens.map(children => {
				newItems = newItems.concat(getChildren(children));
			})
		}
		return newItems;
	}

	function onRowAdd(newData) {

		if (newData && newData.name) {
			const libData = {}
			if (newData.lib || newData.changedLib) {
				libData['lib'] = newData.changedLib || newData.lib;
			}
			const data = newData.group ? { name: newData.name, group: newData.group, ...libData, parentId: newData.parentId || '', level: newData.level || 0 } : { name: newData.name, ...libData, parentId: newData.parentId || '', level: newData.level || 0 }

			cancelEditing();
			return dispatch(addSkillData(data));


		} else {
			cancelEditing();
			return Promise.all([])
		}
	}

	function onRowDelete(oldData) {

		const childrens = getChildren(oldData);
		const promises = [];
		childrens.map(children => {
			promises.push(dispatch(deleteSkillData(children.id)));
		})
		return Promise.all(promises);

	}

	function onRowUpdate(newData, oldData) {
		const libData = {};
		const { name, id, level, parentId } = newData;
		if (name && id) {
			if (oldData.lib || oldData.changedLib) {
				libData['lib'] = oldData.changedLib || oldData.lib;
			}
			cancelEditing();
			return dispatch(updateSkillData({ id, name, parentId, level, ...libData }));
		} else {
			cancelEditing();
			return Promise.all([])
		}
	}

	function expandTreeParent(data, newItem) {
		if (newItem) {
			const index = data.findIndex(item => item.id === newItem.parentId);

			if (index > 0) {
				const parent = data[index];
				if (parent) {
					parent.tableData = {
						...parent.tableData,
						isTreeExpanded: true
					}

					expandTreeParent(data, parent);
				}
			}
		}
	}

	function onNewItem() {
		setNewItem({
			parentId: "", isNew: true, level: 0, tableData: { editing: "add" }
		});
		setIsOpen("add")
	}

	function onEditItem(data) {
		setNewItem(data);
		setIsOpen("edit")
	}

	useEffect(() => {
		dispatch(getSkillData());
		dispatch(getLibData());
	}, [])

	useEffect(() => {
		if (data.length === 0) {
			setData(items)
		} else {
			const arrNew = [];

			items.map(item => {
				const dataOld = data.find(i => i.id === item.id);

				arrNew.push({
					...item,
					tableData: dataOld ? dataOld.tableData : {}
				})
			})

			setData(arrNew)
		}
	}, [items && JSON.stringify(items)])

	useEffect(() => {
		expandTreeParent(items, newItem);
	}, [newItem])

	useEffect(() => {
		loadGroup()
	}, [])

	function cancelEditing() {
		setNewItem(undefined);
		setIsOpen(false)
	}

	function onAddChildren(data) {
		const group = data && data.group
		setNewItem({
			parentId: data ? data.id : "", group, isNew: true, level: data.level !== undefined ? data.level + 1 : 0,
			tableData: { editing: "add" }
		});
		setIsOpen("add")
	}

	function onAdvertising(data) {
		history.push("/admin/advertising-details", data);
	}
	const formattedData = (data, myItems) => {
		let filteredData = data.filter(item => item && item.group && item.group.value ? item.group.value === loggedUser.network : { ...item })
		const selectedFilter = myItems ? [...new Set(myItems.map(item => item.value))] : []
		filteredData = filteredData.filter(item => selectedFilter.length === 0 || selectedFilter.length === 2 || (selectedFilter.includes(1) && item.group && item.group.value === loggedUser.network) || (selectedFilter.includes(2) && !item.group))
		return filteredData
	}

	const loadGroup = async () => {
		const group = await getGroup({ workGroup: loggedUser.workGroup, email: loggedUser.email })
		setAllGroups(group)
	}


	const onDeleteItem = async (oldData) => {

		ModalAlert.danger({
			title: "Excluir",
			text: "Você deseja mesmo excluir o item selecionado?",
			onConfirm: () => onRowDelete(oldData)
		})
	}

	return (
		<>
			<SkillsModal
				values={values}
				setValues={setValues}
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				data={newItem}
				onRowAdd={onRowAdd}
				onRowUpdate={onRowUpdate}
				cancelEditing={cancelEditing}
				loggedUser={loggedUser}
				allGroups={allGroups}
				realData={data}
			/>
			<Skills
				isAdmin={loggedUser.isAdmin}
				network={loggedUser.network}
				title={"Vistoria"}
				titleList={titleList}
				data={formattedData(data, myItems)}
				setMyItems={setMyItems}
				myItems={myItems}
				isLoading={isLoading}
				onRowAdd={onRowAdd}
				onAdvertising={onAdvertising}
				// onRowUpdate={onRowUpdate}
				onDeleteClick={onDeleteItem}
				// onDeleteClick={onDeleteClick}
				onAddChildren={onAddChildren}
				cancelEditing={cancelEditing}
				onAddClick={onNewItem}
				onEditClick={onEditItem}
				// onValueChange={onValueChange}
				// comboOptions={normalizeCombo("id", "name", this.props.libItems)}
				comboOptions={[]}
				options={{ paging: false }}
			/>
		</>
	)
}

// class SkillContainer extends React.Component {
// 	static defaultProps = {
// 		title: "Vistoria"
// 	}
// 	constructor(props) {
// 		super(props);

// 		this.state = {

// 		}

// 		this.onRowAdd = this.onRowAdd.bind(this);
// 		this.getChildren = this.getChildren.bind(this);
// 		this.onRowDelete = this.onRowDelete.bind(this);
// 		this.onRowUpdate = this.onRowUpdate.bind(this);
// 		this.onAddChildren = this.onAddChildren.bind(this);
// 		this.onAdvertising = this.onAdvertising.bind(this);
// 		this.cancelEditing = this.cancelEditing.bind(this);
// 		this.expandTreeParent = this.expandTreeParent.bind(this);
// 	}

// 	onAdvertising(data) {
//         this.props.history.push("/admin/advertising-details", data);
//     }

// 	onAddChildren(data) {
// 		this.setState({newItem: { 
// 			parentId: data.id, isNew: true, level: data.level !== undefined ? data.level + 1 : 0, 
// 			tableData: {editing: "add"}
// 		}});
// 	}

// 	getChildren(oldData) {
// 		const { items } = this.props;
// 		let newItems = [];
// 		newItems.push(oldData);
// 		if(oldData.id) {
// 			const childrens = items.filter(item => item.parentId === oldData.id);
// 			childrens.map(children => {
// 				newItems = newItems.concat(this.getChildren(children));
// 			})
// 		}
// 		return newItems;
// 	}

// 	onRowAdd(newData) {
// 		if(newData && newData.name){
// 			const libData = {}
// 			if(newData.lib || newData.changedLib){
// 				libData['lib'] = newData.changedLib || newData.lib;
// 			}
// 			const data = { name: `  ${newData.name}`, ...libData, parentId: newData.parentId || '', level: newData.level || 0  };
// 			this.cancelEditing();
// 			return this.props.dispatch(addSkillData(data));
// 		} else {
// 			this.cancelEditing();
// 			return Promise.all([])
// 		}
// 	}

// 	onRowDelete(oldData) {
// 		const childrens = this.getChildren(oldData);
// 		const promises = [];
// 		childrens.map(children => {
// 			promises.push(this.props.dispatch(deleteSkillData(children.id)));			
// 		})
// 		return Promise.all(promises);

// 	}

// 	onRowUpdate(newData, oldData) {
// 		const libData = {};
// 		const { name, id, level, parentId } = newData;
// 		if(name && id){
// 			if(oldData.lib || oldData.changedLib){
// 				libData['lib'] = oldData.changedLib || oldData.lib;
// 			}
// 			return this.props.dispatch(updateSkillData({ id, name, parentId, level, ...libData  }));
// 		} else {
// 			this.cancelEditing();
// 			return Promise.all([])
// 		}
// 	}

// 	cancelEditing(){
// 		this.setState({newItem: undefined});
// 	}

// 	componentWillMount() {
// 		this.getData();
// 	}

// 	getData(){
// 		this.props.dispatch(getSkillData());
// 		this.props.dispatch(getLibData());
// 	}

// 	expandTreeParent(data, newItem){
// 		if(newItem){
// 			const index = data.findIndex(item => item.id === newItem.parentId);

// 			if(index > 0){
// 				const parent = data[index];
// 				if(parent){
// 					parent.tableData = {
// 						...parent.tableData,
// 						isTreeExpanded: true
// 					}

// 					this.expandTreeParent(data, parent);
// 				}
// 			}
// 		}
// 	}

// 	render() {
// 		const { newItem } = this.state;
// 		const data = newItem ? this.props.items.concat([newItem]) : this.props.items;
// 		this.expandTreeParent(data, newItem);

// 		return (
// 			<Skills
// 				title={this.props.title}
// 				data={data}
// 				isLoading={this.props.isLoading}
// 				onRowAdd={this.onRowAdd}
// 				onAdvertising={this.onAdvertising}
// 				onRowUpdate={this.onRowUpdate}
// 				onRowDelete={this.onRowDelete}
// 				onAddChildren={this.onAddChildren}
// 				cancelEditing={this.cancelEditing}
// 				onValueChange={this.onValueChange}
// 				comboOptions={normalizeCombo("id", "name", this.props.libItems)}
// 			/>
// 		);
// 	}
// }

export default connect(state => ({
	isLoading: state.skill.isLoading,
	items: state.skill.items,
	libItems: state.lib.items,
	errorMessage: state.skill.error
}))(SkillContainer);
