import React from "react";
import ReactDOM from "react-dom";
import App from './App';
import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import configureStore, { getPersist } from 'core/redux-store/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { setConfig } from 'react-hot-loader';
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";
import Loader from 'views/pages/Loader';

export const history = createBrowserHistory();
const store = configureStore();

setConfig({
  reloadHooks: false,
});

const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<Loader/>} persistor={getPersist()}>
          <Router history={history}>
            <Component />
          </Router>
        </PersistGate>
      </Provider>,
    document.getElementById('root')
  );
};

render(App);


if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(NextApp);
  })
}