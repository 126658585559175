import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardWorksView from '../../views/works/CardWorksView';
import { withRouter } from 'react-router-dom';
import { scopeHasPower } from '../UserContainer';

function mapStateToProps(state) {
    return {
        isAdmin: state.login.isAdmin,
        groups: state.login.groups
    };
}

class CardWorksContainer extends Component {

    constructor(props) {
        super(props);
        this.onCardClick = this.onCardClick.bind(this)
        this.onEditClick = this.onEditClick.bind(this)
    }


    onCardClick() {
        const { history, id, name, file, workType, company, address, responsible, regProf } = this.props;
		history.push("/admin/works-inspection", {company, work: {name, image: file.url, address, responsible, regProf}, workId: id, workType});
    }

    onEditClick(e) {
        e.stopPropagation();
        const { history, id } = this.props;
		history.push("/admin/works-details", {id});
    }
    
    render() {
        const { idCompany, groups, isAdmin } = this.props;
        const hasPower = isAdmin || scopeHasPower(idCompany, groups)
        return (
            <CardWorksView
                onCardClick={this.onCardClick}
                onEditClick={this.onEditClick}
                hasPower={hasPower}
                {...this.props}
            />
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
)(CardWorksContainer));