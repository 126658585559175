import React from 'react';
import CustomTable from '../../components/CustomTable/CustomTable';
import { Row, Col, Badge } from 'reactstrap';
import moment from 'moment';
import { defaultColor, warningColor } from '../../variables/theme';
import GenericMaterialTable from '../../components/GenericMaterialTable';
import { AddAlertButton, ViewQuiltButton } from '../../components/Button/Button';

export const StatusEnum = {
    planejado: 1,
    liberado: 2,
    executando: 3,
    concluido: 4,
    parado: 5,
    impedido: 6
}

export const StatusEnumName = {
    1: "Planejado",
    2: "Liberado",
    3: "Executando",
    4: "Concluído",
    5: "Parado",
    6: "Impedido"
}

export const BadgeStatus = ({ rowData, onClickRestart }) => {
    return rowData && rowData.status ? (
        <>
            {rowData.status === StatusEnum.liberado && <Badge style={{ borderRadius: 30, maxWidth: 90, marginRight: 10, alignSelf: "center", ...rowData.style }} color="info" onClick={() => onClickRestart(rowData, 2)}>Liberado</Badge>}
            {rowData.status === StatusEnum.executando && <Badge style={{ borderRadius: 30, maxWidth: 90, marginRight: 10, alignSelf: "center", ...rowData.style }} color="danger" onClick={() => onClickRestart(rowData, 3)}>Executando</Badge>}
            {rowData.status === StatusEnum.planejado && <Badge style={{ borderRadius: 30, maxWidth: 90, marginRight: 10, alignSelf: "center", ...rowData.style }} color="default" onClick={() => onClickRestart(undefined, 1)}>Planejado</Badge>}
            {rowData.status === StatusEnum.concluido && <Badge style={{ borderRadius: 30, maxWidth: 90, marginRight: 10, alignSelf: "center", ...rowData.style }} color="success" onClick={() => onClickRestart(undefined, 4)}>Concluído</Badge>}
            {rowData.status === StatusEnum.parado && <Badge style={{ borderRadius: 30, maxWidth: 90, marginRight: 10, alignSelf: "center", ...rowData.style, backgroundColor: "#dc3545", borderColor: "#dc3545" }} color="success" onClick={() => onClickRestart(undefined, 5)}>Parado</Badge>}
            {rowData.status === StatusEnum.impedido && <Badge style={{ borderRadius: 30, maxWidth: 90, marginRight: 10, alignSelf: "center", ...rowData.style, backgroundColor: "#e65b4e", borderColor: "#e65b4e" }} color="success" onClick={() => onClickRestart(undefined, 6)}>Impedido</Badge>}
        </>
    ) : <div />
}

const filterStatus = (rowData, _field, status) => {
    const arrStatus = status && status.map(item => {
        return item.value
    })
    return !status || status.length == 0 || arrStatus.includes(rowData.status);
}

const columns = (compareDate, onClickRestart, user, workData) => [
    {
        title: 'Nome',
        field: 'Name',
        defaultSearch: true,
        filtered: true,
        // defaultSort: 'asc',
        cellStyle: {
            maxWidth: 300,
            minWidth: 300,
        },
        customSort: compareDate,
        render: rowData => (
            <Row className={`row-user padding-${rowData.level}`}>
                <Col className="details schedule-list">
                    {/* {rowData.UID === -1 ? `Fichas de inspeção ${rowData.Name}` : `${rowData.OutlineNumber} ${rowData.Name}`} */}
                    {workData && workData.noSchedule ? `${rowData.Name}` : `${rowData.OutlineNumber} ${rowData.Name}`}
                </Col>
            </Row>
        )
    },
    {
        title: 'Estado',
        field: 'status',
        cellStyle: {
            maxWidth: 100,
            minWidth: 100,
        },
        customSearch: filterStatus,
        render: rowData => (
            <Row className="row-user">
                <Col className="details schedule-list badge-status">
                    <BadgeStatus rowData={rowData} onClickRestart={onClickRestart} />
                </Col>
            </Row>
        )
    },
    {
        title: 'Início',
        field: 'startDate',
        defaultSort: 'asc',
        customSort: compareDate,
        moreFiltered: true,
        type: 'moreFiltered',
        cellStyle: {
            maxWidth: 85,
            minWidth: 85,
        },
        render: rowData => (
            <Row className={`row-user padding-${rowData.level}`}>
                <Col className="details schedule-list">
                    {!user.groupData.isModel && rowData.Start && moment(rowData.Start).format("DD/MM/YYYY")}
                </Col>
            </Row>
        )
    },
    {
        title: 'Fim',
        field: 'endDate',
        defaultSort: 'asc',
        customSort: compareDate,
        cellStyle: {
            maxWidth: 120,
            minWidth: 120,
        },
        render: rowData => (
            <Row className={`row-user padding-${rowData.level}`} style={{ marginRight: 30 }}>
                <Col className="details schedule-list">
                    {!user.groupData.isModel && rowData.Finish && moment(rowData.Finish).format("DD/MM/YYYY")}
                </Col>
            </Row>
        )
    },
]

// import { Container } from './styles';

const ScheduleList = (props) => {
    const { title, setEditRecord, compareDate, getFilteredData, data, filterValues, history, alerts, rightComponent, moreModalFields, onClickRestart, user, workData } = props;
    console.log({ props })
    return <GenericMaterialTable
        title={title}
        columns={columns(compareDate, onClickRestart, user, workData)}
        idTable={"schedule-list"}
        {...props}
        data={getFilteredData(data, filterValues)}
        isSchedule={true}
        parentChildData={(row, rows) => rows.find(a => a.OutlineNumber !== row.OutlineNumber
            && a.OutlineNumber === row.OutlineNumber.substring(0, row.OutlineNumber.lastIndexOf('.')))
        }
        rightComponent={rightComponent}
        canAdd={false}
        moreModalFields={moreModalFields}
        actions={[
            rowData => {
                const alert = alerts.find(alert => alert.UID === rowData.UID);
                return ({
                    icon: (props) => !user.groupData.isModel && <AddAlertButton {...props} active={!alert} iconBlue />,
                    tooltip: "Sinalizar",
                    onClick: () => {
                        setEditRecord(rowData)
                    },
                    iconProps: {
                        style: {
                            fontSize: 24,
                            color: alert ? warningColor : defaultColor
                        }
                    }
                })
            },
            rowData => {
                return ({
                    icon: props => <ViewQuiltButton {...props} />,
                    tooltip: "Módulo de qualidade",
                    onClick: () => {
                        history.push("/admin/quality", { ...rowData, ...props.location, allData: getFilteredData(data, filterValues) })
                    },
                    iconProps: {
                        style: {
                            fontSize: 24
                        }
                    }
                })
            },
        ]}
    />
}

export default ScheduleList;