import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const BreadcrumbHeaderRouter = ({ history, data }) => {
  // const goTo = (item, index) => {
  //   if (item.route) {
  //     if (item.params) {
  //       history.push(item.route, item.params)
  //     } else {
  //       history.push(item.route)
  //     }
  //   } else if (data.length - 2 === index) {
  //     history.goBack();
  //   }
  // }

  return <div />;

  // return (
  //   <Breadcrumb className="breadcrumb" tag="nav" listTag="div">
  //     {data.map((item, index) => {
  //       return <BreadcrumbItem active={item.active} onClick={() => goTo(item, index)}>{item.name}</BreadcrumbItem>
  //     })}
  //   </Breadcrumb>
  // );
};

export const BreadcrumbHeader = withRouter(BreadcrumbHeaderRouter)