import { actionsCreator } from "core/utils/redux/Actions";
import { getEvaluation, addEvaluation, deleteEvaluation, updateEvaluation} from "core/database/evaluation";

const { actions, functions } = actionsCreator("evaluation");
export { actions };

export const getEvaluationData = (id) => {
	return dispatch => {
		dispatch(functions.getEvaluationBegin({}))
		return getEvaluation(id).then(response => {
			dispatch(functions.getEvaluationSuccess({ ...response }));
		}).catch(error => {
			dispatch(functions.getEvaluationError(error.message))
		})
	}
}
export const addEvaluationData = (item) => {
	return dispatch => {
		dispatch(functions.addEvaluationBegin({}))
		return addEvaluation(item).then(response => {
			dispatch(functions.addEvaluationSuccess({ ...response }));
		}).catch(error => {
			dispatch(functions.addEvaluationError(error.message))
		})
	}
}
export const deleteEvaluationData = (id) => {
	return dispatch => {
		dispatch(functions.deleteEvaluationBegin({}))
		return deleteEvaluation(id).then(response => {
			dispatch(functions.deleteEvaluationSuccess({ ...response }));
		}).catch(error => {
			dispatch(functions.deleteEvaluationError(error.message))
		})
	}
}
export const updateEvaluationData = (item) => {
	return dispatch => {
		dispatch(functions.updateEvaluationBegin({}))
		return updateEvaluation(item).then(response => {
			dispatch(functions.updateEvaluationSuccess({ ...response }));
		}).catch(error => {
			dispatch(functions.updateEvaluationError(error.message))
		})
	}
}
