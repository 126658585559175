import { imageNameUtils } from "./Utils";
import { storage } from '../../core/database/firebaseConfig';

export const uploadImage = (workId, inspectionId, image) => {
    const { url, filename, id } = image;
    const uniqueFilename = imageNameUtils(filename || id);
    const storageRef = storage.ref(`Works/${workId}/inpections/${inspectionId}/images`).child(uniqueFilename);
    const task = storageRef.putString(url.split('data:image/png;base64,')[1], 'base64', { contentType: 'image/png' });
    return new Promise((resolve) => {
        task.then(() => {
            resolve(storageRef.getDownloadURL());
        })
    })

}