import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { ListComment } from '../views/ListComment';
import { getLibData, deleteLibData, updateLibData, getLibTagsData } from '../core/redux-store/lib/Actions';
import { getSkillData } from '../core/redux-store/skill/Actions';
import { normalizeCombo } from '../core/utils/Utils';
import { getWorkTypes } from '../core/database/skill';
import Dialog from 'react-bootstrap-dialog'
import ModalAlert from '../layoutV2/components/Modal/ModalAlert';

const ListCommentContainer = (props) => {
    const [comboWorkTypes, setComboWorkTypes] = useState([])
    const isAdmin = useSelector(state => state.login.isAdmin);
    const network = useSelector(state => state.login.network);
    const libs = useSelector(state => state.lib.items || []);
    const comboTags = useSelector(state => state.skill.items || []);
    const email = useSelector(state => state.login.email)
    const groupData = useSelector(state => state.login.groupData)
    const { isDetails, report, onSelectedChange } = props;
    const dispatch = useDispatch();
    const dialog = createRef();

    useEffect(() => {
        loadWorks()
        dispatch(getSkillData({ level: 1 }));
        dispatch(getLibData({ tag: "IcjvF32VXhY7Y40fmIV9", workGroup: network }));
        Dialog.setOptions({
            defaultOkLabel: 'Sim',
            defaultCancelLabel: 'Cancelar',
            primaryClassName: 'btn-success'
        })
    }, []);

    const loadWorks = async () => {
        // const works = await getWorkTypes()
        // setComboWorkTypes(works);
        const works = await getWorkTypes({ network })
        const newWorks = groupData && groupData.workTypeIds ? works.filter(work => groupData.workTypeIds.includes(work.value)) : works
        setComboWorkTypes(newWorks);
    }

    function onRowDelete(oldData) {
        return dispatch(deleteLibData(oldData.id));
    }

    function onRowUpdate(newData, oldData) {
        return dispatch(updateLibData(newData));
    }

    function onNewItem() {
        props.history.push("/admin/comment-details");
    }

    function onEditItem(data) {
        const params = { ...data };
        delete params.tableData;
        props.history.push("/admin/comment-details", params);
    }

    function onDeleteItem(oldData) {
        /* dialog.current.show({
            title: 'Apontamento',
            body: 'Deseja mesmo remover este apontamento? Ao realizar esta ação o item não poderá mais ser acessado e a ação não poderá ser revertida.',
            defaultOkLabel: 'Sim',
            defaultCancelLabel: 'Cancelar',
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => {
                    // dispatch(deleteLibData(oldData.id));
                })
            ],
            bsSize: 'small',
        }) */
        ModalAlert.danger({
            title: "Apontamento",
            text: "Deseja mesmo remover este apontamento? Ao realizar esta ação o item não poderá mais ser acessado e a ação não poderá ser revertida.",
            onConfirm: () => dispatch(deleteLibData(oldData.id))
        })
    }

    // console.log({ idStep: props.idStep, libs });

    return (
        <>
            <ListComment
                data={libs.filter(item => !props.idStep || (props.idStep && item.linkSteps && item.linkSteps.find(item => item.value === props.idStep)))}
                onRowUpdate={isAdmin && onRowUpdate}
                onRowDelete={isAdmin && onRowDelete}
                onDeleteItem={onDeleteItem}
                onAddClick={(isAdmin || (groupData.responsible === email)) && onNewItem}
                onEditClick={onEditItem}
                isAdmin={isAdmin}
                isDetails={isDetails}
                report={report}
                onSelectedChange={onSelectedChange}
                comboTags={comboTags}
                comboWorkTypes={comboWorkTypes}
                network={network}
                groupData={groupData}
                email={email}
            />
            <Dialog ref={dialog} />
        </>
    );
};

export default ListCommentContainer;