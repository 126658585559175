import ActionCreator from "../../utils/redux/ActionCreator";
import { getInvite, registerUser } from "core/database/user";

const { actions, defaultReducer, get, set } = new ActionCreator("invite");
export { actions, defaultReducer };

export const getInviteData = get(getInvite);
export const updateRegisterData = get(registerUser);
export const setInviteError = set("SET_INVITE_ERROR");

/*
import { actionsCreator } from "core/utils/redux/Actions";


const { actions, functions } = actionsCreator("invite");
export { actions as userActions };

export const getInviteData = (id) => {
	return dispatch => {
		dispatch(functions.getInviteBegin({}))
		return getInvite(id).then(response => {
			dispatch(functions.getInviteSuccess({ ...response }));
		}).catch(error => {
			dispatch(functions.getInviteError(error.message))
		})
	}
}
export const addInviteData = (item) => {
	return dispatch => {
		dispatch(functions.addInviteBegin({}))
		return addInvite(item).then(response => {
			dispatch(functions.addInviteSuccess({ ...response }));
		}).catch(error => {
			dispatch(functions.addInviteError(error.message))
		})
	}
}
export const deleteInviteData = (id) => {
	return dispatch => {
		dispatch(functions.deleteInviteBegin({}))
		return deleteInvite(id).then(response => {
			dispatch(functions.deleteInviteSuccess({ ...response }));
		}).catch(error => {
			dispatch(functions.deleteInviteError(error.message))
		})
	}
}
export const updateInviteData = (item) => {
	return dispatch => {
		dispatch(functions.updateInviteBegin({}))
		return updateInvite(item).then(response => {
			dispatch(functions.updateInviteSuccess({ ...response }));
		}).catch(error => {
			dispatch(functions.updateInviteError(error.message))
		})
	}
}*/
