import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const Signal = ({ icon, name, count, backgroundColor, typeImprovement = undefined }) => {
    return <div
        id={`signal${count}${icon}`}
        style={{ display: "flex", alignItems: "center", backgroundColor: backgroundColor, color: "white", borderRadius: 8, fontSize: 12, marginRight: 10, padding: 5 }}
    >
        <i
            class="material-icons"
            style={{ color: "white", fontSize: 20 }}
        >
            {icon}
        </i>
        {/* {typeof typeImprovement === 'string' ? <div style={{ paddingInline: 5 }}>{typeImprovement}</div> : <div />} */}
        <UncontrolledTooltip placement="top" target={`signal${count}${icon}`}>
            {typeImprovement || name}
        </UncontrolledTooltip>
    </div>
}

export const SignalReport = ({ signal, count, typeImprovement }) => {
    switch (signal) {
        case 1:
            return <Signal
                icon="construction"
                name="Melhoria"
                count={count}
                backgroundColor="#2A3F92"
                typeImprovement={"Melhoria"}
            />;
        case 2:
            return <Signal icon="architecture" name="As Built" count={count} backgroundColor="#E84E1B" />
        case 3:
            return <Signal
                icon="attach_money"
                name="Custo"
                count={count}
                backgroundColor="#2A3F92"
                typeImprovement={"Custo"}
            />;
        case 4:
            return <Signal
                icon="schedule"
                name="Prazo"
                count={count}
                backgroundColor="#2A3F92"
                typeImprovement={"Prazo"}
            />;
    }
};