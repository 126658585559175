import React from "react";
import { connect } from "react-redux"
import ScopeContainer from '../ScopeContainer';
import CompanyDetailsView from '../../views/company/CompanyDetailsView';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import Alert from '../../components/Alert/Alert';
import { updateCompanyData, addCompanyData, getCompanyByIdData, deleteCompanyData } from '../../core/redux-store/company/Actions';
import { normalizeCombo } from '../../core/utils/Utils';
import { getGroupData, getAllGroupData } from "../../core/redux-store/group/Actions";
import { addUser, updateUser } from "../../core/database/user";
import { setLoginData } from "../../core/redux-store/login/Actions";
import { setCanReload } from "../Works/WorksContainer";

class CompanyDetailsContainer extends ScopeContainer {
	constructor(props) {
		super(props);

		this.state = {
			categories: []
		}

		this.onChange = this.onChange.bind(this);
		this.saveData = this.saveData.bind(this);
		this.onBack = this.onBack.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
	}

	onLocationChange(location) {
		const { state } = location;
		const { dispatch, workGroup } = this.props;
		if (state && state.id) {

			dispatch(getCompanyByIdData(state.id)).then(item => {
				// console.log({ item })
				this.setState({ ...item });
			})
		}
		// console.log({ workGroup })
		dispatch(getAllGroupData())
	}

	onScopeChange(scope) {
		const { dispatch } = this.props;
	}

	async saveData() {
		const { dispatch, network, groupData, user } = this.props;
		let { id, name, file, users, workGroup } = this.state;
		console.log({file})
		if (!workGroup) {
			workGroup = network
		}

		try {
			if (!name) {
				throw new Error(I18n[keyEnum.nameRequired]);
			}
			if (!file) {
				throw new Error(I18n[keyEnum.imageRequired]);
			}

			if (!users) {
				users = [];
			}

			if (!workGroup) {
				throw new Error(I18n[keyEnum.groupNotExists]);
			}

			const onDataCompleted = async (response) => {
				if (!id) {
					this.setState({ id: response })
				}
				setCanReload(true);
				Alert.success("Empresa Salva!")
			}

				if (id) {
					return dispatch(updateCompanyData({ id, name, file, workGroup, users: normalizeCombo("value", "label", users) })).then(onDataCompleted);
				} else {
					const company = await dispatch(addCompanyData({ name, file, workGroup, users: normalizeCombo("value", "label", users) })) //.then(onDataCompleted);
					onDataCompleted(company.id)
					const newData = await addUser({ newData: { email: groupData.responsible, workGroup: [workGroup] }, scope: { value: company.id }, hasPower: true })
					const userData = await addUser({ newData: { email: user.email, workGroup: [workGroup] }, scope: { value: company.id }, hasPower: true })
					dispatch(setLoginData({ groups: userData.groups }))
					return company
				}

		} catch (error) {
			Alert.error(error.message);
		}
	}

	onChange(field, value) {
		this.setState({ [field]: value })
	}

	onBack() {
		// this.props.history.push("/admin/works")
		this.props.history.goBack()
	}

	onDelete() {
		this.props.dispatch(deleteCompanyData(this.state.id)).then(() => {
			this.onBack();
		})
	}

	onFileChange(file) {
		this.setState({ file });
	}

	render() {
		const { isLoading, allGroups } = this.props;

		return (
			<CompanyDetailsView
				saveData={this.saveData}
				onChange={this.onChange}
				onFileChange={this.onFileChange}
				onBack={this.onBack}
				onDelete={this.onDelete}
				isLoading={isLoading}
				{...this.props}
				{...this.state}
				allGroups={normalizeCombo("id", "name", allGroups)}
			/>
		);
	}
}

export default connect(state => ({
	hasPower: state.login.hasPower,
	isAdmin: state.login.isAdmin,
	scope: state.login.scope,
	workGroup: state.login.workGroup,
	allGroups: state.group.items,
	network: state.login.network,
	groupData: state.login.groupData,
	user: state.login,
}))(CompanyDetailsContainer);
