import { getItemTable, getItem, addItem, deleteItem, updateItem, execute, executeAndFormat } from './firebaseFunctions';

export const getAlerts = async (location, isCompany) => {
    const parentId = isCompany ? { parentId: location.state.id } : { parentId: location.state.workId };
    return getItem(`${isCompany ? "AlertCompany" : "Alert"}`, parentId)
}

export const addAlert = async (location, isCompany, item) => {
    const parentId = isCompany ? location.state.company.id : location.state.workId;
    // console.log(`${isCompany ? "AlertCompany" : "Alert"}`, { ...item, parentId })
    return addItem(`${isCompany ? "AlertCompany" : "Alert"}`, { ...item, parentId });
}

export const deleteAlert = async (id) => {
    return deleteItem("Alert", id);
}

export const updateAlert = async (location, isCompany, item) => {
    const parentId = isCompany ? location.state.company.id : location.state.workId;
    // console.log(`${isCompany ? "AlertCompany update" : "Alert update"}`, { ...item, parentId })
    return updateItem(`${isCompany ? "AlertCompany" : "Alert"}`, { ...item, parentId }).then(data => {
        return { ...data }
    });
}