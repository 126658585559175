import translation from './locale/pt';

function getKeyEnum(){
	const keys = {};

	Object.keys(translation).map(key => {
		keys[key] = key;
	})

	return keys;
}

const keyEnum = getKeyEnum();

export default keyEnum;
