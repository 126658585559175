import React, { useState, ElementConfig } from 'react';
import { InputGroup } from 'reactstrap';
import InputFloat from 'react-floating-input'
import { BsEyeSlash, BsEye } from "react-icons/bs";
import MultiSelect from "react-multi-select-component";
import Select, { components } from 'react-select';
import DateRangePicker from "../../../components/DateRange/DateRangePicker";
import DateYearSelect from '../../../components/DateRange/DateYearSelect';
import { FiChevronDown } from "react-icons/fi";
import { DatePicker } from '../../../components/DateRange/DatePicker';
import Creatable from 'react-select/lib/Creatable';

/*

Componente de texto

*/

export const TextInput = (props) => {
    return <>
        <InputGroup className={`v2-textinput ${props.value ? "active" : ""} ${props.error ? "error" : ""} ${props.icon ? "icon" : ""}`}>
            <InputFloat color="#5F5F5F" activeColor="#5F5F5F" type="text" {...props} label={"teste"} />
            {props.icon}
        </InputGroup>
        {props.error && <p className="v2-text-error">{props.error}</p>}
    </>;
}

/*

Componente de senha

*/

export const PasswordInput = (props) => {
    const [showPassword, setShowPassword] = useState()
    return <>
        <InputGroup className={`v2-textinput ${props.value ? "active" : ""} ${props.error ? "error" : ""}`}>
            <InputFloat color="#5F5F5F" activeColor="#5F5F5F" type={showPassword ? "text" : "password"} {...props} label={"teste"} />
            <a className="right-icon v2-primary-color" href="#" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <BsEyeSlash /> : <BsEye />}</a>
        </InputGroup>
        {props.error && <p className="v2-text-error">{props.error}</p>}
    </>;
}

/*

Componente de seleção

*/

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <FiChevronDown primaryColor={"#2A3F92"} />
        </components.DropdownIndicator>
    );
};

export const SelectInput = ({ options, title, value, onChange, labelledBy, isSingle = false, isDisabled = false, hasSort = true }) => {
    // console.log({ value })
    var active = Array.isArray(value) ? value.length > 0 : value ? (value && value.value === false || value && value.value === 0 || value && value.value) : false

    if (options && hasSort) {
        options = options.sort((a, b) => {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
    }

    return <div className={`select-input v2-input-select ${active ? "active" : "deactivated"} ${isDisabled && "disabled"}`}>
        {isSingle ? <Select
            noOptionsMessage={() => "Não há opções para mostrar"}
            className="react-select dark-gray"
            classNamePrefix="react-select"
            placeholder={labelledBy}
            name="multipleSelect"
            closeMenuOnSelect={true}
            options={options}
            value={value}
            onChange={onChange}
            isDisabled={isDisabled}
            components={{ DropdownIndicator }}
        /> : <MultiSelect
            options={options}
            value={Array.isArray(value) ? value : value ? [value] : []}
            onChange={onChange}
            labelledBy={labelledBy}
            disabled={isDisabled}
            overrideStrings={{
                "selectSomeItems": labelledBy,
                "allItemsAreSelected": "Todos os itens estão selecionados",
                "selectAll": "Selecionar Todos",
                "search": "Buscar",
                "clearSearch": "Limpar busca",
                "noOptions": "Não ha opções"
            }}
        />}
        {title && <label className="label-title">{title}</label>}
    </div>
}

export const CreatableInput = ({ options, title, labelledBy, value, onChange, isDisabled = false, hasSort = true }) => {
    var active = Array.isArray(value) ? value.length > 0 : value ? (value && value.value === false || value && value.value === 0 || value && value.value) : false

    if (options && hasSort) {
        options = options.sort((a, b) => {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        })
    }

    return <div className={`select-input v2-input-select ${active ? "active" : "deactivated"} ${isDisabled && "disabled"} creatable-input`}>
        <Creatable
            noOptionsMessage={() => "Não há opções para mostrar"}
            className="react-select dark-gray"
            classNamePrefix="react-select"
            placeholder={labelledBy}
            name="multipleSelect"
            closeMenuOnSelect={false}
            value={value}
            isMulti
            onChange={onChange}
            isDisabled={isDisabled}
            options={options}
        />
        {title && <label className="label-title">{title}</label>}
    </div>
}

/*

Componente de seleção de data

*/

export const DateRange = (props) => <div className="v2-daterangepicker"><DatePicker {...props} /></div>

/*

Componente des seleção de ano

*/

export const DateRangeYear = (props) => <div className="v2-yearpicker"><DateYearSelect {...props} /></div>