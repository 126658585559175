import { getTags, addTag, deleteTag, updateTag} from "core/database/tag";
import ActionCreator from 'core/utils/redux/ActionCreator';

const { actions, defaultReducer, get, add, remove, update } = new ActionCreator("tag");
export { actions, defaultReducer };

export const getTagData = get(getTags);
export const addTagData = add(addTag);
export const updateTagData = update(updateTag);
export const deleteTagData = remove(deleteTag);
