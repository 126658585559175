import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Groups from "../views/Groups.jsx";
import { getGroupData, updateGroupData, deleteGroupData, addGroupData, getAllGroupData } from "../core/redux-store/group/Actions";
import { setLoginData, verifyUserData } from '../core/redux-store/login/Actions';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import Alert from "../components/Alert/Alert.js";
import { getAllWorkTypes, getWorkTypes } from "../core/database/skill.js";
import { getWorks } from "../core/database/works.js";
import { getCompanies } from "../core/database/company.js";
import { getItem } from "../core/database/firebaseFunctions.js";
import sort from "fast-sort"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import 'moment/locale/pt-br';
import { SaveButton } from "../layoutV2/components/Button/index.jsx";
import { getFullSize } from "../core/database/size.js";


const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const exportToXLSX = (csvData, datacsv, fileName) => {
	const ws = XLSX.utils.json_to_sheet(csvData);
	const ws2 = XLSX.utils.json_to_sheet(datacsv);
	// const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
	const wb = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(wb, ws, "Grupos");
	XLSX.utils.book_append_sheet(wb, ws2, "Tamanho das obras");

	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });



	const data = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(data, fileName + fileExtension);
}

const GroupContainer = (props) => {
	const dispatch = useDispatch()
	const isLoading = useSelector(state => state.group.isLoading)
	const items = useSelector(state => state.group.items)
	const workGroup = useSelector(state => state.login.workGroup)
	const groupData = useSelector(state => state.login.groupData)
	const isAdmin = useSelector(state => state.login.isAdmin)
	const user = useSelector(state => state.login)
	const network = useSelector(state => state.login.network)
	const [open, setOpen] = useState(false)
	const [loadingXLSX, setLoadingXLSX] = useState(false)
	const [comboWorkTypes, setComboWorkTypes] = useState([])

	useEffect(() => {
		dispatch(getAllGroupData());
	}, [open])

	useEffect(() => {
		loadWorks()
	}, [])

	const loadWorks = async () => {
		const works = await getWorkTypes({ network: user.network })
		setComboWorkTypes(works);
	}

	const onRowDelete = (oldData) => {
		return dispatch(deleteGroupData(oldData.id));
	}

	const onSave = (values) => {
		try {
			if (!values.file) {
				throw new Error(I18n[keyEnum.imageRequired]);
			}

			if (!values.name) {
				throw new Error(I18n[keyEnum.nameRequired]);
			}

			if (!values.responsible) {
				throw new Error(I18n[keyEnum.emailRequired]);
			}
			/* if (!values.workTypeIds) {
				throw new Error(I18n[keyEnum.workTypeRequired]);
			} */
			if (!values.isModel) {
				values.isModel = false
			}

			const onDataCompleted = async () => {
				Alert.success("Grupo Salvo!")
				if ((values && values.id) === (groupData && groupData.id)) {
					await dispatch(setLoginData({ groupData: { ...groupData, ...values } }))
				}
				setOpen(false)
			}

			if (open.id) {
				dispatch(updateGroupData(values)).then(onDataCompleted);
			} else {
				dispatch(addGroupData(values)).then(onDataCompleted)
			}
		} catch (error) {
			Alert.error(error.message);
		}
	}

	const exportCSV = async () => {
		setLoadingXLSX(true)
		const dataCSV = []
		const data = []

		for (let i = 0; i < items.length; i++) {
			const companies = await getItem("Company", { workGroup: items[i].id }, false)

			let works = []
			const promisesWorks = []
			for (let j = 0; j < companies.length; j++) {
				const worksCompany = getItem("Works", { idCompany: companies[j].id }, false)
				promisesWorks.push(worksCompany)
			}

			await Promise.all(promisesWorks).then(worksItems => {
				worksItems.map(work => works.push(work.flat()))
			})

			const allWorks = [...works.flat()]
			const allCompanies = [...companies.flat()]
			for (let j = 0; j < allWorks.length; j++) {
				const work = allWorks[j]
				if (work && work.id) {
					const size = await getFullSize(work.id, work.name)
					const company = allCompanies.find(item => item.id === work.idCompany)
					data.push({
						"Grupo": items[i].name,
						"Empresa": company.name,
						"Empresa Ativa": company.active ? "Sim" : "Não",
						"Id da Obra": work.id,
						"Obra Ativa": work.active ? "Sim" : "Não",
						"Obra": work.name,
						"Tamanho da obra": size,
					})
				}
			}

			dataCSV.push({
				"Grupo": items[i].name,
				"Quantidade de empresas": allCompanies.length,
				"Quantidade de obras": allWorks.length,
			})
		}

		sort(dataCSV).asc([
			u => u.Grupo,
		])

		sort(data).asc([
			u => u.Grupo,
			u => u.Empresa,
			u => u.Obra,
		])
		// const test = items.map(async group => {
		// for (let i = 0; i < items.length; i++) {
		// 	const promisesCompanies = [];
		// 	const promisesWorks = [];

		// 	promisesCompanies.push(getItem("Company", { workGroup: items[i].id }).then(async companies => {
		// 		companies.map(company => {
		// 			promisesWorks.push(getItem("Works", { idCompany: company.id }))
		// 		})
		// 		return companies
		// 	}))

		// 	const companies = await Promise.all(promisesCompanies).then(items => items.flat()).catch(() => ([]))
		// 	const works = await Promise.all(promisesWorks).then(items => items.flat()).catch(() => ([]))

		// 	dataCSV.push({
		// 		"Grupo": items[i].name,
		// 		"Quantidade de empresas": companies.length,
		// 		"Quantidade de obras": works.length,
		// 	})
		// }

		exportToXLSX(dataCSV, data, `grupos-${moment().locale('pt-br').format('L')}`)
		setLoadingXLSX(false)
	}

	return (
		<Groups
			data={items.filter(item => item.name)}
			isLoading={isLoading}
			onEditClick={(values) => setOpen(values)}
			onRowDelete={onRowDelete}
			onAddClick={() => setOpen(true)}
			open={open}
			setOpen={setOpen}
			comboWorkTypes={comboWorkTypes}
			onSave={onSave}
			loadingXLSX={loadingXLSX}
			// rightComponent={<SaveButton className="btn-save-quality" onClick={() => exportCSV()} label="Exportar" />}
			leftComponent={() => <div className="buttons-schedule-left">
				<SaveButton className="btn-save-quality" style={{ marginBottom: 0 }} onClick={() => exportCSV()} label="Exportar" />
			</div>}
		/>
	);
}

export default GroupContainer