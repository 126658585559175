import React, { Component } from 'react';
import { Card, CardImg, CardBody, CardTitle, Col, Badge, Button } from 'reactstrap';


class CardWorksView extends Component {
    getTypeCss(type){
        if(type && type.value){
            const value = type.value;
            if(value === 1){
                return "primary"
            } else if(value === 2){
                return "danger"
            }
        }
    }
    getTypeName(type){
        if(type && type.value){
            const value = type.value;
            if(value === 1){
                return "Vistoria"
            } else if(value === 2){
                return "Perícia"
            }
        }
    }
    render() {
        const { name, type, file, hasPower } = this.props;
        return (
            <Col md="4">
                <Card className="card-work" onClick={this.props.onCardClick} >
                    <CardImg top width="100%" className="card-image" src={file && file.url} />
                    <CardBody>
                        <CardTitle>{name}</CardTitle>
                        <Badge color={this.getTypeCss(type)}>{this.getTypeName(type)}</Badge>
                    </CardBody>
                    {hasPower === true && <Button 
                        onClick={this.props.onEditClick}
                        className="btn-icon button-edit"
                        color="warning"
                        size="sm"
                        type="button"
                    >
                        <i className="fa fa-edit" />
                    </Button>}
                </Card>
            </Col>
        );
    }
}

export default CardWorksView;