import React from 'react';
import { Progress } from 'reactstrap';
import {
    Col,
    FormGroup,
    Input,
} from "reactstrap";
import ReactDropZone from 'components/ReactDropZone/ReactDropZone';
import { storage } from 'core/database/firebaseConfig'
import { addItem, deleteItem } from 'core/database/firebaseFunctions';
import { GridListTile } from '@material-ui/core';
import sizeMe from 'react-sizeme';
import FileGalleryItem from '../components/FileGalleryItem/FileGalleryItem';
import { imageNameUtils } from 'core/utils/Utils';

class FileSimpleContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: this.props.file
        }

        this.onPreviewDrop = this.onPreviewDrop.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.renderFile = this.renderFile.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.file !== nextProps.file) {
            this.setState({ file: nextProps.file })
        }
    }

    onPreviewDrop = async (files) => {
        const { dataReference } = this.props;
        console.log({ dataReference:this.props })
        files.forEach(file => {
            const reader = new FileReader();
            const filename = file.name;
            const uniqueFilename = imageNameUtils(filename);
            const storageRef = storage.ref(dataReference).child(uniqueFilename);
            const task = storageRef.put(file);
            task.on("state_changed", (snapshot) => {
                const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({
                    uploadInProgress: { filename, uniqueFilename, percentage: percentage > 0 ? percentage - 1 : percentage },
                    file: undefined
                });
            });
            task.then(() => {
                storageRef.getDownloadURL().then((url) => {
                    console.log({ url, filename, uniqueFilename })
                    this.onAddItem({ url, filename, uniqueFilename });
                });
            })

            reader.readAsDataURL(file);
        })
    }

    onAddItem(item) {
        const { dataReference } = this.props;
        const { url, filename, uniqueFilename } = item;

        addItem(dataReference, item).then(data => {
            const file = { id: data.id, url, filename, uniqueFilename };
            this.setState({ file, uploadInProgress: undefined });

            if (this.props.onFileChange) {
                this.props.onFileChange(file);
            }
        });
    }

    onRemoveItem(item) {
        const { dataReference } = this.props;
        deleteItem(dataReference, item.id).then(() => {
            this.setState({ file: undefined });

            if (this.props.onFileChange) {
                this.props.onFileChange(undefined);
            }
        });
    }

    renderFile() {
        const { uploadInProgress, file } = this.state;

        if (uploadInProgress) {
            return (
                <ReactDropZone
                    multiple={false}
                    accept="image/*"
                    circle={this.props.circle}
                    mini={this.props.mini}
                    onDrop={this.onPreviewDrop}
                    hidden={true}
                    disabled={this.props.disabled}
                >
                    <GridListTile key={uploadInProgress.url} cols={1} className="tile">
                        <div className="file-item file-item-bordered file-simple">
                            <div className="text-center">{`${Math.round(uploadInProgress.percentage)}%`}</div>
                            <Progress value={uploadInProgress.percentage} />
                        </div>
                    </GridListTile>
                </ReactDropZone>
            )
        }

        if (file) {
            return (
                <ReactDropZone
                    multiple={false}
                    accept="image/*"
                    onDrop={this.onPreviewDrop}
                    hidden={true}
                    mini={this.props.mini}
                    circle={this.props.circle}
                    disabled={this.props.disabled}
                >
                    <GridListTile key={file.url} cols={1} className="tile">
                        <FileGalleryItem {...file} hideTile={true} onRemove={this.onRemoveItem} />
                    </GridListTile>
                </ReactDropZone>

            )
        }

        return (
            <ReactDropZone
                multiple={false}
                circle={this.props.circle}
                mini={this.props.mini}
                disabled={this.props.disabled}
                accept="image/*"
                onDrop={this.onPreviewDrop}
            />
        )
    }

    render() {
        const { mini } = this.props

        return (
            <div className={`file-container ${mini && "mini"}`}>
                {this.renderFile()}
            </div>
        )
    }
}

export default sizeMe()(FileSimpleContainer);



