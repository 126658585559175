import React, { useState, useEffect } from 'react';
import CustomTable from "../../components/CustomTable/CustomTable";
import { Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import { FilterSearch, FilterPicker } from '../../components/Filter/FilterHooks';
import WorkProjectDetailsContainer from '../../container/Works/WorkProjectDetailsContainer';
import { ScheduleContainer } from '../../container/Works/ScheduleContainer';
import { WorkReportContainer } from '../../container/Works/WorkReportContainer';
import AdminNavbar from '../../components/Navbars/AdminNavbar';
import { BreadcrumbHeader } from '../../components/Breadcrumb/Breadcrumb';
import { useSelector } from 'react-redux';
import { normalizeCombo } from '../../core/utils/Utils';
import WorksDetailsContainer from '../../container/Works/details/WorksDetailsContainer';
import { ButtonGroup } from '../../layoutV2/components/Button/index'
import GenericMaterialTable from '../../components/GenericMaterialTable';
import { ViewImagesButton } from '../../components/Button/Button'
import sort from 'fast-sort'
import { Roles } from '../../components/Group/ModalNewGroup';

export const toDateFormat = (date, format = 'DD/MM/YYYY') => {

    if (!date) {
        return;
    }

    const formattedDate = date.seconds ? new Date(date.seconds * 1000) : new Date(date);
    return moment(formattedDate).format(format ? format : 'DD/MM/YYYY');
}

export const toDate = (date) => {
    if (!date) {
        return;
    }

    return date.seconds ? new Date(date.seconds * 1000) : new Date(date);
}

export const compareDate = (a, b) => {
    const dateA = toDate(a.startDate);
    const dateB = toDate(b.startDate);

    if (!dateA || !dateB) {
        return 0;
    }

    return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
}

/* const getFilteredData = (data, filterValues, user) => {
    console.log({ filterValues })
    return data.filter(item => (((item && item.idUser) === user.id) || user.isAdmin) && 
    (item.name && filterValues.search && item.name.toLowerCase().includes(filterValues.search.toLowerCase())) )
} */

const getFilteredData = (data, filterValues, user, groupHasPower, isView) => {
    const newData = data.filter(item => (groupHasPower || ((item && item.idUser) === user.id) || user.isAdmin) && doFilter(item, filterValues)).filter(item => (isView === 4 && item.isQuality) || (isView === 1 && !item.isQuality))
    return isView === 1 ? sort(newData).asc(u => u.name) : newData
}

const doFilter = (rowData, filterValues) => {
    let hasSearch = true;
    if (filterValues.search) {
        hasSearch = rowData.name && filterValues.search && rowData.name.toLowerCase().includes(filterValues.search.toLowerCase())
    }

    let hasUsers = true;
    if (filterValues.users) {
        const arrUsers = Array.isArray(filterValues.users) ? filterValues.users.map(item => {
            return item.value
        }) : [filterValues.users.value]

        hasUsers = filterValues.users.length == 0 || (rowData.idUser && arrUsers.includes(rowData.idUser));
    }

    return hasSearch && hasUsers
}

let defaultFilterValues = {
    search: '',
    users: []
};

export const InspectionView = (props) => {

    const { isView, setIsView, setDefaultView, goBack } = props;
    const [filterValues, setFilterValues] = useState(defaultFilterValues);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [comboUsers, setComboUsers] = useState([]);
    const user = useSelector(state => state.login);
    const network = useSelector(state => state.login.network)
    const groupAuth = user.groups && user.groups.find(item => item.details.id === props.location.state.company.id);
    const groupHasPower = groupAuth && groupAuth.hasPower === true;
    // console.log('aaaa', { network, user })
    /* function onFilterSearch(_field, search) {
        setFilterValues(search);
        defaultSearch = search;
    } */

    const onFilter = (field, value) => {
        // console.log({ field, value })
        let values;
        if (field === "name") {
            values = { ...filterValues, "search": value }
        } else {
            values = { ...filterValues, [field]: value }
        }
        setFilterValues(values);
        defaultFilterValues = values;
    }

    const filterUsers = (rowData, _field, users) => {
        const arrUsers = users && users.map(item => {
            return item.value
        })
        return !users || users.length == 0 || arrUsers.includes(rowData.idUser);
    }

    const onSelectionChange = (files) => {
        setSelectedFiles(files);
    }

    const onViewChange = (view) => {
        setIsView(view);
        setDefaultView(view);
    }

    useEffect(() => {
        if (props.hasPower === 0) {
            onViewChange(0)
        }
    }, [props.router])

    useEffect(() => {
        const users = [...new Set(props.data.filter(item => item.nmUser).map(item => item.nmUser))].map(nm => props.data.find(item => item.nmUser === nm))
        setComboUsers(normalizeCombo("idUser", "nmUser", users))
    }, [props.data])

    const buttons = [
        { label: "Sobre a obra", id: 5, index: null, subIndex: null },
        { label: "Insp. Livres", id: 1, index: 1, subIndex: 1 },
        { label: "Insp. Guiadas", id: 4, index: 2, subIndex: 2 },
        { label: "Documentos", id: 0, index: 4, subIndex: 4 },
        { label: user && user.groupData && user.groupData.isModel ? "Fichas de inspeção" : "Cronograma", id: 2, index: 5, subIndex: 5 },
        { label: "Relatório", id: 3, index: 1, subIndex: 2 },
    ].filter(button => user && user.groupData && button && button.index ? user && user.groupData && user.groupData.roles && user.groupData.roles.includes(button && button.index) : true)

    // console.log({ teste: buttons.filter(button => user && user.groupData && button && button.index /* && button.subIndex */ ? user.groupData.roles.includes(button && button.index && button.subIndex) : true) })

    const columns = [
        { title: "Filtrar por usuário", field: "users", type: "multiple", options: comboUsers, hidden: true, defaultSort: 'asc', filtered: true, customSearch: filterUsers },
        {
            title: "Nome", field: "name", defaultSort: 'asc', filtered: true, editable: "always", defaultSearch: true, render: rowData => (
                <Row className={`row-user padding-${rowData.level} label-primary`}>
                    <Col className="details truncated">
                        {rowData.name} <br />
                        {rowData.startDate && <span className="text-muted">
                            <small>{`${rowData && rowData.nmUser ? `${rowData.nmUser} - ` : ""}${toDateFormat(rowData.startDate)}`}</small>
                        </span>}
                    </Col>
                </Row>
            )
        },
        // {
        //     title: 'Nome',
        //     field: 'name',
        //     editable: 'always',
        //     defaultSort: 'asc',
        //     cellStyle: {
        //         maxWidth: 250,
        //         minWidth: 250
        //     },
        //     customSort: compareDate,
        //     editComponent: props => {
        //         const { rowData } = props;
        //         return (<Row className="edit-custom-row">
        //             <input
        //                 level="text"
        //                 placeholder="Nome"
        //                 className="editing-input-custom"
        //                 value={props.value}
        //                 onChange={e => {
        //                     props.onChange(e.target.value)


        //                     if (rowData && e.target.value) {
        //                         rowData.name = e.target.value
        //                     }
        //                 }}
        //             />
        //         </Row>)
        //     },
        //     render: rowData => (
        //         <Row className={`row-user padding-${rowData.level}`}>
        //             <Col className="details truncated">
        //                 {rowData.name} <br />
        //                 {rowData.startDate && <span className="text-muted">
        //                     <small>{`${rowData && rowData.nmUser ? `${rowData.nmUser} - ` : ""}${toDateFormat(rowData.startDate)}`}</small>
        //                 </span>}
        //             </Col>
        //         </Row>
        //     )
        // },
    ].filter(item => item)

    return (
        <>
            <div className="content skills-screen skills">
                <AdminNavbar goBack={goBack} />
                {/* <BreadcrumbHeader data={props.router} />
                </AdminNavbar> */}
                {/* <div className='button-header'>
                    <ButtonGroup size="sm" className='all-buttons'>
                        {props.hasPower !== 0 && <Button variant="dark" className='button-group' active={isView === 5} color="link"
                            onClick={() => onViewChange(5)}>Sobre a obra</Button>}
                        {props.hasPower !== 0 && <Button variant="dark" className='button-group' active={isView === 1} color="link"
                            onClick={() => onViewChange(1)}>Inspeções</Button>}
                        {props.hasPower !== 0 && <Button variant="dark" className='button-group' active={isView === 4} color="link"
                            onClick={() => onViewChange(4)}>Qualidade</Button>}
                        <Button variant="dark" className='button-group' active={isView === 0} color="link" onClick={() => onViewChange(0)}>Documentos</Button>
                        {props.hasPower !== 0 && <Button variant="dark" className='button-group' active={isView === 2} color="link"
                            onClick={() => onViewChange(2)}>Cronograma</Button>}
                        {props.hasPower !== 0 && <Button variant="dark" className='button-group' active={isView === 3} color="link"
                            onClick={() => onViewChange(3)}>Relatório</Button>}
                    </ButtonGroup>
                </div> */}
                <ButtonGroup buttons={buttons.filter(button => (user && !user.groupData) || (user && user.groupData && button && button.index /* && button.subIndex */ ? user.groupData.roles.includes(button && button.index && button.subIndex) : true))} onClick={value => onViewChange(value)} active={value => isView === value} />
                {isView === 0 && <WorkProjectDetailsContainer
                    id={props.location.state.workId}
                    routeData={props.router}
                    filter={{ company: props.location.state.company, workId: props.location.state.workId }}
                    onSelectionChange={onSelectionChange}
                    history={props.history}
                    hasPower={props.hasPower}
                />}
                {[1, 4].includes(isView) && <GenericMaterialTable
                    title={props.title}
                    columns={columns}
                    {...props}
                    idTable={`${isView === 1 ? "inspections" : "quality"}-list`}
                    data={getFilteredData(props.data, filterValues, user, groupHasPower, isView)}
                    filterComponent={() =>
                        <div className="filter-container">
                            <div className="actions">
                                <FilterSearch onFilterSearch={onFilter} value={filterValues.search} />
                                {(groupHasPower || user.isAdmin) && <FilterPicker onFilter={onFilter} value={filterValues.users} comboOptions={comboUsers} placeholder="Filtrar por usuário" field={"users"} />}
                            </div>
                        </div>
                    }
                    actions={[
                        rowData => ({
                            icon: () => <ViewImagesButton marginZero />,//"image_search",
                            tooltip: "Imagens da vistoria",
                            onClick: () => {
                                props.onImages(rowData);
                            },
                            iconProps: {
                                style: {
                                    fontSize: 24,
                                    color: "#ef6d2e"
                                }
                            }
                        }),
                    ]}
                    headerAdd={isView === 1}
                />}
                {isView === 2 && <ScheduleContainer location={props.location} history={props.history} />}
                {isView === 3 && <WorkReportContainer location={props.location} />}
                {isView === 5 && <WorksDetailsContainer location={props.location} history={props.history} hideHeader={true} />}
            </div>
        </>
    );
};