import React, { useEffect, useState } from "react";
import { DefaultImpact, DefaultSignal } from "../views/DefaultFilters";
import { Col, Row } from 'reactstrap';
import { FilterPicker, FilterDate } from "../components/Filter/FilterHooks";
import { getItem } from "../core/database/firebaseFunctions";
import { getCompaniesData } from "../core/redux-store/company/Actions";
import { getWorksData } from "../core/redux-store/works/Actions";
import { useSelector, useDispatch } from "react-redux";
import { normalizeCombo } from "../core/utils/Utils";
import { DateRange, SelectInput } from "../layoutV2/components/Form";

export const getWorks = (works, company) => {
    let worksFilter = works;
    if (company && company.length > 0) {
        worksFilter = works.filter(work => Array.isArray(company) ? company.map(item => item.value).includes(work.idCompany) : work.idCompany === company.value)
    }
    return worksFilter
}

const getSteps = (data) => {
    let steps = []
    let stepFilter = [...new Set(data.filter(item => item.step).map(item => item.step.id))];
    steps = stepFilter.map(idStep => data.find(item => item.step ? idStep === item.step.id : false).step)
    return steps
}

const getSubject = (data) => {
    let subjects = []
    let subjectFilter = [...new Set(data.filter(item => item.subject).map(item => item.subject.id))];
    subjects = subjectFilter.map(idSubject => data.find(item => item.subject ? idSubject === item.subject.id : false).subject)
    return subjects
}

const FilterDashboardContainer = ({ filter, setFilter, data, companies, works, inspections }) => {
    const onFilter = (field, value) => {
        setFilter(oldFilter => ({ ...oldFilter, [field]: value }))
    }

    const disabledWork = !filter.company || filter.company.length === 0;
    const disabled = (!filter.work || filter.work.length === 0) || disabledWork;

    return (
        <div id="filter-dashboard" >
            <Row className="filter-report">
                <Col lg="3" sm="12" >
                    {/* <FilterDate
                        onDateChange={onFilter}
                        startDate={filter.dates.startDate}
                        endDate={filter.dates.endDate}
                    /> */}
                    <DateRange
                        onDateChange={onFilter}
                        startDate={filter.dates.startDate}
                        endDate={filter.dates.endDate}
                    />
                </Col>
                <Col lg="3" sm="12">
                    {/* <FilterPicker
                        onFilter={onFilter}
                        value={filter.company}
                        comboOptions={normalizeCombo("id", "name", companies)}
                        placeholder="Filtrar por empresa"
                        field="company"
                        multiple={true}
                    /> */}
                    <SelectInput
                        options={normalizeCombo("id", "name", companies)}
                        title="Filtrar por empresa"
                        labelledBy={"Filtrar por empresa"}
                        value={filter.company}
                        onChange={data => onFilter("company", data)}
                        isSingle={false}
                    />
                </Col>
                <Col lg="3" sm="12">
                    {/* <FilterPicker
                        onFilter={onFilter}
                        value={filter.work}
                        comboOptions={normalizeCombo("id", "name", getWorks(works, filter.company))}
                        placeholder={"Filtrar por obra"}
                        field="work"
                        disabled={disabledWork}
                    /> */}
                    <SelectInput
                        options={normalizeCombo("id", "name", getWorks(works, filter.company))}
                        title="Filtrar por obra"
                        labelledBy={"Filtrar por obra"}
                        value={filter.work}
                        onChange={data => onFilter("work", data)}
                        isSingle={false}
                        isDisabled={disabledWork}
                    />
                </Col>
                <Col lg="3" sm="12">
                    {/* <FilterPicker
                        onFilter={onFilter}
                        value={filter.step}
                        comboOptions={normalizeCombo("id", "name", getSteps(data))}
                        placeholder={"Filtrar por etapa"}
                        field="step"
                        disabled={disabled}
                    /> */}
                    <SelectInput
                        options={normalizeCombo("id", "name", getSteps(data))}
                        title="Filtrar por etapa"
                        labelledBy={"Filtrar por etapa"}
                        value={filter.step}
                        onChange={data => onFilter("step", data)}
                        isSingle={false}
                        isDisabled={disabled}
                    />
                </Col>
            </Row>
            <Row className="filter-report ">
                <Col lg="3" sm="12">
                    {/* <FilterPicker
                        onFilter={onFilter}
                        value={filter.impact}
                        comboOptions={DefaultImpact()}
                        placeholder={"Filtrar por impacto"}
                        field="impact"
                        disabled={disabled}
                        hasSort={false}
                    /> */}
                    <SelectInput
                        options={DefaultImpact()}
                        title="Filtrar por impacto"
                        labelledBy={"Filtrar por impacto"}
                        value={filter.impact}
                        onChange={data => onFilter("impact", data)}
                        isSingle={false}
                        isDisabled={disabled}
                        hasSort={false}
                    />
                </Col>
                <Col lg="3" sm="12">
                    {/* <FilterPicker
                        onFilter={onFilter}
                        value={filter.subject}
                        comboOptions={normalizeCombo("id", "name", getSubject(data, filter.work))}
                        placeholder={"Filtrar por categoria"}
                        field="subject"
                        disabled={disabled}
                    /> */}
                    <SelectInput
                        options={normalizeCombo("id", "name", getSubject(data, filter.work))}
                        title="Filtrar por categoria"
                        labelledBy={"Filtrar por categoria"}
                        value={filter.subject}
                        onChange={data => onFilter("subject", data)}
                        isSingle={false}
                        isDisabled={disabled}
                    />
                </Col>
                <Col lg="3" sm="12">
                    {/* <FilterPicker
                        onFilter={onFilter}
                        value={filter.signal}
                        comboOptions={DefaultSignal}
                        placeholder={"Filtrar pelo sinalizar"}
                        field="signal"
                        disabled={disabled}
                    /> */}
                    <SelectInput
                        options={DefaultSignal}
                        title="Filtrar pelo sinalizar"
                        labelledBy={"Filtrar pelo sinalizar"}
                        value={filter.signal}
                        onChange={data => onFilter("signal", data)}
                        isSingle={false}
                        isDisabled={disabled}
                    />
                </Col>
                <Col lg="3" sm="12">
                    {/* <FilterPicker
                        onFilter={onFilter}
                        value={filter.signal}
                        comboOptions={DefaultSignal}
                        placeholder={"Filtrar pelo sinalizar"}
                        field="signal"
                        disabled={disabled}
                    /> */}
                    <SelectInput
                        options={normalizeCombo("id", "name", inspections.filter(item => item.isQuality))}
                        title="Vistoria guiada"
                        labelledBy={"Vistoria guiada"}
                        value={filter.quality}
                        onChange={data => onFilter("quality", data)}
                        isSingle={false}
                        isDisabled={disabled || (filter.inspection && filter.inspection.length > 0)}
                    />
                </Col>

            </Row>
            <Row className="filter-report last">
                <Col lg="3" sm="12">
                    {/* <FilterPicker
                        onFilter={onFilter}
                        value={filter.signal}
                        comboOptions={DefaultSignal}
                        placeholder={"Filtrar pelo sinalizar"}
                        field="signal"
                        disabled={disabled}
                    /> */}
                    <SelectInput
                        options={normalizeCombo("id", "name", inspections.filter(item => !item.isQuality))}
                        title="Vistoria livre"
                        labelledBy={"Vistoria livre"}
                        value={filter.inspection}
                        onChange={data => onFilter("inspection", data)}
                        isSingle={false}
                        isDisabled={disabled || (filter.quality && filter.quality.length > 0)}
                    />
                </Col>
            </Row>
        </div >
    )
}

export default FilterDashboardContainer