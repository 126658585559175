import React, { useState } from "react";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import { Button, Input, Row, Col, Label } from 'reactstrap';
import Select from "react-select";
import FileGalleryContainer from "../container/FileGalleryContainer";
import { SelectInput, TextInput } from "../layoutV2/components/Form";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiTrashAlt } from "react-icons/bi";
import FileContainer from "../container/File/FileContainer";
import { AddNavButton, OutlineButton, SaveButton } from "../layoutV2/components/Button";
import { DeleteButton } from '../components/Button/Button'

const Question = ({ deleteItem, item }) => {
    const { id, name } = item;
    return (
        <div className="div-question">
            <span className='details-description'>
                {/* <span style={{ fontSize: 18, marginRight: 5 }} class="material-icons">
                    check_box
                    </span> */}
                {name}
            </span>
            {/* <Button style={{ marginLeft: 10, height: 24, width: 24, minWidth: 24 }}
                onClick={() => deleteItem(id)}
                className="btn-icon"
                color="primary"
                size="sm"
                type="button"
            > */}
            <DeleteButton onClick={() => deleteItem(id)} />
            {/* <BiTrashAlt className='icon-question' onClick={() => deleteItem(id)} /> */}
            {/* </Button>{" "} */}
        </div>
    )
}

export const InspectionModelDetails = ({ onBack, values, onSave, setValues, comboWorkTypes, formatSkills, question, setQuestion, addAsk, deleteItem, service, setService }) => {
    return (
        <div className="content inspection-model">
            <AdminNavbar goBack={onBack} />
            <div className="inspection-card">
                <Row style={{ marginBottom: 10, marginTop: 20 }}>
                    <Col sm="12">
                        {/* <Input
                        placeholder="Nome do modelo"
                        defaultValue={values.name}
                        onChange={(event) => setValues({ ...values, "name": event.target.value })}
                    /> */}
                        <TextInput
                            id="model-name"
                            placeholder="Nome do modelo"
                            type="text"
                            value={values.name}
                            onChange={(event) => setValues({ ...values, "name": event.target.value })}
                        />
                    </Col>
                </Row>
                <Row className="items-col" style={{ marginBottom: 10 }}>
                    <Col>
                        {/* <span>Tipo de obra</span>
                    <Select
                        noOptionsMessage={() => "Não há opções para mostrar"}
                        className="react-select dark-gray"
                        classNamePrefix="react-select"
                        placeholder="Selecione o tipo de obra"
                        name="multipleSelect"
                        closeMenuOnSelect={true}
                        options={comboWorkTypes}
                        value={values.workType}
                        onChange={value => setValues({ ...values, "workType": value })}
                    /> */}
                        <SelectInput
                            options={comboWorkTypes}
                            title="Tipo de obra"
                            labelledBy={"Selecione o tipo de obra"}
                            value={values.workType}
                            onChange={value => setValues({ ...values, "workType": value })}
                            isSingle={true}
                        />
                    </Col>
                    <Col>
                        {/* <span>Etapa</span>
                    <Select
                        noOptionsMessage={() => "Não há opções para mostrar"}
                        className="react-select dark-gray"
                        classNamePrefix="react-select"
                        placeholder="Selecione as etapas"
                        name="multipleSelect"
                        closeMenuOnSelect={true}
                        options={formatSkills()}
                        value={values.linkStep}
                        onChange={value => setValues({ ...values, "linkStep": value })}
                        isDisabled={!values.workType}
                    /> */}
                        <SelectInput
                            options={formatSkills()}
                            title="Etapas"
                            labelledBy={"Selecione as etapas"}
                            value={values.linkStep}
                            onChange={value => setValues({ ...values, "linkStep": value })}
                            isSingle={true}
                            isDisabled={!values.workType}
                        />
                    </Col>
                </Row>
                <Row className="items-col" style={{ marginBottom: 30 }}>
                    <Col>
                        <Label className='details-title' style={{ fontWeight: "normal" }}>Adicione os critérios para checklist de início de <Label style={{ fontWeight: "bold" }}>inspeção</Label></Label>
                        {/* <div style={{ display: "flex" }}>
                        <Input
                            placeholder="Insira uma ou mais perguntas separadas por ';'"
                            type="textarea"
                            value={question}
                            onChange={(event) => setQuestion(event.target.value)}
                            className='details-input-quality'
                        />
                        <Button className="btn-add-quality" onClick={() => addAsk(question, 1)}>
                            Adicionar
                        </Button>
                    </div> */}
                        <TextInput
                            id="model-question"
                            placeholder="Insira uma ou mais perguntas separadas por ';'"
                            type="text"
                            value={question}
                            onChange={(event) => setQuestion(event.target.value)}
                            // icon={<AiOutlinePlusCircle onClick={() => addAsk(question, 1)} />}
                            icon={<AddNavButton label="" onClick={() => addAsk(question, 1)} />}
                        />
                        {/* {values.asks && values.asks.filter(item => item.type === 1).length > 0 && <Label className='details-title' style={{ display: "flex" }}>Critérios do checklist:</Label>} */}
                        {values.asks && values.asks.filter(item => item.type === 1).map(item => <Question deleteItem={deleteItem} key={item.id} item={item} />)}
                    </Col>
                    <Col>
                        <Label className='details-title' style={{ fontWeight: "normal" }}>Adicione os critérios para o checklist de <Label style={{ fontWeight: "bold" }}>serviço</Label></Label>
                        {/* <div style={{ display: "flex" }}>
                        <Input
                            placeholder="Insira uma ou mais perguntas separadas por ';'"
                            type="textarea"
                            value={service}
                            onChange={(event) => setService(event.target.value)}
                            className='details-input-quality'
                        />
                        <Button className="btn-add-quality" onClick={() => addAsk(service, 2)}>
                            Adicionar
                        </Button>
                    </div> */}
                        <TextInput
                            id="model-service"
                            placeholder="Insira uma ou mais perguntas separadas por ';'"
                            type="text"
                            value={service}
                            onChange={(event) => setService(event.target.value)}
                            // icon={<AiOutlinePlusCircle onClick={() => addAsk(service, 2)} />}
                            icon={<AddNavButton label="" onClick={() => addAsk(service, 2)} />}
                        />
                        {/* {values.asks && values.asks.filter(item => item.type === 2).length > 0 && <Label className='details-title' style={{ display: "flex" }}>Critérios do checklist:</Label>} */}
                        {values.asks && values.asks.filter(item => item.type === 2).map(item => <Question deleteItem={deleteItem} key={item.id} item={item} />)}
                    </Col>
                </Row>
                {values && values.id ?
                    <Row style={{ marginBottom: 30 }}>
                        <Col>
                            {/* <Label className='details-title' style={{ display: "flex" }}>Anexos</Label>
                        <div className="category editable">
                            <FileGalleryContainer
                                useCrop={true}
                                dataReference={`InspectionModel/${values.id}/files`}
                                // dataReference={`Images/InspectionModel/data`}
                                canSelect={true}
                                isMultipleSelect={true}
                                onFileChange={(files) => setValues({ ...values, files })}
                            />
                        </div> */}
                            <FileContainer
                                // title={name}
                                useCrop={true}
                                dataReference={`InspectionModel/${values.id}/files`}
                                canSelect={true}
                                isMultipleSelect={true}
                                disabled={!values.id}
                                style={{ minHeight: 100 }}
                                inspection
                            // styleDiv={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                            // styleSubtitle={{ marginLeft: 30, marginBottom: 10 }}
                            // styleTitle={{ fontWeight: "300", fontSize: 18 }}
                            // styleFiles={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
                            />
                        </Col>
                    </Row> : <div />}
                <Row>
                    <Col sm="12" style={{ display: "flex", justifyContent: "flex-end" }}>
                        {/* {values && values.id && <OutlineButton label="Excluir" />} */}
                        <SaveButton onClick={onSave} />
                    </Col>
                </Row>
            </div>
        </div>
    );
};