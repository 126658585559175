import React from 'react';
import { Progress, UncontrolledTooltip } from 'reactstrap';
import {
    Button,
    ButtonGroup,
    Input,
} from "reactstrap";
import ReactDropZone from 'components/ReactDropZone/ReactDropZone';
import { storage } from 'core/database/firebaseConfig'
import { addItem, getItem, updateItem, deleteItem } from 'core/database/firebaseFunctions';
import { GridList, GridListTile } from '@material-ui/core';
import sizeMe from 'react-sizeme';
import FileGalleryItem from '../components/FileGalleryItem/FileGalleryItem';
import { imageNameUtils } from 'core/utils/Utils';
import EditPdfWizard from '../views/pdf/EditPdfWizard';
import ImageGallery from 'react-image-gallery';
import PasteComponent from '../components/PasteComponent/PasteComponent';
import sort from 'fast-sort';
import Dialog from 'react-bootstrap-dialog'
import { GalleryContainer } from './GalleryContainer';
import { confirmAlert } from 'react-confirm-alert';
import ModalAlert from '../layoutV2/components/Modal/ModalAlert';
import FileContainer from './File/FileContainer';
import { BiTrashAlt } from "react-icons/bi";
import { OutlineButton, SaveButton } from '../layoutV2/components/Button';

class FileGalleryContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            uploadInProgress: [],
            isGallery: false,
            selectAll: false
        }

        this.onPreviewDrop = this.onPreviewDrop.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.removeFromStorage = this.removeFromStorage.bind(this);
        this.onSelectItem = this.onSelectItem.bind(this);
        this.saveFiles = this.saveFiles.bind(this);
        this.onStateChanged = this.onStateChanged.bind(this);
        this.onUpdateFile = this.onUpdateFile.bind(this);
        this.getGallery = this.getGallery.bind(this);
        this.setIsGallery = this.setIsGallery.bind(this);
        this.onHideChange = this.onHideChange.bind(this)
        this.onImagePaste = this.onImagePaste.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.deselectAll = this.deselectAll.bind(this);
        this.dialog = React.createRef();
    }

    componentWillMount() {
        const filter = this.props.filter ? this.props.filter : {};
        getItem(this.props.dataReference, filter).then(files => {
            const { defaultSelect } = this.props;

            files = files.map(file => {
                const defaultItem = defaultSelect && defaultSelect.filter(item => item.url === file.url)[0];
                if (defaultItem) {
                    file.isSelected = true;
                } else {
                    file.isSelected = false;
                }
                return file;
            })

            this.setState({
                files: files.map(item => ({ ...item, isSelected: false }))
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.saveState !== nextProps.saveState) {
            const filter = this.props.filter ? this.props.filter : {};
            getItem(this.props.dataReference, filter).then(files => {
                const newFiles = [...files.map(item => ({ ...item, isSelected: false }))]
                this.setState({
                    files: newFiles
                });
            });
        }
    }

    onStateChanged(snapshot, uniqueFilename, filename) {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        this.setState(state => {
            const uploadIndex = state.uploadInProgress.findIndex(item => item.uniqueFilename === uniqueFilename);
            if (uploadIndex >= 0) {
                state.uploadInProgress[uploadIndex] = { ...state.uploadInProgress[uploadIndex], percentage };
                return {
                    // files: state.files.filter(item => item.uniqueFilename !== uniqueFilename),
                    uploadInProgress: state.uploadInProgress
                }
            } else {
                return {
                    // files: state.files.filter(item => item.uniqueFilename !== uniqueFilename),
                    uploadInProgress: state.uploadInProgress.concat({ filename, uniqueFilename, percentage: percentage > 0 ? percentage - 1 : percentage }),
                }
            }
        });
    }

    onPreviewDrop = async (files) => {
        const { dataReference } = this.props;

        /* files.forEach(file => {
            const reader = new FileReader();
            const filename = file.name;
            const uniqueFilename = imageNameUtils(filename);
            const storageRef = storage.ref(dataReference).child(uniqueFilename);
            const task = storageRef.put(file);
            task.on("state_changed", (snapshot) => {
                this.onStateChanged(snapshot, uniqueFilename, filename);
            });
            task.then(() => {
                storageRef.getDownloadURL().then((url) => {
                    this.onAddItem({ url, filename, uniqueFilename });
                });
            })

            reader.readAsDataURL(file);
        }) */
    }

    onAddItem(item) {
        const { dataReference } = this.props;
        // const { files, uploadInProgress } = this.state;
        const { url, filename, uniqueFilename } = item;

        const filter = this.props.filter ? this.props.filter : {};

        addItem(dataReference, { ...item, ...filter, time: new Date().getTime() }).then(data => {
            this.setState(state => {
                const newFiles = state.files.concat({ id: data.id, url, filename, uniqueFilename });
                if (this.props.onFileChange) {
                    this.props.onFileChange(newFiles)
                }
                return {
                    files: newFiles,
                    uploadInProgress: state.uploadInProgress.filter(item => item.uniqueFilename !== uniqueFilename),
                }
            });
        });
    }

    onUpdateItem(item) {
        const { dataReference } = this.props;
        const { id, url, filename, uniqueFilename } = item;
        console.log("onupdate", { id, url, filename, uniqueFilename })
        updateItem(dataReference, item).then(data => {
            this.setState(state => {
                const newFiles = state.files;
                const index = newFiles.findIndex(item => item.id === id);

                console.log(state.files, index, newFiles[index]);
                if (index > -1) {
                    newFiles[index] = { id: data.id, url, filename, uniqueFilename };
                }

                return {
                    files: newFiles,
                    uploadInProgress: state.uploadInProgress.filter(item => item.uniqueFilename !== uniqueFilename),
                }
            });
        });
    }

    onRemoveItem(item) {
        const { dataReference } = this.props;
        return deleteItem(dataReference, item.id).then(() => {
            /* const newFiles = files.filter(file => file.id !== item.id);
            this.setState({
                files: newFiles
            });
    
            if (this.props.onFileChange) {
                this.props.onFileChange(newFiles)
            } */

            this.setState(state => {
                const newFiles = state.files.filter(file => file.id !== item.id);
                if (this.props.onFileChange) {
                    this.props.onFileChange(newFiles)
                }
                return {
                    files: newFiles,
                }
            });

            this.removeFromStorage(item);
        });
    }

    confirmDelete() {
        /* Dialog.setOptions({
            defaultOkLabel: 'Sim',
            defaultCancelLabel: 'Cancelar',
            primaryClassName: 'btn-success',
            defaultButtonClassName: 'btn-secondary'
        })
        this.dialog.current.show({
            title: `Imagens`,
            body: `Deseja mesmo remover estas imagens?`,
            actions: [
                Dialog.CancelAction(),
                Dialog.OKAction(() => {
                    this.deleteAll()
                })
            ],
            bsSize: 'small',
        }) */
        /* confirmAlert({
            title: 'Deseja remover estas imagens?',
            message: 'Ao remover, suas imagens não será mais exibida, esta ação não é reversível, deseja mesmo remover?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => this.deleteAll()
                },
                {
                    label: 'Não'
                }
            ]
        }); */
        ModalAlert.danger({
            title: "Deseja remover estas imagens?",
            text: "Ao remover, suas imagens não será mais exibida, esta ação não é reversível, deseja mesmo remover?",
            onConfirm: () => this.deleteAll()
        })
    }

    removeFromStorage(item) {
        const { dataReference } = this.props;
        let data = item.uniqueFilename
        if (data && dataReference && dataReference !== "" && data !== "") {
            console.log({ dataReference, data })
            const storageRef = storage.ref(dataReference).child(data);
            storageRef.delete();
        }
    }

    onSelectItem(file) {
        if (this.props.canSelect) {
            let files = [];
            if (this.props.isMultipleSelect) {
                const index = this.state.files.findIndex(item => item.id === file.id);
                this.state.files[index] = { ...this.state.files[index], isSelected: !file.isSelected };
                files = this.state.files;
            } else {
                files = this.state.files.map(oFile => {
                    if (oFile.id === file.id) {
                        return { ...oFile, isSelected: !file.isSelected }
                    } else {
                        return { ...oFile, isSelected: false }
                    }
                })
            }

            this.setState({
                files
            });

            if (this.props.onSelectionChange) {
                this.props.onSelectionChange(files.filter(file => file.isSelected));
            }
        }
    }

    onHideChange(item) {
        const { dataReference } = this.props;
        let { files } = this.state;
        const { id, isHiding } = item;

        updateItem(dataReference, { id, isHiding }).then(() => {
            files = files.map(file => {
                if (file.id === item.id) {
                    file.isHiding = isHiding;
                }
                return file;
            })

            this.setState({
                files
            });

            if (this.props.onFileChange) {
                this.props.onFileChange(files)
            }
        });

    }

    saveFiles(files) {
        const { dataReference } = this.props;
        files.forEach(file => {
            const filename = file.fileName;
            const uniqueFilename = imageNameUtils(filename);
            const storageRef = storage.ref(dataReference).child(uniqueFilename);
            const task = storageRef.putString(file.url.split('data:image/png;base64,')[1], 'base64', { contentType: 'image/png' });
            task.on("state_changed", (snapshot) => {
                this.onStateChanged(snapshot, uniqueFilename, filename);
            });
            task.then(() => {
                storageRef.getDownloadURL().then((url) => {
                    this.onAddItem({ url, filename, uniqueFilename });
                });
            })
        })
    }

    onUpdateFile(data, duplicate = false) {
        let { uniqueFilename, filename } = data;
        const { id, url } = data;
        const { dataReference } = this.props;

        if (!filename) {
            filename = new Date().getTime() + ".png";
        }

        if (!uniqueFilename) {
            uniqueFilename = imageNameUtils(filename);
        }
        const storageRef = storage.ref(dataReference).child(uniqueFilename);
        const task = storageRef.putString(url.split('data:image/png;base64,')[1], 'base64', { contentType: 'image/png' });
        task.on("state_changed", (snapshot) => {
            this.onStateChanged(snapshot, uniqueFilename, filename);
        });
        task.then(() => {
            storageRef.getDownloadURL().then((url) => {
                if (duplicate) {
                    this.onAddItem({ url, filename, uniqueFilename });
                } else {
                    this.onUpdateItem({ id, url, filename, uniqueFilename });
                }
            });
        })
    }

    getGallery() {
        const { files } = this.state;
        const arrFiles = files.map(item => ({
            ...item,
            original: item.url,
            thumbnail: item.url
        }))
        return (
            <div className="preview-container">
                <ImageGallery
                    renderPlayPauseButton={() => {
                        return (
                            <></>
                        );
                    }}
                    items={arrFiles}
                    renderItem={(file) => (
                        <div className="gallery-div">
                            <FileGalleryItem onChange={this.onUpdateFile} {...file} canSelect={this.props.canSelect} onSelectItem={this.onSelectItem} onRemove={this.onRemoveItem} isComment={this.props.isComment} />
                        </div>
                    )}
                />
            </div>
        )
    }

    setIsGallery(isGallery) {
        this.setState({ isGallery })
    }

    onImagePaste(images) {
        if (images) {
            images.map(image => {
                this.blobToBase64(image, (base64) => {
                    this.saveFiles([{ url: `data:image/png;base64,${base64}`, fileName: `${new Date().getTime()}.png` }])
                });
            })
        }
    }

    blobToBase64(blobUrl, callback) {
        fetch(blobUrl)
            .then(response => response.blob())
            .then(blob => {
                var reader = new FileReader();
                reader.onload = function () {
                    var dataUrl = reader.result;
                    var base64 = dataUrl.split(',')[1];
                    callback(base64);
                };
                reader.readAsDataURL(blob);
            })
    };

    selectAll() {
        const { files } = this.state;
        const hasSelected = files.filter(file => file.isSelected === true).length > 0

        const newFiles = files.map(file => {
            return hasSelected ? { ...file, ...file.isSelected = false } : { ...file, ...file.isSelected = true }
        })

        this.setState({
            newFiles
        });

        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(files.filter(file => file.isSelected));
        }
    }

    deselectAll() {
        const { files } = this.state;

        const newFiles = files.map(file => ({ ...file, ...file.isSelected = false }))

        this.setState({
            newFiles
        });

        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(files.filter(file => file.isSelected));
        }
    }

    async deleteAll() {
        const { files } = this.state;
        const { dataReference } = this.props;
        const selecteds = files.filter(item => item.isSelected === true);
        for (let index = 0; index < selecteds.length; index += 1) {
            await deleteItem(dataReference, selecteds[index].id);
            this.removeFromStorage(selecteds[index]);
        }

        const newFiles = files.filter(item => !item.isSelected);

        this.setState({
            files: newFiles
        });

        if (this.props.onFileChange) {
            this.props.onFileChange(newFiles)
        } else if (this.props.onSelectionChange) {
            this.props.onSelectionChange([])
        }
    }

    render() {
        const { files } = this.state;
        const { showDropzone = true, largeDropzone = false } = this.props
        // const { width } = this.props.size;
        const filesLength = files.filter(file => file.isSelected === true).length
        const hasSelected = filesLength > 0
        console.log({ files })
        sort(files).by([
            { desc: u => u.time },
            { asc: u => u.filename },
        ]);

        return (
            <>
                <Dialog ref={this.dialog} />
                <PasteComponent onChange={this.onImagePaste} />
                <div className="drop-zone-header">
                    <div className="button-left">
                        {this.props.useCrop && <EditPdfWizard saveFiles={this.saveFiles} />}
                    </div>
                    <div className='button-right'>
                        {this.props.canSelect && this.props.isMultipleSelect && <div className='select-buttons'>
                            {/* <Button
                                className='button-delete'
                                // outline
                                // color="primary"
                                onClick={() => this.confirmDelete()}
                                disabled={!hasSelected}
                                id="deleteAll"
                            >
                                <BiTrashAlt />
                            </Button> */}
                            <OutlineButton label={"Excluir"} onClick={() => this.confirmDelete()} disabled={!hasSelected} />
                            {/* <UncontrolledTooltip placement="top" target="deleteAll">
                                {filesLength === 1 ? `Excluir imagem selecionada` : `Excluir imagens selecionadas`}
                            </UncontrolledTooltip> */}
                            {/* <Button
                                className='button-select'
                                outline
                                color="primary"
                                onClick={() => this.selectAll()}
                            >
                                {hasSelected ? `Desselecionar todas` : `Selecionar todas`}
                            </Button> */}
                            <SaveButton label={hasSelected ? `Desselecionar todas` : `Selecionar todas`} onClick={() => this.selectAll()} />
                        </div>}
                        {/* <ButtonGroup size="sm">
                            <Button variant="dark" className='button-group' active={!isGallery} color="link" onClick={() => this.setIsGallery(false)}>Lista</Button>
                            <Button variant="dark" className='button-group' active={isGallery} color="link" onClick={() => this.setIsGallery(true)}>Galeria</Button>
                        </ButtonGroup> */}
                    </div>
                </div>
                {/* {showDropzone && <ReactDropZone
                    // accept="image/*"
                    accept={this.props.accept}
                    onDrop={this.onPreviewDrop}
                />} */}
                {showDropzone && <FileContainer
                    useCrop={true}
                    dataReference={this.props.dataReference}
                    canSelect={true}
                    isMultipleSelect={true}
                    showFiles={false}
                    style={{ minHeight: "auto" }}
                    accept={this.props.accept}
                    onFileChange={files => {
                        console.log({ files })
                        this.setState({ files })
                    }}
                    files={files}
                    largeDropzone={largeDropzone}
                />}
                <div style={{ marginTop: 30 }}>
                    <GalleryContainer
                        images={files}
                        onSelectItem={this.onSelectItem}
                        onChange={this.onUpdateFile}
                        onRemove={this.onRemoveItem}
                        isComment={this.props.isComment}
                        onHideChange={this.onHideChange}
                    />
                </div>
            </>
        )
    }
}


/* {(this.props.modalFixed !== undefined) ? <div className="overflow-body">
                    {(!isGallery && (this.state.uploadInProgress.length > 0 || this.state.files.length > 0)) &&
                        <div className="preview-container">
                            <GridList cellHeight={300} cols={Math.floor(width / 300)} className="tile-grid">
                                {this.state.files.map((file) => (
                                    <GridListTile key={file.url} cols={1} className="tile">
                                        <FileGalleryItem onHideChange={this.onHideChange} onChange={this.onUpdateFile} {...file} canSelect={this.props.canSelect} onSelectItem={this.onSelectItem} onRemove={this.onRemoveItem} isComment={this.props.isComment} />
                                    </GridListTile>
                                ))}
                                {this.state.uploadInProgress.map((file) => (
                                    <GridListTile key={file.url} cols={1} className="tile">
                                        <div className="file-item file-item-bordered">
                                            <div className="column">
                                                <i class="material-icons">cloud_upload</i>
                                                <p className="truncated">{file.filename}</p>
                                            </div>
                                            <div className="text-center">{`${Math.round(file.percentage)}%`}</div>
                                            <Progress value={file.percentage} />
                                        </div>
                                    </GridListTile>
                                ))}
                            </GridList>
                        </div>
                    }
                    {isGallery && this.getGallery()}
                </div> : <>
                        {(!isGallery && (this.state.uploadInProgress.length > 0 || this.state.files.length > 0)) &&
                            <div className="preview-container">
                                <GridList cellHeight={300} cols={Math.floor(width / 300)} className="tile-grid">
                                    {this.state.files.map((file) => (
                                        <GridListTile key={file.url} cols={1} className="tile">
                                            <FileGalleryItem onHideChange={this.onHideChange} onChange={this.onUpdateFile} {...file} canSelect={this.props.canSelect} onSelectItem={this.onSelectItem} onRemove={this.onRemoveItem} isComment={this.props.isComment} />
                                        </GridListTile>
                                    ))}
                                    {this.state.uploadInProgress.map((file) => (
                                        <GridListTile key={file.url} cols={1} className="tile">
                                            <div className="file-item file-item-bordered">
                                                <div className="column">
                                                    <i class="material-icons">cloud_upload</i>
                                                    <p className="truncated">{file.filename}</p>
                                                </div>
                                                <div className="text-center">{`${Math.round(file.percentage)}%`}</div>
                                                <Progress value={file.percentage} />
                                            </div>
                                        </GridListTile>
                                    ))}
                                </GridList>
                            </div>
                        }
                        {isGallery && this.getGallery()}
                    </>
                } */


export default sizeMe()(FileGalleryContainer);