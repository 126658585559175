import React, { useState } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, Row
} from 'reactstrap';
import FileGalleryContainer from '../../container/FileGalleryContainer';
import { NewModal } from '../../layoutV2/components/Modal/Modal';
import Alert from '../Alert/Alert';
import { ViewImagesButton } from '../Button/Button';

export const WorkModalImages = ({ location, data, onDataChange, floatButton, field }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selecteds, setSelecteds] = useState([]);
    const toggle = () => setIsOpenModal(!isOpenModal);

    const onSelectionChange = (files) => {
        setSelecteds(files);
    }

    const onSaveClick = () => {
        const selectedsId = selecteds.map(item => item.id);
        const imagesId = data[field].map(item => item.id);
        const hasData = data[field] && data[field].find(item => selectedsId.includes(item.id));

        console.log(selectedsId.length)

        if (hasData) {
            const toInsert = selecteds.filter(item => !imagesId.includes(item.id));

            if (toInsert.length > 0) {
                data[field] = data[field] ? data[field].concat(toInsert) : toInsert;
                setIsOpenModal(false);
                onDataChange(data);
            }

            Alert.warn(selectedsId.length > 1 ? `Uma ou mais imagens selecionadas já estão adicionadas.` : `A imagem selecionada já está adicionada.`)
        } else {
            data[field] = data[field] ? data[field].concat(selecteds) : selecteds;
            setIsOpenModal(false);
            onDataChange(data);
        }
    }

    return (
        <>
            {floatButton ? <ViewImagesButton onClick={() => setIsOpenModal(true)} className="paste-button float-button top2" />
                // <Button onClick={() => setIsOpenModal(true)} size="sm" className="paste-button float-button top2" title="Mostrar imagens da vistoria">
                //     <i class="material-icons">
                //         add_photo_alternate
                //         </i>
                // </Button> 
                : <Button className='add-image-button' color="primary" size="sm" outline onClick={() => setIsOpenModal(true)}>
                    Adicionar Imagem
            </Button>}
            <NewModal
                open={isOpenModal}
                toggle={toggle}
                title={"Detalhes"}
                className="report-modal modal-gallery-images"
                onClickConfirm={onSaveClick}
            >
                {/* <div className="category editable"> */}
                    <FileGalleryContainer field={field} modalFixed saveState={true} filter={{}} canSelect={true} isMultipleSelect={true} accept="image/*" onSelectionChange={onSelectionChange} useCrop={true} dataReference={`Works/${location.state.workId}/inpections/${data.inspectionId}/images`} />
                {/* </div> */}
            </NewModal>
        </>
    );
};

{/* <Modal isOpen={isOpenModal} toggle={toggle} style={{ maxWidth: '60%' }}>
    <ModalHeader toggle={toggle}>Detalhes</ModalHeader>
    <ModalBody className='details-wizard'>
        <div className="category editable">
            <FileGalleryContainer modalFixed saveState={true} filter={{}} canSelect={true} isMultipleSelect={true} accept="image/*" onSelectionChange={onSelectionChange} useCrop={true} dataReference={`Works/${location.state.workId}/inpections/${data.inspectionId}/images`} />
        </div>
        <Row>
            <Button color="success" className="comment-save" outline onClick={onSaveClick}>{"Salvar"}</Button>
        </Row>
    </ModalBody>
</Modal> */}