import { db } from './firebaseConfig';
import { getItem, addItem, deleteItem, updateItem, execute } from './firebaseFunctions';

export const getTags = async () => {
    // const table = db.collection("Libs").doc("Tags").collection("values").where("active", "==", true);
    return getItem(`Tags/Libs/values`);
}

export const addTag = async (item) => {
    return addItem(`Tags/Libs/values`, item);
}

export const deleteTag = async (id) => {
    return deleteItem(`Tags/Libs/values`, id);
}

export const updateTag = async (item) => {
    return updateItem(`Tags/Libs/values`, item);
}