import React, { useEffect } from 'react';
import AnimationView from '../views/AnimationView';
import { addConfirmRead } from '../core/database/confirmRead';

const ConfirmReadContainer = (props) => {

	useEffect(() => {
		saveData()
	}, [])

	const saveData = async () => {
		let search = props.location.search.split('?')
		let formatedId = search[1].split('=')[1]
		let formatedEmail = search[2].split('=')[1]
		if (formatedId && formatedEmail) {
			await addConfirmRead({ idItem: formatedId, email: formatedEmail, date: new Date() })
		}
	}

	return <AnimationView {...props} />
}

export default ConfirmReadContainer;