import React, { useEffect } from "react";
import classnames from "classnames";
import {
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown
} from "reactstrap";
import { MoreVertOutlined } from "@material-ui/icons";
import { logout } from 'core/redux-store/login/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumbs } from "../../layoutV2/components/Breadcrumb";
import { setLoginData, silentSignIn } from "../../core/redux-store/login/Actions";
import { FaAngleDown } from "react-icons/fa";
import { AvatarUser } from "../../layoutV2/components/Avatar";
import { useUserType } from "../../core/user/hooks";
import logo from "assets/img/logo-ike.png";
import { useLocation, } from "react-router-dom";


const AdminNavbar = ({ children, goBack }) => {
	const { hasPower } = useUserType();
	const workGroup = useSelector(state => state.login.workGroup)
	const dispatch = useDispatch()
	const location = useLocation()

	const selectGroup = () => {
		dispatch(setLoginData({ network: undefined, groupData: {} }))
	}

	const doLogout = () => {
		dispatch(logout());
	}

	return (
		<>
			<div className="home-navbar">
				<div className="logo">
					{goBack ? <i className="material-icons icon" onClick={goBack}>
						arrow_back_ios
					</i> : <i className="material-icons icon" onClick={() => document.body.classList.toggle("sidebar-mini")}>
						menu
					</i>}
					<div className="logo-img">
						<img src={logo} />
					</div>
				</div>
				<div className="breadcrumb-header">
					<Breadcrumbs goBack={goBack} />
					<div className="navbar-children">{children}</div>
				</div>
				<UncontrolledDropdown className="btn-rotate">
					<DropdownToggle
						aria-haspopup={true}
						caret={false}
						color="default"
						data-toggle="dropdown"
						className="toggle-button-nav"
						id="navbarDropdownMenuLink"
						nav
					>
						<AvatarUser hasPower={hasPower} />
						<FaAngleDown />
					</DropdownToggle>
					<DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
						{(workGroup && workGroup.length > 1) && (
							<>
								{
									!`${location.pathname}`.includes('/admin/termos') && <DropdownItem
										onClick={selectGroup}
									>
										Trocar de grupo
									</DropdownItem>
								}
							</>


						)}
						<DropdownItem
							onClick={doLogout}
						>
							Sair
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			</div>
			<div className="breadcrumb-mobile">
				<Breadcrumbs goBack={goBack} />
			</div>
		</>
	)
}

export default AdminNavbar;