import React from "react";
import { connect } from "react-redux";
import Libs from "views/Libs.jsx";
import { getLibData, updateLibData, deleteLibData, getLibTagsData } from "core/redux-store/lib/Actions";
import { scopeHasPower } from './UserContainer';
import { getSkillData } from "../core/redux-store/skill/Actions";
import Dialog from 'react-bootstrap-dialog'
import ModalAlert from "../layoutV2/components/Modal/ModalAlert";

class LibContainer extends React.Component {
	constructor(props) {
		super(props);

		this.onRowDelete = this.onRowDelete.bind(this);
		this.onRowUpdate = this.onRowUpdate.bind(this);
		this.onNewItem = this.onNewItem.bind(this);
		this.onEditItem = this.onEditItem.bind(this);
		this.setTags = this.setTags.bind(this);
		this.onDeleteItem = this.onDeleteItem.bind(this);
		this.dialog = React.createRef();
	}

	onRowAdd() {

	}

	setTags(tags) {
		this.tags = tags;
	}

	onRowDelete(oldData) {
		return this.props.dispatch(deleteLibData(oldData.id));
	}

	onRowUpdate(newData, oldData) {
		return this.props.dispatch(updateLibData(newData));
	}

	componentWillMount() {
		let extraProps = {}
		if (this.props.isComment) {
			extraProps = { tag: "IcjvF32VXhY7Y40fmIV9" }
		}
		this.props.dispatch(getLibTagsData());
		this.props.dispatch(getLibData({ ...extraProps, workGroup: this.props.network }));
		this.props.dispatch(getSkillData({ level: 1 }));
	}

	componentDidMount() {
		Dialog.setOptions({
			defaultOkLabel: 'Sim',
			defaultCancelLabel: 'Cancelar',
			primaryClassName: 'btn-success'
		})
	}

	onNewItem() {
		const { history, isComment } = this.props;
		history.push("/admin/lib-details", { new: true, tags: this.tags, isProject: this.props.isProject, filter: this.props.filter, route: this.props.routeData, isComment });
	}

	onEditItem(data) {
		const { history, isComment } = this.props;
		delete data.tableData;
		history.push("/admin/lib-details", { ...data, isProject: this.props.isProject, filter: this.props.filter, route: this.props.routeData, isComment });
	}

	onDeleteItem(oldData) {
		/* this.dialog.current.show({
			title: 'Documento',
			body: 'Deseja mesmo remover este documento? Ao realizar esta ação o item não poderá mais ser acessado e a ação não poderá ser revertida.',
			// defaultOkLabel: 'Sim',
			// defaultCancelLabel: 'Cancelar',
			actions: [
				Dialog.CancelAction(),
				Dialog.OKAction(() => {
					// this.onRowDelete(oldData);
				})
			],
			bsSize: 'small',
		}) */
		ModalAlert.danger({
			title: "Documento",
			text: "Deseja mesmo remover este documento? Ao realizar esta ação o item não poderá mais ser acessado e a ação não poderá ser revertida.",
			onConfirm: () => this.onRowDelete(oldData)
		})
	}

	onSelectionChange(files) {

	}

	render() {
		const { isDetails, showToolbar, isAdmin, isProject, groups, id, groupData, email, network, headerAdd } = this.props;

		const hasPower = this.props.hasPower || isAdmin || scopeHasPower(id, groups)

		return (
			<>
				<Libs
					data={this.props.items.filter(item => !item.tags || item.tags.length == 0 || (item.tags.find(tag => this.props.isComment ? tag.value === "IcjvF32VXhY7Y40fmIV9" : tag.value !== "IcjvF32VXhY7Y40fmIV9")))}
					isLoading={this.props.isLoading}
					onRowAdd={this.onRowAdd}
					onRowDelete={hasPower && !isDetails && this.onRowDelete}
					onDeleteItem={this.onDeleteItem}
					onAddClick={this.onNewItem}
					onEditClick={this.onEditItem}
					comboTags={this.props.comboTags}
					skills={this.props.skills}
					setTags={this.setTags}
					isDetails={isDetails}
					onSelectionChange={this.onSelectionChange}
					showToolbar={showToolbar}
					isProject={isProject}
					hideHistory={this.props.hideHistory}
					network={network}
					hasPower={hasPower}
					groupData={groupData}
					isComment={this.props.isComment}
					email={email}
					headerAdd={headerAdd}
				/>
				<Dialog ref={this.dialog} />
			</>
		);
	}
}

export const LibContainerComponent = LibContainer;

export default connect(state => ({
	email: state.login.email,
	isLoading: state.lib.isLoading,
	items: state.lib.items,
	comboTags: state.lib.comboTags,
	skills: state.skill.items,
	errorMessage: state.lib.error,
	isAdmin: state.login.isAdmin,
	network: state.login.network,
	groupData: state.login.groupData,
}))(LibContainer);
