import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { CommentDetails } from "../views/CommentDetails";
import { normalizeCombo } from 'core/utils/Utils';
import { getItem, updateItem } from "../core/database/firebaseFunctions";
import { getLibByIdData, addLibData, updateLibData } from "../core/redux-store/lib/Actions";
import { getSkillData } from "../core/redux-store/skill/Actions";
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import Alert from '../components/Alert/Alert';
import { getWorkTypes } from "../core/database/skill";
import { getGroupData } from "../core/redux-store/group/Actions";

let defaultValues = {
    section: 1
};

const CommentDetailsContainer = (props) => {
    const dispatch = useDispatch();
    const skills = useSelector(state => state.skill.items)
    const isLoading = useSelector(state => state.lib.isLoading)
    const isAdmin = useSelector(state => state.login.isAdmin)
    const email = useSelector(state => state.login.email)
    const allGroups = useSelector(state => state.group.items)
    const network = useSelector(state => state.login.network)
    const groupData = useSelector(state => state.login.groupData)
    const workGroup = useSelector(state => state.login.workGroup)
    const [values, setValues] = useState(defaultValues);
    const [categories, setCategories] = useState([]);
    const [lib, setLib] = useState({});
    const [images, setImages] = useState([]);
    const [workTypes, setWorkTypes] = useState([])

    useEffect(() => {
        if (props.location && props.location.state && props.location.state.section) {
            setValues({ section: props.location.state.section })
        }
    }, [props.location && props.location.state && props.location.state.section])

    useEffect(() => {
        loadWorks()
        dispatch(getGroupData(workGroup))
        dispatch(getSkillData({ level: 1 }));
        if (props.location.state && props.location.state.id) {
            dispatch(getLibByIdData({ id: props.location.state.id })).then(items => {
                const lib = items[0];
                if (props.location.state.isDraft) {
                    setLib({
                        ...lib,
                        isDraft: true,
                        name: lib.name !== "Rascunho" ? lib.name : undefined,
                    })
                } else {
                    setLib({ ...lib });
                }

                getItem(`Libs/${props.location.state.id}/categories`).then(categories => {
                    setCategories(categories);
                })

                getItem(`Libs/${props.location.state.id}/images`).then(images => {
                    setImages(images);
                })
            })
        } else {
            dispatch(addLibData({ name: "Rascunho", active: false })).then(lib => {
                props.history.replace({ ...props.history.location, state: { ...props.history.location.state, id: lib.id, isDraft: true } });
            });
        }
    }, [props.location]);

    const loadWorks = async () => {
        // const works = await getWorkTypes()
        // setWorkTypes(works);
        const works = await getWorkTypes({network})
        const newWorks = groupData && groupData.workTypeIds ? works.filter(work => groupData.workTypeIds.includes(work.value)) : works
        setWorkTypes(newWorks);
    }

    const onEditImage = (data, image) => {
        // onUpdateFile(location.state.workId, location.state.id, data, image, setData);
        console.log("onEditImage")
    }

    const onDataChange = (newData) => {
        /* updateLinkedImageInspection(location.state.workId, location.state.id, newData).then(() => {
            reload(setData, data, newData)
            Alert.success("Salvo!")
        }); */
        console.log("onDataChange")
    }

    function saveData() {
        const item = {
            ...lib,
            ...values
        }

        let { id, name, year, description, tags, linkSteps, section, link, workType, adminWorkGroup, workGroup, hasChanged } = item;
        let extraData = {}

        try {
            if (!name) {
                throw new Error(I18n[keyEnum.nameRequired]);
            }

            if (!description) {
                description = "";
            }

            if (!link) {
                link = "";
            }

            if (!year) {
                year = "";
            }

            if (!linkSteps) {
                linkSteps = [];
            }

            if (!section) {
                section = 0;
            }

            if (!workType) {
                workType = [];
            }

            if (adminWorkGroup || workGroup) {
                if (hasChanged) {
                    if (adminWorkGroup) {
                        extraData['workGroup'] = adminWorkGroup
                    } else {
                        extraData['isPublic'] = true
                    }
                } else {
                    extraData['workGroup'] = workGroup
                }
            } else if (isAdmin) {
                extraData['isPublic'] = true
            } else if (network) {
                extraData['workGroup'] = network
            }

            const onDataCompleted = async (response) => {
                // this.setState({ hasChange: false })
                Alert.success("Arquivo Salvo!");
            }

            return dispatch(updateLibData({
                id, name, description, year, link, tags, section, workType, ...extraData,
                linkSteps, tags: [{ value: "IcjvF32VXhY7Y40fmIV9", label: "Apontamento pré-formatado" }], active: true
            })).then(onDataCompleted)
        } catch (error) {
            Alert.error(error.message);
        }
    }

    function onChange(field, value) {
        if (field === "adminWorkGroup") {
            setValues({ ...values, "hasChanged": true, [field]: value });
        } else {
            setValues({ ...values, [field]: value/* , hasChange: true */ });
        }
    }

    function onBack() {
        props.history.goBack();
    }

    function onFileChange(files) {
        setImages(files);
    }

    const hasChange = (grid, oldGrid) => {
        if (!oldGrid) {
            return true;
        }

        return grid.x !== oldGrid.x || grid.y !== oldGrid.y || grid.w !== oldGrid.w || grid.h !== oldGrid.h;
    }

    const onSaveLayout = (data, layouts) => {
        const promises = [];
        const newImages = data.images.map(image => {
            let hasChangeToUpdate = false;
            const grid = layouts.find(item => item.i === image.id);
            if (grid && hasChange(grid, image.grid)) {
                hasChangeToUpdate = true;
                image.grid = { x: grid.x, y: grid.y, w: grid.w, h: grid.h };
            }

            if (hasChangeToUpdate) {
                promises.push(updateItem(`Libs/${props.location.state.id}/images`, { id: image.id, grid: image.grid }));
            }

            return image;
        });

        Promise.all(promises).then(() => {
            setImages(newImages);
        })
    }

    const formatSkills = () => {
        const skillsFiltered = (values.workType && values.workType.length > 0) ?
            skills.filter(skill => values.workType.find(type => skill.parentId === type.value))
            : skills
        return normalizeCombo("id", "name", skillsFiltered).sort((a, b) => a.label && b.label && a.label.localeCompare(b.label))
    }

    return (
        <CommentDetails
            editable={isAdmin || ((groupData.responsible === email) && !lib.isPublic)} //&& (values.workGroup === network)}
            saveData={saveData}
            onChange={onChange}
            onBack={onBack}
            isLoading={isLoading}
            skills={formatSkills()}
            categories={categories}
            {...lib}
            {...values}
            images={images}
            onSaveLayout={onSaveLayout}
            onEditImage={onEditImage}
            onDataChange={onDataChange}
            onFileChange={onFileChange}
            workTypes={workTypes}
            allGroups={normalizeCombo("id", "name", allGroups)}
            isAdmin={isAdmin}
        />
    );
}

export default CommentDetailsContainer;