import React, { useEffect, useState } from 'react';
import { getItem, updateItem } from '../core/database/firebaseFunctions';
import { getLibByIdData } from '../core/redux-store/lib/Actions';
import { CommentView } from '../views/works/CommentView';
import { useDispatch } from 'react-redux'

function onLoad(id, setData, setDetails, dispatch) {
    // console.log("getLibByIdData load", id);  
    dispatch(getLibByIdData({ id })).then(item => {
        // console.log("getLibByIdData", item);
        setDetails(item[0]);

        getItem(`Libs/${id}/images`).then(images => {
            // console.log("images", images);
            setData(images);
        })
    })
}

export const CommentContainer = ({ content = {}, report = false, defaultImages = undefined, defaultDetails = undefined, id, onSave, onChange, index = undefined, isRecorrent = undefined, delComment = () => { } }) => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [details, setDetails] = useState();

    useEffect(() => {
        setDetails(defaultDetails);
        setData(defaultImages);
    }, [defaultDetails, defaultImages])

    useEffect(() => {
        if (!defaultDetails) {
            onLoad(id, setData, setDetails, dispatch)
        }
    }, []);

    const onEditImage = (data, image) => {
        // onUpdateFile(location.state.workId, location.state.id, data, image, setData);
        console.log("onEditImage")
    }

    const hasChange = (grid, oldGrid) => {
        if (!oldGrid) {
            return true;
        }

        return grid.x !== oldGrid.x || grid.y !== oldGrid.y || grid.w !== oldGrid.w || grid.h !== oldGrid.h;
    }

    const onSaveLayout = (data, layouts) => {
        const promises = [];
        const newImages = data.images.map(image => {
            let hasChangeToUpdate = false;
            const grid = layouts.find(item => item.i === image.id);
            if (grid && hasChange(grid, image.grid)) {
                hasChangeToUpdate = true;
                image.grid = { x: grid.x, y: grid.y, w: grid.w, h: grid.h };
            }

            if (hasChangeToUpdate) {
                promises.push(updateItem(`Libs/${id}/images`, { id: image.id, grid: image.grid }));
            }

            return image;
        });

        Promise.all(promises).then(() => {
            setData(newImages);
        })
    }

    const onDataChange = (newData) => {
        /* updateLinkedImageInspection(location.state.workId, location.state.id, newData).then(() => {
            reload(setData, data, newData)
            Alert.success("Salvo!")
        }); */
        console.log("onDataChange")
    }

    return (
        <CommentView
            data={data}
            details={details}
            onSave={onSave}
            onSaveLayout={onSaveLayout}
            onEditImage={onEditImage}
            onDataChange={onDataChange}
            index={index}
            isRecorrent={isRecorrent}
            report={report}
            delComment={delComment}
            content={content}
        />
    );
};