import React from "react";
import { Card, CardTitle, Col } from "reactstrap";
import { NivoPieChart } from "../Charts/NivoPieChart";

const getNumber = (data, type, count) => {
    let number = data.filter(item => type ? item[type] : !item.hasImpediment && !item.completed).length
    if (count) {
        return parseFloat(((number / data.length) * 100 || 0).toFixed(2))
    }
    return number > 1 ? `${number} inspeções` : `${number} inspeção`
}


const StateCard = ({ data }) => {

    const dataFormatted = [
        {
            "id": "Não Conformidade",
            "label": `Não Conformidade (${getNumber(data, "hasImpediment")})`,
            "value": getNumber(data, "hasImpediment", true),
            "color": "hsl(39, 99%, 54%)"
        },
        {
            "id": "Executando",
            "label": `Executando (${getNumber(data, undefined)})`,
            "value": getNumber(data, undefined, true),
            "color": "hsl(22, 87%, 51%)"
        },
        {
            "id": "Finalizado",
            "label": `Finalizado (${getNumber(data, "completed")})`,
            "value": getNumber(data, "completed", true),
            "color": "hsl(41, 91%, 52%)"
        },
    ]

    return (
        <Col lg="6" md="12">
            <Card style={{ padding: 20, height: 500, width: "100%", marginBottom: 10 }}>
                <div style={{ display: "flex", fontWeight: "bold", fontSize: 20, textAlign: 'center', justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
                    {`Estado das tarefas - ${data.length} ${data.length > 1 ? "inspeções" : "inspeção"}`}
                </div>
                <NivoPieChart data={dataFormatted} legendWidth={120} />
            </Card>
        </Col>
    )
}

export default StateCard