import React from "react";
import { connect } from "react-redux"
import ScopeContainer from '../../ScopeContainer';
import WorksDetailsView from 'views/works/details/WorksDetailsView';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import Alert from '../../../components/Alert/Alert';
import { updateWorksData, addWorksData, getWorksByIdData } from 'core/redux-store/works/Actions';
import { getUserData } from "core/redux-store/user/Actions";
import { getCompanyByIdData } from '../../../core/redux-store/company/Actions';
import { deleteWorksData } from '../../../core/redux-store/works/Actions';
import { getWorkTypes } from "../../../core/database/skill";
import { normalizeCombo } from "../../../core/utils/Utils";
import { notifyError, notifySuccess } from "../../../layoutV2/components/Toast";
import { setCanReload } from "../WorksContainer";

class WorksDetailsContainer extends ScopeContainer {
	constructor(props) {
		super(props);

		this.state = {
			workTypes: []
		}

		this.onChange = this.onChange.bind(this);
		this.saveData = this.saveData.bind(this);
		this.onBack = this.onBack.bind(this);
		this.onFileChange = this.onFileChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	onLocationChange(location) {
		const { groupData, email, network } = this.props;
		const { users = [] } = this.state;
		const { state } = location;
		console.log({ teste2: this.state })
		getWorkTypes({ network }).then(workTypes => {
			const newWorks = groupData && groupData.workTypeIds ? workTypes.filter(work => groupData.workTypeIds.includes(work.value)) : workTypes
			this.setState({ workTypes: newWorks });
		})
		if (state) {
			if (state.workId) {
				this.props.dispatch(getWorksByIdData(state.workId)).then(item => {
					this.setState(item);
					console.log({ item })

					// dispatch(getCompanyByIdData(item.idCompany))
					this.props.dispatch(getUserData({ value: item.idCompany })).then(user => {
						// console.log({ user })
						this.setState({ companyUsers: normalizeCombo("email", "email", user) });
					});
				});
			} else if (state.idCompany) {
				this.setState({ idCompany: state.idCompany });

				/* 	this.props.dispatch(getCompanyByIdData(state.idCompany)).then(company => {
						console.log({ company })
						this.setState({ companyUsers: company.users });
					}); */

				this.props.dispatch(getUserData({ value: state.idCompany })).then(user => {
					// console.log({ user })

					this.setState({ companyUsers: normalizeCombo("email", "email", user) });
				});
			}


		}
	}

	onDelete() {
		this.props.dispatch(deleteWorksData(this.state.id)).then(() => {
			this.onBack();
		})
	}

	onScopeChange(scope) {
		const { dispatch } = this.props;
	}

	saveData() {
		const { dispatch, email } = this.props;
		let { id, name, type, file, idCompany, state, workType, users = [], address, regProf, responsible, qualityResponsible = [], qualityUsers = [], hideProduction, hideTeam, noSchedule } = this.state;
		// console.log({ noSchedule })
		// console.log({ id, name, type, file, idCompany, state, workType, users, address, regProf, responsible, qualityResponsible, qualityUsers, hideProduction, hideTeam })
		try {
			if (!id) {
				users = users.concat({ value: email, label: email });
				qualityResponsible = qualityResponsible.concat({ value: email, label: email });
				qualityUsers = qualityUsers.concat({ value: email, label: email });
			}

			if (!name) {
				throw new Error(I18n[keyEnum.nameRequired]);
			}
			if (!type) {
				throw new Error(I18n[keyEnum.typeRequired]);
			}
			if (!file) {
				throw new Error(I18n[keyEnum.imageRequired]);
			}
			if (!workType) {
				throw new Error(I18n[keyEnum.workTypeRequired]);
			}

			let extraData = {
				hideProduction: hideProduction || false,
				hideTeam: hideTeam || false
			};

			if (address) {
				extraData.address = address;
			}

			if (responsible) {
				extraData.responsible = responsible;
			}

			if (regProf) {
				extraData.regProf = regProf;
			}

			if (qualityResponsible) {
				extraData.qualityResponsible = qualityResponsible;
			}

			if (qualityUsers) {
				extraData.qualityUsers = qualityUsers;
			}

			if (!state) {
				state = 1; //default "Executando"
			}

			if (!noSchedule) {
				noSchedule = noSchedule || false
			}

			const onDataCompleted = async (response) => {
				if (!id) {
					this.setState({ id: response.id })
				}
				Alert.success(`Obra ${id ? "atualizada" : "cadastrada"} com sucesso!`)
				setCanReload(true);
				// notifySuccess(`Obra ${id ? "atualizada" : "cadastrada"} com sucesso!`)
			}
			// console.log({ workType })
			if (id) {
				return dispatch(updateWorksData({ id, name, file, users, type, workType, state, noSchedule, ...extraData })).then(onDataCompleted);
			} else {
				return dispatch(addWorksData({ name, idCompany, type, file, users, workType, state, noSchedule, ...extraData })).then(onDataCompleted);
			}

		} catch (error) {
			Alert.error(error.message);
			// notifyError(`Ocorreu um erro ao ${id ? "atualizar" : "cadastrar"} a obra. Por favor, tente novamente!`)
		}
	}

	onChange(field, value) {
		this.setState({ [field]: value })
	}

	onBack() {
		// this.props.history.push("/admin/works")
		this.props.history.goBack()
	}

	onFileChange(file) {
		this.setState({ file });
	}

	render() {
		const { isLoading, hideHeader } = this.props;

		return (
			<WorksDetailsView
				saveData={this.saveData}
				onChange={this.onChange}
				onBack={this.onBack}
				isLoading={isLoading}
				onDelete={this.onDelete}
				onFileChange={this.onFileChange}
				{...this.state}
				hideHeader={hideHeader}
			/>
		);
	}
}

export default connect(state => ({
	hasPower: state.login.hasPower,
	scope: state.login.scope,
	email: state.login.email,
	groupData: state.login.groupData,
	network: state.login.network
}))(WorksDetailsContainer);
