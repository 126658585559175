import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';
import { getSkillData } from '../../core/redux-store/skill/Actions';

export default function SkillsCheck({ onStepsChange, sections, location }) {
    const dispatch = useDispatch();
    const skills = useSelector(state => state.skill.items).sort((a, b) => a.name && b.name && a.name.localeCompare(b.name));
    const [selecteds, setSelecteds] = useState(sections)

    useEffect(() => {
        if (location && location.state && location.state.workType) {
            dispatch(getSkillData({ parentId: location.state.workType })).then(items => {
                onStepsChange(items.map(item => ({ ...item, selected: getSelected(item.id) })));
            });
        }
    }, [])

    const getSelected = (id) => {
        return selecteds.filter(item => item.id === id)[0];
    }

    const onClick = (item) => {
        let newSelecteds = [];
        if (getSelected(item.id)) {
            newSelecteds = selecteds.filter(row => row.id !== item.id)
        } else {
            newSelecteds = selecteds.concat([item])
        }

        setSelecteds(newSelecteds);
        const stepsChange = skills.map(item => ({
            ...item,
            selected: newSelecteds.find(selected => selected.id === item.id) !== undefined
        }));

        onStepsChange(stepsChange);
    }

    return (skills && skills.length > 0) && (
        <>
            <h2 className="alert-task-title">Etapas</h2>
            <Row className="row-alert-task" style={{marginBottom:20}}>
                {skills.map(skill => {
                    return (
                        <Col lg="2" md="3" sm="6">
                            <Alert className="alert-task" color={getSelected(skill.id) ? "success" : "secondary"} onClick={() => onClick(skill)}>
                                {skill.name}
                            </Alert>
                        </Col>
                    )
                })}
            </Row>
        </>
    );
}
