import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import queryString from 'query-string'
import { registerRoute, loginRoute } from "routes.jsx";

const LoginRequiredRoute = ({ component: Component, ...rest }) => {
    if (rest.loggedIn && rest.location.pathname !== "/admin/select-group" && rest.location.pathname !== "/admin/termos" && !rest.network) {
        return <Redirect to="/admin/select-group" />
    }

    return (
        <Route {...rest} render={props => {
            const query = queryString.parse(props.location.search);
            return (
                rest.loggedIn ? (
                    <>
                        <Component {...props} />
                        {rest.location.pathname !== "/admin/termos" && !rest.term_accepted_date && <Redirect to="/admin/termos" />}
                    </>
                ) : (
                    <Redirect to={{
                        pathname: query.registerId ? registerRoute : loginRoute,
                        state: { from: props.location }
                    }} />
                )
            )
        }} />
    )
}

export default connect(
    state => ({
        loggedIn: state.login.loggedIn,
        network: state.login.network,
        term_accepted_date: state.login.term_accepted_date,
    })
)(LoginRequiredRoute);