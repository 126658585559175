import { getCompanies, addCompany, deleteCompany, updateCompany, getCompany} from "core/database/company";
import ActionCreator from 'core/utils/redux/ActionCreator';

const { actions, defaultReducer, get, add, remove, update, getSimple } = new ActionCreator("company");
export { actions, defaultReducer };

export const getCompaniesData = get(getCompanies);
export const addCompanyData = add(addCompany);
export const updateCompanyData = update(updateCompany);
export const deleteCompanyData = remove(deleteCompany);
export const getCompanyByIdData = getSimple(getCompany);
