import React, { useEffect, useState } from 'react';
import { AiOutlineFilter } from 'react-icons/ai'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Badge from 'reactstrap/lib/Badge';
import { SelectInput, TextInput, DateRange, DateRangeYear } from '../Form';
import { FiSearch } from 'react-icons/fi'
import { AddNavButton } from '../Button';
import { NewModal } from '../Modal/Modal';

let timeout;

/*

Campos de seleçã customizados para o filtro

*/

const CustomField = ({ onFilter, options, filter, ...item }) => {
    if (item.type === "year") {
        return <DateRangeYear
            startDate={filter[item.field] ? filter[item.field].startDate : undefined}
            endDate={filter[item.field] ? filter[item.field].endDate : undefined}
            onDateChange={(values) => onFilter([item.field], values)} //onFilter(selected => ({ ...selected, [item.field]: values }))
        />
    }
    if (item.type === "moreFiltered") {
        return <div />
    }

    return (
        <SelectInput
            options={options[item.field]}
            value={filter[item.field]}
            onChange={(values) => onFilter([item.field], values)} //onFilter(selected => ({ ...selected, [item.field]: values }))
            labelledBy={item.title}
            title={item.title}
            isDisabled={item.isDisabled || false}
        />
    )
}

/*

Componente de campos dinamicos para o filtro

*/

const FilterFields = ({ fields, filter, onFilter, options, moreModalFields }) => {
    return <>
        {fields.filter(item => !item.defaultSearch).map(item => <CustomField onFilter={onFilter} filter={filter} {...item} options={options} />)}
        <TextInput
            onChange={({ target }) => onFilter("term", target.value)} // onFilter(selected => ({ ...selected, term: target.value }))
            value={filter.term}
            placeholder="Pesquise por um termo"
            id="table-term-search"
            icon={<span style={{ marginRight: 10 }}><FiSearch /></span>}
        />
        {moreModalFields && moreModalFields(onFilter, filter)}
    </>
}

/*

Componente utilizado para o filtro

*/

export const MaterialFilter = ({ fields, options, onFilter, filter, rightComponent, onAddClick, headerAdd = false, leftComponent, moreModalFields }) => {
    const [modal, setModal] = useState(false);
    const [chips, setChips] = useState([]);
    const toggle = () => setModal(!modal);

    // const [selected, setSelected] = useState(filter || {});

    const apply = () => {
        // onFilter(selected);
        toggle();
    }

    const getChips = () => {
        let chips = [];

        Object.keys(filter).map(key => {
            chips = chips.concat(filter[key])
            return key;
        })

        return chips;
    }

    useEffect(() => {
        setChips(getChips())
    }, [filter])// eslint-disable-line

    // console.log({ fields, filterFields: fields.filter(item => !item.defaultSearch) })
    return <div className="material-filter">
        {headerAdd && <div style={{ display: "flex", flex: 1, height: 50, marginTop: 10, alignItems: "center" }}>
            <AddNavButton label="Novo" onClick={onAddClick} />
        </div>}
        {rightComponent}
        <FilterFields fields={fields} filter={filter} onFilter={onFilter} options={options} />
        {leftComponent && leftComponent(onFilter, filter)}
        {fields.filter(item => !item.defaultSearch || item.moreFiltered).length > 0 && <Button color="primary" className="filter-button" onClick={toggle}>
            <AiOutlineFilter />
            {chips.length > 0 && <div className="has-filter" />}
            <span>Filtro avançado</span>
        </Button>}
        {modal && <NewModal
            open={modal}
            toggle={toggle}
            onClickConfirm={apply}
            title={"Filtro avançado"}
            label="Aplicar"
            onClickCancel={toggle}
            className="filter-modal"
        >
            <FilterFields fields={fields} filter={filter} onFilter={onFilter} options={options} moreModalFields={moreModalFields} />
        </NewModal>}
        {/* {modal && <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Filtro avançado</ModalHeader>
            <ModalBody className="modal-material-filter">
                <FilterFields fields={fields} filter={filter} onFilter={onFilter} options={options} moreModalFields={moreModalFields} />
            </ModalBody>
            <ModalFooter className="material-filter-footer">
                <Button color="link" onClick={toggle}>Cancelar</Button>
                <Button color="primary" onClick={apply}>Aplicar</Button>{' '}
            </ModalFooter>
        </Modal>} */}
    </div >
}