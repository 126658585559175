import { FireSQL } from 'firesql';
import firebase from 'firebase';
import 'firesql/rx';
import 'firebase/firestore';

var config = {
    apiKey: "AIzaSyBS5DQ1A1m0Lkpm6MJgSAvPqKrfj5IgkMc",
    authDomain: "app-ike.firebaseapp.com",
    databaseURL: "https://app-ike.firebaseio.com",
    projectId: "app-ike",
    storageBucket: "app-ike.appspot.com",
    messagingSenderId: "153815051081",
    appId: "1:153815051081:web:1dad2c0201391c5c"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

export const db = firebase.firestore();
export const firestore = firebase.firestore;
firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});
firestore().enablePersistence();
export const database = firebase.database();
export const firebaseAuth = firebase.auth;
export const storage = firebase.app().storage("gs://app-ike.appspot.com/");
export const fireSQL = new FireSQL(db, { includeId: 'id' });



