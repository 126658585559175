import React from 'react';
import { Settings } from '../views/Settings';

const SettingsContainer = (props) => {
    console.log({ props })
    return (
        <Settings {...props} />
    );
};

export default SettingsContainer;