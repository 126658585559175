import React, { Component } from 'react';
import AdminNavbar from '../components/Navbars/AdminNavbar';
import { BreadcrumbHeader } from '../components/Breadcrumb/Breadcrumb';

const data = [
  {
    name: "Início",
    active: true,
    route: "/admin/dashboard"
  },
  {
    name: "Dicas de uso"
  }
]

class UsageTips extends Component {
  render() {
    return (
      <div className="content">
        <AdminNavbar>
          <BreadcrumbHeader data={data} />
        </AdminNavbar>
      </div>
    );
  }
}

export default UsageTips;