const dateRangeLocale = {
	"format": "DD/MM/YYYY",
	"separator": " - ",
	"applyLabel": "Aplicar",
	"cancelLabel": "Cancelar",
	"fromLabel": "De",
	"toLabel": "Para",
	"customRangeLabel": "Customizado",
	"daysOfWeek": [
		"Seg",
		"Ter",
		"Qua",
		"Qui",
		"Sex",
		"Sab",
		"Dom"
	],
	"monthNames": [
		"Janeiro",
		"Fevereiro",
		"Março",
		"Abril",
		"Maio",
		"Junho",
		"Julho",
		"Agosto",
		"Setembro",
		"Outubro",
		"Novembro",
		"Dezembro"
	],
	"firstDay": 1
}
const tableTranslate = {
	pagination: {
		labelDisplayedRows: "{from}-{to} de {count}", // {from}-{to} of {count}
		labelRowsPerPage: "Linhas por página:", // Rows per page:
		firstAriaLabel: "Primeira página", // First Page
		firstTooltip: "Primeira página", // First Page
		previousAriaLabel: "Página anterior", // Previous Page
		previousTooltip: "Página anterior", // Previous Page
		nextAriaLabel: "Próxima página", // Next Page
		nextTooltip: "Próxima página", // Next Page
		lastAriaLabel: "Última página", // Last Page
		lastTooltip: "Última página", // Last Page
		labelRowsSelect: "Linhas"
	},
	toolbar: {
		nRowsSelected: ' {0} Linha(s) selecionada(s)', // {0} row(s) selected
		showColumnsTitle: "Mostrar colunas", // Show Columns
		showColumnsAriaLabel: "Mostrar colunas", // Show Columns
		// exportTitle: 'Exportieren', // Export
		// exportAriaLabel: 'Exportieren', // Export
		// exportName: 'Als CSV exportieren', // Export as CSV
		searchTooltip: 'Pesquisar', // Search
		searchPlaceholder: 'Pesquisar', // Search
	},
	header: {
		actions: "" // Actions
	},
	body: {
		emptyDataSourceMessage: "Não há itens para mostrar", // No records to display
		filterRow: {
			filterTooltip: "Filtro" // Filter
		},
		editRow: {        
			saveTooltip: "Confirmar",
			cancelTooltip: "Cancelar",
			deleteText: "Você deseja mesmo excluir o item selecionado?"
		},      
		addTooltip: "Adicionar",
		deleteTooltip: "Excluir",
		editTooltip: "Editar"
	}
}

const translation = {
	tableTranslate,
	dateRangeLocale,
	doLogin: "Entrar",
	doRegister: "Cadastre-se",
	doRecovery: "Recuperar",
	login: "Nome de usuário",
	password: "Senha",
	forgetPassword: "Esqueceu sua senha?",
	alreadyRegistered: "Já sou cadastrado",
	socialName: "Razão social",
	cnpj: "Cnpj",
	email: "Email",
	name: "Nome",
	cel: "Número de celular",
	cpf: "Cpf",
	rg: "Rg",
	registerTitle: "Preencha seus dados!",
	loginAlreadyExists: "Usuário já cadastrado!",
	emailAlreadyExists: "Email já cadastrado!",
	groupNotExists: "Este usuário não pertence a nenhum grupo, verifique com o administrador.",
	loginNotExists: "Usuário não existe!",
	invalidPassword: "Senha inválida!",
	invalidPasswordType: "Deve conter ao menos uma letra maiúscula, um número e um caracter especial",
	required: "Obrigatório",
	invalidEmail: "Email inválido",
	minLengthSix: "Deve ter no mínimo 6 caracteres",
	recoveryTitle: "Recuperação de senha",
	logout: "Sair",
	successLogin: "Login efetuado!",
	successRegister: "Usuário registrado!",
	inviteExpired: "Convite inválido ou usuário já registrado!",
	groupFieldRequired: "Grupo obrigatório",
	requiredAll: "Preencha todos os campos",
	passwordDoesNotMatch: "Senhas não conferem",
	resendInvite: "Reenviar convite",
	nameRequired: "Nome é obrigatório",
	emailRequired: "Email é obrigatório",
	workTypeRequired: "Tipo de obra é obrigatório",
	descriptionRequired: "Descrição é obrigatória",
	checklistRequired: "Critérios são obrigatórios",
	groupRequired: "Selecione um grupo",
	dateInvalid: "Formato de ano inválido",
	linkInvalid: "Formato do link inválido",
	imageRequired: "Imagem é obrigatória",
	typeRequired: "Tipo é obrigatório",
	workTypeRequired: "Tipo da obra é obrigatório",
	stepRequired: "Etapa é obrigatória",
	settingsGroup: "Grupo é obrigatório",
}

export default translation;
