import React, { useState, useEffect } from 'react';
import { getLinkedImagesInspection, updateLinkedImageInspection, updateImageInspection, getLinkedImagesAllInspection, deleteLinkedImageInspection } from '../../core/database/inspections';
import { WorkReportView } from '../../views/works/WorkReportView';
import Alert from '../../components/Alert/Alert';
import { imageNameUtils } from '../../core/utils/Utils';
import { storage } from '../../core/database/firebaseConfig';
import { getItem } from '../../core/database/firebaseFunctions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import ModalAlert from '../../layoutV2/components/Modal/ModalAlert';

const hasChange = (grid, oldGrid) => {
    if (!oldGrid) {
        return true;
    }

    return grid.x !== oldGrid.x || grid.y !== oldGrid.y || grid.w !== oldGrid.w || grid.h !== oldGrid.h;
}

const onUpdateFile = (workId, inspectionId, data, image) => {
    const { url, filename, id } = image;
    const uniqueFilename = imageNameUtils(filename || id);
    // console.log({ uniqueFilename });
    const storageRef = storage.ref(`Works/${workId}/inpections/${inspectionId}/images`).child(uniqueFilename);
    const task = storageRef.putString(url.split('data:image/png;base64,')[1], 'base64', { contentType: 'image/png' });
    task.then(() => {
        storageRef.getDownloadURL().then((url) => {
            data.images = data.images.map(oldImage => {
                if (image.id === oldImage.id) {
                    oldImage.url = url;
                }
                return oldImage;
            });
            updateLinkedImageInspection(workId, inspectionId, data);
        });
    })
}

const onGroupFile = (workId, inspectionId, row, image, setData, data) => {
    const { url, filename } = image;
    const uniqueFilename = imageNameUtils(filename);
    const storageRef = storage.ref(`Works/${workId}/inpections/${inspectionId}/images`).child(uniqueFilename);
    const task = storageRef.putString(url.split('data:image/png;base64,')[1], 'base64', { contentType: 'image/png' });
    task.then(() => {
        storageRef.getDownloadURL().then((url) => {
            row.customImage = url;
            updateLinkedImageInspection(workId, inspectionId, row);
            reload(setData, data, row);
        });
    })
}

const reload = (setData, data, newData) => {
    const index = data.findIndex(item => item.id === newData.id);
    if (index > -1) {
        const newArr = data.slice(0);
        newArr[index] = newData;
        setData(newArr);
    }
}

export const WorkReportContainer = ({ location }) => {
    const [data, setData] = useState([]);
    const [inspections, setInspections] = useState([]);

    useEffect(() => {
        loadData();
    }, [inspections]);

    const confirmDelete = async (dataReport) => {
        await deleteLinkedImageInspection(dataReport.id);
        const newData = data.filter(item => item.id !== dataReport.id)

        dataReport.images.map(image => {
            image.linked = false;
            updateImageInspection(location.state.workId, dataReport.inspectionId, image);
        })
        setData(newData);
    }

    const delReport = async (dataReport) => {
        /* confirmAlert({
            title: 'Deseja remover este apontamento?',
            message: 'Ao remover, seu apontamento não será mais exibido no relatório e suas imagens irão retornar para galeria, esta ação não é reversível, deseja mesmo remover?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => confirmDelete(dataReport)
                },
                {
                    label: 'Não'
                }
            ]
        }); */
        ModalAlert.danger({
            title: "Deseja remover este apontamento?",
            text: "Ao remover, seu apontamento não será mais exibido no relatório e suas imagens irão retornar para galeria, esta ação não é reversível, deseja mesmo remover?",
            onConfirm: () => confirmDelete(dataReport)
        })
    }

    const onFinish = (dataRow) => {
        /* confirmAlert({
            title: dataRow.state === 1 ? 'Marcar como pendente' : 'Finalizar apontamento',
            message: 'Você deseja alterar o estado deste apontamento?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => onSave(dataRow, "state", !dataRow.state ? 1 : 0)
                },
                {
                    label: 'Não'
                }
            ]
        }); */
        ModalAlert.default({
            title: dataRow.state === 1 ? 'Marcar como pendente' : 'Finalizar apontamento',
            text: 'Você deseja alterar o estado deste apontamento?',
            onConfirm: () => onSave(dataRow, "state", !dataRow.state ? 1 : 0)
        })
    }

    const onSave = async (dataRow, field, value) => {
        dataRow[field] = value;
        // console.log({ dataRow, field, value })

        return updateLinkedImageInspection(location.state.workId, dataRow.inspectionId, dataRow).then(() => {
            reload(setData, data, dataRow);
            // Alert.success("Salvo!")
        });
    }

    const onEditImage = (data, image) => {
        onUpdateFile(location.state.workId, data.inspectionId, data, image, setData);
    }

    const onSaveLinkData = async (data) => {
        return updateLinkedImageInspection(location.state.workId, data.inspectionId, data);
    }

    const onDataChange = (newData) => {
        updateLinkedImageInspection(location.state.workId, newData.inspectionId, newData).then(() => {
            reload(setData, data, newData)
            Alert.success("Salvo!")
        });
    }
    // item.images.map(image => {
    //     if (image.url.includes("file://")) {
    //         promiseImages.push(getItem(`Works/${location.state.workId}/inpections/${inspection.value}/images`, {id: image.id}).then(arrImages => arrImages[0]))
    //     } else {
    //         promiseImages.push(Promise.resolve(image));
    //     }
    // })
    const loadData = () => {
        if (inspections) {
            const promises = [];
            inspections.map(inspection => {

                promises.push(getLinkedImagesInspection(location.state.workId, inspection.value).then(async items => {
                    // console.log({ items })
                    const images = await getItem(`Works/${location.state.workId}/inpections/${inspection.value}/images`);
                    console.log(`Works/${location.state.workId}/inpections/${inspection.value}/images`)
                    // Works/RrXveQonp8Xni4MJKyfx/inpections/RrXveQonp8Xni4MJKyfx/images/wQoXxKsQzIrFsjnx0Obf
                    console.log({images, items})
                    return items.map(item => ({
                        ...item,
                        images: item.images.map(image => image.url && (image.url.includes("file://") || image.url.includes("ph://") || image.url.includes("content://")) ? images.find(item => item.id === image.id) : image),
                        solvedImages: !item.solvedImages ? [] : item.solvedImages.map(image => image.url && (image.url.includes("file://") || image.url.includes("ph://") || image.url.includes("content://")) ? images.find(item => item.id === image.id) : image),
                        inspectionId: inspection.value,
                        section: item.section || 1,
                    }))
                }))
            })
            Promise.all(promises).then(items => {
                let allItems = [];
                
                items.map(itemData => {
                    allItems = allItems.concat(itemData);
                })
                console.log({allItems});

                allItems = allItems.sort(function (a, b) {

                    if (a.step && b.step && a.step.name > b.step.name) return 1;
                    if (a.step && b.step && a.step.name < b.step.name) return -1;

                    if (a.commentId > b.commentId) return 1;
                    if (a.commentId < b.commentId) return -1;

                    if (a.date > b.date) return 1;
                    if (a.date < b.date) return -1;

                    return 0;
                });

                setData(allItems);
            });
        }
    }

    const onSetInspections = (inspection) => {
        setInspections(inspection)
    }

    const onSaveCustomImage = (row, url) => {
        if (url) {
            onGroupFile(location.state.workId, location.state.id, row, { filename: data.id, url }, setData, data);
        } else {
            row.customImage = '';
            updateLinkedImageInspection(location.state.workId, location.state.id, row);
            reload(setData, data, row)
        }
    }

    const confirmDeleteComment = async (dataComment) => {
        const newData = { ...dataComment, commentId: "" };
        await updateLinkedImageInspection(location.state.workId, dataComment.inspectionId, newData)
        reload(setData, data, newData);
    }

    const delComment = async (dataComment) => {
        // confirmAlert({
        //     title: 'Deseja remover este apontamento?',
        //     message: 'Ao remover, seu apontamento não será mais exibido no relatório, esta ação não é reversível, deseja mesmo remover?',
        //     buttons: [
        //         {
        //             label: 'Sim',
        //             onClick: () => confirmDeleteComment(dataComment)
        //         },
        //         {
        //             label: 'Não'
        //         }
        //     ]
        // });
        ModalAlert.danger({
            title: "Deseja remover este apontamento?",
            text: "Ao remover, seu apontamento não será mais exibido no relatório, esta ação não é reversível, deseja mesmo remover?",
            onConfirm: () => confirmDeleteComment(dataComment)
        })
    }

    /* const onSelectItem = (item, index) => {
        const arrData = data;
        arrData[index] = { ...item, selected: !item.selected }
        setData(arrData);
        setForce(!force);
    } */

    const onSaveData = (row) => {
        // reload(setData, data, row);
        loadData();
        // console.log("ok")
    }

    return (
        <WorkReportView
            data={data}
            onSave={onSave}
            onSaveLinkData={onSaveLinkData}
            onEditImage={onEditImage}
            location={location}
            onDataChange={onDataChange}
            onSaveCustomImage={onSaveCustomImage}
            onSetInspections={onSetInspections}
            delReport={delReport}
            delComment={delComment}
            onSaveData={onSaveData}
            onFinish={onFinish}
        />
    )
}