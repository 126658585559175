import React from 'react';
import { Card } from 'reactstrap';
import { SaveButton } from '../../layoutV2/components/Button';
import { NivoLineChart } from '../../components/Charts/NivoLineChart'

const data = [
    {
        "id": "japan",
        "color": "hsl(300, 70%, 50%)",
        "data": [
            {
                "x": "1",
                "y": 0
            },
            {
                "x": "2",
                "y": 60
            },
            {
                "x": "3",
                "y": 40
            },
            {
                "x": "4",
                "y": 80
            },
            {
                "x": "5",
                "y": 60
            },
            {
                "x": "6",
                "y": 160
            },
            // {
            //     "x": "7",
            //     "y": 80
            // },
            // {
            //     "x": "8",
            //     "y": 100
            // },
            // {
            //     "x": "9",
            //     "y": 120
            // },
            // {
            //     "x": "10",
            //     "y": 150
            // },
        ]
    }
]
const dataVistory = [
    {
        "id": "japan",
        "color": "hsl(300, 70%, 50%)",
        "data": [
            {
                "x": "1",
                "y": 100
            },
            {
                "x": "2",
                "y": 140
            },
            {
                "x": "3",
                "y": 120
            },
            {
                "x": "4",
                "y": 170
            },
            {
                "x": "5",
                "y": 230
            },
            {
                "x": "6",
                "y": 370
            },
            // {
            //     "x": "7",
            //     "y": 128
            // },
            // {
            //     "x": "8",
            //     "y": 200
            // },
            // {
            //     "x": "9",
            //     "y": 240
            // },
            // {
            //     "x": "10",
            //     "y": 270
            // },
            // {
            //     "x": "11",
            //     "y": 300
            // },
            // {
            //     "x": "12",
            //     "y": 310
            // }
        ]
    }
]

export const MenuDashboardView = ({ history }) => {
    return (
        <>
            <div className='card-full dashboard-menu'>
                <Card className='card-dashboard'>
                    <h4 className='card-title'><b>Fichas de inspeção</b></h4>
                    {/* <div className='card-legend'>
                        <div className='card-columm'>
                            <text>Numero de inspeções: 50</text>
                        </div>
                    </div> */}
                    <div className='line-chart'>
                        {/* <NivoLineChart
                            data={data}
                        /> */}
                        <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_a9xyhp9v.json" background="transparent" speed="1" style={{ width: 300, height: 300 }} loop autoplay></lottie-player>
                    </div>
                    <div className='btn-dashboard'>
                        <SaveButton
                            label='Acessar'
                            onClick={() => history.push("/admin/quality-dashboard")}
                        />
                    </div>
                </Card>
                <Card className='card-dashboard'>
                    <h4 className='card-title'><b>Inspeções</b></h4>
                    {/* <div className='card-legend'>
                        <div className='card-columm'>
                            <text >Numero de vistorias: 50</text>
                        </div>
                    </div> */}
                    {/* <NivoLineChart
                        data={dataVistory}
                    /> */}
                    <div className='line-chart'>
                        <lottie-player src="https://assets10.lottiefiles.com/private_files/lf30_d4ldfuu6.json" background="transparent" speed="1" style={{ width: 300, height: 300 }} loop autoplay></lottie-player>
                    </div>
                    <div className='btn-dashboard'>
                        <SaveButton
                            label='Acessar'
                            onClick={() => history.push("/admin/inspection-dashboard")}
                        />
                    </div>
                </Card>
            </div>
        </>
    )
};