import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import AdminNavbar from '../components/Navbars/AdminNavbar';
import { getCountInspection, getCountQuality } from '../core/database/dashboard';
import { getCompaniesData } from '../core/redux-store/company/Actions';
import { getWorksData } from '../core/redux-store/works/Actions';
import { MenuDashboardView } from '../views/MenuDashboardView/MenuDashboardView';

const MenuDashboardContainer = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.login)
    const companies = useSelector(state => state.company.items)
    const works = useSelector(state => state.works.items) || []

    useEffect(() => {
        if(companies && companies.length === 0) {
            dispatch(getCompaniesData({ groups: user.groups, isAdmin: user.isAdmin, workGroup: user.network }));
        }
        if(works && works.length === 0) {
            dispatch(getWorksData({ groups: user.groups, isAdmin: user.isAdmin, email: user.email }));
        }
    }, [companies && companies.length === 0 || works && works.length === 0])

    /* useEffect(() => {
        loadData()
    }, [works && works.length > 0])

    const loadData = async () => {
        const allQuality = await getCountQuality()
        const allInspections = await getCountInspection()
        console.log({ allQuality, allInspections })
    } */
    return (
        <>
            <div className="content skills-screen skills">
                <AdminNavbar />
                <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                    <MenuDashboardView  {...props} />
                </div>
            </div>
        </>
    )

};

export default MenuDashboardContainer;