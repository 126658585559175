import React from 'react';
import { Row } from "reactstrap";
import { CardCommentDetails } from '../components/CardCommentDetails/CardCommentDetails';
import AdminNavbar from '../components/Navbars/AdminNavbar';
import { BreadcrumbHeader } from '../components/Breadcrumb/Breadcrumb';

const dataRoute = [
    {
        name: "Início",
        active: true,
        route: "/admin/dashboard"
    },
    {
        name: "Apontamentos",
        active: true,
        route: "/admin/comment"
    },
    {
        name: "Detalhes"
    }
]

export const CommentDetails = (props) => {
    return (
        <>
            <div className="content comment-details-container">
                <AdminNavbar>
                    <BreadcrumbHeader data={dataRoute} />
                </AdminNavbar>
                <Row style={{paddingTop: 30}}>
                    <CardCommentDetails
                        editable={props.editable}
                        options={props.options}
                        {...props}
                    />
                </Row>
            </div>
        </>
    );
};