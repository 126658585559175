import React from 'react';
import { IconButton } from '@material-ui/core';
import { Button } from 'reactstrap';
import { Close, Check, DeleteOutlined } from '@material-ui/icons';

const DeleteButton = ({ removeEvent }) => {
    return (
        <div className="delete-controls">
            <Button color="danger" onClick={removeEvent}>
                <DeleteOutlined />
            </Button>
        </div>
    )
}

export default DeleteButton;