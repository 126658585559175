import { actions, defaultReducer } from "./Actions";
import { success } from "core/utils/redux/Reducers";

export const initialState = {
	isLoading: true,
	error: null,
	items: []
};

export default function (state = initialState, action) {
	let arrItems;
	switch (action.type) {
		case actions.ADD_SKILL_SUCCESS:
			arrItems = state.items;
			arrItems.push(action.payload.item);
			state = success(state, { items: arrItems });
			break;
		case actions.DELETE_SKILL_SUCCESS:
			arrItems = state.items.filter(row => row.id !== action.payload.id);
			state = success(state, { items: arrItems });
			break;
		case actions.UPDATE_SKILL_SUCCESS:
			const index = state.items.findIndex(row => row.id === action.payload.item.id);
			arrItems = state.items;
			arrItems[index] = {
				...action.payload.item
			};
			state = success(state, { items: arrItems });
			break;
		default:
			state = defaultReducer(state, action);
			break;
	}
	return state; 
}

const getLibItems = (items) => {
	const libIds = [];
	const libs = items.map(item => { 
		if(item.level === 2 && item.lib){
			const key = item.lib.value;
			if(!libIds.includes(key)){
				libIds.push(key);
				return item.lib;
			}
		}
	}).filter(item => item != undefined);
	return libs;
}