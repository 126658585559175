import { ContactSupportOutlined } from '@material-ui/icons';
import React from 'react';
// import RichEditorQuill from '../../components/RichEditor/RichEditorQuill';
// import ImageGridLayout from '../../components/DragDrop/ImageGridLayout';
import { Badge, Button } from 'reactstrap';
import { DeleteButton } from '../../components/Button/Button';

const getImage = (url, filename) => {
    if (/\.(jpe?g|png|gif|bmp)$/i.test(filename)) {
        return url;
    }

    // return require('../../../../assets/roundDocument.png')
}

/* const renderItem = (item) => {
    const image = getImage(item.url, item.filename);
    return (
        <img src={image} />
    )
};

const colNum = (length) => {
    if (length === 1) {
        return "col-img-1"
    } else if (length === 2) {
        return "col-img-2"
    }
    return "col-img-3"
} */

const DocDescription = ({ content, report, details, data, onSaveLayout, onEditImage, onDataChange, index, isRecorrent, delComment }) => {
    // console.log({ content, details })
    return (
        <>
            {report && <div className="list-margin headerText" title={details.name}>
                {`Apontamento`}
                <div className="list-margin headerText index">
                    {`‏‏‎ ‎‎‎‎‎${index}`}
                </div>
                {isRecorrent && <Badge color="primary" pill className="badge" style={{ maxWidth: "none", marginLeft: "auto", marginRight:15 }}>Problema Recorrente</Badge>}
                <DeleteButton className="hide-mobile" onClick={() => delComment(content)}/>
            </div>}
            <div className="list-margin bottom hidden" style={{ height: (490 * details.section) + 50 }}>
                {/* <RichEditorQuill
                    defaultValue={details.description}
                    onSave={(value) => onSave(data, value, true)}
                    placeholder={'Insira aqui seus comentários, para isso, destrave clicando em "Editar Comentário".'}
                /> */}
                <div dangerouslySetInnerHTML={{ __html: details.description }} />
            </div >
            {/* <ImageGridLayout data={data ? { images: data } : []} isComment onSaveLayout={onSaveLayout} onEditImage={onEditImage} onDataChange={onDataChange} /> */}
            {/* <div className={`lib-images-container ${colNum(data.length)}`}>
                {data.map(item => {
                    return renderItem(item)
                })}
            </div> */}
        </>
    )
}

export const CommentView = ({ content, report, data, details, onSave, onChange, onSaveLayout, onEditImage, onDataChange, index, isRecorrent, delComment }) => {
    if (!details) {
        return <div />
    }
    return (
        <div>
            {(details.description || (data && data.length > 0)) &&
                <DocDescription
                    report={report}
                    details={details}
                    data={data}
                    onSave={onSave}
                    onSaveLayout={onSaveLayout}
                    onEditImage={onEditImage}
                    onDataChange={onDataChange}
                    index={index}
                    isRecorrent={isRecorrent}
                    delComment={delComment}
                    content={content}
                />}
        </div>
    )
};
