import React, { useState } from 'react';
import { Input, ModalHeader, ModalBody, Modal, Button } from 'reactstrap';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as rdrLocales from 'react-date-range/dist/locale';
import { ModalFooter } from 'react-bootstrap';
import { NewModal } from '../../layoutV2/components/Modal/Modal';
import { FiCalendar } from 'react-icons/fi'

const formatDate = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
        return "Selecione um período"
    }
    return moment(startDate, "DD-MM-YYYY").format("DD/MM/YYYY") + " - " + moment(endDate, "DD-MM-YYYY").format("DD/MM/YYYY");
}

const formatValueDate = (props) => {
    let dates = {};
    if (props.startDate) {
        dates['startDate'] = moment(props.startDate, "DD-MM-YYYY").format("MM-DD-YYYY")
    }

    if (props.endDate) {
        dates['endDate'] = moment(props.endDate, "DD-MM-YYYY").format("MM-DD-YYYY");
    }

    return dates;
}

const formatInverseValueDate = (props) => {
    const startDate = moment(props.startDate, "MM-DD-YYYY").format("DD-MM-YYYY");
    const endDate = moment(props.endDate, "MM-DD-YYYY").format("DD-MM-YYYY");

    return { startDate, endDate };
}

export const DatePicker = (props) => {
    const [open, setOpen] = useState(false);
    const [dates, setDates] = useState(formatValueDate(props));
    const { onDateChange } = props;
    const onEvent = (range) => {
        const ranges = range['range1']
        if (ranges) {
            setDates(ranges);
        }
    }

    const toggle = () => setOpen(!open);

    return (
        <>
            <div style={{ ...props.style }} className={props.disabled ? "react-picky-not-select" : "react-bootstrap-daterangepicker-container"} onClick={() => props.disabled ? {} : setOpen(true)} >
                <Input disabled={true} value={formatDate(props)} />
                <FiCalendar />
            </div>
            <NewModal
                open={open}
                toggle={toggle}
                onClickConfirm={() => {
                    if (onDateChange) {
                        onDateChange("dates", formatInverseValueDate(dates))
                        toggle();
                    }
                }}
                onClickCancel={() => {
                    onDateChange("dates", {})
                    toggle();
                }}
                title={"Período"}
                label={"Confirmar"}
                outlineLabel={"Limpar"}
                className="datepicker-modal"
            >
                <div className="modal-datepicker">
                    <DateRange
                        locale={rdrLocales.pt}
                        ranges={[{
                            startDate: dates.startDate,
                            endDate: dates.endDate
                        }]}
                        onChange={onEvent}
                    />
                </div>
            </NewModal>
            {/* <Modal className='datepicker-modal' isOpen={open} toggle={toggle}>
                <ModalHeader className='datepicker-header' toggle={toggle}>Período</ModalHeader>
                <ModalBody className='datepicker-body'>
                    <DateRange
                        locale={rdrLocales.pt}
                        ranges={[{
                            startDate: dates.startDate,
                            endDate: dates.endDate
                        }]}
                        onChange={onEvent}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="button-date-confirm"
                        outline
                        color="primary"
                        size="sm"
                        onClick={() => {
                            if (onDateChange) {
                                onDateChange("dates", formatInverseValueDate(dates))
                                toggle();
                            }
                        }}
                    >
                        Confirmar
                    </Button>
                    <Button
                        className="button-date-confirm"
                        outline
                        color="link"
                        size="sm"
                        onClick={() => {
                            onDateChange("dates", {})
                            toggle();
                        }}
                    >
                        Limpar
                    </Button>
                </ModalFooter>
            </Modal> */}
        </>
    )
}