import { getItem, addItem, deleteItem, updateItem, getItemsByFilter, execute } from './firebaseFunctions';
import { db, fireSQL } from './firebaseConfig';

export const getWork = async (id) => {
    const table = db.collection("Works").doc(id)
    return execute(table).then(items => {
        return items[0];
    });
}

export const getWorks = async ({ filter, groups, isAdmin, email }) => {
    if (isAdmin) {
        return getItem(`Works`, filter);
    } else {
        const groupIds = groups ? groups.map(item => item.details.id) : [];
        return getItemsByFilter(`Works`, groupIds, "idCompany").then(response => {
            return response.filter(item => item.users && item.users.filter(item => item.value === email).length > 0);
        })
    }
}

export const addWorks = async (item) => {
    return addItem(`Works`, item);
}

export const deleteWorks = async (id) => {
    return deleteItem(`Works`, id);
}

export const updateWorks = async (item) => {
    return updateItem(`Works`, item);
}

export const addWorkTasks = async (id, item) => {
    return addItem(`Works/${id}/Tasks`, item);
}

export const updateWorkTasks = async (id, item) => {
    return updateItem(`Works/${id}/Tasks`, item);
}

export const getWorkTasks = async (id) => {
    return getItem(`Works/${id}/Tasks`);
}

export const getTasks = async (location, isCompany) => {
    const id = isCompany ? location.state.id : location.state.workId;
    return getItem(`${isCompany ? "Company" : "Works"}/${id}/Tasks`);
}

export const addTasks = async (location, item, isCompany) => {
    const id = isCompany ? location.state.id : location.state.workId;
    return addItem(`${isCompany ? "Company" : "Works"}/${id}/Tasks`, item);
}

export const updateTasks = async (location, item, isCompany) => {
    const id = isCompany ? location.state.id : location.state.workId;
    console.log({ location, id, item, isCompany })
    return updateItem(`${isCompany ? "Company" : "Works"}/${id}/Tasks`, item);
}

export const getWorkReferenceLocals = async (works, activities) => {
    return await fireSQL.query(`SELECT * from ReferenceLocal WHERE workId in (${works.join(",")}) AND UID in (${activities.join(",")})`)
}