import React, { Component } from 'react';
import { connect } from 'react-redux';
import TagList from '../../views/libs/TagList';
import { updateTagData, addTagData, deleteTagData, getTagData } from '../../core/redux-store/tag/Actions';

function mapStateToProps(state) {
    return {
        items: state.tag.items,
    };
}
class TagContainer extends Component {

    constructor(props) {
        super(props);
        
        this.onRowUpdate = this.onRowUpdate.bind(this);
        this.onRowDelete = this.onRowDelete.bind(this);
        this.onRowAdd = this.onRowAdd.bind(this);
    }    
    
    componentWillMount() {
        const { dispatch } = this.props;
        dispatch(getTagData())
    }

    onRowUpdate(newData, oldData) {
        const { dispatch } = this.props;
        return dispatch(updateTagData(newData));
    }

    onRowDelete(oldData) {
        const { dispatch } = this.props;
        return dispatch(deleteTagData(oldData.id));
    }

    onRowAdd(newData) {
        const { dispatch } = this.props;
        return dispatch(addTagData(newData));
    }
    
    render() {
        const { items } = this.props;
        return (
            // <div className="content">
                <TagList
                    data={items}
                    onRowUpdate={this.onRowUpdate}
                    onRowDelete={this.onRowDelete}
                    onRowAdd={this.onRowAdd}
                />
            // </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(TagContainer);