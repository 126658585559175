import React, { useRef } from 'react';
import { xml2json } from 'xml-js';
import { Button } from 'reactstrap';

const formatData = (data) => {
    return data.Project.Tasks.Task.map(item => {
        const object = {}
        Object.keys(item).map(key => {
            object[key] = item[key]["_text"];
        })

        return object;
    })
}

export const InputXml = ({ onDataChanged, noSchedule = false }) => {
    const input = useRef(null)
    let fileReader;

    const handleFileRead = (e) => {
        const content = fileReader.result;

        const data = xml2json(content, { compact: true });

        onDataChanged(formatData(JSON.parse(data)));
    }

    const handleFileChosen = (file) => {
        try {
            fileReader = new FileReader();
            fileReader.onloadend = handleFileRead;
            fileReader.readAsText(file);
        } catch (e) {
            console.log({ e })
        }
    }

    return !noSchedule && (
        <>
            <input
                ref={input}
                type="file"
                id="file"
                className="input-file"
                accept=".xml"
                style={{ position: "absolute", top: -1000, left: -1000 }}
                onChange={e => handleFileChosen(e.target.files[0])}
            />
            <Button className="btn-refresh-schedule" onClick={() => input.current.click()}>
                Inserir .XML
            </Button>
        </>
    )
}