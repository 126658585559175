import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { NewModal } from '../../layoutV2/components/Modal/Modal';
import { toDateFormat } from '../../views/works/InspectionView';
import { PeopleButton } from '../Button/Button';

export const ModalSharedComment = ({ shareds }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <PeopleButton onClick={() => setOpen(true)} />
            <NewModal
                open={open}
                toggle={() => setOpen(false)}
                title={"Compartilhado com:"}
                className="report-modal"
                onClickConfirm={() => setOpen(false)}
                label={"Fechar"}
            >
                <ListGroup>
                    {shareds.map(user => {
                        return <ListGroupItem key={user.id} style={{ padding: 10 }}>
                            <div>
                                <b>
                                    {`${user.nmUser} - ${toDateFormat(user.createdAt)}`}
                                </b>
                            </div>
                            <div>
                                {user.text}
                            </div>
                        </ListGroupItem>
                    })}
                </ListGroup>
            </NewModal>
        </>
    )
}

{/* <Modal isOpen={open} toggle={() => setOpen(false)} className="modal-edit-image" backdrop={true}>
    <ModalHeader toggle={() => setOpen(false)}>Compartilhado com:</ModalHeader>
    <ModalBody style={{maxHeight: "100%", overflowY: "auto"}}>
        <ListGroup>
            {shareds.map(user => {
                return <ListGroupItem key={user.id} style={{padding: 10}}>
                    <div>
                        <b>
                            {`${user.nmUser} - ${toDateFormat(user.createdAt)}`}
                        </b>
                    </div>
                    <div>
                        {user.text}
                    </div>
                </ListGroupItem>
            })}                  
        </ListGroup>                    
    </ModalBody>
    <ModalFooter>
        <Button color="success" onClick={() => setOpen(false)}>Fechar</Button>{' '}
    </ModalFooter>
</Modal> */}