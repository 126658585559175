import React, { useState } from "react";
import { Row, Col, Button, Card, CardHeader, CardBody, CardTitle, FormGroup, Input, Spinner } from "reactstrap";
import Select from 'react-select';
import FileGalleryContainer from "../../container/FileGalleryContainer";
import Category from "../CardLibDetails/Category";
import ImageGridLayout from "../DragDrop/ImageGridLayout";
import RichEditorQuill from "../RichEditor/RichEditorQuill";
import sort from 'fast-sort'

export const CardCommentDetails = (props) => {
    const workDefault = props.hasChanged ? props.allGroups.find(group => group.value === (props.adminWorkGroup || props.network)) : props.allGroups.find(group => group.value === props.workGroup)
    sort(props.allGroups).asc(u => u.label);

    if (!props.name && props.isLoading) {
        return <Spinner color="warning" className="spinner-details" />
    }

    return (props.isDraft || props.name) ? (
        <Col className="card-lib-details" md="12" lg="10">
            <Card className="card-tasks">
                <CardHeader>
                    <Row>
                        <Col sm="12" className="header-col">
                            <CardTitle tag="h4">Apontamento</CardTitle>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="10">
                            <FormGroup>
                                <Input disabled={!props.editable} placeholder="Nome" type="text" defaultValue={props.name} onChange={(event) => props.onChange("name", event.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col sm="2">
                            <FormGroup>
                                <Input disabled={!props.editable} placeholder="Ano" type="text" defaultValue={props.year} onChange={(event) => props.onChange("year", event.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <FormGroup>
                                <RichEditorQuill
                                    defaultValue={props.description}
                                    onChange={(value) => props.onChange("description", value)}
                                    placeholder={'Insira aqui suas observações'}
                                    // dataReference={`Libs/${props.id}/images`}
                                    height={490 * props.section}
                                    width={891.48}
                                    disabled={!props.editable}
                                />
                            </FormGroup>
                        </Col>
                        {props.editable && <Col sm="12">
                            <FormGroup>
                                <Button color="link" className="section-button" onClick={() => props.onChange("section", props.section === 2 ? 2 : props.section + 1)}>
                                    <i className="material-icons">
                                        add
                                    </i>
                                </Button>
                                <Button color="link" className="section-button" onClick={() => props.onChange("section", props.section === 1 ? 1 : props.section - 1)}>
                                    <i className="material-icons">
                                        remove
                                    </i>
                                </Button>
                                <span className="section-span">Seções</span>
                            </FormGroup>
                        </Col>}
                        <Col md="12">
                            <FormGroup style={{ marginTop: 10 }}>
                                <span>Tipo da obra</span>
                                <Select
                                    noOptionsMessage={() => "Não há opções para mostrar"}
                                    className="react-select dark-gray"
                                    classNamePrefix="react-select"
                                    placeholder="Tipo da obra"
                                    name="multipleSelect"
                                    closeMenuOnSelect={true}
                                    options={props.workTypes}
                                    value={props.workType}
                                    isMulti
                                    onChange={data => props.onChange("workType", data)}
                                    isDisabled={!props.editable}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup>
                                <span>Etapas</span>
                                <Select
                                    noOptionsMessage={() => "Não há opções para mostrar"}
                                    className="react-select dark-gray"
                                    classNamePrefix="react-select"
                                    placeholder="Selecione as etapas"
                                    name="multipleSelect"
                                    closeMenuOnSelect={false}
                                    options={props.skills}
                                    value={props.linkSteps}
                                    isMulti
                                    onChange={value => props.onChange("linkSteps", value)}
                                    onDeleteItem={props.onDeleteTag}
                                    formatCreateLabel={label => `Criar ${label}`}
                                    isDisabled={!props.editable || !(props.workType && props.workType.length > 0)}
                                />
                            </FormGroup>
                        </Col>
                        {props.isAdmin && <Col md="12">
                            <FormGroup>
                                <span>Grupo de empresa</span>
                                <Select
                                    noOptionsMessage={() => "Não há opções para mostrar"}
                                    className="react-select dark-gray"
                                    classNamePrefix="react-select"
                                    placeholder="Grupo de empresa"
                                    name="multipleSelect"
                                    closeMenuOnSelect={true}
                                    options={props.allGroups}
                                    value={workDefault || null}
                                    onChange={data => props.onChange("adminWorkGroup", (data.value === (workDefault && workDefault.value)) ? null : data.value)}
                                />
                            </FormGroup>
                        </Col>}
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        {props.editable &&
                            <Col md="12">
                                <Button color="success" className="save-button" outline onClick={props.saveData}>{"Salvar"}</Button>
                            </Col>
                        }
                    </Row>
                    <hr />
                    {(props.id && props.editable) && <div className="category editable">
                        <FileGalleryContainer
                            useCrop={true}
                            dataReference={`Libs/${props.id}/images`}
                            isComment={true}
                            onFileChange={props.onFileChange}
                        />
                    </div>
                    }
                    <Row>
                        {props.categories && props.categories.map(category => {
                            return (
                                <Category key={category.id} idCategory={category.id} idLib={props.id} editable={props.editable} onChange={props.onChange} deleteCategory={props.deleteCategory} {...category} />
                            )
                        })}
                    </Row>
                </CardBody>
            </Card>
        </Col>
    ) : <div />;
}