import React from "react";
import ReactWizard from "react-bootstrap-wizard";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PdfView from "./PdfView";
import PdfSelectView from './PdfSelectView';
import { OutlineButton } from "../../layoutV2/components/Button";
import { NewModal } from "../../layoutV2/components/Modal/Modal";

class EditPdfWizard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      files: []
    };

    this.toggle = this.toggle.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onChangeFiles = this.onChangeFiles.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  onSelectionChange(selectedFiles) {
    this.setState({ file: selectedFiles[0] });
  }

  onChangeFiles(files) {
    this.setState({ files })
  }

  onFinish() {
    if (this.props.saveFiles) {
      this.props.saveFiles(this.state.files)
    }
    this.toggle();
  }

  render() {
    var steps = [
      {
        stepName: "Arquivo",
        stepIcon: "nc-icon nc-paper",
        component: <div style={{ marginTop: 10 }}><PdfSelectView onSelectionChange={this.onSelectionChange} /></div>
      },
      {
        stepName: "Editar",
        stepIcon: "nc-icon nc-scissors",
        component: <PdfView file={this.state.file} onChangeFiles={this.onChangeFiles} />
      },
    ];
    return (
      <>
        {/* <Button color="link" className="crop-label" onClick={this.toggle}>Adicionar recorte</Button> */}
        <OutlineButton label={"Adicionar recorte"} onClick={this.toggle} />
        {/* <Modal isOpen={this.state.modal} toggle={this.toggle} className="pdf-modal">
          <ModalHeader toggle={this.toggle}>Edição</ModalHeader>
          <ModalBody> */}
        <NewModal
          open={this.state.modal}
          toggle={this.toggle}
          title={"Edição"}
          className="report-modal pdf-modal"
        >
          <ReactWizard
            steps={steps}
            navSteps
            validate
            previousButtonText="Anterior"
            finishButtonText="Salvar"
            nextButtonText="Próximo"
            title="PDF"
            description="Adicione recortes de pdf's nos itens da sua biblioteca"
            headerTextCenter
            finishButtonClasses="v2-primary-btn"
            nextButtonClasses="v2-primary-btn"
            previousButtonClasses="v2-secondary-btn"
            finishButtonClick={this.onFinish}
          />
        </NewModal>
        {/* </ModalBody>
        </Modal> */}
      </>
    );
  }
}

export default EditPdfWizard;
