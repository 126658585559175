import React from "react";
import { Row } from "reactstrap";
import CardLibDetails from "../components/CardLibDetails/CardLibDetails";
import AdminNavbar from '../components/Navbars/AdminNavbar';

class LibDetails extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="content">
				<AdminNavbar goBack={this.props.onBack}/>
				<Row>
					<CardLibDetails
						editable={this.props.editable}
						options={this.props.options}
						{...this.props}
					/>
				</Row>
			</div>
		);
	}
}

export default LibDetails;
