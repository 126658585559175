import React from 'react';
import { NavLink } from 'react-router-dom';

const getTypeName = (type) => {
    if (type && type.value) {
        const value = type.value;
        if (value === 1) {
            return "Vistoria"
        } else if (value === 2) {
            return "Perícia"
        }
    }
}

const getState = (value) => {
    if (value) {
        if (value === 1) {
            return "Executando"
        } else if (value === 2) {
            return "Finalizado"
        }
    }
}

export const WorkCard = (props) => {
    // console.log({ props })
    const { company, id, workType, file, name, address, responsible, state, type, regProf } = props
    // console.log({ company, work: { name, image: file.url, address, responsible, regProf, state }, workId: id, workType })
    return (
        <NavLink to={{
            pathname: "/admin/works-inspection",
            state: { company: { ...company, tableData: undefined }, work: { name, image: file.url, address, responsible, regProf, state, type }, workId: id, workType }
        }} className="work-card">
            <div className="img" style={{ background: `url('${file && file.url}')` }} />
            <div className="work-card-info">
                <h5>{name}</h5>
                <div className="flex1" />
                <div className="work-card-footer">
                    <small className={`work-badge ${type && type.value === 2 && "danger"}`}>{getTypeName(type)}</small>
                    <small className={`status ${state && state === 2 && "finish"}`}>{getState(state)}</small>
                </div>
            </div>
        </NavLink>
    )
}