import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Input, Row, Col } from 'reactstrap';
import { ModalFooter } from 'react-bootstrap';
import { useEffect } from "react";
import FileSimpleContainer from "../../container/FileSimpleContainer";
import { NewModal } from "../../layoutV2/components/Modal/Modal";
import { SelectInput, TextInput } from "../../layoutV2/components/Form";
import { getWorkTypes } from "../../core/database/skill";
import Checkbox from "rc-checkbox";
// import JSZip from 'jszip';
// import { saveAs } from 'file-saver';
// import { storage } from "../../core/database/firebaseConfig";

export const Roles = {
    Vistoria: 1,
    VistoriaGuiada: 2,
    Biblioteca: 3,
    Documentacacao: 4,
    Cronograma: 5,
    Chat: 6
}
// export const downloadFolderAsZip = async (folderPath) => {
//     const jszip = new JSZip();
//     const folderRef = storage.ref(folderPath);
//     console.log({ jszip, folderPath, folderRef,  })
//     const files = (await folderRef.listAll()).items;
//     const downloadUrls = await Promise.all(
//         files.map(({ name }) => folderRef.child(name).getDownloadURL())
//     );
//     const downloadedFiles = await Promise.all(downloadUrls.map(url => fetch(url).then(res => res.blob())));
//     downloadedFiles.forEach((file, i) => jszip.file(files[i].name, file));
//     const content = await jszip.generateAsync({ type: 'blob' });
//     saveAs(content, folderPath);
// };

const ModalNewGroup = ({ open, setOpen, onSave, comboWorkTypes }) => {
    const [values, setValues] = useState({ roles: [] })
    console.log({ testeeeee: values.file })
    const toggle = () => {
        setOpen(false)
        setValues({ roles: [] })
    }

    useEffect(() => {
        if (open && open.id) {
            setValues({ roles: [], ...open })
        }
    }, [open.id])

    const onPermissionChange = (value) => {

        setValues(values => ({ ...values, roles: values && values.roles && values.roles.filter(item => item === value).length > 0 ? values && values.roles && values.roles.filter(item => item !== value) : values && values.roles && values.roles.concat(value) }))
    }

    return (
        <>
            <NewModal
                open={open}
                toggle={toggle}
                onClickConfirm={() => onSave(values)}
                title={open.id ? "Editar grupo" : "Criar grupo"}
            >
                <FileSimpleContainer
                    file={values.file}
                    onFileChange={(file) => setValues({ ...values, file })}
                    single={true}
                    dataReference={`Images/WorkGroups/data`}
                    mini
                    circle={true}
                />
                {/* <Input
                    placeholder="Nome"
                    defaultValue={values.name}
                    onChange={(event) => setValues({ ...values, "name": event.target.value })}
                />
                <Input
                    placeholder="Email do Responsável"
                    defaultValue={values.responsible}
                    onChange={(event) => setValues({ ...values, "responsible": event.target.value })}
                /> */}
                <TextInput
                    id="group-name"
                    placeholder="Nome"
                    type="text"
                    value={values.name}
                    onChange={(event) => setValues({ ...values, "name": event.target.value })}
                />
                <TextInput
                    id="group-email-responsible"
                    placeholder="Email do Responsável"
                    type="text"
                    value={values.responsible}
                    onChange={(event) => setValues({ ...values, "responsible": event.target.value })}
                />
                <SelectInput
                    options={comboWorkTypes}
                    title="Tipo de obra"
                    labelledBy={"Selecione o tipo de obra"}
                    value={values && values.workTypeIds && Array.isArray(comboWorkTypes) && comboWorkTypes.filter(item => values.workTypeIds.includes(item.value))}
                    onChange={item => setValues({ ...values, "workTypeIds": item.map(item => item.value) })}
                />
                <p><Checkbox
                    style={{ marginLeft: 5, }}
                    checked={values.isModel}
                    onChange={(event) => setValues({ ...values, "isModel": event.target.checked })}
                /> Usar ficha de inspeção no cronograma</p>
                <div style={{ margin: 5 }}>

                    <text >Permissões:</text>
                </div>
                <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <div style={{ display: "flex", flexDirection: 'column', flex: 1 }}>
                        <text >
                            <Checkbox
                                style={{ margin: 5 }}
                                checked={values && values.roles && values.roles.filter(item => item === Roles.Vistoria).length > 0}
                                onChange={() => onPermissionChange(Roles && Roles.Vistoria)}
                            />
                            Inspeção Livre
                        </text>
                        <text>
                            <Checkbox
                                style={{ margin: 5 }}
                                checked={values && values.roles && values.roles.filter(item => item === Roles.VistoriaGuiada).length > 0}
                                onChange={() => onPermissionChange(Roles && Roles.VistoriaGuiada)}
                            />
                            Inspeção Guiada
                        </text>
                        <text >
                            <Checkbox
                                style={{ margin: 5 }}
                                checked={values && values.roles && values.roles.filter(item => item === Roles.Biblioteca).length > 0}
                                onChange={() => onPermissionChange(Roles && Roles.Biblioteca)}
                            />
                            Biblioteca
                        </text>
                    </div>
                    <div style={{ display: "flex", flexDirection: 'column', flex: 1 }}>
                        <text >
                            <Checkbox
                                style={{ margin: 5 }}
                                checked={values && values.roles && values.roles.filter(item => item === Roles.Documentacacao).length > 0}
                                onChange={() => onPermissionChange(Roles && Roles.Documentacacao)}
                            />
                            Documentação
                        </text>
                        <text >
                            <Checkbox
                                style={{ margin: 5 }}
                                checked={values && values.roles && values.roles.filter(item => item === Roles.Cronograma).length > 0}
                                onChange={() => onPermissionChange(Roles && Roles.Cronograma)}
                            />
                            Cronograma
                        </text>
                        <text >
                            <Checkbox
                                style={{ margin: 5 }}
                                checked={values && values.roles && values.roles.filter(item => item === Roles.Chat).length > 0}
                                onChange={() => onPermissionChange(Roles && Roles.Chat)}
                            />
                            Chat
                        </text>

                    </div>
                </div>

            </NewModal>
        </>
    )
}

export default ModalNewGroup