import React from 'react';
import { CommentContainer } from '../../container/CommentContainer';
import CommentReportContainer from '../../container/CommentReportContainer';
// import { toDate } from '../../views/works/InspectionView';
import sort from 'fast-sort';

const ShowCommentComp = ({data, allData, onSave, count, delComment, indexStr, first}) => {
    const commentId = (data.comment && data.comment.value) || data.commentId;
    if (commentId) {
        if(count !== first) {
            return <></>;
        }

        return (
            <div>
                <CommentContainer
                    delComment={delComment}
                    content={data}
                    report={true}
                    id={commentId}
                    onSave={onSave}
                    index={indexStr}
                    isRecorrent={allData.filter(item => item.commentId === commentId).length > 1}
                />
            </div>
        )
    } else {
        return <CommentReportContainer data={data} onSave={onSave} />
    }
}

export const ShowComment = ({ data, allData, onSave, addCount, count, delComment, showBlankSpaces, ordination = {value: 1} }) => {
    const arrayIndex = allData.map((item, index) => ({ ...item, index })).filter(item => item.step && data.step && item.step.id === data.step.id);
    
    const onlyIndex = arrayIndex.filter(item => item.commentId && item.commentId === data.commentId).map(item => item.index + 1);
    let indexStr = ""
    onlyIndex.map((item, index) => {
        indexStr += index === 0 ? item : index < onlyIndex.length - 1 ? `, ${item}` : ` e ${item}`
    })

    var last = onlyIndex[onlyIndex.length - 1];
    var first = onlyIndex[0];

    return (
        <>
            <div className={`content-report`} id={`${count}-step`}>
                <div className={"report-auto-section"}>
                    <ShowCommentComp {...{data, allData, onSave, addCount, count, delComment, indexStr, last, first}}/>
                </div>
            </div>
        </>
    )
};