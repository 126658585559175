import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { storage } from 'core/database/firebaseConfig'

function MyDropzone({ children, onDrop, hidden, circle, accept = undefined, fullChildren, mini, disabled = false }) {
  /*const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result
    }

    acceptedFiles.forEach(file => {
      const storageRef = storage.ref().child('teste.jpg');
      storageRef.put(file).then(function (snapshot) {
        console.log('Uploaded a blob or file!');
      });
      return file;//reader.readAsBinaryString(file)
    })
  }, [])*/
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: useCallback(onDrop), accept })

  if (fullChildren) {
    return <div {...getRootProps({ disabled })} className="v2-react-dropzone">
      <input {...getInputProps({ disabled })} />
      {children}
    </div>
  }

  return (
    <div className={`v2-react-dropzone react-dropzone ${hidden && "hidden"} ${circle && "circle"} ${mini && "mini"} ${disabled && "disabled"}`} {...getRootProps()}>
      <input {...getInputProps({ disabled })} />
      {!hidden && <div className="icon-labe">
        <i class="material-icons">cloud_upload</i>
        <p>Clique ou arraste seus arquivos</p>
      </div>}
      {children}
    </div>
  )
}

export default MyDropzone;
