import React from "react";
import { connect } from "react-redux";
import Users from "views/Users.jsx";
import { getUserData, updateUserData, deleteUserData, addUserData } from "core/redux-store/user/Actions";
import ScopeContainer from './ScopeContainer';
import { getScopeHasPower } from 'core/utils/Utils';
import { sendEmailInvite } from 'core/database/user';
import Alert from '../components/Alert/Alert';
import { deleteUser } from "../core/database/user";

export const scopeHasPower = (groupId, groups) => {
	const group = groups && groups.find(item => item && item.details.id === groupId);
	return group && group.hasPower;
}

class UserContainer extends ScopeContainer {
	constructor(props) {
		super(props);

		this.onRowAdd = this.onRowAdd.bind(this);
		this.onRowDelete = this.onRowDelete.bind(this);
		this.onHasPowerUpdate = this.onHasPowerUpdate.bind(this);
		this.getScopeHasPower = this.getScopeHasPower.bind(this);
		this.resendInvite = this.resendInvite.bind(this);
	}

	onRowAdd(newData) {
		const { dispatch, scope, workGroup } = this.props;
		return new Promise(resolve => {
			const emails = newData.email.replace(/ /g, '').split(',');

			const allPromise = emails.map(email => {
				return dispatch(addUserData({ newData: { email }, scope })).then(user => {
					// console.log({ scope })
					if (user.isInvite) {
						Alert.success('Convite enviado para: ' + user.email);
					} else {
						Alert.success('Usuário adicionado com sucesso!');
					}
				}).catch(error => {
					Alert.error(error.message);
				});
			})

			Promise.all(allPromise).then(() => {
				resolve(true);
				// dispatch(getUserData(scope));
			})
		})
	}

	onRowDelete(newData) {
		const { dispatch, scope, items } = this.props;
		const user = items.find(item => item.id === newData.id)
		if (user) {
			const groups = user.groups.filter(group => group.details.id !== scope.value)
			return dispatch(deleteUserData({ id: newData.id, groups })).then(() => {
				dispatch(getUserData(scope));
			})
		}
		// return this.props.dispatch(deleteUserData(oldData.id));
	}

	onHasPowerUpdate(newData) {
		const { dispatch, scope } = this.props;
		return dispatch(updateUserData({ newData, scope }));
	}

	componentWillMount() {
		const { dispatch, scope } = this.props;
		// console.log({ scope })
		dispatch(getUserData(scope));
	}

	onScopeChange(scope) {
		const { dispatch } = this.props;
		dispatch(getUserData(scope));
	}

	getScopeHasPower(data) {
		const { scope } = this.props;
		return getScopeHasPower(data, scope);
	}

	resendInvite(data) {
		const { id, email } = data
		sendEmailInvite(id, email).then(() => {
			Alert.success('Convite reenviado com sucesso!')
		});
	}

	render() {
		const { isAdmin, groups, scope } = this.props;
		const hasPower = isAdmin || scopeHasPower(scope.value, groups);

		return (
			<Users
				data={this.props.items}
				isLoading={this.props.isLoading}
				onRowAdd={hasPower && this.onRowAdd}
				onRowDelete={hasPower && this.onRowDelete}
				onHasPowerUpdate={hasPower && this.onHasPowerUpdate}
				getScopeHasPower={this.getScopeHasPower}
				resendInvite={this.resendInvite}
				hasPower={hasPower}
				scope={scope}
			/>
		);
	}
}

export default connect(state => ({
	isLoading: state.user.isLoading,
	items: state.user.items,
	errorMessage: state.user.error,
	isAdmin: state.login.isAdmin,
	groups: state.login.groups
}))(UserContainer);
