import { db, firestore } from "./firebaseConfig";
import { normalizeCombo } from 'core/utils/Utils';

export const getItemTable = (tableName, fields, active = true) => {
    let table = db.collection(tableName)
    if (fields) {
        Object.keys(fields).map(key => {
            table = table.where(key, "==", fields[key])
        })
        if (active) {
            table = table.where("active", "==", true);
        }
    } else {
        table = db.collection(tableName).where("active", "==", true);
    }
    return table;
}

export const getItemById = async (tableName, id) => {
    let table = db.collection(tableName).doc(id);

    return table.get().then(querySnapshot => {
        if (querySnapshot.docs) {
            const data = [];
            querySnapshot.docs.forEach(doc => {
                data.push({ id: doc.id, ...doc.data() });
            })

            return data[0];
        } else {
            return querySnapshot.data()
        }
    });
};

export const getItemsByFilter = async (tableName, items, field, workGroup = undefined) => {
    if (items) {
        const promises = []
        for (let index = 0; index < items.length; index++) {
            const idKey = items[index];
            if (Array.isArray(workGroup)) {
                workGroup.map(item => promises.push(getItem(tableName, { [field]: idKey, workGroup: item })))
            } else if (workGroup) {
                promises.push(getItem(tableName, { [field]: idKey, workGroup }));
            } else {
                promises.push(getItem(tableName, { [field]: idKey }));
            }
        }
        return Promise.all(promises).then(allData => allData.flat());
    }

    return []
}

export const getItem = async (tableName, fields, active = true) => {
    if (fields) {
        let table = db.collection(tableName)
        Object.keys(fields).map(key => {
            if (fields[key] !== undefined) {
                if (Array.isArray(fields[key])) {
                    fields[key].map(item => {
                        if (item && item.operator && item.value) {
                            table = table.where(key, item.operator, item.value)
                        }
                    })
                } else if (fields[key] && fields[key].operator) {
                    table = table.where(key, fields[key].operator, fields[key].value)
                } else {
                    table = table.where(key, "==", fields[key])
                }
            }

        })
        if (active) {
            table = table.where("active", "==", true);
        }

        return table.get().then(querySnapshot => {
            const data = [];
            querySnapshot.docs && querySnapshot.docs.forEach(doc => {
                data.push({ id: doc.id, ...doc.data() });
            })
            return data;
        });
    } else {
        const table = db.collection(tableName).where("active", "==", true);

        return table.get().then(querySnapshot => {
            const data = [];
            querySnapshot.docs && querySnapshot.docs.forEach(doc => {
                data.push({ id: doc.id, ...doc.data() });
            })
            return data;
        });
    }
};

export const getByReference = async (referenceName) => {
    return db.collection(referenceName).get().then(querySnapshot => {
        const data = [];
        querySnapshot.docs && querySnapshot.docs.forEach(doc => {
            data.push({ id: doc.id, ...doc.data() });
        })
        return data;
    });
};

/* export const addItem = async (tableName, item) => {
    const table = db.collection(tableName);
    const data = { active: true, ...item };
    return table.add(data).then(async (event) => {
        return {
            ...data,
            id: event.id
        };
    });
}; */

export const addItem = async (tableName, item) => {
    const table = db.collection(tableName).doc();
    const id = table.id;
    const data = { active: true, ...item, id };
    table.set(data);
    return Promise.resolve(data)
};

export const deleteItem = async (tableName, id) => {
    const table = db.collection(tableName).doc(id);
    const data = { active: false };
    return table.set(data, { merge: true }).then(async () => {
        return data;
    });
};

export const updateItem = async (tableName, item) => {
    const table = db.collection(tableName).doc(item.id);
    const data = { ...item };
    return table.set(data, { merge: true }).then(async () => {
        return data;
    });
};

export const updateUnionItem = async (tableName, id, field, data) => {
    const table = db.collection(tableName).doc(id);
    return table.update({
        [field]: firestore.FieldValue.arrayUnion(data)
    }).then(async () => {
        return data;
    });
};

export const execute = (table, originalData) => {
    return table.get().then(querySnapshot => {
        const data = [];
        if (querySnapshot.exists) {
            data.push({ ...originalData, id: querySnapshot.id, ...querySnapshot.data() });
        } else {
            querySnapshot.docs && querySnapshot.docs.forEach(doc => {
                data.push({ ...originalData, id: doc.id, ...doc.data() });
            })
        }
        return data;
    });
}

export const executeAndFormat = async (table, referenceKeys, arrReferenceKeys, normalize) => {
    return execute(table).then(arrItems => {
        const promises = arrItems.map((item) => {
            const promisesItem = [];
            referenceKeys.map(({ key, id = 'id', name = 'name' }) => {
                if (item[key]) {
                    if (item[key].get) {
                        promisesItem.push(execute(item[key]).then(fetchObj => {
                            item[key] = normalizeCombo(id, name, fetchObj)[0];
                        }));
                    } else {
                        promisesItem.push(new Promise(resolve => resolve(item[key])));
                    }

                }
                return;
            })

            normalize && normalize(item);

            const promisesListItem = [];
            arrReferenceKeys.map(({ key, idField, id = 'id', name = 'name' }) => {
                if (item[key] && Array.isArray(item[key])) {
                    item[key].map(arrayItem => {
                        if (arrayItem[idField]) {
                            if (arrayItem[idField].get) {
                                promisesListItem.push(execute(arrayItem[idField]))
                            } else {
                                promisesListItem.push(new Promise(resolve => resolve({ [id]: arrayItem[idField], [name]: arrayItem[idField] })));
                            }
                        }
                    })
                }

                const arrToConcat = [];
                return Promise.all(promisesListItem).then(arr => {
                    item[key] = normalizeCombo(id, name, arrToConcat.concat(...arr));
                    return;
                });
            })

            return Promise.all(promisesItem.concat(promisesListItem));
        });

        return Promise.all(promises).then(() => {
            return arrItems;
        });
    });
}