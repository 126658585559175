import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fireSQL } from "../database/firebaseConfig";

export const companyRole = (user, idCompany) => {
    let hasPower;
    if (!user.isAdmin) {
        if (user.groups) {
            const groupAdmin = user.groups.filter(item => item.hasPower && (!idCompany || item.details.id === idCompany));
            const groupInvite = user.groups.filter(item => item.hasPower === 0 && (!idCompany || item.details.id === idCompany));
            const groupMember = user.groups.filter(item => item.hasPower === false && (!idCompany || item.details.id === idCompany));

            if (groupAdmin.length > 0) {
                hasPower = true
            } else if (groupMember.length > 0) {
                hasPower = false
            } else if (groupInvite.length > 0) {
                hasPower = 0
            }
        }
    } else {
        hasPower = true
    }

    return hasPower;
}

export function useUserType(idCompany) {
    const user = useSelector(state => state.login);
    const [userType, setUserType] = useState({})

    useEffect(() => {
        if (!user.isAdmin) {
            if (user.groups) {
                try {
                    setUserType({ hasPower: companyRole(user, idCompany) })
                } catch (e) {
                    console.log({ e })
                }

            } else {
                setUserType({})
            }
        } else {
            setUserType({ hasPower: true })
        }
    }, [user.groups, user.id, user.network])

    //user.isAdmin || count > 0
    return userType;
}

export function useHasPowerInGroup() {
    const user = useSelector(state => state.login);
    const [count, setCount] = useState(0)

    useEffect(() => {
        if (!user.isAdmin) {
            if (user.groups) {
                if (user.groupData && user.groupData.responsible === user.email) {
                    setCount(1)
                } else {
                    try {
                        const ids = [...user.groups.filter(item => item.hasPower && item.details).map(item => `\"${item.details.id}\"`)];
                        if (ids && ids.length > 0) {
                            fireSQL.query(`Select name from Company where workGroup = \"${user && user.network}\" and id in (${ids.join(",")})`).then((items) => {
                                setCount(items.length)
                            })
                        }
                    } catch (e) {
                        console.log({ e })
                        setCount(0)
                    }
                }
            } else {
                setCount(0)
            }
        }
    }, [user.groups, user.id, user.network])

    return user.isAdmin || count > 0;
}