import { userActions } from "./Actions";
import { failure, begin, success } from "core/utils/redux/Reducers";
import { getScopeHasPower, normalizeCombo } from 'core/utils/Utils';

export const initialState = {
	isLoading: false,
	error: null,
	loggedIn: false, // undefined
	loggedOut: false,
	hasPower: false,
	rehydrated: false
};

export default function (state = initialState, action) {
	if (userActions) {
		let data, hasPower, scope;
		switch (action.type) {
			case 'SET_GROUPSELECT_SUCCESS':
				data = state.groups ? state : action.payload;
				hasPower = getScopeHasPower(data, action.payload.scope);
				state = success(state, { ...action.payload, hasPower });
				break;
			case 'SET_LOGIN_DATA':
				state = success(state, { ...action.payload });
				break;
			case userActions.GET_LOGIN_SUCCESS:
				scope = state.scope || normalizeCombo("id", "name", action.payload.groups)[0];
				data = state.groups ? state : action.payload;
				hasPower = getScopeHasPower(data, scope);
				state = success(state, { ...action.payload, scope, hasPower, loggedIn: true });
				break;
			case userActions.GET_LOGIN_BEGIN:
				state = begin(state);
				break;
			case userActions.GET_LOGIN_ERROR:
				state = failure(state, action.payload.error);
				break;
			case userActions.GET_LOGOUT_SUCCESS:
				state = success(initialState, { ...action.payload, rehydrated: true, loggedIn: false, loggedOut: true });
				break;
			default:
				break;
		}
	}
	return state;
}