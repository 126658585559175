import React, { useState } from 'react';
import { Modal, Label } from 'reactstrap';
import { MdClose } from "react-icons/md";
import { OutlineButton, SaveButton } from '../Button';

export const NewModal = (props) => {
    const { open, children, OpenButton, toggle, onClickConfirm, title, onClick, label = "Salvar", outlineLabel = "Cancelar", onClickCancel, className = '' } = props

    return (
        <>
            {OpenButton ? <OpenButton onClick={onClick} toggle={toggle} /> : <div />}
            <Modal isOpen={open} toggle={toggle} className={`all-modal ${className}`} >
                <div className="new-modal">
                    <div className="header">
                        <Label className="title">{title}</Label>
                        <MdClose className="icon" onClick={toggle} />
                    </div>
                    <div className="body">
                        {children}
                    </div>
                    {(onClickCancel || onClickConfirm) && <div className="footer">
                        {onClickCancel && <OutlineButton label={outlineLabel} onClick={onClickCancel} />}
                        {onClickConfirm && <SaveButton onClick={onClickConfirm} label={label} />}
                    </div>}
                </div>
            </Modal>
        </>
    )
}