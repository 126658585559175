import React from 'react';
import DeleteLabel from '../DeleteLabel/DeleteLabel';
import { GridListTileBar } from '@material-ui/core';
import { CustomInput } from 'reactstrap';
import { ModalEditImage } from '../ModalEditImage/ModalEditImage';
import { ButtonHideImage } from '../ButtonHideImage/ButtonHideImage';

class FileGalleryItem extends React.Component {

    constructor(props) {
        super(props);
    }

    getImage() {
        return (
            <>
                {this.props.isComment && <ButtonHideImage {...this.props} />}
                {this.props.onChange && <ModalEditImage {...this.props} />}
                {/* <LazyLoad height={200}> */}
                <div
                    onClick={(e) => e.preventDefault()}
                    alt="Preview"
                    style={{ background: `url('${`https://aelyjcyado.cloudimg.io/v7/${this.props.url}&h=300&org_if_sml=1`}')` }}
                    className="file-item img"
                />
                {/* </LazyLoad> */}
                {(!this.props.hideTile && this.props.filename) && <a href={this.props.url} target="_blank">
                    <GridListTileBar
                        className="tile-bar"
                        title={this.props.filename}
                    />
                </a>}
            </>
        )
    }

    getFile() {
        let url = this.props.url;

        if (/\.pdf|PDF$/.test(this.props.filename)) {
            url = require('assets/img/pdf.svg');
        } else if (/\.doc|docx|DOC|DOCX$/.test(this.props.filename)) {
            url = require('assets/img/doc.svg');
        }
       
        return (
            <>
                {this.props.onChange && <ModalEditImage {...this.props} />}
                {/* <LazyLoad height={200}> */}
                <img
                    onClick={(e) => e.preventDefault()}
                    alt="Preview"
                    src={this && this.props && this.props.url && this.props.url.startsWith("data:image") ? this.props.url : `https://aelyjcyado.cloudimg.io/v7/${url}&h=300&org_if_sml=1`}
                    className="file-item"
                />
                {/* </LazyLoad> */}
                {(!this.props.hideTile && this.props.filename) && <a href={this.props.url} target="_blank">
                    <GridListTileBar
                        className="tile-bar"
                        title={this.props.filename}
                    />
                </a>}
            </>
        )

    }

    render() {
        const isImage = (/\.(gif|jpg|jpeg|tiff|png|svg)$/i).test(this.props.filename);

        return (
            <>
                <div
                    className="file-item file-item-div"
                >
                    {/* <DeleteLabel label={"Deseja mesmo remover esse arquivo?"} removeEvent={() => this.props.onRemove(this.props)} /> */}
                    {isImage ? this.getImage() : this.getFile()}
                    {!this.props.isComment && <CustomInput className="check-select" checked={this.props.isSelected} onClick={() => this.props.onSelectItem && this.props.onSelectItem(this.props)} type="checkbox" />}
                </div>
            </>
        )
    }
}

export default FileGalleryItem;

// {this.props.canSelect && <CustomInput className="check-select" checked={this.props.isSelected} onClick={() => this.props.onSelectItem && this.props.onSelectItem(this.props)} type="checkbox" />}