import { getInspectionModel, getAllInspectionModel, addInspectionModel, deleteInspectionModel, updateInspectionModel } from "core/database/inspectionModel";
import ActionCreator from 'core/utils/redux/ActionCreator';

const { actions, defaultReducer, get, add, remove, update } = new ActionCreator("inspectionModel");
export { actions, defaultReducer };

export const getInspectionModelData = get(getInspectionModel);
export const getAllInspectionModelData = get(getAllInspectionModel)
export const addInspectionModelData = add(addInspectionModel);
export const updateInspectionModelData = update(updateInspectionModel);
export const deleteInspectionModelData = remove(deleteInspectionModel);
