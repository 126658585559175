import React from "react";
import {
	Row,
	Col,
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	FormGroup,
	Input,
	Spinner,
	Collapse,
	UncontrolledTooltip
} from "reactstrap";
import Category from './Category'
import Select from 'react-select';
import FileGalleryContainer from "../../container/FileGalleryContainer";
import RichEditorQuill from '../RichEditor/RichEditorQuill';
import sort from 'fast-sort'
import { SelectInput, TextInput } from "../../layoutV2/components/Form";
import { FileItem, UploadedFile } from "../../layoutV2/components/FileItem";
import { AddNavButton, OutlineButton, PrimaryButton, SaveButton } from "../../layoutV2/components/Button";
import { SizeMe } from "react-sizeme";
import FileContainer from "../../container/File/FileContainer";
/*
<ReactQuill
								// value={description}
								onChange={this.handleChange}
							/>	
*/

class CardLibDetails extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			text: "",
			collapseImage: false,
			collapseCategories: false,
		}

		this.handleChange = this.handleChange.bind(this)
	}

	handleChange(value) {
		this.setState({ text: value })
	}

	cardHeaderEditable(disabled) {
		const { onChange, name, categories, year, isProject, description, tags, comboTags, newCategory, skills, linkSteps, workTypes, workType, isAdmin, allGroups, adminWorkGroup, workGroup, hasChangedWorkGroup } = this.props;
		const workDefault = hasChangedWorkGroup ? allGroups.find(group => group.value === adminWorkGroup) : allGroups.find(group => group.value === workGroup)

		sort(skills).asc(u => u.label);
		sort(comboTags).asc(u => u.label);
		sort(allGroups).asc(u => u.label);

		const md = isAdmin && !this.props.isComment ? 3 : 4

		return (
			<div className="v2-card-lib-details">
				<Row style={{ zIndex: 99999999 }}>
					<Col sm="12" md={!isProject ? "10" : "12"}>
						<TextInput id="lib-name" disabled={disabled} placeholder="Nome" type="text" value={name} onChange={(event) => onChange("name", event.target.value)} />
					</Col>
					{!isProject && <Col sm="6" md="2">
						<TextInput id="lib-year" disabled={disabled} placeholder="Ano" type="text" value={year} onChange={(event) => onChange("year", event.target.value)} />
					</Col>}
					{!isProject && <>
						{!this.props.isComment && <Col md={md} sm="6" style={{ zIndex: 99999999 }}>
							<SelectInput
								options={comboTags}
								title="Tipo de documento"
								labelledBy={"Tipo de documento"}
								value={tags}
								onChange={value => onChange("tags", value)}
								isDisabled={disabled}
							/>
						</Col>}
						{/* {(!disabled && tags && tags.find(item => item.label && item.label.includes("ABNT"))) && <Col sm="12">
							<Button href="https://www.abntcatalogo.com.br/" target="_blank">
								Adquirir norma da ABNT
							</Button>
						</Col>} */}
						<Col md={md} sm="6" style={{ zIndex: 99999999 }}>
							<SelectInput
								options={workTypes}
								title="Tipo da obra"
								labelledBy={"Tipo da obra"}
								value={workType}
								onChange={value => onChange("workType", value)}
								isDisabled={disabled}
							/>
						</Col>
						<Col md={md} sm="6" style={{ zIndex: 99999999 }}>
							<SelectInput
								options={skills}
								title="Etapas"
								labelledBy={"Etapas"}
								value={linkSteps}
								onChange={value => onChange("linkSteps", value)}
								isDisabled={disabled || !(workType && workType.length > 0)}
							/>
						</Col>
						{isAdmin && <Col md={md} sm="6" style={{ zIndex: 99999999 }}>
							<SelectInput
								options={allGroups}
								title="Grupo de empresa"
								labelledBy={"Grupo de empresa"}
								value={workDefault || null}
								onChange={data => onChange("adminWorkGroup", (data.value === (workDefault && workDefault.value)) ? null : data.value)}
								//onChange={value => onChange("linkSteps", value)}
								isSingle={true}
							/>
						</Col>}
					</>}
				</Row>
				<SizeMe
					monitorHeight
					render={({ size: { height, width } }) => {
						return <Row className="row-editor">
							<Col md="8" sm="12" style={{ marginBottom: 10 }}>
								{height > 0 && <RichEditorQuill
									defaultValue={description}
									onChange={(value) => onChange("description", value)}
									disabled={disabled}
									height={width < 600 ? 450 : height - 91}
								/>}
							</Col>
							<Col md="4" sm="12" style={{ marginBottom: 10 }}>
								<div className="category editable">
									<FileContainer
										useCrop={true}
										dataReference={`Libs/${this.props.id}/images`}
										canSelect={true}
										isMultipleSelect={true}
										disabled={!this.props.id || !this.props.editable}
										style={{ minHeight: 450 }}
									/>
								</div>
							</Col>
						</Row>
					}}
				/>
				<Row className="v2-categories">
					{/* <Col md="12"> */}
					{categories.map(category => {
						return (
							<Category key={category.id} idCategory={category.id} idLib={this.props.id} editable={this.props.editable} onChange={onChange} deleteCategory={this.props.deleteCategory} {...category} />
						)
					})}
					{!this.props.isComment && this.props.id && this.props.editable && <AddNavButton onClick={newCategory} label={"Nova Categoria"} />}
					{/* </Col> */}
				</Row>
			</div>
		);
	}

	onSelectionChange(files) {
		// console.log({ files })
	}

	render() {
		const { editable, isProject, onChange, deleteCategory, newCategory, id, name, categories, isLoading } = this.props;
		const { collapseImage, collapseCategories } = this.state

		if (!name && isLoading) {
			return <Spinner color="warning" className="spinner-details" />
		}

		return (
			<div className="card-tasks">
				{this.cardHeaderEditable(!editable)}
				<div>
					{editable && <Col sm="12" className="company-footer">
						{/* <OutlineButton label="Excluir" onClick={() => { console.log("delete") }} /> */}
						<SaveButton onClick={this.props.saveData} />
					</Col>}
				</div>
				{/*<CardBody>
						 <Row className="row-button">
							{!isProject && <>
								<Button id="image" size="sm" className={`button-alert`} active={collapseImage} onClick={() => this.setState({ collapseImage: !collapseImage })} >
									<i class="material-icons">{"photo_library"}</i>
								</Button>
								<UncontrolledTooltip placement="top" target="image">
									{collapseImage ? "Esconder documentos" : "Mostrar documentos"}
								</UncontrolledTooltip>
							</>}
							<Button id="categories" size="sm" className={`button-alert`} active={collapseCategories} onClick={() => this.setState({ collapseCategories: !collapseCategories })} >
								<i class="material-icons">{"library_add"}</i>
							</Button>
							<UncontrolledTooltip placement="top" target="categories">
								{collapseCategories ? "Esconder categorias" : "Mostrar categorias"}
							</UncontrolledTooltip>
						</Row>
						<Collapse isOpen={collapseImage}>
							{(id && editable) && <div className="category editable">
								<FileGalleryContainer
									useCrop={true}
									dataReference={`Libs/${id}/images`}
									canSelect={true}
									isMultipleSelect={true}
								/>
							</div>}
							<br />
						</Collapse>
						<Collapse isOpen={collapseCategories}>
							<Row>
								{categories.map(category => {
									return (
										<Category key={category.id} idCategory={category.id} idLib={id} editable={editable} onChange={onChange} deleteCategory={deleteCategory} {...category} />
									)
								})}
							</Row>
						</Collapse>
						<hr /> */}
				{/* <Row>
							{editable &&
								<Col md="12">
									<Button color="primary" onClick={() => { newCategory(); this.setState({ collapseCategories: true }) }} >{"Nova categoria"}</Button>
									<PrimaryButton color="success" className="save-button" outline onClick={this.props.saveData}>{"Salvar"}</PrimaryButton>
								</Col>
							}
						</Row> 
						
					</CardBody>*/}
			</div>
		);
	}
}

export default CardLibDetails;