import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ModalFooter } from 'react-bootstrap';
import { NewModal } from '../../layoutV2/components/Modal/Modal';

export const ScheduleConfirmModal = ({ isOpenModal, toggle, onConfirm }) => {
    const confirmClick = () => {
        onConfirm();
        toggle();
    }
    return (
        <NewModal
            open={isOpenModal}
            toggle={toggle}
            title={"Atualizar cronograma"}
            className="link-image-modal"
            onClickConfirm={confirmClick}
        >
            <span className="subtitle-center">Ao realizar esta ação, seu cronograma antigo será substituído, deseja prosseguir?</span>
        </NewModal>
    )
}

    // <>
    //     <Modal className="pdi-modal" isOpen={isOpenModal} toggle={toggle}>
    //         <ModalHeader toggle={toggle}>Atualizar cronograma</ModalHeader>
    //         <ModalBody>
    //             <span>Ao realizar esta ação, seu cronograma antigo será substituído, deseja prosseguir?</span>
    //         </ModalBody>
    //         <ModalFooter>
    //             <Button color="link" className="save-button" outline onClick={toggle}>{"Cancelar"}</Button>
    //             <Button color="success" className="save-button" outline onClick={confirmClick}>{"Prosseguir"}</Button>
    //         </ModalFooter>
    //     </Modal>
    // </>