import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

export const ButtonHideImage = (props) => {
    const [isHiding, setIsHiding] = useState(props.isHiding);

    const toggle = () => {
        const isHidingNew = !isHiding;
        setIsHiding(isHidingNew)
        onSave(isHidingNew)
    };

    const onSave = (isHidingNew) => {
        const item = {
            ...props.file,
            isHiding: isHidingNew
        };
        props.onHideChange(item);
    }

    return (
        <>
            <Button
                color="success"
                className={`btn-icon button-hide-image ${isHiding && 'hiding'}`}
                size="sm"
                type="button"
                onClick={toggle}
                style={{
                    zIndex: 999,
                    margin: 0,
                    pointerEvents: "auto",
                    width: 23,
                    height: 23,
                    position: "absolute",
                    top: 8,
                    right: 68,
                    minWidth: 23,
                    borderRadius: 20,
                }}
            >
                <i className={isHiding ? "fa fa-eye-slash" : "fa fa-eye"} style={{ fontSize: 15, top: 11 }} />
            </Button>
        </>
    );
};