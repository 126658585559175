import React, { useState, useEffect } from "react";
import sort from 'fast-sort';
import BarChart from "../Charts/BarChart";
import { Card } from "reactstrap";

const getNumber = (data, subject, type, count) => {
    let all = data.filter(item => (item.signal && item.signal[0]) === type).length
    let number = data.filter(item => (item.subject && item.subject.id) === subject.id).filter(item => item.signal ? item.signal[0] === type : false).length
    if (count) {
        return parseFloat(((number / all) * 100 || 0).toFixed(2))
    }
    return number
}

function randomRgba() {
    var o = Math.round, r = Math.random, s = 255;
    const rgba = 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ','
    return { rgba1: rgba + 1 + ')', rgba2: rgba + 0.2 + ')' };
}

const SignalCard = ({ data }) => {
    const [subjects, setSubjects] = useState([])

    const loadSubject = async () => {
        let subjectFilter = [...new Set(data.filter(item => item.subject).map(item => item.subject.id))];
        let subjects = subjectFilter.map(idSubject => data.find(item => item.subject ? idSubject === item.subject.id : false).subject)
        sort(subjects).asc(step => step.name);
        setSubjects(subjects)
    }

    useEffect(() => {
        loadSubject()
    }, [data])

    const dataChart = (isPercent = false, type) => {
        const color = subjects.map(step => randomRgba())
        return {
            labels: subjects.map(subject => subject.name),
            datasets: [{
                label: isPercent ? `% dos Apontamentos` : `N dos Apontamentos`,
                data: subjects.map(subject => isPercent ? getNumber(data, subject, type, true) : getNumber(data, subject, type)),
                backgroundColor: color.map(color => color.rgba2),
                borderColor: color.map(color => color.rgba1),
                borderWidth: 1
            }],
        }
    }

    const countAsBuilt = data.filter(item => (item.signal && item.signal[0]) === 2).length
    const countMelhoria = data.filter(item => (item.signal && item.signal[0]) === 2).length

    const options = {
        legend: {
            display: false,
        },
        scales: {
            xAxes: [{ display: false, }],
        },
    }

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Card style={{ flex: 1, marginRight: 5, padding: 20 }}>
                    <div style={{ display: "flex", paddingTop: 5, fontWeight: "bold", fontSize: 20, justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
                        {`Sinalizar - As built`}
                    </div>
                    {/* <div style={{ display: "flex", paddingTop: 5, fontWeight: "bold", fontSize: 15, justifyContent: "center", alignItems: "center" }}>
                    {`Total de itens "as built": ${data.filter(item => (item.signal && item.signal[0]) === 2).length}`}
                </div> */}
                    <BarChart data={dataChart(undefined, 2)} height={"200%"} options={options} />
                </Card>
                <Card style={{ flex: 1, marginLeft: 5, padding: 20 }}>
                    <div style={{ display: "flex", paddingTop: 5, fontWeight: "bold", fontSize: 20, justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
                        {`Sinalizar - As built`}
                    </div>
                    <BarChart data={dataChart(true, 2)} height={"200%"} options={options} />
                </Card>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Card style={{ flex: 1, marginRight: 5, padding: 20 }}>
                    <div style={{ display: "flex", paddingTop: 5, fontWeight: "bold", fontSize: 20, justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
                        {`Sinalizar - Melhoria`}
                        {/* {`Total de itens "melhoria": ${data.filter(item => (item.signal && item.signal[0]) === 1).length}`} */}
                    </div>
                    <BarChart data={dataChart(undefined, 1)} height={"200%"} options={options} />
                </Card>
                <Card style={{ flex: 1, marginLeft: 5, padding: 20 }}>
                    <div style={{ display: "flex", paddingTop: 5, fontWeight: "bold", fontSize: 20, justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
                        {`Sinalizar - Melhoria`}
                        {/* {`Total de itens "melhoria": ${data.filter(item => (item.signal && item.signal[0]) === 1).length}`} */}
                    </div>
                    <BarChart data={dataChart(true, 1)} height={"200%"} options={options} />
                </Card>
            </div>
        </div >
    )
}

export default SignalCard