import React from 'react';
import { useState } from 'react';
import { Gluejar } from "@charliewilco/gluejar";

export default function PasteComponent({onChange}) {
    const [copyImages, setCopyImages] = useState([]);
    return (
        <Gluejar
            onPaste={files => {
                const newImages = files.images.slice(copyImages.length, files.images.length);
                setCopyImages(files.images)
                onChange(newImages);
            }}
            onError={err => console.error(err)}
        />
    );
}
