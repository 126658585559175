import React from 'react';
import { allRoutes } from 'routes';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { FaAngleLeft } from 'react-icons/fa'
/*

Componente utilizado para mostrar título e voltar das telas

*/

const BreadcrumbsComponent = ({ breadcrumbs, goBack }) => {
    const breadcrumb = breadcrumbs[breadcrumbs.length-1];
    const route = allRoutes.find(item => breadcrumb && `${item.layout}${item.path}` === breadcrumb.key)

    return <div className="breadcrumbs">
        {goBack && <FaAngleLeft onClick={goBack}/>}
        <h2>
            {route && route.name}
        </h2>
    </div>;
}

export const Breadcrumbs = withBreadcrumbs(allRoutes)(BreadcrumbsComponent)