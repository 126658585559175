import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import DeleteLabel from '../DeleteLabel/DeleteLabel';
import { Button } from 'reactstrap';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import Painterro from 'painterro'
import html2canvas from 'html2canvas';
import { WorkModalImages } from '../WorkModal/WorkModaImages';
import DeleteButton from '../DeleteLabel/DeleteButton';
import { confirmAlert } from 'react-confirm-alert';
import { uploadImage } from '../../core/utils/Uploader';
import { updateLinkedImageInspection } from '../../core/database/inspections';
import { toJpeg } from 'html-to-image';
import ModalAlert from '../../layoutV2/components/Modal/ModalAlert';
import { EditButton } from '../Button/Button';
import { DeleteButton as NewDeleteButton, UndoButton } from '../Button/Button'

const getGrid = (item, index) => {
    if (item.grid) {
        return item.grid;
    } else {
        if (index === 0) {
            return { x: 0, y: 0, w: 4, h: 7 }
        } else if (index === 1) {
            return { x: 4, y: 0, w: 2, h: 7 }
        } else {
            return { x: (index - 2) % 3 * 2, y: 0, w: 2, h: 7 }
        }
    }
}

const isClipboardApiEnabled = (navigator) => (
    typeof navigator === 'object' &&
    typeof navigator.clipboard === 'object'
);

function getTranslateXValue(translateString) {

    var n = translateString.indexOf("(");
    var n1 = translateString.indexOf(",");

    var res = parseInt(translateString.slice(n + 1, n1 - 2));

    return res;

}

function getTranslateYValue(translateString) {

    var n = translateString.indexOf(",");
    var n1 = translateString.indexOf(")");

    var res = parseInt(translateString.slice(n + 1, n1 - 1));
    return res;

}

const ImageGridLayout = ({ field = 'images', location, data, isComment, onSaveLayout, onEditImage, onDataChange, onSaveCustomImage, disabled = false }) => {
    const [images, setImages] = useState([]);
    const [editItem, setEditItem] = useState({});
    const [customImage, setCustomImage] = useState(data.customImage);
    // const [lastScrollX, setLastScrollX] = useState(0)
    // const [lastScrollY, setLastScrollY] = useState(0)
    const [border, setBorder] = useState(0);
    const divRef = useRef();
    let divWidth, divHeigth, lastScrollX = 0, lastScrollY = 0

    if (divRef) {
        divWidth = divRef.current ? divRef.current.offsetWidth : 0
        divHeigth = divRef.current ? divRef.current.offsetHeight : 470
    }

    const verifyImages = (images) => {
        let newImages = images.map(image => {
            if (divRef.current && divRef.current.children) {
                let newArr = [...divRef.current.children]
                newArr.map(item => {
                    if (image.id === item.id) {
                        const itemX = getTranslateXValue(item.style.transform)
                        const itemY = getTranslateYValue(item.style.transform)
                        var d = document.getElementById(image.id);
                        /* console.log(
                            itemLeft, itemTop
                        ) */
                        /* if ((item.offsetWidth + item.offsetLeft) > divWidth || (item.offsetHeight + item.offsetTop) > divHeigth ||
                            item.offsetLeft < 0 || item.offsetTop < 0) {
                            console.log({ d })
                            // d.style.left = 0;
                            // d.style.top = 0;
                            d.style.transform = "translate(0px, 0px)"
                            return { ...image, layout: { ...image.layout, x: 0, y: 0 } }
                        } */

                        // console.log(item, "|", item.offsetWidth, "+", itemX, "ou", item.offsetLeft, ">", divWidth, "|", itemX, "||||",
                        // item.offsetHeight, "+", itemY, "ou", item.offsetTop, ">", divHeigth, "|", itemY)
                        if ((item.offsetWidth + itemX + item.offsetLeft) > divWidth || itemX < 0) {
                            let newX = 0;
                            let newY = itemY;
                            d.style.transform = `translate(${newX}px, ${newY}px)`
                            // console.log({ newX, newY })
                            return { ...image, layout: { ...image.layout, x: newX, y: newY } }
                        } else if ((item.offsetHeight + itemY + item.offsetTop) > divHeigth || itemY < 0) {
                            let newX = itemX;
                            let newY = 0;
                            d.style.transform = `translate(${newX}px, ${newY}px)`
                            // console.log({ newX, newY })
                            return { ...image, layout: { ...image.layout, x: newX, y: newY } }
                        }
                    }
                })
            }
            return { ...image }
        })
        // console.log({ newImages })
        return newImages
    }

    const onEditImageGrid = (image) => {
        onEditImage(data, image);
        const index = images.findIndex(item => item.id === image.id);
        const arrNewImages = images.slice(0);
        arrNewImages[index].url = image.url;
        setImages(arrNewImages);
    }

    const onRemoveImage = (image) => {
        data[field] = data[field].filter(item => item.id !== image.id);
        onDataChange(data)
    }

    useEffect(() => {
        if (data) {
            setImages(data[field]);
        }
    }, [data && data[field]])

    const onToggle = (data) => {
        setEditItem(data);
    }

    const onDrag = (id, ui) => {
        const index = data[field].findIndex(item => item.id === id);
        if (index !== -1) {
            let newArr = data[field].slice(0);
            let image = newArr[index];
            let layout = image.layout && image.layout
            image = {
                ...image,
                layout: {
                    ...layout,
                    x: ui.lastX,
                    y: ui.lastY
                }
            }
            newArr[index] = image;
            data[field] = newArr
            onSaveLayout(data);
            setImages(newArr);
        }
    };

    const onRezise = (id, size) => {
        const index = data[field].findIndex(item => item.id === id);
        if (index !== -1) {
            let newArr = data[field].slice(0);
            let image = newArr[index];
            let layout = image.layout && image.layout
            image = {
                ...image,
                layout: {
                    ...layout,
                    h: size.height,
                }
            }
            newArr[index] = image;
            let testArr = verifyImages(newArr)
            data[field] = newArr
            onSaveLayout(data);
            setImages(newArr);
        }
    }

    const onDragCustom = (ui) => {
        data.customLayout = {
            ...data.customLayout,
            x: ui.lastX,
            y: ui.lastY
        };
        onSaveLayout(data);
    };

    const onReziseCustom = (size) => {
        data.customLayout = {
            ...data.customLayout,
            h: size.height,
        };
        onSaveLayout(data);
    }

    function setImage() {
        window.scrollTo(0, 0);
        window.onscroll = function () { window.scrollTo(0, 0); };
        /* window.onscroll = function () {
            window.scrollTo(0, 0);
        }; */
        // dirty = false;
    }

    function onClose() {
        // console.log({ lastScrollX, lastScrollY })
        window.onscroll = function () { };
        window.scrollTo(lastScrollX, lastScrollY);
    }

    const openEdit = () => {
        // console.log({ scrollX: window.scrollX, scrollY: window.scrollY, window })
        // setLastScrollX(window.scrollX);
        // setLastScrollY(window.scrollY);
        lastScrollX = window.scrollX
        lastScrollY = window.scrollY
        toJpeg(document.getElementById(`${field}-${data.id}`))
            .then(function (dataUrl) {
                setImage()
                Painterro({
                    language: 'pt-BR',
                    hiddenTools: ["open"],
                    onBeforeClose: function (unsavedChanged, doClose) {
                        doClose()
                        onClose()
                    },
                    saveHandler: function (image, done) {
                        const uri = image.asDataURL();
                        setCustomImage(uri);
                        onSaveCustomImage(data, uri)
                        Painterro().hide();
                        done(true);
                        onClose()
                    },
                }).show(dataUrl)
            })
        // html2canvas(document.getElementById(data.id), {
        //     scrollX: 0,
        //     scrollY: -window.scrollY,
        //     logging: true,
        //     letterRendering: 1,
        //     useCORS: true
        // }).then(canvas => {
        // console.log(canvas.toDataURL());
        // Painterro({
        //     language: 'pt-BR',
        //     hiddenTools: ["open"],
        //     saveHandler: function (image, done) {
        //         const uri = image.asDataURL();
        //         setCustomImage(uri);
        //         onSaveCustomImage(data, uri)
        //         Painterro().hide();
        //         done(true);
        //     },
        // }).show(canvas.toDataURL())
        // });
    }

    const toDataUrl = async (url) => {
        return new Promise((resolve) => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    resolve(reader.result);
                }
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        })

    }

    const rotateImage = (offScreenCanvas, image, isClockwise) => {
        var offScreenCanvasCtx = offScreenCanvas.getContext('2d');

        // // // set its dimension to rotated size
        offScreenCanvas.height = image.width;
        offScreenCanvas.width = image.height;

        // // rotate and draw source image into the off-screen canvas:
        if (isClockwise) {
            offScreenCanvasCtx.rotate(90 * Math.PI / 180);
            offScreenCanvasCtx.translate(0, -image.height);
        } else {
            offScreenCanvasCtx.rotate(-90 * Math.PI / 180);
            offScreenCanvasCtx.translate(-image.width, 0);
        }
        offScreenCanvasCtx.drawImage(image, 0, 0);

        // encode image to data-uri with base64  
        return offScreenCanvas.toDataURL("image/png", 100);
    }

    const rotateImageData = async (item) => {
        const img = document.getElementById(`image-${item.id}`);
        const image = document.createElement('img');
        image.setAttribute("crossorigin", "anonymous")
        image.setAttribute("src", img.src);

        html2canvas(img, {
            scrollX: 0,
            scrollY: -window.scrollY,
            logging: true,
            letterRendering: 1,
            useCORS: true
        }).then(async canvas => {
            const url = rotateImage(canvas, image, true);
            item.url = await uploadImage(location.state.workId, data.inspectionId, { ...item, url });
            // onEditImageGrid(item)
            updateLinkedImageInspection(location.state.workId, data.inspectionId, item)
            const index = images.findIndex(row => row.id === item.id);
            const arrNewImages = images.slice(0);
            arrNewImages[index].url = item.url;
            setImages(arrNewImages);
        });
    }

    const sendToBack = (item) => {
        const index = data[field].findIndex(data => data.id === item.id);
        if (index !== -1) {
            const newArr = [data[field][index]].concat(data[field].slice(0).filter(data => data.id !== item.id))
            data[field] = newArr
            onSaveLayout(data);
            setImages(newArr);
        }
    }

    const desgroup = () => {
        onSaveCustomImage(data);
        setCustomImage('');
    }

    const resetImages = () => {
        const newArr = data[field].map(item => ({ ...item, layout: {} }))
        data[field] = newArr
        onSaveLayout(data);
        setImages(newArr);
    }

    const viewCustomImage = () => {
        const defaultPosition = data.customLayout && data.customLayout.x && data.customLayout.y ? { x: data.customLayout.x, y: data.customLayout.y } : { x: 0, y: 0 }
        const height = data.customLayout && data.customLayout.h ? data.customLayout.h : 300

        return <Draggable id={customImage} defaultPosition={defaultPosition} bounds="parent" handle="strong" onDrag={(_e, ui) => onDragCustom(ui)}>
            <div className={`box no-cursor white`} id={customImage}>
                <ResizableBox
                    height={height}
                    onResizeStop={(e, ui) => onReziseCustom(ui.size)}
                >
                    <img id={`image-${customImage}`} src={customImage} className="list-image" crossorigin="anonymous" />
                </ResizableBox>
            </div>
        </Draggable>
    }

    const delImage = async (item) => {
        /* confirmAlert({
            title: 'Deseja remover essa imagem?',
            message: 'Ao remover, sua imagem não será mais exibida, esta ação não é reversível, deseja mesmo remover?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => onRemoveImage(item)
                },
                {
                    label: 'Não'
                }
            ]
        }); */
        ModalAlert.danger({
            title: "Deseja remover esta imagem?",
            text: "Ao remover, sua imagem não será mais exibida, esta ação não é reversível, deseja mesmo remover?",
            onConfirm: () => onRemoveImage(item)
        })
    }

    return (
        <div className="draggable-layout-image">
            {(!disabled) && <div className="float-button-container">
                {/* <Button onClick={openEdit} size="sm" className="paste-button float-button" title="Editar imagens">
                    <i class="material-icons">
                        edit
                    </i>
                </Button> */}
                <EditButton onClick={openEdit} className="paste-button float-button" />
                {!customImage && <WorkModalImages location={location} data={data} onDataChange={onDataChange} floatButton={true} field={field} />}
                {customImage && <NewDeleteButton onClick={desgroup} className="paste-button float-button top2" />}
                {/* <Button onClick={desgroup} size="sm" className="paste-button float-button top2" title="Remover imagem editada">
                     <i class="material-icons">
                         delete_forever
                     </i>
                </Button>} */}
                {!customImage && <UndoButton onClick={resetImages} className="paste-button float-button top3" />}
                {/* <Button onClick={resetImages} size="sm" className="paste-button float-button top3" title="Retornar imagens originais">
                    <i class="material-icons">
                        undo
                    </i>
                </Button>} */}
                {!customImage && <div className="div-border top4">
                    <a onClick={() => setBorder(0)} active={border === 0} className={`border-color white ${border === 0 && `active`}`} />
                    <a onClick={() => setBorder(1)} active={border === 1} className={`border-color black ${border === 1 && `active`}`} />
                </div>}
            </div>}
            <div className="box-container">
                <div id={`${field}-${data.id}`} ref={divRef} style={{ backgroundColor: "white" }}>
                    {customImage && viewCustomImage()}
                    {(!customImage && images) && images.filter(item => item && !item.isHiding).map((item, index) => {
                        const defaultPosition = item.layout && item.layout.x !== undefined && item.layout.y !== undefined ? { x: item.layout.x, y: item.layout.y } : { x: 0, y: 0 }
                        const height = item.layout && item.layout.h !== undefined ? item.layout.h : 280
                        const color = border === 0 ? "white" : border === 1 ? "black" : ""
                        return (
                            <Draggable
                                id={item.id}
                                defaultPosition={defaultPosition}
                                bounds="parent"
                                handle="strong"
                                onDrag={(_e, ui) => onDrag(item.id, ui)}
                                disabled={disabled}
                            >
                                <div className={`box no-cursor ${color}`} id={item.id}>
                                    {(!isComment) && <>
                                        {/* <DeleteLabel label={"Deseja mesmo remover essa imagem?"} removeEvent={() => onRemoveImage(item)} /> */}
                                        <DeleteButton removeEvent={() => delImage(item)} />
                                        <Button
                                            onClick={() => rotateImageData(item)}
                                            className="btn-icon button-rotate-image"
                                            color="warning"
                                            size="sm"
                                            type="button"
                                        >
                                            <i className="material-icons">
                                                rotate_right
                                            </i>
                                        </Button>
                                        <Button
                                            onClick={() => sendToBack(item)}
                                            className="btn-icon button-up-image"
                                            color="warning"
                                            size="sm"
                                            type="button"
                                        >
                                            <i className="material-icons">
                                                arrow_drop_down
                                            </i>
                                        </Button>
                                    </>}
                                    <ResizableBox
                                        height={height}
                                        onResizeStop={(e, ui) => onRezise(item.id, ui.size)}
                                        className={disabled ? "image-resizable" : {}}
                                    >
                                        <img id={`image-${item.id}`} src={"https://aelyjcyado.cloudimg.io/v7/" + item.url + `&h=${height}&org_if_sml=1`} className="list-image" crossorigin="anonymous" />
                                    </ResizableBox>
                                    {!disabled && <>
                                        <strong className="cursor drag-icon">
                                            <i className="material-icons">
                                                drag_indicator
                                            </i>
                                        </strong>
                                        <strong className="cursor drag-icon top">
                                            <i className="material-icons">
                                                drag_indicator
                                            </i>
                                        </strong>
                                    </>}
                                </div>
                            </Draggable>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ImageGridLayout;