import { db, firebaseAuth, fireSQL } from './firebaseConfig';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import EntityEnum from './EntityEnum';
import { getItem, addItem, deleteItem, updateItem } from './firebaseFunctions';
import { useSelector } from 'react-redux';


export const getActivity = async (fields) => {
    /* return await fireSQL.query(`
        SELECT * from Activity where workGroup = ${workGroup}
    `) */
    return getItem("Activity", fields);
}

export const getAllActivity = async () => {
    return await fireSQL.query(`
        SELECT * from Activity where active = true
    `)
}

export const getWorksActivity = async (works) => {
    return await fireSQL.query(`
        SELECT * from Activity WHERE active = true AND workId in (${works.join(",")})
    `)
}

export const getActivityWithUID = async (works, activities) => {
    return await fireSQL.query(`
        SELECT * from Activity WHERE active = true AND workId in (${works.join(",")}) AND UID in (${activities.join(",")})
    `)
}

export const addActivity = async (item) => {
    return addItem("Activity", item);
}

export const deleteActivity = async (id) => {
    return deleteItem("Activity", id);
}

export const updateActivity = async (item) => {
    return updateItem("Activity", item);
}



/**
 * const users = db.collection(EntityEnum.user);
    const query = users.where('invite', '==', true);

    return query.get().then(snapshot => {
        if (snapshot.empty) {
            return;
        } else {
            snapshot.docs.forEach(document => {
                if (document.exists && document.id === id) {
                    return document;
                } else {
                    throw new Error(I18n[keyEnum.inviteExpired]);
                }
            });
            return;
        }
    })
 */