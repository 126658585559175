import React, { Component } from 'react';
import FileGalleryContainer from '../../container/FileGalleryContainer';
import { connect } from 'react-redux'

class PdfSelectView extends Component {

  constructor(props) {
    super(props)

    this.state = {
      file: undefined
    }

    this.onSelectionChange = this.onSelectionChange.bind(this);
  }

  onSelectionChange(files) {
    this.setState({ file: files[0] });

    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(files);
    }
  }

  isValidated() {
    return this.state.file !== undefined;
  }

  render() {
    return <FileGalleryContainer accept={"application/pdf"} onSelectionChange={this.onSelectionChange} dataReference={`Gallery/${this.props.idUser}/pdfs`} canSelect={true} />;
  }
}

const mapToProps = state => ({
  idUser: state.login.id
})

export default connect(mapToProps)(PdfSelectView);