import React from 'react';
import { IconButton } from '@material-ui/core';
import { Button } from 'reactstrap';
import { Close, Check, DeleteOutlined } from '@material-ui/icons';
import { confirmAlert } from 'react-confirm-alert';
import ModalAlert from '../../layoutV2/components/Modal/ModalAlert';
import { DeleteButton } from '../Button/Button';

const delComment = async (removeEvent, file) => {
    /* confirmAlert({
        title: 'Deseja remover esta imagem?',
        message: 'Ao remover, sua imagem não será mais exibida, esta ação não é reversível, deseja mesmo remover?',
        buttons: [
            {
                label: 'Sim',
                onClick: () => removeEvent(file)
            },
            {
                label: 'Não'
            }
        ]
    }); */
    ModalAlert.danger({
        title: "Deseja remover esta imagem?",
        text: "Ao remover, sua imagem não será mais exibida, esta ação não é reversível, deseja mesmo remover?",
        onConfirm: () => removeEvent(file)
    })
}

const DeleteGallery = ({ removeEvent, file }) => {
    return (
        <div className="button-delete-gallery">
            <DeleteButton onClick={() => delComment(removeEvent, file)} />
        </div>
    );
};

export default DeleteGallery;

{/* <Button
    color="danger"
    onClick={() => delComment(removeEvent, file)}
    style={{
        zIndex: 999,
        pointerEvents: "auto",
        position: "absolute",
        top: 8,
        right: 8,
        margin: 0,
        height: 23,
        width: 23,
        padding: 0,
        borderRadius: 20,
    }}
>
    <DeleteOutlined style={{ fontSize: 19 }} />
</Button> */}