import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, Badge, ModalFooter } from 'reactstrap';
import { WorkDetails } from './WorkDetails';
import { getItem, getItemById } from '../../core/database/firebaseFunctions';
import { addLinkedImageInspection, updateImageInspection, updateLinkedImageInspection } from '../../core/database/inspections';
import { getLibData } from '../../core/redux-store/lib/Actions';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import Alert from '../Alert/Alert';
import { normalizeCombo } from '../../core/utils/Utils';
import { NewModal } from '../../layoutV2/components/Modal/Modal';
import { SaveButton } from '../../layoutV2/components/Button';
import { EditButton } from '../Button/Button';

export const WorkModal = ({ setSelectedFiles, onSaveData, content = undefined, hasButton = false, location, selection = -1, saveState = undefined, setSaveState = undefined }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [data, setData] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [needRefreshCount, setNeedRefreshCount] = useState(0)
    const toggle = () => { setIsOpenModal(false); setNeedRefreshCount(0) };
    const dispatch = useDispatch();
    const libs = useSelector(state => state.lib.items);
    const network = useSelector(state => state.login.network);

    useEffect(() => {
        setFormValues({
            signal: [],
            typeImprovement: [],
            ...content,
            step: content && content.step ? { value: content.step.id, label: content.step.name } : undefined,
            subject: content && content.subject ? { value: content.subject.id, label: content.subject.name } : undefined,
        })
    }, [content && JSON.stringify(content)])

    useEffect(() => {
        if (isOpenModal) {
            const settedLib = normalizeCombo("id", "name", libs).find(item => item.value === formValues.commentId);
            setFormValues(values => ({ ...values, comment: settedLib ? settedLib : undefined }))
        }
    }, [libs, isOpenModal, formValues && formValues.commentId]);

    const loadData = async () => {
        const work = await getItemById(`Works`, location.state.workId);
        if (work) {
            getItem(`Skills`, { parentId: work.workType }).then(items => {
                setData(items);
            })
        }
        dispatch(getLibData({ tag: "IcjvF32VXhY7Y40fmIV9", workGroup: network }));
    }

    useEffect(() => {
        if (isOpenModal) {
            loadData();
        }
    }, [location && location.state, isOpenModal])

    const onSave = async () => {
        /* const addData = { ...formValues, images: selection, selectedComments: selectedComments.map(item => item.id) };
        console.log("addData", addData) */

        let promises = [];

        try {
            if (!formValues.step) {
                throw new Error(I18n[keyEnum.stepRequired]);
            }

            const data = {}

            if (formValues.localization) {
                data['localization'] = formValues.localization
            }

            if (formValues.description) {
                data['description'] = formValues.description
            }

            if (formValues.step && formValues.step.value) {
                data['step'] = { id: formValues.step.value, name: formValues.step.label }
            }

            if (formValues.subject && formValues.subject.value) {
                data['subject'] = { id: formValues.subject.value, name: formValues.subject.label }
            }

            if (formValues.scale) {
                data['scale'] = formValues.scale
            } else {
                data['scale'] = ""
            }

            if (formValues.signal) {
                data['signal'] = formValues.signal
            }

            if (formValues.typeImprovement) {
                data['typeImprovement'] = formValues.typeImprovement
            }

            if (formValues.comment && formValues.comment.value) {
                data['commentId'] = formValues.comment.value
            } else {
                data['commentId'] = ""
            }

            if (hasButton) {
                const newData = { ...data, id: formValues.id }
                await updateLinkedImageInspection(location.state.workId, location.state.id, newData);
                if (onSaveData) {
                    onSaveData(newData)
                }
                Alert.success("Alterado!")
                toggle();
                setFormValues({
                    signal: [],
                    typeImprovement: [],
                    ...content,
                    step: content && content.step ? { value: content.step.id, label: content.step.name } : {},
                    subject: content && content.subject ? { value: content.subject.id, label: content.subject.name } : {},
                })
            } else {
                addLinkedImageInspection(location.state.company.id, location.state.workId, location.state.id, { ...data, images: selection, date: new Date() }).then(() => {
                    promises = selection.map(itemSelection => {
                        return updateImageInspection(location.state.workId, location.state.id, { id: itemSelection.id, linked: true })
                    })

                    Promise.all(promises).then(() => {
                        toggle();
                        setFormValues({
                            signal: [],
                            typeImprovement: [],
                            ...content,
                            step: content && content.step ? { value: content.step.id, label: content.step.name } : {},
                            subject: content && content.subject ? { value: content.subject.id, label: content.subject.name } : {},
                        })
                        setSaveState(!saveState);
                    })
                });
            }
        } catch (error) {
            Alert.error(error.message);
        }
    }

    const onFormChange = (field, value) => {
        setFormValues(values => ({
            ...values,
            [field]: value
        }))
    }

    /* const onSelectionChange = (files) => {
        setSelectedComments(files);
    } */

    {/* <Button size="sm" className="paste-button simple-remove" onClick={() => setIsOpenModal(true)}>
        <i class="material-icons">
            edit
        </i>
    </Button> */}
    return (
        <>
            {hasButton && (
                <EditButton onClick={() => setIsOpenModal(true)} />
            )}
            {!hasButton && <div className='work-footer'>
                {/* <Button disabled={selection.length === 0} className='work-footer-button' color="primary" onClick={() => setIsOpenModal(true)}>
                    <i class="material-icons">linked_camera</i>
                    <span>Vincular</span>
                </Button> */}
                <SaveButton disabled={selection.length === 0 || selection.length > 6} onClick={() => setIsOpenModal(true)} label={<div style={{ display: "flex", alignItems: "center" }}>
                    <i class="material-icons">linked_camera</i>
                    <span>Vincular</span>
                </div>} />
            </div>}
            {isOpenModal &&
                <NewModal
                    open={isOpenModal !== false}
                    toggle={toggle}
                    onClickConfirm={onSave}
                    title={"Vincular imagem"}
                    className="report-modal link-image-modal"
                >
                    {/* <Modal isOpen={isOpenModal !== false} style={{ maxWidth: '60%' }}>
                        <ModalHeader toggle={toggle}>{hasButton ? "Editar vínculo" : "Detalhes"}</ModalHeader>
                        <ModalBody className='details-wizard'> */}
                    <WorkDetails
                        onFormChange={onFormChange}
                        formValues={formValues}
                        data={data}
                        allLibs={libs}
                        hasButton={hasButton}
                        setNeedRefreshCount={setNeedRefreshCount}
                        needRefreshCount={needRefreshCount}
                    />
                    {/* </ModalBody>
                        <ModalFooter>
                            <Button color="primary" className='modal-button' onClick={() => onSave()}>Salvar</Button>
                        </ModalFooter>
                    </Modal> */}
                </NewModal>}
        </>
    );
};