import { getLib, getLibs, addLib, deleteLib, updateLib, getLibTags} from "core/database/lib";
import ActionCreator from 'core/utils/redux/ActionCreator';

const { actions, defaultReducer, get, add, remove, update, getSimple } = new ActionCreator("lib");
export { actions, defaultReducer };

export const getLibByIdData = getSimple(getLib);
export const getLibTagsData = getSimple(getLibTags);
export const getLibData = get(getLibs);
export const addLibData = add(addLib);
export const updateLibData = update(updateLib);
export const deleteLibData = remove(deleteLib);
