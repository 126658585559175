import { db, firebaseAuth, fireSQL } from './firebaseConfig';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import EntityEnum from './EntityEnum';
import { getItem, addItem, deleteItem, updateItem } from './firebaseFunctions';
import { useSelector } from 'react-redux';


export const getInspectionModel = async ({ fields, standard = undefined }) => {
    /* return await fireSQL.query(`
        SELECT * from InspectionModel where workGroup = ${workGroup}
    `) */
    // console.log({ fields })
    let workGroupModels = await getItem("InspectionModel", fields)
    // console.log({ standard, fields, workGroupModels })
    if (standard) {
        const standardModels = await getItem("InspectionModel", standard)
        workGroupModels = workGroupModels.concat(standardModels)
        // console.log({ workGroupModels, standardModels })
    }
    return workGroupModels
}

export const getAllInspectionModel = async () => {
    return await fireSQL.query(`
        SELECT * from InspectionModel where active = true
    `)
}

export const addInspectionModel = async (item) => {
    return addItem("InspectionModel", item);
}

export const deleteInspectionModel = async (id) => {
    return deleteItem("InspectionModel", id);
}

export const updateInspectionModel = async (item) => {
    return updateItem("InspectionModel", item);
}



/**
 * const users = db.collection(EntityEnum.user);
    const query = users.where('invite', '==', true);

    return query.get().then(snapshot => {
        if (snapshot.empty) {
            return;
        } else {
            snapshot.docs.forEach(document => {
                if (document.exists && document.id === id) {
                    return document;
                } else {
                    throw new Error(I18n[keyEnum.inviteExpired]);
                }
            });
            return;
        }
    })
 */