import React, { useEffect, useState } from 'react';
import { PubNubProvider } from "pubnub-react";
import { Chat, MessageList, ChannelList } from "@pubnub/react-chat-components";
import { FiSearch } from 'react-icons/fi';
import { AiOutlineDown, AiOutlineClose, AiOutlineSearch, AiOutlineUserAdd, AiOutlineMail } from 'react-icons/ai';
import { DateRange, TextInput } from '../../layoutV2/components/Form';
import moment from 'moment';
import { Spinner } from 'reactstrap';
import { IoAdd } from "react-icons/io5";
import { PickerList } from '../Picker';
import { SendInput } from '../SendInput'
import { ListReport } from '../../components/Report/ListReport';
import { DefaultImpact } from '../../views/DefaultFilters';
import { ModalForum } from '../../components/ModalForum/ModalForum';
import { ModalGroup } from '../ModalGroup/ModalGroup';
import { ModalShare } from '../ModalShare/ModalShare';
import { isUnreadMessage, observeChat } from '../../core/communication/PushNotification';
import { IoMdShareAlt } from 'react-icons/io';

const verificationReply = (replyTo, loggedUser, onRespondent) => {
    if (replyTo.work && !replyTo.forward) {
        let work = replyTo.work
        return <a className='reply-div' href={`#${replyTo._id}`} onClick={() => onRespondent ? onRespondent(replyTo) : null}>
            <div className="work-div">
                <ListReport
                    count={0}
                    addCount={() => { }}
                    showBlankSpaces={false}
                    key={1}
                    onSaveCustomImage={() => { }}
                    data={work}
                    index={1}
                    onSave={() => { }}
                    onSaveLinkData={() => { }}
                    onEditImage={() => { }}
                    location={{ state: work.localization }}
                    onDataChange={() => { }}
                    allData={[work]}
                    impact={DefaultImpact(false).filter(impact => work.scale === impact.value)[0]}
                    delReport={() => { }}
                    delComment={() => { }}
                    onSaveData={() => { }}
                    onShare={() => { }}
                    sharedComments={[]}
                    ordination={[]}
                    onFinish={() => { }}
                    isQualityModal={true}
                />
            </div>
        </a>
    } else if (replyTo.forward && replyTo.forward.work) {
        let work = replyTo.forward.work
        return <a className='reply-div' href={`#${replyTo._id}`} onClick={() => onRespondent ? onRespondent(replyTo) : null}>
            <div className="work-div" style={{ backgroundColor: 'white' }}>
                <text className='nm-work'>{work.nmWork}</text>
                <ListReport
                    count={0}
                    addCount={() => { }}
                    showBlankSpaces={false}
                    key={1}
                    onSaveCustomImage={() => { }}
                    data={work}
                    index={1}
                    onSave={() => { }}
                    onSaveLinkData={() => { }}
                    onEditImage={() => { }}
                    location={{ state: work.localization }}
                    onDataChange={() => { }}
                    allData={[work]}
                    impact={DefaultImpact(false).filter(impact => work.scale === impact.value)[0]}
                    delReport={() => { }}
                    delComment={() => { }}
                    onSaveData={() => { }}
                    onShare={() => { }}
                    sharedComments={[]}
                    ordination={[]}
                    onFinish={() => { }}
                    isQualityModal={true}
                />
            </div>
            <text className='msg' style={{ color: replyTo.user._id === loggedUser.id ? 'white' : 'black' }}>{replyTo.text}</text>
        </a>
    } else if (replyTo.image) {
        return <a className='reply-div' href={`#${replyTo._id}`} onClick={() => onRespondent ? onRespondent(replyTo) : null}>
            <img src={replyTo.image} className='img-chat' />
        </a>
    } else if (replyTo.file) {

        return <div className='reply-div' href={`#${replyTo._id}`} onClick={() => onRespondent ? onRespondent(replyTo) : null}>
            <a className='a-link' style={{ color: replyTo.user._id === loggedUser.id ? 'white' : 'black' }} >{replyTo && replyTo.filename ? replyTo.filename : `Relatório-${moment().format("DD-MM-YY")}.pdf`}</a>
        </div>
    }
    else {
        return <a className='reply-div' href={`#${replyTo._id}`} onClick={() => onRespondent ? onRespondent(replyTo) : null}>
            <text>{replyTo.user.name}</text>
            <text>{replyTo.text}</text>
        </a>
    }
}

const verificationFoward = (forward, loggedUser,) => {
    if (forward.work && !forward.forward) {
        let work = forward.work
        return <div className='reply-div' >
            <div className="work-div">
                <ListReport
                    count={0}
                    addCount={() => { }}
                    showBlankSpaces={false}
                    key={1}
                    onSaveCustomImage={() => { }}
                    data={work}
                    index={1}
                    onSave={() => { }}
                    onSaveLinkData={() => { }}
                    onEditImage={() => { }}
                    location={{ state: work.localization }}
                    onDataChange={() => { }}
                    allData={[work]}
                    impact={DefaultImpact(false).filter(impact => work.scale === impact.value)[0]}
                    delReport={() => { }}
                    delComment={() => { }}
                    onSaveData={() => { }}
                    onShare={() => { }}
                    sharedComments={[]}
                    ordination={[]}
                    onFinish={() => { }}
                    isQualityModal={true}
                />
            </div>
        </div>
    } else if (forward.forward) {
        let work = forward.forward.work
        return <div className='reply-div' >
            <div className="work-div" style={{ backgroundColor: 'white' }}>
                <text className='nm-work'>{work.nmWork}</text>
                <ListReport
                    count={0}
                    addCount={() => { }}
                    showBlankSpaces={false}
                    key={1}
                    onSaveCustomImage={() => { }}
                    data={work}
                    index={1}
                    onSave={() => { }}
                    onSaveLinkData={() => { }}
                    onEditImage={() => { }}
                    location={{ state: work.localization }}
                    onDataChange={() => { }}
                    allData={[work]}
                    impact={DefaultImpact(false).filter(impact => work.scale === impact.value)[0]}
                    delReport={() => { }}
                    delComment={() => { }}
                    onSaveData={() => { }}
                    onShare={() => { }}
                    sharedComments={[]}
                    ordination={[]}
                    onFinish={() => { }}
                    isQualityModal={true}
                />
            </div>
            <text className='msg' style={{ color: forward.user._id === loggedUser.id ? 'white' : 'black' }}>{forward.text}</text>
        </div>
    } else if (forward.image) {
        return <div className='reply-div' >
            <img src={forward.image} className='img-chat' />
        </div>
    } else if (forward.file) {

        return <div className='reply-div' >
            <a className='a-link' style={{ color: forward.user._id === loggedUser.id ? 'white' : 'black' }} >{forward && forward.filename ? forward.filename : `Relatório-${moment().format("DD-MM-YY")}.pdf`}</a>
        </div>
    }
    else {
        return <div className='reply-div' >
            <text>{forward.text}</text>
        </div>
    }
}

const textVerification = (item, loggedUser, isOpen, setIsOpen, onReplayMessage, onDeleteMessage, onRespondent, setOpenShareGroup, setOpenShareUsers, btns) => {
    if (item.image) {
        return <div>
            <div className='icon-div'>

                {item.user._id === loggedUser.id ? <AiOutlineDown className={item.user._id === loggedUser.id ? 'icon-down-white' : 'icon-down-black'} onClick={() => setIsOpen(item)} style={{ marginLeft: 15 }} /> : <div />}
                {isOpen === item && item.user._id === loggedUser.id && <PickerList
                    className={item.user._id === loggedUser.id ? 'edit' : 'edit-picker-sender'}
                    loggedUser={loggedUser}
                    isOpen={isOpen}
                    arrayBtn={btns}
                />}
            </div>
            <img src={item.image} className='img-chat' />
        </div>
    } else if (item.file) {
        return <div className='link-pointer'>
            <a className='a-link' style={{ color: item.user._id === loggedUser.id ? 'white' : 'black' }} onClick={() => window.open(item.file)}>{item && item.filename ? item.filename : `Relatório-${moment().format("DD-MM-YY")}.pdf`}</a>
            {item.user._id === loggedUser.id ? <AiOutlineDown className={item.user._id === loggedUser.id ? 'icon-down-white' : 'icon-down-black'} onClick={() => setIsOpen(item)} style={{ marginLeft: 15 }} /> : <div />}
            {isOpen === item && item.user._id === loggedUser.id && <PickerList
                className={item.user._id === loggedUser.id ? 'edit' : 'edit-picker-sender'}
                loggedUser={loggedUser}
                isOpen={isOpen}
                arrayBtn={btns}
            />}
        </div>
    } else if (item.work && !item.forward) {
        let work = item.work
        return <div>
            <div className='icon-div'>
                {item.user._id === loggedUser.id ? <AiOutlineDown className={item.user._id === loggedUser.id ? 'icon-down-white' : 'icon-down-black'} onClick={() => setIsOpen(item)} style={{ marginLeft: 15 }} /> : <div />}
                {isOpen === item && item.user._id === loggedUser.id && <PickerList
                    className={item.user._id === loggedUser.id ? 'edit' : 'edit-picker-sender'}
                    loggedUser={loggedUser}
                    isOpen={isOpen}
                    arrayBtn={btns}
                />}
            </div>
            <div className="work-div">
                <ListReport
                    count={0}
                    addCount={() => { }}
                    showBlankSpaces={false}
                    key={1}
                    onSaveCustomImage={() => { }}
                    data={work}
                    index={1}
                    onSave={() => { }}
                    onSaveLinkData={() => { }}
                    onEditImage={() => { }}
                    location={{ state: work.localization }}
                    onDataChange={() => { }}
                    allData={[work]}
                    impact={DefaultImpact(false).filter(impact => work.scale === impact.value)[0]}
                    delReport={() => { }}
                    delComment={() => { }}
                    onSaveData={() => { }}
                    onShare={() => { }}
                    sharedComments={[]}
                    ordination={[]}
                    onFinish={() => { }}
                    isQualityModal={true}
                />
            </div>
        </div>
    } else if (item.forward && item.forward.work) {
        let work = item.forward.work
        return <div>
            <div className='icon-div'>
                {item.user._id === loggedUser.id ? <AiOutlineDown className={item.user._id === loggedUser.id ? 'icon-down-white' : 'icon-down-black'} onClick={() => setIsOpen(item)} style={{ marginLeft: 15 }} /> : <div />}
                {isOpen === item && item.user._id === loggedUser.id && <PickerList
                    className={item.user._id === loggedUser.id ? 'edit' : 'edit-picker-sender'}
                    loggedUser={loggedUser}
                    isOpen={isOpen}
                    arrayBtn={btns}
                />}
            </div>
            <div className="work-div" style={{ backgroundColor: 'white' }}>
                <ListReport
                    count={0}
                    addCount={() => { }}
                    showBlankSpaces={false}
                    key={1}
                    onSaveCustomImage={() => { }}
                    data={work}
                    index={1}
                    onSave={() => { }}
                    onSaveLinkData={() => { }}
                    onEditImage={() => { }}
                    location={{ state: work && work.localization }}
                    onDataChange={() => { }}
                    allData={[work]}
                    impact={DefaultImpact(false).filter(impact => work && work.scale === impact.value)[0]}
                    delReport={() => { }}
                    delComment={() => { }}
                    onSaveData={() => { }}
                    onShare={() => { }}
                    sharedComments={[]}
                    ordination={[]}
                    onFinish={() => { }}
                    isQualityModal={true}
                />
            </div>
            <text className='msg' style={{ color: item.user._id === loggedUser.id ? 'white' : 'black' }}>{item.text}</text>
        </div>
    } else if (item.replyTo) {
        return <div>
            <div style={{ display: 'flex' }}>
                {verificationReply(item.replyTo, loggedUser, onRespondent)}
                <div className='icon-div'>
                    {item.user._id === loggedUser.id ? <AiOutlineDown className={item.user._id === loggedUser.id ? 'icon-down-white' : 'icon-down-black'} onClick={() => setIsOpen(item)} style={{ marginLeft: 15 }} /> : <div />}
                    {isOpen === item && item.user._id === loggedUser.id && <PickerList
                        className={item.user._id === loggedUser.id ? 'edit' : 'edit-picker-sender'}
                        loggedUser={loggedUser}
                        isOpen={isOpen}
                        arrayBtn={btns}
                    />}
                </div>
            </div>
            <text className='msg' style={{ color: item.user._id === loggedUser.id ? 'white' : 'black' }}>{item.text}</text>
        </div>
    } else if (item.forward) {
        return <div style={{ display: 'flex', }}>
            <div>
                <div style={{ marginBottom: 5 }}>
                    <IoMdShareAlt className="icon-forward" />
                    <text className="text-forward">Encaminhada</text>
                </div>
                {verificationFoward(item.forward, loggedUser)}
            </div>
            <div >
                <text className='msg' style={{ color: item.user._id === loggedUser.id ? 'white' : 'black' }}>{item.text}</text>
            </div>
            <div className='icon-div'>
                {item.user._id === loggedUser.id ? <AiOutlineDown className={item.user._id === loggedUser.id ? 'icon-down-white' : 'icon-down-black'} onClick={() => setIsOpen(item)} style={{ marginLeft: 15 }} /> : <div />}
                {isOpen === item && item.user._id === loggedUser.id && <PickerList
                    className={item.user._id === loggedUser.id ? 'edit' : 'edit-picker-sender'}
                    loggedUser={loggedUser}
                    isOpen={isOpen}
                    arrayBtn={btns}
                />}
            </div>
        </div>
    } else {
        return <div style={{ display: 'flex' }}>
            <div >
                <text className='msg' style={{ color: item.user._id === loggedUser.id ? 'white' : 'black' }}>{item.text}</text>
            </div>
            <div className='icon-div'>
                {item.user._id === loggedUser.id ? <AiOutlineDown className={item.user._id === loggedUser.id ? 'icon-down-white' : 'icon-down-black'} onClick={() => setIsOpen(item)} style={{ marginLeft: 15 }} /> : <div />}
                {isOpen === item && item.user._id === loggedUser.id && <PickerList
                    className={item.user._id === loggedUser.id ? 'edit' : 'edit-picker-sender'}
                    loggedUser={loggedUser}
                    isOpen={isOpen}
                    arrayBtn={btns}
                />}
            </div>
        </div>
    }

}

const respondentMessages = (respondent, loggedUser) => {
    if (respondent.image) {
        return <div>
            <img src={respondent.image} className='img-chat' />
        </div>
    } else if (respondent.file) {
        return <div className='link-pointer'>
            <a className='a-link'  >{respondent && respondent.filename ? respondent.filename : `Relatório-${moment().format("DD-MM-YY")}.pdf`}</a>
        </div>
    } else if (respondent.work && !respondent.forward) {
        let work = respondent.work
        return <div>
            <div className="work-div">
                <ListReport
                    count={0}
                    addCount={() => { }}
                    showBlankSpaces={false}
                    key={1}
                    onSaveCustomImage={() => { }}
                    data={work}
                    index={1}
                    onSave={() => { }}
                    onSaveLinkData={() => { }}
                    onEditImage={() => { }}
                    location={{ state: work.localization }}
                    onDataChange={() => { }}
                    allData={[work]}
                    impact={DefaultImpact(false).filter(impact => work.scale === impact.value)[0]}
                    delReport={() => { }}
                    delComment={() => { }}
                    onSaveData={() => { }}
                    onShare={() => { }}
                    sharedComments={[]}
                    ordination={[]}
                    onFinish={() => { }}
                    isQualityModal={true}
                />
            </div>
        </div>
    } else if (respondent.forward && respondent.forward.work) {
        let work = respondent.forward.work
        return <div>
            <div className="work-div" style={{ backgroundColor: 'white' }}>
                <ListReport
                    count={0}
                    addCount={() => { }}
                    showBlankSpaces={false}
                    key={1}
                    onSaveCustomImage={() => { }}
                    data={work}
                    index={1}
                    onSave={() => { }}
                    onSaveLinkData={() => { }}
                    onEditImage={() => { }}
                    location={{ state: work.localization }}
                    onDataChange={() => { }}
                    allData={[work]}
                    impact={DefaultImpact(false).filter(impact => work.scale === impact.value)[0]}
                    delReport={() => { }}
                    delComment={() => { }}
                    onSaveData={() => { }}
                    onShare={() => { }}
                    sharedComments={[]}
                    ordination={[]}
                    onFinish={() => { }}
                    isQualityModal={true}
                />
            </div>
            <text className='msg' style={{ color: respondent.user._id === loggedUser.id ? 'white' : 'black' }}>{respondent.text}</text>
        </div>
    } else {
        return <text>{respondent && respondent.text}</text>
    }
}

const dateMessage = (data, createdAt) => {
    const formatedDate = createdAt.filter(row => row.timestamp === data.createdAt)
    return formatedDate[0] && formatedDate[0].createdAt
}

export const SendMessage = ({ pubnub, channel, currentChannel, theme, users, onSend, values, loggedUser = undefined, loading, reload, isOpen, setIsOpen, onChangeCalendary, onDeleteMessage, uploadFiles, setChangeMessage, onReplayMessage, respondent, respondentClose, changeMessage, onClickFilter, openFilter, onRespondent, scrollRespondent, setOpenShareGroup, openShareGroup, setOpenShareUsers, openShareUsers, setReload, loadingMessages, }) => {

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSend(changeMessage)
        }
    }

    const createdAt = users.map(item => { return { ...item, createdAt: moment(new Date(item.createdAt)).format('ll'), dateFull: item.createdAt } }).filter(function (a) {
        return !this[JSON.stringify(a.createdAt)] && (this[JSON.stringify(a.createdAt)] = true);
    }, Object.create(null))

    // console.log({loadingMessages,loadingReceived})

    return <>
        <PubNubProvider client={pubnub}>
            <Chat {...{ currentChannel, theme }}>
                {channel && <div className="chat-header">
                    <div>
                        <img className='img-avatar' src={channel.custom.thumb} style={{ marginRight: 15 }} />
                        <text>{channel.name}</text>
                    </div>
                    <div className='div-filter'>
                        {openFilter ? <DateRange
                            onDateChange={onChangeCalendary}
                            startDate={values && values.dates && values.dates.startDate}
                            endDate={values && values.dates && values.dates.endDate}
                            style={{ marginBottom: 0 }}
                        /> : <div />}
                        {openFilter ? <AiOutlineClose onClick={onClickFilter} className="icon-close" /> : <AiOutlineSearch className="icon-filter" onClick={onClickFilter} />}
                    </div>
                </div>}
                <MessageList
                    fetchMessages={false}
                    onScroll={false}
                >
                    {loading || loadingMessages ? <div className="spinner-chat">
                        <Spinner color="warning" />
                        {loading && <span className="name-send">Enviando arquivo!</span>}
                    </div> : !channel ?
                        <div className='not-chat'>
                            <text>
                                Escolha uma conversa para enviar mensagem
                            </text>
                        </div>
                        : users.map((item, index) => {

                            const btns = [
                                { id: 0, name: 'Responder', onClick: () => onReplayMessage(item) },
                                { id: 1, name: 'Excluir', onClick: () => onDeleteMessage(item) },
                                { id: 2, name: 'Compartilhar com grupo', onClick: () => setOpenShareGroup(item) },
                                { id: 3, name: 'Compartilhar com Usuário', onClick: () => setOpenShareUsers(item) },
                                { id: 4, name: 'Cancelar', onClick: () => setIsOpen(false) },
                            ]
                            return !item.idChat ? <div /> : <div>
                                <div className='date-message'>
                                    <text className='text-date'>
                                        {dateMessage(item, createdAt,)}
                                    </text>
                                </div>
                                <div id={item._id} className={scrollRespondent._id === item._id ? 'div-scroll-respondent' : item.user._id === loggedUser.id ? 'chat-end' : 'chat-start'}>
                                    <div className={item.user._id === loggedUser.id ? 'div-row-blue' : 'div-row-gray'}>
                                        <div className='div-collumn-chat'>
                                            {/* item.group && */ item.user._id !== loggedUser.id ?
                                                <div className='div-icon-arrow'>
                                                    <text className='text-user'>{item.user.name}</text>

                                                    <AiOutlineDown className={item.user._id === loggedUser.id ? 'icon-down-white' : 'icon-down-black'} onClick={() => setIsOpen(item)} style={{ marginLeft: 15 }} />
                                                    {isOpen === item && <PickerList
                                                        className={item.user._id === loggedUser.id ? 'edit' : 'edit-picker-sender'}
                                                        loggedUser={loggedUser}
                                                        isOpen={isOpen}
                                                        arrayBtn={btns}
                                                    />}
                                                </div>
                                                : <div />}
                                            <div>
                                                {textVerification(item, loggedUser, isOpen, setIsOpen, onReplayMessage, onDeleteMessage, onRespondent, setOpenShareGroup, setOpenShareUsers, btns)}
                                            </div>
                                            <div className='div-time'>
                                                <text className='time' style={{ color: item.user._id === loggedUser.id ? 'white' : 'black' }}>{moment(new Date(item.createdAt)).format('LT')}</text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                </MessageList>
                <div>
                    {respondent ? <div className="div-respondent">
                        <div className="div-icon-close">
                            <AiOutlineClose onClick={respondentClose} />
                        </div>
                        <div className="div-row-respondent">
                            <text>{respondent && respondent.user && respondent.user._id === loggedUser.id ? 'Você' : respondent && respondent.user && respondent.user.name}</text>
                            {respondentMessages(respondent, loggedUser)}
                        </div>
                    </div> : <div />}
                    {channel && <div>
                        <SendInput
                            value={changeMessage.text}
                            onChange={event => setChangeMessage({ text: event.target.value, type: 'text' })}
                            onClick={() => onSend(changeMessage)}
                            uploadFiles={drop => uploadFiles(drop)}
                            onKeyDown={(e) => handleKeyDown(e)}
                        />
                    </div>}
                </div>
            </Chat>
        </PubNubProvider>
        <ModalShare
            open={openShareGroup ? openShareGroup : openShareUsers}
            setOpen={openShareGroup ? setOpenShareGroup : setOpenShareUsers}
            channel={channel}
            type={openShareGroup ? 'Group' : 'Users'}
            setIsOpen={setIsOpen}
            reload={reload}
            setReload={setReload}
        />
    </>

};

export const MessageChanelList = ({ data, value, onChangeSearch, placeholder = 'Pesquisar...', loggedUser, onChannelSwitched, channel, newForum, setNewForum, newGroup, setNewGroup, onSaveNewForum, onSaveNewGroup, values, onChange, setValues, setOpenConfig, openConfig, removeForum, onEditConfig, editGroup, markedUnread,setChatMessage, }) => {

    const btns = [
        { id: 0, name: 'Editar', onClick: () => onEditConfig() },
        { id: 1, name: 'Remover', onClick: () => removeForum(openConfig) },
        { id: 2, name: 'Marcar como não lido', onClick: () => markedUnread(openConfig) },
        { id: 3, name: 'Cancelar', onClick: () => setOpenConfig(false) },
    ]

    return (
        <>
            <div className='div-primary'>
                <div>
                    <div className='div-image'>
                        <img className='img-avatar' src={`https://ui-avatars.com/api/?background=random&name=${loggedUser.name}`} />
                        <div className='div-form'>
                            <text className='txt-user'>{loggedUser.name}</text>
                            <text className='txt-user-info'>{loggedUser.isAdmin ? 'Admin' : 'Membro'}</text>
                        </div>
                        <div className='icon-more'>
                            <AiOutlineUserAdd className='icon-add' onClick={() => setNewForum(true)} />
                            <IoAdd className='icon-add' onClick={() => setNewGroup(true)} />
                        </div>
                    </div>
                    <div className='div-search'>
                        <TextInput
                            onChange={onChangeSearch}
                            value={value}
                            placeholder={placeholder}
                            icon={<span style={{ marginRight: 10 }}><FiSearch /></span>}
                        />
                    </div>
                    <span className='span-list'>Mensagens</span>
                </div>
                <div className='div-list'>
                    <ChannelList
                        channels={data}
                        className={'pn-channel teste'}
                        sort={(a, b) => a.name ? -1 : 0}
                        channelRenderer={item => {

                            return <ListChat
                                item={item}
                                loggedUser={loggedUser}
                                btns={btns}
                                onChannelSwitched={onChannelSwitched}
                                openConfig={openConfig}
                                channel={channel}
                                setOpenConfig={setOpenConfig}
                                setChatMessage={setChatMessage}

                            />
                           
                        }}
                    />
                </div>
            </div>
            <ModalForum
                title={'Novo fórum'}
                open={newForum}
                setOpen={setNewForum}
                onSave={onSaveNewForum}
                onChange={onChange}
                values={values}
                setValues={setValues}
            />
            <ModalGroup
                title={openConfig ? 'Editar grupo' : 'Novo grupo'}
                open={newGroup}
                setOpen={setNewGroup}
                onSave={openConfig ? editGroup : onSaveNewGroup}
                onChange={onChange}
                values={values}
                setValues={setValues}
                openConfig={openConfig}
                setOpenConfig={setOpenConfig}
            />
        </>
    )
}

const ListChat = ({ item, loggedUser, btns, onChannelSwitched, openConfig, channel, setOpenConfig,setChatMessage }) => {
    const isUnread = isUnreadMessage(item, loggedUser, item.nmGroup ? "ChatGroups" : "Chats",);
  

    useEffect(() => {
        let observe;

        if (item.id) {
            observe = observeChat(item.nmGroup ? 'ChatGroups' : 'Chats', item, setChatMessage);

        }
        return () => {
            observe && observe()
        }

    }, [item && item.lastMessage && item.lastMessage.date])

    
    return <div className={item.id === channel.id ? 'div-channel-list-activity' : 'div-channel-list'} onClick={() => onChannelSwitched(item)}>
        <div className={'div-row-itens'}>
            <img className='img-avatar' src={item.custom.thumb} />
            <div className='div-data-list'>
                <text className='txt-user'>{item.name}</text>
                <text className='last-message-bold' >{item.nmWork}</text>
                <div className='text-max'>
                    <text className={isUnread ? 'last-message-viewer' : 'last-message'}>{item.senderLastMessage}: {item.lastMessage.text}</text>
                    {isUnread && <AiOutlineMail className='icon-isUnread' />}
                </div>
            </div>
        </div>
        <div class={openConfig && openConfig.id === item.id ? null : "description"} onClick={() => setOpenConfig(item)}> <AiOutlineDown className={'icon-down-black'} /></div>
        {openConfig && openConfig.id === item.id && <PickerList
            className={'edit-picker'}
            loggedUser={loggedUser}
            isOpen={true}
            data={item}
            arrayBtn={btns}
        />}
    </div>
}
