import React, { Component, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import WorksCompanyContainer from './WorksCompanyContainer';
import WorksToolbarContainer from './WorksToolbarContainer';
import { getCompaniesData } from '../../core/redux-store/company/Actions';
import { getWorksData } from '../../core/redux-store/works/Actions';
import WorksEmptyView from '../../views/works/WorksEmptyView';
import { CircularProgress } from '@material-ui/core';
import AdminNavbar from '../../components/Navbars/AdminNavbar';
import { BreadcrumbHeader } from '../../components/Breadcrumb/Breadcrumb';
import sort from 'fast-sort'
import { getGroupData, getAllGroupData } from '../../core/redux-store/group/Actions';
import GenericMaterialTable from '../../components/GenericMaterialTable';
import { WorkCard } from '../../layoutV2/components/Company';
import { companyRole } from '../../core/user/hooks';
import { EditButton, AddButton } from '../../components/Button/Button';
import { setLoginData } from '../../core/redux-store/login/Actions';

let canReload = true;

export const setCanReload = (reload) => {
    canReload = reload;
}

export const useReload = (entity) => {
    const dispatch = useDispatch();
    const reload = useSelector(state => state.login[`reload-${entity}`])

    const reloadData = () => {
        canReload = true;
        dispatch(setLoginData({ [`reload-${entity}`]: !reload }))
    }

    return {
        reload, reloadData
    };
}

function mapStateToProps(state) {
    return {
        isLoading: state.company.isLoading,
        companies: state.company.items,
        works: state.works.items,
        isAdmin: state.login.isAdmin,
        groups: state.login.groups,
        network: state.login.network,
        user: state.login,
    };
}

const WorksContainer = (props) => {
    const { isLoading, history, companies, works } = props;
    const [state, setState] = useState({})

    useEffect(() => {
        if (canReload) {
            canReload = false
            onFetch({});
        }
    }, [])

    function onFetch(filter) {
        const { dispatch, groups, isAdmin, user, network } = props;

        dispatch(getWorksData({ filter, groups, isAdmin, email: user.email }));
        dispatch(getCompaniesData({ groups, isAdmin, workGroup: network }));

        if (isAdmin) {
            dispatch(getAllGroupData())
        }
        setCanReload(true)
    }

    function onFilter(field, value) {
        //     if (field === "name") {
        //         let works = this.props.works.filter(item => item[field].toLowerCase().includes(value.toLowerCase()));
        //         const idCompanies = [...new Set(works.map(work => work.idCompany))];
        //         const companies = this.props.companies.filter(item => item[field].toLowerCase().includes(value.toLowerCase()) || idCompanies.includes(item.id));

        //         if (works.length === 0 && companies.length > 0) {
        //             const idSetCompanies = [...new Set(companies.map(company => company.id))];
        //             works = this.props.works.filter(work => idSetCompanies.includes(work.idCompany));
        //         }

        //         this.setState({ companies, works });
        //     } else if (field === "state") {
        //         const filter = value !== 0 ? { [field]: value } : {};
        //         this.onFetch(filter);
        //     } else if (field === "adminWorkGroup") {
        //         const workGroupsId = value.map(item => item.value)
        //         this.setState({ [field]: workGroupsId.length > 0 ? workGroupsId : undefined });
        //         const filter = value !== 0 ? { "workGroup": workGroupsId.length > 0 ? workGroupsId : undefined } : {};
        //         this.onFetch(filter);
        //     }
    }

    const goToCompany = (rowData) => {
        history.push("/admin/company-details", { id: rowData.id });
    }

    const goToWork = (state) => {
        history.push("/admin/works-details", state);
    }

    const { workGroup } = state;

    const columns = [
        {
            hidden: true, field: "term", customSearch: (rowData, field, term) => {
                const worksCompany = works.filter(item => item.idCompany === rowData.id && `${item.name}`.toLowerCase().includes(`${term}`.toLowerCase()))
                console.log({ rowData, field, term, works, worksCompany, rowDataField: rowData[field] })
                return `${rowData.name}`.toLowerCase().includes(`${term}`.toLowerCase()) || worksCompany.length > 0;
            }
        },
        {
            title: "Nome", field: "name", defaultSort: 'asc', filtered: true, defaultSearch: true,
            // customSearch: (rowData, field, term) => {
            //     console.log({ rowData, field, term, works })
            //     const worksCompany = works.filter(item => item.idCompany === rowData.id && `${item.name}`.toLowerCase().includes(`${term}`.toLowerCase()))
            //     return `${rowData[field]}`.toLowerCase().includes(`${term}`.toLowerCase()) || worksCompany.length > 0;
            // },
            render: (rowData) => {
                const worksCompany = works.filter(item => item.idCompany === rowData.id);
                return (
                    <div className="work-row">
                        <div className="img" style={{ background: `url('${rowData.file && rowData.file.url}') no-repeat center` }} />
                        <div className="work-col">
                            <h5>{rowData.name}</h5>
                            <p>{`${worksCompany.length ? worksCompany.length : "Nenhuma"} ${worksCompany.length > 1 ? "obras cadastradas" : "obra cadastrada"}`}</p>
                        </div>
                    </div>
                )
            }
        }
    ]

    return (
        <div className="content">
            <AdminNavbar>
                <WorksToolbarContainer onFilter={onFilter} adminWorkGroup={workGroup} />
            </AdminNavbar>
            <div className="company-works-list">
                <GenericMaterialTable
                    title=""
                    columns={columns}
                    idTable={"works-list"}
                    options={{
                        paging: false,
                        search: true
                    }}
                    data={companies}
                    detailPanel={rowData => {
                        const companyWorks = works.filter(item => item.idCompany === rowData.id)
                        return companyWorks.length > 0 ? <div className="details-works">{companyWorks.map(work => <WorkCard company={rowData} {...work} />)}</div> : <WorksEmptyView message="Não há itens para mostrar" />
                    }}
                    actions={[
                        rowData => ({
                            icon: (iconProps) => companyRole(props.user, rowData.id) ? <EditButton {...iconProps} /> : <div />,
                            onClick: (_event, rowData) => companyRole(props.user, rowData.id) && goToCompany(rowData)
                        }),
                        rowData => ({
                            icon: (iconProps) => companyRole(props.user, rowData.id) ? <AddButton {...iconProps} /> : <div />,
                            onClick: (_event, rowData) => companyRole(props.user, rowData.id) && goToWork({ idCompany: rowData.id })
                        })
                    ]}
                />
                {/* {!isLoading && companies.length === 0 && <WorksEmptyView message="Não existem itens para mostrar" />} */}
                {/* {companies.map(company => <WorksCompanyContainer {...company} collapse={collapseIds.includes(company.id)} onToggle={this.onToggle} works={works.filter(work => work.idCompany === company.id)} />)} */}
                {isLoading && <div className="works-progress"><CircularProgress /></div>}
            </div>
        </div>
    );
}

export default connect(
    mapStateToProps,
)(WorksContainer);