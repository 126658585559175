import { getItem, addItem, deleteItem, updateItem, execute } from './firebaseFunctions';
import { db } from './firebaseConfig';
import { getLib } from 'core/database/lib';

export const getInspection = async (idWork, id) => {
    const table = db.collection(`Works/${idWork}/inpections`).doc(id)
    return execute(table).then(items => {
        return items[0];
    });
}

export const getInspections = async (idWork) => {
    return getItem(`Works/${idWork}/inpections`);
}

export const addInspection = async (idWork, item) => {
    return addItem(`Works/${idWork}/inpections`, item);
}

export const deleteInspection = async (idWork, id) => {
    return deleteItem(`Works/${idWork}/inpections`, id);
}

export const updateInspection = async (idWork, item) => {
    return updateItem(`Works/${idWork}/inpections`, item);
}

export const addImageInspection = async (idWork, idInspection, item) => {
    return addItem(`Works/${idWork}/inpections/${idInspection}/images`, item);
}

export const updateImageInspection = async (idWork, idInspection, item) => {
    return updateItem(`Works/${idWork}/inpections/${idInspection}/images`, item);
}

export const getImagesInspection = async (idWork, idInspection) => {
    const table = db.collection(`Works/${idWork}/inpections/${idInspection}/images`);
    return execute(table);
}

export const addLinkedImageInspection = async (idCompany, idWork, idInspection, item) => {
    return addItem(`WorkData`, {idCompany, idWork, idInspection, ...item});
    // return addItem(`Works/${idWork}/inpections/${idInspection}/linked`, item);
}

export const deleteLinkedImageInspection = async (item) => {
    return deleteItem(`WorkData`, item);
}

export const updateLinkedImageInspection = async (idWork, idInspection, item) => {
    return updateItem(`WorkData`, {...item});
    // return updateItem(`Works/${idWork}/inpections/${idInspection}/linked`, item);
}

export const getLinkedImagesInspection = async (idWork, idInspection) => {
    return getItem(`WorkData`, { idWork, idInspection })
    // const table = db.collection(`Works/${idWork}/inpections/${idInspection}/linked`)
    // return execute(table);
}

export const getCountRecurrencyCompany = async (idCompany, data) => {
    return getItem(`WorkData`, { idCompany }).then(items => {
        const arrData = [];
        const commentIds = [...new Set(items ? items.map(item => item.commentId) : [])];
        const idsInSection = [...new Set(data ? data.map(item => item.commentId) : [])];

        const promises = commentIds.map(async id => {
            if(id && idsInSection.includes(id)){
                const countCompany = items.filter(item => item.commentId === id).length;
                const count = data.filter(item => item.commentId === id).length;
    
                if(count > 1){
                    const comment = await getLib({id});
                    arrData.push({
                        id,
                        ...comment[0],
                        countCompany,
                        count
                    })
                }
            }
        })

        return Promise.all(promises).then(() => {
            return arrData;
        })
    })
    // const table = db.collection(`Works/${idWork}/inpections/${idInspection}/linked`)
    // return execute(table);
}