import React from "react";
import { Row, Button } from "reactstrap";
import AdminNavbar from '../../components/Navbars/AdminNavbar';
import { BreadcrumbHeader } from '../../components/Breadcrumb/Breadcrumb';
import CompanyDetailsCard from "./CompanyDetailsCard";

const routeData = [
	{
		name: "Início",
		active: true,
		route: "/admin/dashboard"
	},
	{
		name: "Empresas e Obras",
		active: true,
		route: "/admin/works"
	},
	{
		name: "Detalhes"
	}
]

class CompanyDetailsView extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<>
				<div className="content">
					<AdminNavbar goBack={this.props.onBack}>
						<BreadcrumbHeader data={routeData} />
					</AdminNavbar>
					<CompanyDetailsCard
							editable={this.props.editable}
							options={this.props.options}
							{...this.props}
					/>
				</div>
			</>
		);
	}
}

export default CompanyDetailsView;
