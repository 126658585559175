import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AdvDetailsView } from '../views/advertising/AdvDetailsView';
import { updateSkillData } from "core/redux-store/skill/Actions";
import Alert from '../components/Alert/Alert';

const AdvDetailsContainer = (props) => {
    const dispatch = useDispatch();
    const [hasChange, setHasChange] = useState(false)

    function onBack() {
        // props.history.push("/admin/skill-survey")
        props.history.goBack()
    }

    function saveAdv(images) {
        const { id } = props.location.state;
        dispatch(updateSkillData({ id, images })).then(() => {
            Alert.success("Salvo com sucesso!")
        });
        setHasChange(false)
	}

    return (
        <AdvDetailsView
            onBack={onBack}
            hasChange={hasChange}
            setHasChange={setHasChange}
            saveAdv={saveAdv}
            {...props}
        />
    );
};

export default AdvDetailsContainer;