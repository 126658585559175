import React, { useEffect, useState } from 'react';
import { InspectionView } from '../../views/works/InspectionView';
import { getInspections, addInspection, deleteInspection, updateInspection } from '../../core/database/inspections';
import { scopeHasPower } from '../UserContainer';
import { useSelector } from 'react-redux';

const dataRouter = [
    {
        name: "Início",
        active: true,
        route: "/admin/dashboard"
    },
    {
        name: "Empresas e Obras",
        route: "/admin/works",
        active: true,
    }
]

let defaultView = 3;

const reload = (setData, data, newData) => {
    const index = data.findIndex(item => item.id === newData.id);
    if (index > -1) {
        const newArr = data.slice(0);
        newArr[index] = newData;
        setData(newArr);
    }
}

export const InspectionContainer = (props) => {
    // const isAdmin = useSelector(state => state.login.isAdmin);
    // const groups = useSelector(state => state.login.groups);
    const [isView, setIsView] = useState(defaultView);
    const user = useSelector(state => state.login)
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const router = dataRouter.concat([{
        name: props.location && props.location.state && props.location.state.work && props.location.state.work.name
    }]);

    useEffect(() => {
        setIsLoading(true)
        getInspections(props.location.state.workId).then(items => {
            setData(items);
            setIsLoading(false);
        })
    }, []);

    function onRowAdd(newData) {
        if (newData && newData.name) {
            return addInspection(props.location.state.workId, { ...newData, idUser: user.id, isQuality: isView === 4, nmUser: user.name, startDate: new Date() }).then(item => {
                setData(data.concat([item]));
            });
        } else {
            return Promise.all([])
        }
    }

    function onRowDelete(oldData) {
        return deleteInspection(props.location.state.workId, oldData.id).then(() => {
            setData(data.filter(item => item.id !== oldData.id));
        });
    }

    function onRowUpdate(newData, oldData) {
        const { name, id } = newData;
        if (name && id) {
            return updateInspection(props.location.state.workId, { name, id }).then(item => {
                // const dataAux = data.map(row => row.id === item.id ? ({ ...row, ...item }) : row)
                // console.log({ data, item, newData, oldData, dataAux })
                // const dataAux = ({ ...newData, tableData: oldData.tableData })
                // setData(dataAux); //data.concat([item])
                // return true;
                reload(setData, data, newData);
            });
        } else {
            return Promise.all([])
        }
    }

    function onImages(data) {
        props.history.push("/admin/works-image", {
            ...data,
            router,
            ...props.location.state,
        })
    }

    const setDefaultView = (view) => {
        defaultView = view;
    }

    const hasPower = user.isAdmin || props.location.state && props.location.state.company && scopeHasPower(props.location.state.company.id, user.groups);

    const goBack = () => {
        props.history.goBack()
    }
    
    return (
        <InspectionView
            title={"Inspeções"}
            comboOptions={[]}
            data={data}
            onRowAdd={onRowAdd}
            onRowUpdate={onRowUpdate}
            onRowDelete={onRowDelete}
            isLoading={isLoading}
            onImages={onImages}
            router={router}
            hasPower={hasPower}
            isView={isView}
            setIsView={setIsView}
            setDefaultView={setDefaultView}
            {...props}
            goBack={goBack}
        />
    );
};