import React from "react";
import CustomTable from "../components/CustomTable/CustomTable";
import { Row, Col, Spinner } from 'reactstrap';
import AdminNavbar from "../components/Navbars/AdminNavbar";
import { BreadcrumbHeader } from "../components/Breadcrumb/Breadcrumb";
import ModalNewGroup from "../components/Group/ModalNewGroup";
import { defaultColor } from "../variables/theme";
import GenericMaterialTable from "../components/GenericMaterialTable";
import { AddNavButton } from "../layoutV2/components/Button";
import { EditButton } from "../components/Button/Button";

const data = [
  {
    name: "Início",
    active: true,
    route: "/admin/dashboard"
  },
  {
    name: "Grupos"
  }
]

const Groups = (props) => {

  return (
    <>
      <div className="content lib-list">
        <AdminNavbar>
          {/* <BreadcrumbHeader data={data}  /> */}
          <AddNavButton label="Novo grupo" onClick={props.onAddClick} />
        </AdminNavbar>
        <ModalNewGroup open={props.open} setOpen={props.setOpen} onSave={props.onSave} comboWorkTypes={props.comboWorkTypes} />
        {props.loadingXLSX ? <div className="empty-xlsx">
          <Spinner color="warning" />
          <span>Aguarde alguns minutos enquanto geramos seu arquivo!</span>
        </div> : <GenericMaterialTable
          title="Grupos"
          idTable={"groups-list"}
          columns={[
            {
              title: 'Nome',
              field: 'name',
              editable: true,
              defaultSearch: true,
              cellStyle: {
                maxWidth: 230,
                minWidth: 230
              },
              defaultSort: 'asc',
              render: rowData => {
               
                return rowData && (
                  <Row className="row-user">
                    <Col className="details truncated" style={{ flex: 0, width: 120, minWidth: 80 }}><img src={rowData.file && rowData.file.url} style={{ width: 50, height: 50, minWidth: 50, borderRadius: '50%', backgroundColor: "#e3e3e3" }} /></Col>
                    <Col className="details truncated">{rowData.name}</Col>
                  </Row>
                )
              }
            },
          ]}
          actions={[
            {
              icon: (props) => <EditButton {...props} />,
              iconProps: {
                style: {
                  fontSize: 24,
                  color: defaultColor
                }
              },
              onClick: (_event, rowData) => props.onEditClick(rowData)
            }
          ]}
          {...props}
        />}
      </div>
    </>
  );
}

export default Groups;

/* {
  title: "Admin",
  field: "admin",
  editable: false,
  render: rowData => (
    <Row className="row-user">
      <Col className="details truncated">
        <Text>Aqui vai o modal</Text>
         <PlanCompanyModal {...rowData} onDataUpdate={this.props.onDataUpdate} scope={this.props.scope} />
      </Col>
    </Row>
  ),
}, */