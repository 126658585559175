import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
// import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import { QualityDetails } from '../../views/works/QualityDetails';
import { toDateFormat } from '../../views/works/InspectionView';
import { InspectionEnum } from '../../container/QualityContainer';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import { BiCheck, BiListCheck } from 'react-icons/bi'
import clsx from 'clsx';
import StepConnector from '@material-ui/core/StepConnector';
import { withStyles } from '@material-ui/core';

// function getSteps() {
//   return [{ label: 'Inspeção de início', subtitle: "01/08/2020" }, { label: 'Inspeção de início', subtitle: "02/08/2020" }, { label: 'Inspeção de início', subtitle: "teste" }];
// }

function getStepContent(step) {
  switch (step) {
    case 0:
      return `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`;
    case 1:
      return 'An ad group contains one or more ads which target a shared set of keywords.';
    case 2:
      return `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`;
    default:
      return 'Unknown step';
  }
}

const classes = {

}

const QontoConnector = withStyles({
  vertical: {
    marginLeft: 18, // half icon
    padding: '0 0 8px',
  },
})(StepConnector);

function ColorlibStepIcon(type) {
  // const classes = useColorlibStepIconStyles();

  // console.log({ type })
  const icons = {
    1: <BiCheck size={25} />,
    2: <BiListCheck size={25} />,
  };

  return (
    <div
      style={{
        backgroundColor: type === 1 ? '#E84E1B' : '#2A3F92',
        zIndex: 1,
        color: '#fff',
        width: 35,
        height: 35,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {icons[String(type)]}
    </div>
  );
}

export default function QualityStepper(props) {
  const { inspections, state, setReload, reload, id, isResponsible, removeInspection } = props
  
  if (!inspections.length) {
    return <div className={classes.root}>
      <div style={{ minHeight: 500, width: "100%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
        <span style={{ fontWeight: "bold", fontFamily: 'Manrope', fontSize: 16 }}>Não foram realizadas inspeções nesta atividade!</span>
      </div>
    </div>
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={inspections.length} orientation="vertical" connector={<QontoConnector />}>
        {inspections.map((inspection, index) => {
          // console.log({ props, inspection })
          const label = inspection.type === InspectionEnum.start ? "Inspeção de início" : "Inspeção de serviço"
          const model = Array.isArray(props.model) && props.model.find(item => item.value === inspection.modelId)
          const referenceLocal = props.allReferenceLocal.find(item => item.id === inspection.referenceLocalId)
          return (
            <Step key={label} className='see-around' >
              <StepLabel StepIconComponent={() => ColorlibStepIcon(inspection && inspection.type)}>
                <div className="title-step">{label}</div>
                <div className="subtitle-step">{toDateFormat(inspection.date)}</div>
              </StepLabel>
              <Typography className="description-step">
                <div style={{ marginTop: 5, marginBottom: 5 }}>
                  {inspection.step && inspection.step.label}
                  {inspection.referenceLocal && inspection.referenceLocal.name}
                  {model && model.label && <div className="subtitle-step bold">{model.label}</div>}
                  {referenceLocal && referenceLocal.name && <div className="subtitle-step">{referenceLocal.name}</div>}
                </div>
                {(inspections.length !== index) && <QualityDetails {...inspection} isResponsible={isResponsible} removeInspection={removeInspection} idActivity={id} state={state} setReload={setReload} reload={reload} model={model} referenceLocal={referenceLocal} />}
              </Typography>
            </Step>
          )
        })}
      </Stepper>
    </div>
  );
}