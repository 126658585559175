import React from "react";

// reactstrap components
import {
  Spinner,
  Container,
  Row,
  Col,
  Form
} from "reactstrap";
import { CardRegister } from 'components/Login/Login';

class Register extends React.Component {

  componentDidMount() {
    document.body.classList.toggle("register-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }

  render() {
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto col-login" lg="5" md="6" sm="10">
              {
                this.props.isLoading ? <Spinner color="warning" /> : <Form action="" className="form" method="">
                  <CardRegister {...this.props} />
              </Form>
              }
            </Col>
          </Row>
        </Container>
        <Col className="img-login-container" lg="5" md="6" sm="10" style={{
          background: `url("${require("../../assets/img/img-login.png")}")`
        }}/>
      </div>
    );
  }
}

export default Register;
