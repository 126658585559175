import React, { Component } from 'react';
import { CardTitle, Button } from 'reactstrap';

class WorksEmptyView extends Component {
    render() {
        return (
            <>
                <div className="works-company-empty">
                    <CardTitle tag="h5">{this.props.message}</CardTitle>
                </div>
            </>
        );
    }
}

export default WorksEmptyView;