import React, { useState, useEffect } from "react";
import { InspectionModel } from '../views/InspectionModel';
import { useDispatch, useSelector } from "react-redux";
import { getInspectionModelData, updateInspectionModelData, deleteInspectionModelData, addInspectionModelData, getAllInspectionModelData } from "../core/redux-store/inspectionModel/Actions";
import sort from 'fast-sort'
import ModalAlert from "../layoutV2/components/Modal/ModalAlert";

const InspectionModelContainer = (props) => {
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.inspectionModel.isLoading)
    const items = useSelector(state => state.inspectionModel.items)
    const network = useSelector(state => state.login.network)

    useEffect(() => {
        dispatch(getInspectionModelData({ fields: { workGroup: network }, standard: network !== "FvoPZCcXbTskLPIQjltC" && { workGroup: "FvoPZCcXbTskLPIQjltC" } }));
    }, [])

    const onRowDelete = (oldData) => {
        return dispatch(deleteInspectionModelData(oldData.id));
    }

    const onDeleteItem = (oldData) => {
        ModalAlert.danger({
            title: "Ficha de inspeção",
            text: "Deseja mesmo remover esta ficha? Ao realizar esta ação o item não poderá mais ser acessado e a ação não poderá ser revertida.",
            // onConfirm: () => this.onRowDelete(oldData)
            onConfirm: () => onRowDelete(oldData)
        })
    }

    /* const onRowUpdate = (newData, oldData) => {
        return dispatch(updateInspectionModelData(newData));
    } */

    const goToNewModel = () => {
        props.history.push("/admin/inspection-model-details");
    }

    const goToEditModel = (values) => {
        props.history.push("/admin/inspection-model-details", values);
    }

    sort(items).asc(u => u.name)
    return (
        <InspectionModel
            data={items}
            isLoading={isLoading}
            onEditClick={goToEditModel}
            onDeleteItem={onDeleteItem}
            goToNewModel={goToNewModel}
            network={network}
        />
    );
};

export default InspectionModelContainer;