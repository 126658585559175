import React, { createRef, useState } from 'react';
import Gallery from 'react-grid-gallery';
import DeleteGallery from '../components/DeleteLabel/DeleteGallery';
import { ModalEditImage } from '../components/ModalEditImage/ModalEditImage';
import { ButtonHideImage } from '../components/ButtonHideImage/ButtonHideImage';
import { toDate } from '../views/works/InspectionView';
import sort from "fast-sort"

const formatFiles = (files, props) => {
    let newFiles;
    newFiles = files.map(file => {
        const isImage = (/\.(gif|jpg|jpeg|tiff|png|svg)$/i).test(file.filename);

        if (!isImage) {
            let url = file.url;
            let urlDocument;

            if (/\.pdf|PDF$/.test(file.filename)) {
                urlDocument = require('assets/img/pdf.svg');
            } else if (/\.doc|docx|DOC|DOCX$/.test(file.filename)) {
                urlDocument = require('assets/img/doc.svg');
            }

            return {
                ...file,
                src: urlDocument ? urlDocument : `https://aelyjcyado.cloudimg.io/v7/${url}&h=500&org_if_sml=1`,
                thumbnail: urlDocument ? urlDocument : `https://aelyjcyado.cloudimg.io/v7/${url}&h=180&org_if_sml=1`,
                isSelected: file.isSelected,
                tags: [{ value: file.filename, title: file.filename }],
                caption: file.filename ? file.filename : undefined,
                thumbnailHeight: 300,
                // thumbnailWidth: 200,
                customOverlay: <div style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    width: "100%",
                    height: "100%",
                    padding: "2px",
                    pointerEvents: "none",
                }} >
                    {props.onChange && <ModalEditImage {...props} file={file} vertical />}
                    <DeleteGallery removeEvent={props.onRemove} file={file} />
                </div >,
            }
        } else {
            return {
                ...file,
                src: `https://aelyjcyado.cloudimg.io/v7/${file.url}&h=500&org_if_sml=1`,
                thumbnail: `https://aelyjcyado.cloudimg.io/v7/${file.url}&w=300&org_if_sml=1`,
                isSelected: file.isSelected,
                tags: [{ value: file.filename, title: file.filename }],
                caption: file.filename,
                // thumbnailHeight: 300,
                thumbnailWidth: 300,
                customOverlay: <div style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    width: "100%",
                    height: "100%",
                    padding: "2px",
                    pointerEvents: "none",
                }} >
                    {props.isComment && <ButtonHideImage {...props} file={file} />}
                    {props.onChange && <ModalEditImage {...props} file={file} vertical />}
                    <DeleteGallery removeEvent={props.onRemove} file={file} />
                </div >,
            }
        }
    })
    return newFiles
}

/* const onClickThumbnail = (index, images) => {
    let data = images
    if (images.length > 0) {
        data = images.find((image, i) => i === index)
    }

    if (/\.pdf|PDF|docx|DOC|DOCX|doc$/.test(data.filename)) {
        window.open(data.url, '_blank');
    }
} */

export const GalleryContainer = (props) => {
    const { images, onSelectItem } = props
    const [viewIndex, setViewIndex] = useState()

    const files = formatFiles(images, props);

    sort(files).asc(u => toDate(u.date))

    return (
        <Gallery
            images={files}
            onSelectImage={(index) => {
                onSelectItem(files[index])
                setViewIndex(-1)
            }}
            // onClickThumbnail={(index) => onClickThumbnail(index, images)}
            lightboxWillOpen={index => {
                let data = images
                if (images.length > 0) {
                    data = images.find((image, i) => i === index)
                }

                if (data && data.filename) {
                    if (/\.pdf|PDF|docx|DOC|DOCX|doc$/.test(data.filename)) {
                        window.open(data.url, '_blank');
                    }
                }
            }}
            currentImageWillChange={(index) => {
                console.log({ index });
                if (index != 0) {
                    setViewIndex(index)
                }
            }}
            thumbnailImageComponent={(imageProps) => {
                return imageProps.item.url && !imageProps.item.url.startsWith("https://") ? <div {...imageProps} style={{ display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center", width: 250, height: 300 }}><span>Esta imagem ainda não foi enviada para o servidor, verifique seu celular!</span></div>
                    : <img
                        className={viewIndex === imageProps.index ? "gallery-focus" : ""}
                        key={imageProps.item.id}
                        {...imageProps}
                        {...imageProps.item}
                    />
            }}
            lightBoxProps={{
                style: { transform: "rotate(2deg)" }
            }}
        />
    );
};