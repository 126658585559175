import React from 'react';
import DateRange from 'react-bootstrap-daterangepicker';
import { Input } from 'reactstrap';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';

export default class DateRangePicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props
        }

        this.onEvent = this.onEvent.bind(this);
    }

    onEvent(event, target) {
        const { onDateChange } = this.props;
        if (onDateChange) {
            onDateChange({ startDate: target.startDate, endDate: target.endDate });
        }
    }

    format() {
        const { startDate, endDate } = this.props;
        return moment(startDate, "DD-MM-YYYY").format("DD/MM/YYYY") + " - " + moment(endDate, "DD-MM-YYYY").format("DD/MM/YYYY");
    }

    render() {
        return (
            <DateRange
                {...this.props}
                onApply={this.onEvent}
                autoApply={true}
                locale={I18n[keyEnum.dateRangeLocale]}
            >
                <Input disabled={true} value={this.format()} placeholder="Selecione um período" />
            </DateRange>
        )
    }
}