import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import TagContainer from '../container/Libs/TagContainer';
import SkillContainer from "../container/SkillContainer.jsx";
import { BreadcrumbHeader } from '../components/Breadcrumb/Breadcrumb';
import AdminNavbar from '../components/Navbars/AdminNavbar';
import { ButtonGroup } from '../layoutV2/components/Button';
import { useSelector } from 'react-redux';

const data = [
    {
        name: "Início",
        active: true,
        route: "/admin/dashboard"
    },
    {
        name: "Configurações"
    }
]

let defaultIsTags = true;

export const Settings = (props) => {
    const hasFullPower = useSelector(state => state.login.isAdmin)
    const [isTags, setIsTags] = useState(hasFullPower && defaultIsTags || false);

    const onTagChange = (isTag) => {
        setIsTags(isTag);
        defaultIsTags = isTag;
    }

    const buttons = [
        hasFullPower && { label: "Tipos de documentos", id: true },
        { label: "Modelo EAP", id: false },
    ].filter(item => item)
    
    return (
        <>
            <div className="content skills-screen skills">
                <AdminNavbar>
                    <BreadcrumbHeader data={data} />
                </AdminNavbar>
                {/* <div className='button-header'>
                    <ButtonGroup size="sm">
                        <Button variant="dark" className='button-group' active={isTags} color="link" onClick={() => onTagChange(true)}>Tipos de documentos</Button>
                        <Button variant="dark" className='button-group' active={!isTags} color="link" onClick={() => onTagChange(false)}>Modelo EAP</Button>
                    </ButtonGroup>
                </div> */}
                <ButtonGroup buttons={buttons} onClick={value => onTagChange(value)} active={value => isTags === value} />
                {isTags ? <TagContainer {...props} /> : <SkillContainer {...props} titleList={"linkStep"} />}
            </div>
        </>
    );
};