import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../components/Alert/Alert';
import { UserProfile } from '../views/UserProfile'
import { addUserData, updateUserData } from "../core/redux-store/user/Actions";
import { getScopeHasPower } from '../core/utils/Utils';

export const splitFullName = (name) => {
    var tmp = name.split(" ");
    return tmp[0] + "+" + tmp[tmp.length - 1];
}

const UserProfileContainer = (props) => {
    // console.log({ props })
    const { scope, data } = props
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [avatar, setAvatar] = useState()
    const [values, setValues] = useState({})
    const [error, setError] = useState("")

    useEffect(() => {
        if (data && data.email && data.name) {
            loadData()
        }
    }, [open])

    const loadData = async () => {
        console.log({ data })
        const { email, name, groups, id, regProf } = data
        const hasPower = await getScopeHasPower(data, scope);
        setValues({ email, name, hasPower, groups, regProf, id })
        setAvatar(data.avatar)
    }

    const toggle = () => {
        setOpen(open => !open)
        setAvatar()
        setValues({})
        setError("")
    }

    const onFileChange = (avatar) => {
        setAvatar(avatar);
    }

    const onChange = (field, value) => {
        setValues(values => ({ ...values, [field]: value }))
    }

    const onVerify = () => {
        const { email, name, hasPower, regProf } = values
        if (!name) {
            setError("Nome é obrigatório!")
        } else if (!email) {
            setError("E-mail é obrigatório!")
        } else if (!regProf) {
            setError("Registro profissional é obrigatório!")
        } else if (!hasPower && hasPower !== 0 && hasPower !== false) {
            setError("Permissão é obrigatória!")
        } else {
            return true
        }
        return false

        /* 
         else if (!avatar) {
            setError("Imagem é obrigatória!")
        } 
        */
    }

    const onClickButton = () => {
        const { email, name, hasPower, groups, id, regProf } = values
        // console.log({ newData: values, scope, email, name, hasPower })
        let extraData = {}
        if (avatar) {
            extraData["avatar"] = avatar
        }
        // console.log({ newData: { email, name, groups, regProf, id, ...extraData }, scope, hasPower })
        if (onVerify()) {
            if (values && values.id) {
                console.log("update", { newData: { email, name, groups, id, regProf, ...extraData }, scope, hasPower })
                dispatch(updateUserData({ newData: { email, name, groups, id, regProf, ...extraData }, scope, hasPower })).then(user => {
                    // console.log({ user })
                    Alert.success('Usuário atualizado com sucesso!');
                    toggle()
                }).catch(error => {
                    Alert.error(error.message);
                })
            } else {
                // console.log("add")
                dispatch(addUserData({ newData: { email, name, regProf, ...extraData }, scope, hasPower })).then(user => {
                    // console.log({ user })
                    if (user.isInvite) {
                        Alert.success('Convite enviado para: ' + user.email);
                    } else {
                        Alert.success('Usuário adicionado com sucesso!');
                    }
                    toggle()
                }).catch(error => {
                    Alert.error(error.message);
                })
            }
        }
    }

    return (
        <UserProfile
            {...props}
            open={open}
            setOpen={setOpen}
            toggle={toggle}
            avatar={avatar}
            onFileChange={onFileChange}
            onChange={onChange}
            values={values}
            onClickButton={onClickButton}
            error={error}
        />
    );
};

export default UserProfileContainer;