import React, { useState } from 'react';
import { Search } from '@material-ui/icons';
import { Input } from 'reactstrap';
import DateYearSelect from '../DateRange/DateYearSelect';
import TagPicker from '../TagPicker/TagPicker';
import { DatePicker } from '../DateRange/DatePicker';

let filterTimeout;

const onFilterAction = (action) => {
  if (filterTimeout) {
    clearTimeout(filterTimeout);
  }

  filterTimeout = setTimeout(() => {
    action();
  }, 500)
}

export const FilterSearch = ({ onFilterSearch, value }) => {
  return (
    <div className="filter-search">
      <div className="input-form">
        <Search className="icon-search" />
        <Input
          placeholder="Pesquisar"
          defaultValue={value}
          onChange={e => {
            const value = e.target.value;
            onFilterAction(() => onFilterSearch("name", value))
          }}
        />
      </div>
    </div>
  )
}

export const FilterPicker = ({ onFilter, value, comboOptions, placeholder, field = "", hasSort = true, multiple = true, disabled = false }) => {
  return (
    <TagPicker
      comboOptions={comboOptions}
      placeholder={placeholder}
      onFilter={(values) => onFilter(field, values)}
      value={value}
      multiple={multiple}
      disabled={disabled}
      hasSort={hasSort}
    />
  )
}

export const FilterYear = ({ onDateChange, dates }) => {
  return (
    <DateYearSelect
      onDateChange={onDateChange}
      placeholder="Filtrar por ano"
      dates={dates}
    />
  )
}

export const FilterDate = ({ style = {}, onDateChange, startDate, endDate, disabled = false }) => {
  return (
    <DatePicker
      opens={'left'}
      onDateChange={onDateChange}
      startDate={startDate}
      endDate={endDate}
      disabled={disabled}
      style={style}
    />
  )
}

/* export const FilterTag = ({ onFilter, value, comboOptions }) => {
  return (
    <TagPicker
      comboOptions={comboOptions}
      placeholder="Filtrar por tag"
      onFilter={onFilter}
      value={value}
      multiple={true}
    />
  )
} */

/* export const FilterStep = ({ onFilter, value, comboOptions }) => {
  return (
    <TagPicker
      comboOptions={comboOptions}
      placeholder="Filtrar por etapa"
      onFilter={(values) => onFilter("step", values)}
      value={value}
      multiple={true}
    />
  )
} */

/* export const FilterImpact = ({ onFilter, value, comboOptions }) => {
  return (
    <TagPicker
      comboOptions={comboOptions}
      placeholder="Filtrar por impacto"
      onFilter={(values) => onFilter("impact", values)}
      value={value}
      multiple={true}
      hasSort={false}
    />
  )
} */

/* export const FilterState = ({ onFilter, value, comboOptions }) => {
  return (
    <TagPicker
      comboOptions={comboOptions}
      placeholder="Filtrar por estado"
      onFilter={(values) => onFilter("state", values)}
      value={value}
      multiple={true}
    />
  )
} */

/* export const FilterInspection = ({ onFilter, value, comboOptions }) => {
  return (
    <TagPicker
      comboOptions={comboOptions}
      placeholder="Selecionar vistorias"
      onFilter={(values) => onFilter("inspection", values)}
      value={value}
      multiple={true}
    />
  )
} */

/* export const FilterOrdination = ({ onFilter, value, comboOptions }) => {
  return (
    <TagPicker
      comboOptions={comboOptions}
      placeholder="Agrupar por"
      onFilter={(values) => onFilter("ordination", values)}
      value={value}
      multiple={false}
    />
  )
} */