import React from 'react';
import { Button } from 'reactstrap';
import { MdAddCircleOutline } from 'react-icons/md';


/*

Botão com cor primária

*/

export const PrimaryButton = ({ onClick, label, disabled = false }) => {
    return <Button className="v2-primary-btn v2-button-text"
        block
        disabled={disabled}
        color="primary"
        onClick={onClick}
    >
        {label}
    </Button>;
}

/*

Botão para adicionar itens

*/

export const AddNavButton = ({ label, onClick }) => {
    return <a className="v2-button-text v2-add-icon"
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick(e)
        }}
        href="#"
    >
        <div className="icon-container">
            <MdAddCircleOutline />
        </div>
        {label}
    </a>;
}

/*

Botões para salvar

*/

export const OutlineButton = ({ label = "Salvar", onClick, disabled = false, style = {}, className = "", id = "button" }) => {
    return <Button className={`v2-secondary-btn v2-button-text ${className}`} outline color="secondary" onClick={onClick} disabled={disabled} style={style} id={id}>{label}</Button>
}

export const SaveButton = ({ label = "Salvar", onClick, disabled = false, style = {}, className = "" }) => {
    return <Button className={`v2-primary-btn v2-button-text ${className}`} color="primary" onClick={onClick} disabled={disabled} style={style}>{label}</Button>
}

const GroupButton = ({ label, id, active, onClick, color = "", className = "", subvalue = undefined }) => {
    return <a className={`button ${className} ${active(id, subvalue) && "active"} ${color && `${color}`}`} onClick={() => onClick(id, subvalue)}>{label}</a>
}

export const ButtonGroup = ({ buttons, onClick, active, className = "" }) => {
    return <div className={`v2-button-group ${className}`}>
        <div className="border-group">
            {buttons.map(item => <GroupButton {...item} active={active} onClick={onClick} />)}
        </div>
    </div>
}