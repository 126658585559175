import React, { useState, useEffect } from "react";
import FilterDashboardQualityContainer from "./FilterDashboardQualityContainer";
import { getItem } from "../core/database/firebaseFunctions";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { getWorksData } from "../core/redux-store/works/Actions";
import { getCompaniesData } from "../core/redux-store/company/Actions";
import { getWorkReferenceLocals, getWorkTasks } from '../core/database/works'
import _ from 'lodash'
import sort from 'fast-sort'
import { getActivityWithUID, getWorksActivity, getActivity } from "../core/database/activity";
import { getInspectionModel } from "../core/database/inspectionModel";
import DashboardQualityView from "../views/dashboardQuality/DashboardQualityView";

const defaultFilter = {
    dates: {}
}

const getFilteredData = (data, filter) => {
    return data.filter(item => doFilter(item, filter))
}

const doFilter = (rowData, filter) => {

    let hasSteps = true;
    if (filter.step) {
        const arrSteps = Array.isArray(filter.step) ? filter.step.map(item => {
            return item.value
        }) : [filter.step.value]

        hasSteps = filter.step.length == 0 || (rowData.stepId && arrSteps.includes(rowData.stepId));
    }
    let hasActivity = true;
    if (filter.activity) {
        const arrActivity = Array.isArray(filter.activity) ? filter.activity.map(item => {
            return item.value
        }) : [filter.activity.value]

        hasActivity = filter.activity.length == 0 || (rowData.activityUID && arrActivity.includes(rowData.activityUID));
    }
    let hasLocal = true;
    if (filter.local) {
        const arrLocal = Array.isArray(filter.local) ? filter.local.map(item => {
            return item.value
        }) : [filter.local.value]

        hasLocal = filter.local.length == 0 || (rowData.referenceLocalId && arrLocal.includes(rowData.referenceLocalId));
    }
    let hasModel = true;
    if (filter.model) {
        const arrModel = Array.isArray(filter.model) ? filter.model.map(item => {
            return item.value
        }) : [filter.model.value]
        hasModel = filter.model.length == 0 || (rowData.modelId && arrModel.includes(rowData.modelId));
    }

    return hasSteps && hasActivity && hasLocal && hasModel;
}

const getFilteredActivity = (data, filter) => {
    return data.filter(item => doActivity(item.model, filter))
}

const doActivity = (rowData, filter) => {
    let hasModel = true;
    if (filter.model) {
        const arrModel = Array.isArray(filter.model) ? filter.model.map(item => {
            return item.value
        }) : [filter.model.value]
        // for (let i = 0; i < rowData.length; i++) {
        //     hasModel = filter.model.length == 0 || (rowData[i].value && arrModel.includes(rowData[i].value))
        // }
        hasModel = filter.model.length == 0 || rowData.find(({ value }) => arrModel.includes(value))
    }
    return hasModel
}

const DashboardQualityContainer = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const companies = useSelector(state => state.company.items)
    const works = useSelector(state => state.works.items)
    const user = useSelector(state => state.login)
    const [data, setData] = useState([])
    const [allTasks, setAllTasks] = useState([])
    const [allLocal, setAllLocal] = useState([])
    const [allModel, setAllModel] = useState([])
    const [activities, setActivities] = useState([])
    const [filter, setFilter] = useState(defaultFilter)

    useEffect(() => {
        if (companies && companies.length === 0) {
            dispatch(getCompaniesData({ groups: user.groups, isAdmin: user.isAdmin, workGroup: user.network }));
        }
        if (works && works.length === 0) {
            dispatch(getWorksData({ groups: user.groups, isAdmin: user.isAdmin, email: user.email }));
        }
    }, [companies && companies.length === 0 || works && works.length === 0])

    useEffect(() => {
        loadQualityInspectionData()
    }, [filter && filter.dates, filter && filter.work, filter && filter.companies])

    useEffect(() => {
        loadActivitiesAndTasks()
    }, [filter && filter.work])

    useEffect(() => {
        loadModels()
    }, [activities && activities.length > 0])

    useEffect(() => {
        loadLocals()
    }, [filter && filter.activity])

    const loadActivitiesAndTasks = async () => {
        setLoading(true);

        if (filter && filter.work && filter.work.length > 0) {
            const promiseActivity = []
            const promiseTask = []

            filter.work.map(work => {
                promiseTask.push(getWorkTasks(work.value))
                promiseActivity.push(getActivity({ workId: work.value }))
                return work
            });

            // Tasks
            const allTasks = await Promise.all(promiseTask).then(items => items.flat()).catch(() => ([]))
            const allInOne = allTasks.map(task => task.data).flat()
            const uniqueTasks = _.uniqWith(allInOne, function (arrVal, othVal) {
                return arrVal.Name == othVal.Name && arrVal.UID == othVal.UID;
            })
            sort(uniqueTasks).by({
                asc: u => u.UID,
                comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
            });
            setAllTasks(uniqueTasks)

            // Activities
            const allActivities = await Promise.all(promiseActivity).then(items => items.flat()).catch(() => ([]))
            const newActivities = uniqueTasks.map(task => allActivities.find(activity => activity.UID === task.UID) ? { ...task, ...allActivities.find(activity => activity.UID === task.UID) } : undefined).filter(item => item)
            sort(newActivities).asc(u => u.UID)
            setActivities(newActivities)
        }

        setLoading(false)
    }

    /* const loadTasks = async () => {
        const promiseTask = []
        if (filter.work && filter.work.length > 0) {
            setLoading(true);
            filter.work.map(work => promiseTask.push(getWorkTasks(work.value)));
            const allTasks = await Promise.all(promiseTask).then(items => items.flat()).catch(() => ([]))
            const allInOne = allTasks.map(task => task.data).flat()

            const uniqueTasks = _.uniqWith(allInOne, function (arrVal, othVal) {
                return arrVal.Name == othVal.Name && arrVal.UID == othVal.UID;
            })
            sort(uniqueTasks).by({
                asc: u => u.UID,
                comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
            });
            setAllTasks(uniqueTasks)
            console.log({ uniqueTasks, allInOne })
            setLoading(false)
        }
    } */

    const loadLocals = async () => {
        if (filter.work && filter.work.length > 0 && filter.activity && filter.activity.length > 0) {
            const allLocals = await getWorkReferenceLocals(filter.work.map(item => `"${item.value}"`).filter(item => item), filter.activity.map(item => `"${item.value}"`).filter(item => item))
            setAllLocal(allLocals)
        }
    }

    const loadModels = async () => {
        const promiseModel = []
        if (filter.work && filter.work.length > 0/*  && filter.activity && filter.activity.length > 0 */) {
            // const allActivities = await getActivityWithUID(filter.work.map(item => `"${item.value}"`).filter(item => item), filter.activity.map(item => `"${item.value}"`).filter(item => item))
            // const allActivities = activities.filter(item => filter.activity.filter(row => row.value === item.UID).length > 0)
            const allActivityModel = activities.map(item => item.model).flat()
            const modelIds = allActivityModel.map(item => typeof item === 'string' ? item : item.value)
            const uniqueModelIds = [...new Set(modelIds)]
            uniqueModelIds.map(id => promiseModel.push(getInspectionModel({ fields: { id } })))
            const allModels = await Promise.all(promiseModel).then(items => items.flat()).catch(() => ([]))
            setAllModel(allModels)
        }
    }

    const loadQualityInspectionData = async () => {
        const values = {}
        if (filter.dates && filter.dates.startDate && filter.dates.endDate) {
            values['date'] = [
                { operator: ">=", value: moment(filter.dates.startDate, 'DD-MM-YYYY').toDate() },
                { operator: "<=", value: moment(filter.dates.endDate, 'DD-MM-YYYY').toDate() }
            ]
        }
        if (filter.company) {
            setLoading(true);
            let companies = []
            companies = [filter.company.value]
            if (Array.isArray(filter.company)) {
                companies = filter.company.map(item => item.value);
            }
            const promises = []
            companies.map(idCompany => {
                if (idCompany && filter.work) {
                    filter.work.map(work => promises.push(getItem('QualityInspection', { ...values, workId: work.value }).catch(e => { console.log({ e }) })));
                }
            })
            const newData = await Promise.all(promises).then(items => items.flat()).then(items => items.filter(item => item)).catch(() => ([]))
            setData(newData)
            setLoading(false)
        }
    }

    return (
        <>
            <div className="content skills-screen skills">
                <AdminNavbar goBack={() => props.history.goBack()} />
                <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                    {/* <TutorialDashboardView filter={filter} /> */}
                    <FilterDashboardQualityContainer companies={companies} works={works} setFilter={setFilter} filter={filter} data={data} allTasks={allTasks} allLocal={allLocal} allModel={allModel} />
                    <DashboardQualityView data={getFilteredData(data, filter)} filter={filter} loading={loading} activities={getFilteredActivity(activities, filter)} />
                </div>
            </div>
        </>
    )
}

export default DashboardQualityContainer