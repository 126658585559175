import React from 'react';
import { DashboardOutlined, BusinessOutlined, GroupOutlined, WidgetsOutlined, LibraryBooksOutlined, DomainDisabledOutlined, HelpOutline, LabelOutlined, BuildOutlined,MessageOutlined, CommentOutlined, DescriptionOutlined } from '@material-ui/icons';

import LoginContainer from "container/LoginContainer.jsx";
import TermsContainer from "container/TermsContainer.jsx";
import RegisterContainer from "container/RegisterContainer.jsx";
import SkillContainer from "container/SkillContainer.jsx";
import LibContainer from "container/LibContainer.jsx";
import LibDetailsContainer from './container/LibDetailsContainer';
import EditPdfWizard from './views/pdf/EditPdfWizard';
import UsageTips from './views/UsageTips';
import WorksContainer from "container/Works/WorksContainer.jsx";
import TagContainer from './container/Libs/TagContainer';
import CompanyDetailsContainer from './container/Company/CompanyDetailsContainer';
import WorksDetailsContainer from './container/Works/details/WorksDetailsContainer';
import { InspectionContainer } from './container/Works/InspectionContainer';
import { WorkContainer } from './container/Works/WorkContainer';
import AdvertisingContainer from './container/AdvertisingContainer';
import AdvDetailsContainer from './container/AdvDetailsContainer';
import SettingsContainer from './container/SettingsContainer';
import ListCommentContainer from './container/ListCommentContainer';
import CommentDetailsContainer from './container/CommentDetailsContainer';
import BlankDashboard from './views/BlankDashboard';
import RecoveryContainer from './container/RecoveryContainer';
import DashboardContainer from './container/DashboardContainer'
import DashboardQualityContainer from './container/DashboardQualityContainer'
import MenuDashboardContainer from './container/MenuDashboardContainer'
import GroupContainer from './container/GroupContainer';
import SelectGroupContainer from './container/SelectGroupContainer';
import QualityContainer from './container/QualityContainer';
import InspectionModelContainer from './container/InspectionModelContainer';
import InspectionModelDetailsContainer from './container/InspectionModelDetailsContainer';
import ConfirmReadContainer from './container/ConfirmReadContainer';
import ChatContainer from './container/ChatContainer';

export const loginRoute = "/auth/login";
export const registerRoute = "/auth/login";
export const homeRoute = "/admin/dashboard";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "dashboard",
    iconComponent: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18 11H13C11.8954 11 11 11.8954 11 13V18C11 19.1046 11.8954 20 13 20H18C19.1046 20 20 19.1046 20 18V13C20 11.8954 19.1046 11 18 11ZM13 18V13H18V18H13ZM9 2V18C9 19.1046 8.10457 20 7 20H2C0.89543 20 0 19.1046 0 18V2C0 0.89543 0.89543 0 2 0H7C8.10457 0 9 0.89543 9 2ZM7 2H2V18H7V2ZM18 0H13C11.8954 0 11 0.89543 11 2V7C11 8.10457 11.8954 9 13 9H18C19.1046 9 20 8.10457 20 7V2C20 0.89543 19.1046 0 18 0ZM13 7V2H18V7H13Z" fill="white" />
      </svg>
    ),
    component: (props) => <MenuDashboardContainer {...props} />,
    layout: "/admin"
  },
  {
    path: "",
    exact: true,
    name: "Dashboard",
    icon: "dashboard",
    iconComponent: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18 11H13C11.8954 11 11 11.8954 11 13V18C11 19.1046 11.8954 20 13 20H18C19.1046 20 20 19.1046 20 18V13C20 11.8954 19.1046 11 18 11ZM13 18V13H18V18H13ZM9 2V18C9 19.1046 8.10457 20 7 20H2C0.89543 20 0 19.1046 0 18V2C0 0.89543 0.89543 0 2 0H7C8.10457 0 9 0.89543 9 2ZM7 2H2V18H7V2ZM18 0H13C11.8954 0 11 0.89543 11 2V7C11 8.10457 11.8954 9 13 9H18C19.1046 9 20 8.10457 20 7V2C20 0.89543 19.1046 0 18 0ZM13 7V2H18V7H13Z" fill="white" />
      </svg>
    ),
    component: (props) => <MenuDashboardContainer {...props} />,
    layout: "/",
    hidden: true
  },
  {
    path: "/quality-dashboard",
    name: "Dashboard de Qualidade",
    icon: "dashboard",
    iconComponent: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18 11H13C11.8954 11 11 11.8954 11 13V18C11 19.1046 11.8954 20 13 20H18C19.1046 20 20 19.1046 20 18V13C20 11.8954 19.1046 11 18 11ZM13 18V13H18V18H13ZM9 2V18C9 19.1046 8.10457 20 7 20H2C0.89543 20 0 19.1046 0 18V2C0 0.89543 0.89543 0 2 0H7C8.10457 0 9 0.89543 9 2ZM7 2H2V18H7V2ZM18 0H13C11.8954 0 11 0.89543 11 2V7C11 8.10457 11.8954 9 13 9H18C19.1046 9 20 8.10457 20 7V2C20 0.89543 19.1046 0 18 0ZM13 7V2H18V7H13Z" fill="white" />
      </svg>
    ),
    component: (props) => <DashboardQualityContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/inspection-dashboard",
    name: "Dashboard de Inspeção",
    icon: "dashboard",
    iconComponent: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18 11H13C11.8954 11 11 11.8954 11 13V18C11 19.1046 11.8954 20 13 20H18C19.1046 20 20 19.1046 20 18V13C20 11.8954 19.1046 11 18 11ZM13 18V13H18V18H13ZM9 2V18C9 19.1046 8.10457 20 7 20H2C0.89543 20 0 19.1046 0 18V2C0 0.89543 0.89543 0 2 0H7C8.10457 0 9 0.89543 9 2ZM7 2H2V18H7V2ZM18 0H13C11.8954 0 11 0.89543 11 2V7C11 8.10457 11.8954 9 13 9H18C19.1046 9 20 8.10457 20 7V2C20 0.89543 19.1046 0 18 0ZM13 7V2H18V7H13Z" fill="white" />
      </svg>
    ),
    component: (props) => <DashboardContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/works",
    name: "Empresas e Obras",
    icon: "business",
    iconComponent: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.2941 22.5882H17.6V19.7492C18.2014 20.373 18.6516 20.7808 18.6801 20.8064C18.9487 21.0488 19.3572 21.0489 19.6258 20.8064C21.1234 19.4553 24 16.4009 24 14.2588C24 10.9379 20.7253 8.60711 17.6 9.66687V3.71765C17.6 3.32781 17.284 3.01176 16.8941 3.01176H14.7765V0.705882C14.7765 0.316047 14.4604 0 14.0706 0H8.04706C7.65722 0 7.34118 0.316047 7.34118 0.705882V3.01176H5.22353C4.83369 3.01176 4.51765 3.32781 4.51765 3.71765V10.5412H0.705882C0.316047 10.5412 0 10.8572 0 11.2471V23.2941C0 23.684 0.316047 24 0.705882 24H23.2941C23.684 24 24 23.684 24 23.2941C24 22.9043 23.684 22.5882 23.2941 22.5882ZM4.51765 13.5529H3.71765C3.32781 13.5529 3.01176 13.869 3.01176 14.2588C3.01176 14.6487 3.32781 14.9647 3.71765 14.9647H4.51765V16.5647H3.71765C3.32781 16.5647 3.01176 16.8808 3.01176 17.2706C3.01176 17.6604 3.32781 17.9765 3.71765 17.9765H4.51765V19.5765H3.71765C3.32781 19.5765 3.01176 19.8925 3.01176 20.2824C3.01176 20.6722 3.32781 20.9882 3.71765 20.9882H4.51765V22.5882H1.41176V11.9529H4.51765V13.5529ZM8.75294 1.41176H13.3647V3.01176H8.75294V1.41176ZM16.1882 22.5882H5.92941C5.92941 21.8499 5.92941 5.16626 5.92941 4.42353H16.1882V10.427C15.0439 11.3144 14.3059 12.702 14.3059 14.2588C14.3059 15.2858 14.939 16.5945 16.1882 18.1534V22.5882ZM15.7176 14.2588C15.7176 12.3646 17.2587 10.8235 19.1529 10.8235C21.0472 10.8235 22.5882 12.3646 22.5882 14.2588C22.5882 15.5514 20.6432 17.8702 19.1529 19.315C18.0988 18.293 15.7176 15.708 15.7176 14.2588Z" fill="white" />
        <path d="M9.36484 6.02356H8.23542C7.84559 6.02356 7.52954 6.33961 7.52954 6.72944C7.52954 7.11928 7.84559 7.43532 8.23542 7.43532H9.36484C9.75467 7.43532 10.0707 7.11928 10.0707 6.72944C10.0707 6.33961 9.75467 6.02356 9.36484 6.02356Z" fill="white" />
        <path d="M12.753 7.43532H13.8824C14.2722 7.43532 14.5883 7.11928 14.5883 6.72944C14.5883 6.33961 14.2722 6.02356 13.8824 6.02356H12.753C12.3632 6.02356 12.0471 6.33961 12.0471 6.72944C12.0471 7.11928 12.3632 7.43532 12.753 7.43532Z" fill="white" />
        <path d="M9.36484 9.03528H8.23542C7.84559 9.03528 7.52954 9.35133 7.52954 9.74116C7.52954 10.131 7.84559 10.447 8.23542 10.447H9.36484C9.75467 10.447 10.0707 10.131 10.0707 9.74116C10.0707 9.35133 9.75467 9.03528 9.36484 9.03528Z" fill="white" />
        <path d="M14.5883 9.74116C14.5883 9.35133 14.2722 9.03528 13.8824 9.03528H12.753C12.3632 9.03528 12.0471 9.35133 12.0471 9.74116C12.0471 10.131 12.3632 10.447 12.753 10.447H13.8824C14.2722 10.447 14.5883 10.131 14.5883 9.74116Z" fill="white" />
        <path d="M9.36484 12.047H8.23542C7.84559 12.047 7.52954 12.363 7.52954 12.7529C7.52954 13.1427 7.84559 13.4588 8.23542 13.4588H9.36484C9.75467 13.4588 10.0707 13.1427 10.0707 12.7529C10.0707 12.363 9.75467 12.047 9.36484 12.047Z" fill="white" />
        <path d="M9.36484 15.0588H8.23542C7.84559 15.0588 7.52954 15.3749 7.52954 15.7647C7.52954 16.1546 7.84559 16.4706 8.23542 16.4706H9.36484C9.75467 16.4706 10.0707 16.1546 10.0707 15.7647C10.0707 15.3749 9.75467 15.0588 9.36484 15.0588Z" fill="white" />
        <path d="M9.36484 18.0706H8.23542C7.84559 18.0706 7.52954 18.3866 7.52954 18.7764C7.52954 19.1663 7.84559 19.4823 8.23542 19.4823H9.36484C9.75467 19.4823 10.0707 19.1663 10.0707 18.7764C10.0707 18.3866 9.75467 18.0706 9.36484 18.0706Z" fill="white" />
      </svg>
    ),
    component: (props) => <WorksContainer {...props} />,
    layout: "/admin"
  },
  {
    path: "/library",
    name: "Biblioteca",
    icon: "library-books",
    iconComponent: (
      <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M20 2C21.1046 2 22 2.89543 22 4V16C22 17.1046 21.1046 18 20 18H2C0.89543 18 0 17.1046 0 16V2C0 0.89543 0.89543 0 2 0H8C9.12 0 9.83294 0.475451 10.549 1.37885C10.5688 1.40385 10.6171 1.46608 10.6706 1.535L10.6707 1.53515C10.7461 1.63236 10.8318 1.74283 10.8625 1.78082C10.8935 1.81925 10.9196 1.85103 10.9412 1.8773L10.9412 1.87732C11.0292 1.98428 11.0419 1.99978 11.0018 2H20ZM20 16V4L10.9946 3.99999C10.2765 3.99614 9.80869 3.65991 9.30583 3.03654C9.26027 2.98006 9.14958 2.83737 9.0692 2.73376L9.06919 2.73375L9.06905 2.73357C9.02706 2.67944 8.99336 2.636 8.9816 2.62116C8.60702 2.14857 8.38424 2 8 2H2V16H20ZM15.0586 7.76013C14.396 6.14531 12.8381 5 11 5C9.54731 5 8.2401 5.72349 7.43419 6.86245C6.00976 7.35772 5 8.73533 5 10.3333C5 12.3405 6.5931 14 8.6 14H13.8C15.586 14 17 12.5271 17 10.75C17 9.41917 16.207 8.25892 15.0586 7.76013ZM13.8 12H8.6C7.71634 12 7 11.2538 7 10.3333C7 9.41286 7.71634 8.66667 8.6 8.66667C8.64533 8.66667 8.69022 8.66863 8.7346 8.67248C9.06249 7.69845 9.95294 7 11 7C12.3255 7 13.4 8.11929 13.4 9.5C13.4 9.52391 13.3997 9.54774 13.399 9.57148C13.5244 9.52519 13.6594 9.5 13.8 9.5C14.4627 9.5 15 10.0596 15 10.75C15 11.4404 14.4627 12 13.8 12Z" fill="white" />
      </svg>
    ),
    component: (props) => <LibContainer {...props} />,
    layout: "/admin"
  },
  {
    path: "/comment",
    name: "Apontamentos",
    icon: "comment",
    iconComponent: (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0868 16L4 19.8042V16H2C0.89543 16 0 15.1046 0 14V2C0 0.89543 0.89543 0 2 0H18C19.1046 0 20 0.89543 20 2V14C20 15.1046 19.1046 16 18 16H10.0868ZM6 16.1958L9.5132 14H18V2H2V14H6V16.1958ZM13.2929 4.29289L9 8.58579L6.70711 6.29289L5.29289 7.70711L9 11.4142L14.7071 5.70711L13.2929 4.29289Z" fill="white" />
      </svg>
    ),
    component: (props) => <LibContainer isComment={true} {...props} />,
    layout: "/admin",
    // hasFullPower: true
  },
  {
    path: "/inspection-model",
    name: "Ficha de Inspeção",
    icon: "description",
    iconComponent: (
      <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.4142 0H2C0.89543 0 0 0.89543 0 2V20C0 21.1046 0.89543 22 2 22H16C17.1046 22 18 21.1046 18 20V5.58579L12.4142 0ZM2 2H10V6C10 7.10457 10.8954 8 12 8H16V20H2V2ZM12 6V2.41421L15.5858 6H12ZM12.2929 10.2929L8 14.5858L5.70711 12.2929L4.29289 13.7071L8 17.4142L13.7071 11.7071L12.2929 10.2929Z" fill="white" />
      </svg>
    ),
    component: (props) => <InspectionModelContainer {...props} />,
    layout: "/admin"
  },
  {
    path: "/chat",
    name: "Fórum",
    icon: "message",
    iconComponent: <MessageOutlined />,
    component: (props) => <ChatContainer {...props} />,
    layout: "/admin"
  },
  {
    path: "/groups",
    name: "Grupos",
    icon: "group",
    iconComponent: <GroupOutlined />,
    component: (props) => <GroupContainer {...props} />,
    layout: "/admin",
    hasFullPower: true
  },
  {
    path: "/settings",
    name: "Configurações",
    icon: "build",
    iconComponent: <BuildOutlined />,
    component: (props) => <SettingsContainer {...props} />,
    layout: "/admin",
    hasPower: true
  },
  // {
  //   path: "/usage-tips",
  //   name: "Dicas de Uso",
  //   icon: "help_outline",
  //   iconComponent: (
  //     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <path fillRule="evenodd" clipRule="evenodd" d="M10 20C11.7246 20 13.387 19.562 14.8595 18.7418L19.0947 19.0947L18.7418 14.8595C19.562 13.387 20 11.7246 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM14.115 16.8621L14.3916 16.6958L16.9053 16.9053L16.6958 14.3916L16.8621 14.115C17.603 12.8824 18 11.4715 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.4715 18 12.8824 17.603 14.115 16.8621ZM10.0003 14.9983C10.5528 14.9983 11.0007 14.5506 11.0007 13.9983C11.0007 13.4461 10.5528 12.9983 10.0003 12.9983C9.44786 12.9983 9 13.4461 9 13.9983C9 14.5506 9.44786 14.9983 10.0003 14.9983ZM9 12H11V11C11 11.0024 11.0047 10.9972 11.0154 10.9854C11.0417 10.9565 11.1039 10.888 11.2205 10.7955C11.3207 10.716 11.3517 10.6954 11.6048 10.535C12.4661 9.98899 13 9.03956 13 8C13 6.34315 11.6569 5 10 5C8.34315 5 7 6.34315 7 8H9C9 7.44772 9.44771 7 10 7C10.5523 7 11 7.44772 11 8C11 8.34732 10.8225 8.66287 10.534 8.84581C10.2102 9.05108 10.1564 9.08671 9.97749 9.22865C9.38274 9.70048 9 10.2627 9 11V12Z" fill="white"/>
  //     </svg>
  //   ),
  //   component: (props) => <UsageTips {...props} />,
  //   layout: "/admin"
  // },
  {
    path: "/works-inspection",
    name: "Inspeção",
    icon: "business",
    iconComponent: <BusinessOutlined />,
    component: (props) => <InspectionContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/works-image",
    name: "Imagens",
    icon: "business",
    iconComponent: <BusinessOutlined />,
    component: (props) => <WorkContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/lib-details",
    name: "Documento",
    icon: "widgets",
    iconComponent: <WidgetsOutlined />,
    component: (props) => <LibDetailsContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/inspection-model-details",
    name: "Ficha",
    icon: "widgets",
    iconComponent: <WidgetsOutlined />,
    component: (props) => <InspectionModelDetailsContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/comment-details",
    name: "Detalhes",
    icon: "widgets",
    iconComponent: <WidgetsOutlined />,
    component: (props) => <CommentDetailsContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/advertising-details",
    name: "Detalhes",
    icon: "widgets",
    iconComponent: <WidgetsOutlined />,
    component: (props) => <AdvDetailsContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/company-details",
    name: "Empresa",
    icon: "widgets",
    iconComponent: <WidgetsOutlined />,
    component: (props) => <CompanyDetailsContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/works-details",
    name: "Nova Obra",
    icon: "widgets",
    iconComponent: <WidgetsOutlined />,
    component: (props) => <WorksDetailsContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/select-group",
    name: "Selecione um grupo",
    icon: "widgets",
    iconComponent: <WidgetsOutlined />,
    component: (props) => <SelectGroupContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/quality",
    name: "Qualidade",
    icon: "group",
    iconComponent: <GroupOutlined />,
    component: (props) => <QualityContainer {...props} />,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/login",
    name: "Login",
    mini: "L",
    component: LoginContainer,
    layout: "/auth"
  },
  {
    path: "/termos",
    name: "Termos",
    mini: "L",
    component: TermsContainer,
    layout: "/auth"
  },
  {
    path: "/termos",
    name: "Termos",
    mini: "L",
    component: TermsContainer,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/recovery",
    name: "Recovery",
    mini: "L",
    component: RecoveryContainer,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    mini: "R",
    component: RegisterContainer,
    layout: "/auth"
  },
  {
    path: "/confirm-read",
    name: "Confirmação",
    mini: "L",
    component: ConfirmReadContainer,
    layout: "/auth",
  },
];

const subRoutes = routes.map(item => item.views).flat().filter(item => item);

export const allRoutes = routes.concat(subRoutes).filter(item => item.path);

export default routes;

/*
{
    path: "/survey",
    name: "Perícia",
    icon: "playlist_add_check",
    iconComponent: <PlaylistAddCheckOutlined/>,
    component: (props) => <SurveyContainer {...props}/>,
    layout: "/admin"
  },
*/
