import { groupReference } from 'core/database/user';
import Moment from 'moment';

const md5 = require('md5');

export function isEquivalent(a, b) {
    if(!a && !b){
        return true;
    }

    if((a && !b) || !a && b){
        return false;
    }

    if(typeof a === 'object' && typeof b === 'object'){
        // Create arrays of property names
        var aProps = Object.keys(a);
        var bProps = Object.keys(b);
    
        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length != bProps.length) {
            return false;
        }
    
        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];
    
            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
    } else {
        return a === b;
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export const capitalize = (str) => {
	if(str){
		const splitStr = str.toLowerCase().split(' ');
		for (let i = 0; i < splitStr.length; i++) {
			// You do not need to check if i is larger than splitStr length, as your for does that for you
			// Assign it back to the array
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		}
		// Directly return the joined string
		return splitStr.join(' ');
	}

	return;
}

export const normalizeCombo = (valueField, labelField, items) => {
    const arrItems = [];

    if(items){ 
        items.map(item => {
            arrItems.push({
                value: item[valueField],
                label: item[labelField]
            })
        })
    }

    return arrItems;
}

export const getScopeHasPower = (data, scope) => {
    const groupData = data && data.groups && data.groups.filter(item => item.details && item.details.id === groupReference(scope).id)[0];
    return data.hasFullPower || (groupData && groupData.hasPower);
}

export const generateUniqueId = (key) => {
    return md5(`${new Date().getTime()}-${key}`)
}

export const imageNameUtils = (filename) => {
	return `${filename}-${new Date().toLocaleString('pt-BR', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit'
	}).replace(/\//g, '-').replace(" ", "")}.${(filename ? filename : '').split('.').pop()}`
}

const defaultFormat = 'DD/MM/YYYY HH:mm';

export const toDateFormatted = (date, format = defaultFormat) => {
    if (!date) {
        return;
    }

    const formattedDate = date.seconds ? new Date(date.seconds * 1000) : new Date(date);
    return Moment(formattedDate).format(format ? format : defaultFormat);
}