import { getItem, addItem, deleteItem, updateItem } from './firebaseFunctions';

export const getQualityFileHistory = async (fields) => {
    return getItem("QualityFileHistory", fields);
}

export const addQualityFileHistory = async (item) => {
    return addItem("QualityFileHistory", item);
}

export const deleteQualityFileHistory = async (id) => {
    return deleteItem("QualityFileHistory", id);
}

export const updateQualityFileHistory = async (item) => {
    return updateItem("QualityFileHistory", item);
}