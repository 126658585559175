import React, { useState, useEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { ModalEditImage } from '../ModalEditImage/ModalEditImage';
import DeleteLabel from '../DeleteLabel/DeleteLabel';
import { Button } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import DeleteButton from '../DeleteLabel/DeleteButton';
import ModalAlert from '../../layoutV2/components/Modal/ModalAlert';

const ResponsiveGridLayout = WidthProvider(Responsive);

const getGrid = (item, index) => {
    if (item.grid) {
        return item.grid;
    } else {
        if (index === 0) {
            return { x: 0, y: 0, w: 4, h: 7 }
        } else if (index === 1) {
            return { x: 4, y: 0, w: 2, h: 7 }
        } else {
            return { x: (index - 2) % 3 * 2, y: 0, w: 2, h: 7 }
        }
    }
}

/* const getLayoutsFromSomewhere = (images) => {
    return images.map((image, index) => {
        return {
            i: index, x: 0, y: 0, w: 3, minW: 2, maxW: 4, h: 3, minH: 2, maxH: 4, ...image
        }
    });
} */
//, minW: 2, maxW: 4

const ImageGridLayout = ({ edit = true, data, isComment, onSaveLayout, onEditImage, onDataChange }) => {
    const [images, setImages] = useState([]);
    const [layouts, setLayouts] = useState({});
    const [editItem, setEditItem] = useState({});

    const onEditImageGrid = (image, duplicate = false) => {
        onEditImage(data, image, duplicate);
        if (!duplicate) {
            const index = images.findIndex(item => item.id === image.id);
            const arrNewImages = images.slice(0);
            arrNewImages[index].url = image.url;
            setImages(arrNewImages);
        }
    }

    const onRemoveImage = (image) => {
        data.images = data.images.filter(item => item.id !== image.id);
        onDataChange(data)
    }

    useEffect(() => {
        if (data) {
            setImages(data.images);
        }
    }, [data && data.images])

    const onToggle = (data) => {
        setEditItem(data);
    }

    const delImage = async (item) => {
        /* confirmAlert({
            title: 'Deseja remover essa imagem?',
            message: 'Ao remover, sua imagem não será mais exibida, esta ação não é reversível, deseja mesmo remover?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => onRemoveImage(item)
                },
                {
                    label: 'Não'
                }
            ]
        }); */
        ModalAlert.danger({
            title: "Deseja remover esta imagem?",
            text: "Ao remover, sua imagem não será mais exibida, esta ação não é reversível, deseja mesmo remover?",
            onConfirm: () => onRemoveImage(item)
        })
    }

    return (
        <>
            {edit && <ModalEditImage {...editItem} filename="teste.png" onChange={onEditImageGrid} open={editItem.url} onToggle={onToggle} hideButton={true} />}
            <ResponsiveGridLayout
                className="layout"
                rowHeight={30}
                cols={{ lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 }}
                layouts={layouts}
                isDraggable={edit}
                isResizable={edit}
                onLayoutChange={(_layout, layouts) => setLayouts({ layouts })}
                onDragStop={(layout, oldItem) => {
                    if (layout.x !== oldItem.x || layout.y !== oldItem.y) {
                        onSaveLayout(data, layout);
                    }
                }}
                onResizeStop={(layout, oldItem) => {
                    if (layout.w !== oldItem.w || layout.h !== oldItem.h) {
                        onSaveLayout(data, layout);
                    }
                }}
            >
                {images && images.filter(item => item && !item.isHiding).map((item, index) => {
                    const grid = getGrid(item, index);
                    return (
                        <div key={item.id} className="div-image" data-grid={grid}>
                            <div>
                                {(edit && !isComment) && <>
                                    {/* <DeleteLabel label={"Deseja mesmo remover essa imagem?"} removeEvent={() => onRemoveImage(item)} /> */}
                                    <DeleteButton removeEvent={() => delImage(item)} />
                                    <Button
                                        onClick={() => onToggle(item)}
                                        className="btn-icon button-edit-image"
                                        color="warning"
                                        size="sm"
                                        type="button"
                                    >
                                        <i className="fa fa-edit" />
                                    </Button>
                                </>}
                                <img src={item.url} className="list-image" />
                            </div>
                        </div>
                    )
                })}
            </ResponsiveGridLayout>
        </>
    )
}

// !isComment ? index === 0 ? { x: 1, y: 0, w: 4, h: 6 } : { x: (index - 1) % 3 * 2, y: 0, w: 2, h: 5 } : { x: index % 3 * 2, y: 0, w: 2, h: 5 };

export default ImageGridLayout;