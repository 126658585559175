import React from "react";
import { connect } from "react-redux"
import ScopeContainer from './ScopeContainer';
import LibDetails from '../views/LibDetails';
import { normalizeCombo } from 'core/utils/Utils';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import { addLibData, updateLibData, getLibByIdData, getLibTagsData } from 'core/redux-store/lib/Actions';
import Alert from '../components/Alert/Alert';
import { generateUniqueId } from '../core/utils/Utils';
import { deleteItem, addItem, updateItem, getItem, updateUnionItem } from 'core/database/firebaseFunctions';
import { getSkillData } from "core/redux-store/skill/Actions";
import { updateProjectLibData, addProjectLibData, getProjectLibByIdData } from "../core/redux-store/projectLib/Actions";
import { scopeHasPower } from "./UserContainer";
import { getWorkTypes } from "../core/database/skill";
import { getGroupData } from "../core/redux-store/group/Actions";
import { useHasPowerInGroup } from "../core/user/hooks";

const routeData = [
	{
		name: "Início",
		active: true,
		route: "/admin/dashboard"
	},
	{
		name: "Biblioteca",
		route: "/admin/library",
		active: true,
	},
	{
		name: "Detalhes"
	}
]

class LibDetailsContainer extends ScopeContainer {
	constructor(props) {
		super(props);

		this.state = {
			categories: [],
			hasChange: false,
			routeData: [],
			workTypes: []
		}

		this.onChange = this.onChange.bind(this);
		this.saveData = this.saveData.bind(this);
		this.newCategory = this.newCategory.bind(this);
		this.deleteCategory = this.deleteCategory.bind(this);
		this.onBack = this.onBack.bind(this);
	}

	onLocationChange(location) {

		const { dispatch, history, workGroup, groupData, network } = this.props;
		const state = location.state || {};
		const route = state.route ? state.route.map(route => ({ ...route, active: true })).concat([{ name: "Documentos" }]) : routeData
		this.setState({ routeData: route });
		if (state) {
			if (workGroup) {
				dispatch(getGroupData(workGroup))
			}
			dispatch(getLibTagsData())
			dispatch(getSkillData({ level: 1 }));
			getWorkTypes({ network }).then(workTypes => {
				const newWorks = groupData && groupData.workTypeIds ? workTypes.filter(work => groupData.workTypeIds.includes(work.value)) : workTypes
				this.setState({ workTypes: newWorks });
			})
			if (state.id) {
				const getById = state.isProject ? getProjectLibByIdData : getLibByIdData;
				dispatch(getById({ id: state.id })).then(items => {
					const lib = items[0];
					if (state.isDraft) {
						this.setState({
							...lib,
							name: lib.name !== "Rascunho" ? lib.name : undefined,
							tags: state.tags ? state.tags : []
						})
					} else {
						this.setState({ ...lib });
					}

					getItem(`Libs/${state.id}/categories`).then(categories => {
						this.setState({ categories });
					})
				})
			} else {
				const addData = state.isProject ? addProjectLibData : addLibData;
				dispatch(addData({ name: "Rascunho", active: false })).then(lib => {
					history.replace({ ...history.location, state: { ...history.location.state, id: lib.id, isDraft: true } });
				});
			}
		}
	}

	onDeleteTag(tag) {
		/*
		const { value } = tag;
		return deleteItem(`Tags/Libs/values`, value).then(() => {
			this.props.dispatch(getLibTagsData())
		});*/
	}

	onEditTag(tag) {
		const { tags } = this.state;

		const index = tags.findIndex(item => item.value === tag.value);

		if (index > -1) {
			tags[index] = { ...tags[index], ...tag, edited: true };
		}

		this.setState({ tags })
	}

	onScopeChange(scope) {
		const { dispatch } = this.props;
	}

	saveData() {
		const { dispatch, location, network, isAdmin } = this.props;
		const state = location.state || {};
		let { id, name, year, description, tags, linkSteps, categories, link, workType, adminWorkGroup, hasChangedWorkGroup, workGroup } = this.state;
		let extraData = {}

		try {
			if (!name) {
				throw new Error(I18n[keyEnum.nameRequired]);
			}

			if (!description) {
				description = ""
			}

			if (link) {
				const regexLink = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
				if (!regexLink.test(link)) {
					throw new Error(I18n[keyEnum.linkInvalid]);
				}
			} else {
				link = ""
			}

			if (year) {
				if (!/^[12][0-9]{3}$/.test(year)) {
					throw new Error(I18n[keyEnum.dateInvalid]);
				}
			} else { // Se ano não for número inteiro
				year = ""
			}

			if (!workType) {
				workType = [];
			}

			if (adminWorkGroup || workGroup) {
				if (hasChangedWorkGroup) {
					if (adminWorkGroup) {
						extraData['workGroup'] = adminWorkGroup
					} else {
						extraData['isPublic'] = true
					}
				} else {
					extraData['workGroup'] = workGroup
				}
			} else if (isAdmin) {
				extraData['isPublic'] = true
			} else if (network) {
				extraData['workGroup'] = network
			}

			let promises = []
			if (tags) {/*
				promises = tags.map(tag => {
					const { value, label } = tag;
					if(tag['__isNew__']){
						
						return addItem(`Tags/Libs/values`, { label }).then(newTag => {
							const index = tags.findIndex(item => item.value === tag.value);

							if(index > -1){
								tags[index] = {value: newTag.id, label};
							}
					
							this.setState({tags});
						});
					} else {
						return updateItem(`Tags/Libs/values`, {id: value, label});
					}
				});
				*/
			}

			if (state.isComment) {
				tags = [{ value: "IcjvF32VXhY7Y40fmIV9", label: "Apontamento pré-formatado" }]
			}

			if (categories) {
				categories.map(category => {
					if (category.needUpdate) {
						updateItem(`Libs/${id}/categories`, {
							id: category.id, name: category.name
						});
					}
				})
			}


			let project = {}

			if (state.isProject && state.filter) {
				project['workId'] = state.filter.workId;
			}

			const onDataCompleted = async (response) => {
				this.setState({ hasChange: false })
				Alert.success("Arquivo Salvo!")
			}

			Promise.all(promises).then(() => {
				// dispatch(getLibTagsData());
				const updateData = state.isProject ? updateProjectLibData : updateLibData;
				return dispatch(updateData({
					id, name, description, year, link,
					tags: normalizeCombo("value", "label", tags), workType, ...extraData,
					linkSteps: normalizeCombo("value", "label", linkSteps), active: true, ...project
				})).then(onDataCompleted);
			});
		} catch (error) {
			Alert.error(error.message);
		}
	}

	onChange(field, value) {
		const { categories } = this.state;

		if (field && field.startsWith('category')) {
			const index = categories.findIndex(category => category.id === value.idCategory);
			categories[index] = {
				...categories[index], ...value, needUpdate: true
			}
			this.setState({ categories, hasChange: true });
		} else if (field === "adminWorkGroup") {
			this.setState({ "hasChangedWorkGroup": true, [field]: value });
		} else {
			this.setState({ [field]: value, hasChange: true });
		}

	}

	getNewItem() {
		const { id } = this.props;
		const idCategory = generateUniqueId(id);
		return { idCategory };
	}

	newCategory() {
		const { id, categories } = this.state;
		addItem(`Libs/${id}/categories`, { name: 'Arquivos' }).then(data => {
			this.setState({ categories: categories.concat(data) })
		})
	}

	onBack() {
		this.props.history.goBack()
	}

	deleteCategory(category) {
		const { id, categories } = this.state;
		deleteItem(`Libs/${id}/categories`, category.idCategory).then(data => {
			this.setState({ categories: categories.filter(item => item.id !== category.idCategory) })
		})
	}

	mergedTags() {
		const { comboTags } = this.props;
		const { tags } = this.state;
		console.log({ tags });
		return comboTags ? comboTags.map(tag => {
			const changedTag = tags && tags.filter(item => item.edited && item.value === tag.value)[0];

			if (changedTag) {
				return changedTag;
			} else {
				return tag;
			}
		}) : [];
	}

	mergedValues() {
		const { comboTags } = this.props;
		const { tags } = this.state;
		return tags ? tags.map(tag => {
			const comboTag = comboTags && comboTags.filter(item => !tag.edited && item.value === tag.value)[0];

			if (comboTag) {
				return { ...tag, label: comboTag.label };
			} else {
				return tag;
			}
		}) : [];
	}

	render() {
		const { isLoading, skills, location, isAdmin, groups, allGroups, groupData, email, network, workGroup } = this.props;
		const { workType, isPublic } = this.state
		const state = location.state || {};


		const hasPower = isAdmin || location.state && location.state.filter && location.state.filter.company && scopeHasPower(location.state.filter.company.id, groups) ||
			(groupData.responsible === email) && !isPublic
		const skillsFiltered = (workType && workType.length > 0) ?
			skills.filter(skill => workType.find(type => skill.parentId === type.value))
			: skills

		return (
			<LibDetailsComponente	//trocar aqui
				options={normalizeCombo("id", "name", this.props.groups)}
				saveData={this.saveData}
				onChange={this.onChange}
				newCategory={this.newCategory}
				deleteCategory={this.deleteCategory}
				onBack={this.onBack}
				isLoading={isLoading || !this.state.id}
				comboTags={this.mergedTags()}
				onEditTag={this.onEditTag.bind(this)}
				onDeleteTag={this.onDeleteTag.bind(this)}
				{...this.state}
				tags={this.mergedValues()}
				skills={normalizeCombo("id", "name", skillsFiltered)}
				isProject={state.isProject}
				allGroups={normalizeCombo("id", "name", allGroups)}
				isAdmin={isAdmin}
				isPublic={isPublic}
				isComment={state.isComment}
			/>
		);
	}
}


const LibDetailsComponente = (props) => {
	const hasPowerInGroup = useHasPowerInGroup();

	return (
		<LibDetails
			editable={props.isAdmin || (!props.isPublic && hasPowerInGroup)}
			{...props}
		/>
	);
}

export default connect(state => ({
	hasPower: state.login.hasPower,
	isLoading: state.lib.isLoading,
	groups: state.login.groups,
	skills: state.skill.items,
	comboTags: state.lib.comboTags,
	errorMessage: state.lib.error,
	scope: state.login.scope,
	isAdmin: state.login.isAdmin,
	email: state.login.email,
	workGroup: state.login.workGroup,
	allGroups: state.group.items,
	network: state.login.network,
	groupData: state.login.groupData,
}))(LibDetailsContainer);
