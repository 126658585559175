import React, { createRef, Fragment } from 'react';
import { IconButton } from '@material-ui/core';
import { Button, Progress, Card, CardTitle, CardText, CardImg, CardImgOverlay, Row } from 'reactstrap';
import { DeleteOutlined, AddCircleOutlined, Check } from '@material-ui/icons';
import {
    Col,
    FormGroup,
    Input,
} from "reactstrap";
import { DeleteConfirmLabel } from './LibComponents';
import { generateUniqueId, normalizeCombo } from '../../core/utils/Utils';
import Select from "react-select";
import ReactDropZone from 'components/ReactDropZone/ReactDropZone';
import ImageUploader from 'react-images-upload';
import ReactDropzone from "react-dropzone";
import { storage, db } from 'core/database/firebaseConfig'
import { addItem } from '../../core/database/firebaseFunctions';
import FileGalleryContainer from '../../container/FileGalleryContainer';
import FileContainer from '../../container/File/FileContainer';
import { TextInput } from '../../layoutV2/components/Form';

class Category extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            uploadInProgress: [],
            openConfirm: false
        }

        this.setOpenConfirm = this.setOpenConfirm.bind(this);
        this.onPreviewDrop = this.onPreviewDrop.bind(this);
    }

    setOpenConfirm(openConfirm) {
        this.setState({ openConfirm })
    }

    onPreviewDrop = async (files) => {
        const { idLib, idCategory } = this.props;

        files.forEach(file => {
            const reader = new FileReader();
            const filename = file.name;
            const storageRef = storage.ref(`Libs/${idLib}/${idCategory}`).child(file.name);
            const task = storageRef.put(file);
            task.on("state_changed", (snapshot) => {
                const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                if (percentage == 100) {
                    storageRef.getDownloadURL().then((url) => {
                        addItem(`Libs/${idLib}/${idCategory}`, { url, filename }).then(data => {
                            this.setState({
                                files: this.state.files.concat({ id: data.id, url, filename }),
                                uploadInProgress: this.state.uploadInProgress.filter(item => item.filename !== filename),
                            });
                        });
                    });
                } else {
                    const uploadIndex = this.state.uploadInProgress.findIndex(item => item.filename === filename);

                    if (uploadIndex >= 0) {
                        this.state.uploadInProgress[uploadIndex] = { ...this.state.uploadInProgress[uploadIndex], percentage };
                        this.setState({
                            uploadInProgress: this.state.uploadInProgress
                        });
                    } else {
                        this.setState({
                            uploadInProgress: this.state.uploadInProgress.concat({ filename, percentage: percentage > 0 ? percentage - 1 : percentage }),
                        });
                    }
                }
            });

            reader.readAsDataURL(file);
        })
    }

    getFieldByType(field) {
        return;
    }

    render() {
        const { editable, deleteCategory, onChange, name, idCategory, idLib, onBlurCategory } = this.props;
        const { openConfirm } = this.state;
        return (
            <Col md="12">
                <div className={`v2-category category ${editable && 'editable'}`}>
                    {editable ? (
                        <TextInput
                            placeholder="Categoria" type="text" value={name} onChange={(event) => onChange('category-name', { idCategory, name: event.target.value })}
                        />
                    ) : <h4>{name}</h4>}
                    {editable && <div className="controls">
                        <DeleteOutlined onClick={() => this.setOpenConfirm(true)} />
                    </div>}
                    <DeleteConfirmLabel openConfirm={openConfirm} setOpenConfirm={this.setOpenConfirm} removeEvent={() => deleteCategory(this.props)} />
                    <FileContainer
                        // title={name}
                        useCrop={true}
                        dataReference={`Libs/${idLib}/categories/${idCategory}/images`}
                        canSelect={true}
                        isMultipleSelect={true}
                        disabled={!idLib || !editable}
                        style={{minHeight: 100}}
                    />
                </div>
            </Col>
        )
    }
}

export default Category;
