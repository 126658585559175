import React from 'react'
import { useSelector } from 'react-redux'
import { splitFullName } from '../../../container/UserProfileContainer';

/*

Componente utilizado para mostrar a foto do usuário (Ex: Lista de usuários das empresas)

*/
export const AvatarUser = ({ hasPower }) => {
    const user = useSelector(state => state.login);

    return (
        <div className="avatar-user-container">
            <img
                className="avatar-img"
                // src={"https://st3.depositphotos.com/9998432/13335/v/600/depositphotos_133352064-stock-illustration-default-placeholder-profile-icon.jpg"}
                src={user.avatar && user.avatar.url || `https://ui-avatars.com/api/?name=${splitFullName(user.name)}&background=random&size=200`}
            />
            <div className="avatar-info">
                <label>{user.name}</label>
                {hasPower === true && <small>Admin</small>}
                {hasPower === false && <small>Membro</small>}
                {hasPower === 0 && <small>Convidado</small>}
                {![true, false, 0].includes(hasPower) && <small>-</small>}
            </div>
        </div>
    )
}