import React, { useState } from 'react';
import {
    Card, CardBody, Col, CardTitle, Row, CardImg
} from "reactstrap";
import { CircularProgress } from '@material-ui/core';
import AdminNavbar from '../components/Navbars/AdminNavbar';
import { BreadcrumbHeader } from '../components/Breadcrumb/Breadcrumb';

const CardGroup = (props) => {
    return (
        <Col md={3} sm={12}>
            <Card className="card-work card-select-group" onClick={props.onLogin} >
                <CardImg classname="card-img-top" style={{ objectFit: "cover" }} top width="100%" height={150} src={props.file && props.file.url} />
                <CardBody style={{ padding: 20 }}>
                    {props.name}
                </CardBody>
            </Card>
        </Col>
    )
}

export const SelectGroup = ({ loading, allGroups, onLogin }) => {
    
    return loading ? <div className="works-progress"><CircularProgress /></div> : (
        <div className="content">
            <AdminNavbar>
                <BreadcrumbHeader data={[]} />
            </AdminNavbar>
            <div className="company-works-list" style={{ paddingLeft: 30, paddingRight: 30 }}>
                <h5 className="header text-center">Selecione um grupo utilizar o sistema</h5>
                <Row>
                    {allGroups.map(group => <CardGroup {...group} onLogin={() => onLogin(group)} />)}
                </Row>
            </div>
        </div>
    );
};