import { db, firebaseAuth, fireSQL } from './firebaseConfig';
import { getItem, addItem, deleteItem, updateItem } from './firebaseFunctions';


export const getQualityInspection = async (fields, orderBy = undefined, order = "asc", limit = undefined) => {
    /* return await fireSQL.query(`
        SELECT * from InspectionModel where workGroup = ${workGroup}
    `) */
    // console.log({ fields })
    return getItem("QualityInspection", fields, true, orderBy, order, limit);
}

export const getAllQualityInspection = async () => {
    return await fireSQL.query(`
        SELECT * from QualityInspection where active = true
    `)
}

export const addQualityInspection = async (item) => {
    return addItem("QualityInspection", item);
}

export const deleteQualityInspection = async (id) => {
    return deleteItem("QualityInspection", id);
}

export const updateQualityInspection = async (item) => {
    return updateItem("QualityInspection", item);
}



/**
 * const users = db.collection(EntityEnum.user);
    const query = users.where('invite', '==', true);

    return query.get().then(snapshot => {
        if (snapshot.empty) {
            return;
        } else {
            snapshot.docs.forEach(document => {
                if (document.exists && document.id === id) {
                    return document;
                } else {
                    throw new Error(I18n[keyEnum.inviteExpired]);
                }
            });
            return;
        }
    })
 */