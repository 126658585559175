import React from "react";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import routes from "routes.jsx";
import { Toaster } from "react-hot-toast";

var ps;
class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "white",
      activeColor: "info",
      sidebarMini: true
    };
  }
  componentDidMount() {
    // document.body.classList.toggle("sidebar-mini");
    if (navigator.platform.indexOf("Win") > -1) {
      /*
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
      */
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      /*
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
      */
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      // if (prop.layout === "/admin") {

      // } else {
      //   return null;
      // }
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          exact={prop.exact}
          key={key}
        />
      );
    });
  };
  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    const showSideBar = this.props.location.pathname !== '/admin/termos'

    if (!showSideBar) {
      return <div >
        <Switch>{this.getRoutes(routes)}</Switch>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
      </div>
    }

    return (
      <div className="wrapper">
        {showSideBar && <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          handleMiniClick={this.handleMiniClick}
        />}
        <div className="main-panel" ref="mainPanel">
          <Switch>{this.getRoutes(routes)}</Switch>
        </div>
        {/* <ToastContainer autoClose={8000} /> */}
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
      </div>
    );
  }
}

export default Admin;
