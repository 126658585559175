import { db } from './firebaseConfig';
import { getItemTable, getItem, addItem, deleteItem, updateItem, execute, executeAndFormat } from './firebaseFunctions';

export const getProjectLib = async (fields) => {
    const table = db.collection("ProjectLibs").doc(fields.id)
    // return execute(table);
    return executeAndFormat(table, [], [{key: "tags", idField: "tag", name: 'label'}], () => {}).then(libs => {
        return libs.map(lib => {
            if(lib.tags){
                lib.tags = lib.tags.sort((a, b) => {
                    return a.value.localeCompare(b.value);
                });

                lib.tagsSort = lib.tags.map(tag => {
                    return tag.value[0];
                })
            }
            return lib;
        })
    });
}

export const getProjectLibs = async (fields) => {
    let table = getItemTable("ProjectLibs", fields);
    return executeAndFormat(table, [], [], () => {}).then(libs => {
        return libs;
    });
}

export const tagReference = (item) => {
    return db.collection(`Tags/Libs/values`).doc(item.value);
}

export const addProjectLib = async (item) => {
    const tags = [];
    if(item.tags){
        item.tags.map(item => {
            tags.push({ tag: tagReference(item) })
        })
    }
    return addItem("ProjectLibs", {...item, tags});
}

export const deleteProjectLib = async (id) => {
    return deleteItem("ProjectLibs", id);
}

export const updateProjectLib = async (item) => {
    const tags = [];
    if(item.tags){
        item.tags.map(item => {
            tags.push({ tag: tagReference(item) })
        })
    }
    return updateItem("ProjectLibs", {...item, tags}).then(data => {
        return {...data, tags: item.tags}
    });
}