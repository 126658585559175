import React from 'react';
import { WorkView } from '../../views/works/WorkView';

export const WorkContainer = (props) => {
    const beforeRoutes = (props.location && props.location.state && props.location.state.router) ? 
    props.location.state.router.map(item => ({...item, active: true})) : [];
    const router = beforeRoutes.concat([{
        name: props.location && props.location.state && props.location.state.name
    }]);
    return (
        <WorkView {...props} router={router}/>
    );
};