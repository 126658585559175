import React, { useState, useEffect } from "react";
import DashboardView from "../views/dashboard/DashboardView";
import { getItem } from "../core/database/firebaseFunctions";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import { BreadcrumbHeader } from "../components/Breadcrumb/Breadcrumb";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { getWorksData } from "../core/redux-store/works/Actions";
import { getCompaniesData } from "../core/redux-store/company/Actions";
import { getWorks } from "./FilterDashboardQualityContainer";
import TutorialDashboardView from "../views/dashboard/TutorialDashboardView";
import { findOne } from "../views/works/WorkReportView";
import { getInspections, } from '../core/database/inspections';
import FilterDashboardContainer from "./FilterDashboardContainer";

const defaultFilter = {
	dates: {}
}

const BreadcrumbData = [
	{
		name: "Início",
	},
]

const getFilteredData = (data, filter) => {
	return data.filter(item => doFilter(item, filter))
}

const doFilter = (rowData, filter) => {
	let hasSteps = true;
	if (filter.step) {
		const arrSteps = Array.isArray(filter.step) ? filter.step.map(item => {
			return item.value
		}) : [filter.step.value]

		hasSteps = filter.step.length == 0 || (rowData.step && arrSteps.includes(rowData.step.id));
	}

	let hasImpact = true;
	if (filter.impact) {
		const arrImpacts = Array.isArray(filter.impact) ? filter.impact.map(item => {
			return item.value
		}) : [filter.impact.value]

		hasImpact = filter.impact.length == 0 || (rowData.scale && arrImpacts.includes(rowData.scale));
	}

	let hasSubjects = true;
	if (filter.subject) {
		const arrSubjects = Array.isArray(filter.subject) ? filter.subject.map(item => {
			return item.value
		}) : [filter.subject.value]

		hasSubjects = filter.subject.length == 0 || (rowData.subject && arrSubjects.includes(rowData.subject.id));
	}

	let hasSignal = true;
	if (filter.signal) {
		const arrSignal = Array.isArray(filter.signal) ? filter.signal.map(item => {
			return item.value
		}) : [filter.signal.value]

		hasSignal = filter.signal.length == 0 || (rowData.signal && (Array.isArray(rowData.signal) ? rowData.signal : [rowData.signal]).filter(item => arrSignal.includes(item)).length > 0/* .length === arrSignal.length */);

		// hasSignal = filter.signal.length == 0 || (rowData.signal && findOne(arrSignal, Array.isArray(rowData.signal) ? rowData.signal : [rowData.signal]));
	}

	let hasInspection = true;
	if (filter.inspection) {
		const arrInspection = Array.isArray(filter.inspection) ? filter.inspection.map(item => {
			return item.value
		}) : [filter.inspection.value]

		hasInspection = filter.inspection.length == 0 || (rowData.idInspection && arrInspection.includes(rowData.idInspection));

		// hasSignal = filter.signal.length == 0 || (rowData.signal && findOne(arrSignal, Array.isArray(rowData.signal) ? rowData.signal : [rowData.signal]));
	}
	let hasQuality = true;
	if (filter.quality) {
		const arrQuality = Array.isArray(filter.quality) ? filter.quality.map(item => {
			return item.value
		}) : [filter.quality.value]

		hasQuality = filter.quality.length == 0 || (rowData.idInspection && arrQuality.includes(rowData.idInspection));

		// hasSignal = filter.signal.length == 0 || (rowData.signal && findOne(arrSignal, Array.isArray(rowData.signal) ? rowData.signal : [rowData.signal]));
	}

	// let hasSignal = true;
	// if (filter.signal) {
	// 	const arrSignal = Array.isArray(filter.signal) ? filter.signal.map(item => {
	// 		return item.value
	// 	}) : [filter.signal.value]

	// 	// const arrTypeImprovement = Array.isArray(filter.signal) ? filter.signal.map(item => {
	// 	// 	return item.subvalue
	// 	// }) : [filter.signal.subvalue]

	// 	// hasTypeImprovement = filter.signal.filter(item => item.subvalue).length == 0 || findOne(arrTypeImprovement, Array.isArray(rowData.typeImprovement) ? rowData.typeImprovement : [rowData.typeImprovement])
	// 	// hasSignal = filter.signal.length == 0 || (rowData.signal && findOne(arrSignal, Array.isArray(rowData.signal) ? rowData.signal : [rowData.signal])) || hasTypeImprovement && filter.signal.filter(item => item.subvalue).length > 0;
	// 	// console.log(hasSignal, hasTypeImprovement, rowData, arrTypeImprovement, filter);
	// 	// if (hasSignal) {
	// 	// 	hasTypeImprovement = true
	// 	// }

	// 	hasSignal = !filter.signal.value || (rowData.signal && arrSignal.includes(rowData.signal.value));
	// }

	return hasSteps && hasImpact && hasSubjects && hasSignal && hasInspection && hasQuality;
}

function getDataWithActiveInspection(data, inspections) {
	if (inspections && inspections.length > 0 && data && data.length > 0) {
		const idInspections = inspections.map(item => item.id)
		return data.filter(item => idInspections.includes(item.idInspection))
	} else {
		return data
	}
}

const DashboardContainer = (props) => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.login)
	const [loading, setLoading] = useState(false);
	const companies = useSelector(state => state.company.items)
	const works = useSelector(state => state.works.items)
	const [data, setData] = useState([])
	const [inspections, setInspections] = useState([])
	const [filter, setFilter] = useState(defaultFilter)

	useEffect(() => {
		if (companies && companies.length === 0) {
			dispatch(getCompaniesData({ groups: user.groups, isAdmin: user.isAdmin, workGroup: user.network }));
		}
		if (works && works.length === 0) {
			dispatch(getWorksData({ groups: user.groups, isAdmin: user.isAdmin, email: user.email }));
		}
	}, [companies && companies.length === 0 || works && works.length === 0])

	useEffect(() => {
		loadWorkData()
		loadInspections()
	}, [filter && filter.dates, filter && filter.work, filter && filter.companies])

	const loadInspections = async () => {
		const promises = []
		if (filter && filter.work) {
			filter.work.map(work => promises.push(getInspections(work.value)));
		}
		const newInspections = await Promise.all(promises).then(items => items.flat()).catch(() => ([]))
		setInspections(newInspections)
	}

	const loadWorkData = async () => {
		const values = {}

		if (filter.dates && filter.dates.startDate && filter.dates.endDate) {
			values['date'] = [
				{ operator: ">=", value: moment(filter.dates.startDate, 'DD-MM-YYYY').toDate() },
				{ operator: "<=", value: moment(filter.dates.endDate, 'DD-MM-YYYY').toDate() }
			]
		}

		if (filter.company) {
			setLoading(true);
			let companies = []
			companies = [filter.company.value]
			if (Array.isArray(filter.company)) {
				companies = filter.company.map(item => item.value);
			}

			const promises = []

			console.log({ filter })
			companies.map(idCompany => {
				if (idCompany && filter.work) {
					filter.work.map(work => promises.push(getItem('WorkData', { ...values, idWork: work.value }).catch(e => { console.log({ e }) })));
				}
			})

			console.log({ promises })
			const newData = await Promise.all(promises).then(items => items.flat()).catch(() => ([]))
			setData(newData)
			setLoading(false)
		}
	}

	return (
		<>
			<div className="content skills-screen skills">
				<AdminNavbar goBack={() => props.history.goBack()} />
				<div style={{ paddingLeft: 30, paddingRight: 30 }}>
					{/* <TutorialDashboardView filter={filter} /> */}
					<FilterDashboardContainer companies={companies} works={works} setFilter={setFilter} filter={filter} data={data} inspections={inspections} />
					<DashboardView data={getFilteredData(getDataWithActiveInspection(data, inspections), filter)} filter={filter} loading={loading} />
				</div>
			</div>
		</>
	)
}

export default DashboardContainer