import React, { useMemo, useRef, useState } from "react";
import GravityCard from "../../components/Dashboard/GravityCard";
import StepsCard from "../../components/Dashboard/StepsCard";
import SignalCard from "../../components/Dashboard/SignalCard";
import RecurrencyCard from "../../components/Dashboard/RecurrencyCard";
import { Row } from "reactstrap";
import StatusCard from "../../components/Dashboard/StatusCard";
import Lottie from 'react-lottie';
import * as animationData from './18841-bar-graph.json'
import { useReactToPrint } from "react-to-print";
import { SaveButton } from "../../layoutV2/components/Button";

const DashboardView = ({ data, loading, filter }) => {
	const ref = useRef(null)
	const [printing, setPrinting] = useState(false)
	const handlePrint = useReactToPrint({
		content: () => ref.current,
		onBeforePrint: () => setPrinting(true),
		onAfterPrint: () => setPrinting(false),
	});

	if (loading) {
		return (
			<div className="dashboard-animation">
				<lottie-player
					id="loading"
					src="https://assets1.lottiefiles.com/packages/lf20_E3BfOE.json"
					background="transparent"
					speed="1"
					style={{ width: 300, height: 300 }}
					autoplay
					loop
				/>
				<div className="title">{"Buscando dados das obras..."}</div>
				<div className="subtitle">{"Aguarde enquanto preparamos seus dados"}</div>
			</div>
		)
	}

	if (data.length === 0 && filter && filter.company && filter.company.length > 0 && filter.work && filter.work.length > 0) {
		return (
			<div className="dashboard-animation">
				<lottie-player
					id="empty"
					src="https://assets1.lottiefiles.com/packages/lf20_E3BfOE.json"
					background="transparent"
					speed="1"
					style={{ width: 300, height: 300 }}
					autoplay
				/>
				<div className="title">{"Buscar dados das obras"}</div>
				<div className="subtitle">Não foram encontrados dados para esta obra!</div>
			</div>
		)
	}

	if (data.length === 0) {
		return (
			<div className="dashboard-animation">
				<lottie-player
					id="empty"
					src="https://assets1.lottiefiles.com/packages/lf20_E3BfOE.json"
					background="transparent"
					speed="1"
					style={{ width: 300, height: 300 }}
					autoplay
				/>
				<div className="title">{"Buscar dados das obras"}</div>
				<div className="subtitle">Filtre por empresa e obra para iniciar</div>
			</div>
		)
	}

	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}>
				<SaveButton disabled={printing} label="Imprimir" onClick={handlePrint} />
			</div>
			<div ref={ref} className='print-dashboard'>
				<Row id="printarea" >
					<div className='row-cards'>
						<StatusCard data={data} />
						<GravityCard data={data} />
					</div>
					<RecurrencyCard data={data} />
					<div style={{ pageBreakAfter: 'always' }} />
					{filter && filter.subject && filter.subject.length > 0 && <StepsCard lg={12} md={12} data={data} widthNumber={50} xNumber={-25} keyField={"subject"} title="Apontamentos por Categoria" />}
					<StepsCard lg={12} md={12} data={data} widthNumber={40} xNumber={-20} keyField={"step"} title="Apontamentos por Etapa" />
				</Row>
			</div>
		</div>
	)
}

export default DashboardView