import React from 'react';
import { Row, Col } from 'reactstrap';
import { normalizeCombo } from '../../../core/utils/Utils';
import { SelectInput, DateRange } from '../Form';


export const FilterQuality = (props) => {
    const { setFilter, filter, model, allReferenceLocal, UID, models } = props
    // console.log({ allReferenceLocal })

    const locals = [...new Set(allReferenceLocal.filter(item => item.UID === UID || !item.UID).map(item => item.name))]

    const onFilter = (field, value, date = true) => {
        if (date) {
            setFilter(oldFilter => ({ ...oldFilter, [field]: value }))
        } else {
            setFilter(oldFilter => ({ ...oldFilter, [field]: value, localFilter: allReferenceLocal.filter(item => value.find(row => row.label == item.name)) }))
        }
    }

    return (
        <div style={{ paddingLeft: 30, paddingRight: 30 }}>
            <Row className="filter-report">
                <Col lg="3" sm="12"  >
                    <DateRange
                        onDateChange={onFilter}
                        startDate={filter && filter.dates && filter.dates.startDate}
                        endDate={filter && filter.dates && filter.dates.endDate}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <SelectInput options={normalizeCombo("id", "name", locals.map(name => allReferenceLocal.find(item => item.name === name))) || []}
                        labelledBy={'Filtro por local'}
                        title="Filtro por local"
                        value={filter && filter.local}
                        onChange={item => onFilter("local", item, false)}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <SelectInput options={model || normalizeCombo("id", "name", models) || []}
                        labelledBy={'Filtro por modelo de ficha'}
                        title="Filtro por modelo de ficha"
                        value={filter && filter.model}
                        onChange={item => onFilter("model", item, false)}
                    />
                </Col>
            </Row>
        </div>
    )
};