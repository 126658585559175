import React, { useEffect, useState } from "react";
import { deleteQualityInspection, getQualityInspection } from "../core/database/qualityInspection.js";
import Quality from "../views/Quality.jsx";
import { toDate, toDateFormat } from "../views/works/InspectionView.jsx";
import sort from 'fast-sort'
import { getWork } from "../core/database/works.js";
import { useSelector } from "react-redux";
import { getItem } from "../core/database/firebaseFunctions.js";
import ModalAlert from "../layoutV2/components/Modal/ModalAlert.js";
import moment from 'moment';
import { getInspectionModelData } from "../core/redux-store/inspectionModel/Actions.js";
import { getInspectionModel } from "../core/database/inspectionModel.js";

const defaultFilter = {
	dates: {}
}
// const filteredData = (data, filter) => {
// 	console.log({ data, filter })
// 	let newData = data

// 	if (filter && filter.model && filter.model.length > 0) {
// 		newData = newData.filter(item => filter.model.filter(row => row.value === item.modelId).length > 0)
// 	}
// 	if (filter && filter.local && filter.local.length > 0) {
// 		newData = newData.filter(item => filter.local.filter(row => row.value === item.referenceLocalId).length > 0)
// 	}
// 	// if (filter && filter.model && filter.dates.length > 0){
// 	// 	newData = newData.filter(item => filter.dates.filter(row => row.value === item.modelId).length > 0)
// 	// }
// 	if (filter.dates && filter.dates.startDate && filter.dates.endDate) {
// 		const criteriaDates = () => moment(data.Start, "YYYY-MM-DD").diff(moment(filter.dates.startDate, "DD-MM-YYYY"), 'days') >= 0 &&
// 			moment(data.Finish, "YYYY-MM-DD").diff(moment(data.dates.endDate, "DD-MM-YYYY"), 'days') <= 0

// 		const criteriaEnd = () => moment(data.Finish, "YYYY-MM-DD").diff(moment(filter.dates.startDate, "DD-MM-YYYY"), 'days') >= 0 &&
// 			moment(data.Finish, "YYYY-MM-DD").diff(moment(data.dates.endDate, "DD-MM-YYYY"), 'days') <= 0

// 		const criteriaStart = () => moment(data.Start, "YYYY-MM-DD").diff(moment(data.dates.startDate, "DD-MM-YYYY"), 'days') >= 0 &&
// 			moment(data.Start, "YYYY-MM-DD").diff(moment(data.dates.endDate, "DD-MM-YYYY"), 'days') <= 0

// 		// hasDates = criteriaEnd() || criteriaStart() || criteriaDates();
// 	}

// 	return newData
// 	// return data.filter(item => (!filter.text || item.Name.toLowerCase().includes(filter.text.toLowerCase())
// 	// || item.model.filter(model => model.label.toLowerCase().includes(filter.text.toLowerCase())).length)
// 	// && (!filter.status || filter.status.find(row => item.status === row)))
// }
const filteredData = (data, filter) => {
	return data.filter(item => doFilter(item, filter))
}

const doFilter = (rowData, filter) => {
	let hasModel = true;
	if (filter && filter.model && filter.model.length > 0) {
		hasModel = filter.model.filter(row => row.value === rowData.modelId).length > 0
	}
	let hasLocal = true;
	if (filter && filter.localFilter && filter.localFilter.length > 0) {
		hasLocal = filter.localFilter.filter(row => row.id === rowData.referenceLocalId && rowData.type === 2).length > 0
	}
	let hasDates = true;
	if (filter.dates && filter.dates.startDate && filter.dates.endDate) {
		const criteriaDates = () => moment(toDate(rowData.date), "YYYY-MM-DD").hours(0).minutes(0).seconds(0).milliseconds(0).diff(moment(filter.dates.startDate, "DD-MM-YYYY"), 'days') >= 0 &&
			moment(toDate(rowData.date), "YYYY-MM-DD").hours(0).minutes(0).seconds(0).milliseconds(0).diff(moment(filter.dates.endDate, "DD-MM-YYYY"), 'days') <= 0
		hasDates = criteriaDates();
	}

	return hasModel && hasLocal && hasDates;
}

const QualityContainer = (props) => {
	const { location: { state }, history } = props;
	const [inspections, setInspections] = useState([])
	const [allReferenceLocal, setAllReferenceLocal] = useState([])
	const [work, setWork] = useState({})
	const [filter, setFilter] = useState(defaultFilter)
	const [models, setModels] = useState([])
	const user = useSelector(state => state.login);

	const loadInspections = async () => {
		console.log({ activityUID: state.UID, workId: state.workId, state })
		const inspections = await getQualityInspection({ activityUID: state.UID, workId: state.state.workId })
		// console.log({ inspections })
		sort(inspections).asc([
			u => u.type,
			u => u.date
		])
		setInspections(inspections)
	}

	const loadReferenceLocal = async () => {
		const { workId } = state.state
		const allReferenceLocal = await getItem(`ReferenceLocal`, { workId });
		allReferenceLocal.sort(function (a: any, b: any) {
			return a.name && b.name && a.name.localeCompare(b.name);
		})
		console.log('load', { allReferenceLocal })
		setAllReferenceLocal(allReferenceLocal)
	}

	useEffect(() => {
		loadInspections()
		loadWork()
		loadReferenceLocal()
	}, [state.UID, state.id])

	useEffect(() => {
		if (state.UID === "-1" && inspections && inspections.length > 0) {
			loadModels()
		}
	}, [inspections])

	const loadWork = async () => {
		if (state && state.state && state.state.workId) {
			const work = await getWork(state.state.workId)
			if (work && work.id) {
				setWork(work)
			}
		}
	}

	const loadModels = async () => {
		const promises = [];
		inspections.map(item => promises.push(getInspectionModel({ fields: { id: item.modelId } })))

		const models = await Promise.all(promises).then(items => items.flat()).catch(() => ([]));
		const uniqueModels = [...new Map(models.map(item => [item["id"], item])).values()]
		sort(uniqueModels).by({
			asc: u => u.name,
			comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
		});
		setModels(uniqueModels)
	}

	const onRemoveInspection = async (id) => {
		await deleteQualityInspection(id)
		setInspections(inspections => inspections.filter(item => item.id !== id))
	}

	const removeInspection = async (id) => {
		if (id) {
			ModalAlert.danger({
				title: "Qualidade",
				text: "Deseja mesmo remover esta inspeção de qualidade? Ao realizar esta ação o item não poderá mais ser acessado e a ação não poderá ser revertida.",
				onConfirm: () => onRemoveInspection(id)
			})
		}
	}

	// console.log({ work, state, removeInspection, inspections, allReferenceLocal })
	return (

		<Quality
			{...state}
			history={history}
			setFilter={setFilter}
			filter={filter}
			inspections={filteredData(inspections, filter)}
			removeInspection={removeInspection}
			filteredData={filteredData}
			allReferenceLocal={allReferenceLocal}
			work={work}
			models={models}
			isResponsible={work && work.qualityResponsible && work.qualityResponsible.filter(item => item.value === user.email).length > 0} />
	);
}

export default QualityContainer

export const InspectionEnum = {
	start: 1,
	servico: 2
}

/* const inspections = [
	//Vistoria inicial
	{
		UID: "0",
		type: InspectionEnum.start,
		date_start: toDateFormat("01/09/2020", "DD/MM/YYYY"),
		unity: UnityEnum.metro2,
		quantity: 100,
		step: { value: "0", label: "Etapa A" },
		userIds: ["8vERk7ngoGF2E2WLPpd2", "5yfYVWfhOFsvSOqoiFf8"],
		users: [
			{ value: "8vERk7ngoGF2E2WLPpd2", label: "Conext" },
			{ value: "5yfYVWfhOFsvSOqoiFf8", label: "Everton Bernardes" }
		],
		files: [
			"https://firebasestorage.googleapis.com/v0/b/app-ike.appspot.com/o/Images%2FWorks%2Fdata%2Fa-importancia-do-cronograma-de-obras-para-sua-construtora.jpg-22-06-201915%3A30%3A07.jpg?alt=media&token=d5f800bd-004f-42ca-9767-0b39bb252433"
		],
		asks: [{
			id: "f93a2723c5f9445fad30253aebd135c0",
			value: true
		}, {
			id: "227c53da7a0269a973d5161b087535d7",
			value: true
		}, {
			id: 'adeb3604016c0c5450f2586617bf23d5',
			value: true
		},
		{
			id: "ce3657aad30d84243e141c0181b354ba",
			value: true
		},
		{
			id: "d4fbd02ab91fb390362393ba3a073163",
			value: true
		},
		{
			id: "6becf6183f03a38645228dbb58e7e129",
			value: true
		},
		{
			id: "9f1818352e417b1fd44b68191064f9da",
			value: true
		},
		{
			id: "e8148ff34d6c3512ee75e8915f7a98f7",
			value: true
		},
		{
			id: "a0070e39bbea340f5bd48f071a2bc9d6",
			value: true
		},
		{
			id: "b2de9332dc984a230e690d0e5d48d4f5",
			value: true
		},
		{
			id: "3c63cb2729e93b8efc185c7c4ef603f5",
			value: true
		},
		{
			id: "714b8e7df82bbee5de28f9aad5b1a8d1",
			value: true
		}],
		local: "Localização",
		team: [{ function: "Pedreiro (3)" }, { function: "Vidraceiro (2)" }],
		real_date_start: toDateFormat("02/09/2020", "DD/MM/YYYY"),
		observation: "Atividade não aprovada para início"
	},
	{
		UID: "0",
		type: InspectionEnum.servico,
		real_date_start: toDateFormat("05/09/2020", "DD/MM/YYYY"),
		date_start: toDateFormat("03/09/2020", "DD/MM/YYYY"),
		unity: UnityEnum.metro2,
		quantity: 100,
		step: { value: "0", label: "Etapa A" },
		userIds: ["8vERk7ngoGF2E2WLPpd2", "5yfYVWfhOFsvSOqoiFf8"],
		users: [
			{ value: "8vERk7ngoGF2E2WLPpd2", label: "Conext" },
			{ value: "5yfYVWfhOFsvSOqoiFf8", label: "Everton Bernardes" }
		],
		files: [
			"https://firebasestorage.googleapis.com/v0/b/app-ike.appspot.com/o/Images%2FWorks%2Fdata%2Fa-importancia-do-cronograma-de-obras-para-sua-construtora.jpg-22-06-201915%3A30%3A07.jpg?alt=media&token=d5f800bd-004f-42ca-9767-0b39bb252433"
		],
		asks: [{
			id: "21cf1e79e928c7b74038d7b9f4b99ee3",
			value: true,
		},
		{
			id: "a098d9de7cee513c66118bcf3ea297e9",
			value: true,
		},
		{
			id: "a098d9de7cee513c66118bcf3ea297e9",
			value: false,
			obs: "Problema A",
			obsId: "564234"
		},
		{
			id: "68fde2fddb17cc5576bb50c19fcd43e3",
			value: false,
			obs: "Problema B",
			obsId: "564234"
		},
		{
			id: "d4bbe5b7b85712302a24b3acd965eedf",
			value: true,
		},
		{
			id: "0df35030ac9d586675659f214c0fb53e",
			value: false,
			obs: "Problema C",
			obsId: "564234"
		},
		{
			id: "f638ebc642bcfe2801cacb162b907848",
			value: true,
		}],
		local: "Local",
		team: [{ function: "Pedreiro (3)", names: "João, Pedro e Marcos" }, { function: "Vidraceiro (2)", names: "Gabriel e Tiago" }]
	},
	{
		UID: "0",
		type: InspectionEnum.servico,
		real_date_start: toDateFormat("08/09/2020", "DD/MM/YYYY"),
		date_start: toDateFormat("06/09/2020", "DD/MM/YYYY"),
		unity: UnityEnum.metro2,
		quantity: 100,
		step: { value: "0", label: "Etapa A" },
		userIds: ["8vERk7ngoGF2E2WLPpd2", "5yfYVWfhOFsvSOqoiFf8"],
		users: [
			{ value: "8vERk7ngoGF2E2WLPpd2", label: "Conext" },
			{ value: "5yfYVWfhOFsvSOqoiFf8", label: "Everton Bernardes" }
		],
		files: [
			"https://firebasestorage.googleapis.com/v0/b/app-ike.appspot.com/o/Images%2FWorks%2Fdata%2Fa-importancia-do-cronograma-de-obras-para-sua-construtora.jpg-22-06-201915%3A30%3A07.jpg?alt=media&token=d5f800bd-004f-42ca-9767-0b39bb252433"
		],
		asks: [{
			id: "21cf1e79e928c7b74038d7b9f4b99ee3",
			value: true,
		},
		{
			id: "a098d9de7cee513c66118bcf3ea297e9",
			value: true,
		},
		{
			id: "a098d9de7cee513c66118bcf3ea297e9",
			value: false,
			obs: "A",
			obsId: "564234"
		},
		{
			id: "68fde2fddb17cc5576bb50c19fcd43e3",
			value: false,
			obs: "B",
			obsId: "564234"
		},
		{
			id: "d4bbe5b7b85712302a24b3acd965eedf",
			value: false,
			obs: "C",
			obsId: "564234"
		},
		{
			id: "0df35030ac9d586675659f214c0fb53e",
			value: false,
			obs: "D",
			obsId: "564234"
		},
		{
			id: "f638ebc642bcfe2801cacb162b907848",
			value: false,
			obs: "E",
			obsId: "564234"
		},],
		local: "Local",
		team: [{ function: "Pedreiro (3)", names: "João, Pedro e Marcos" }, { function: "Vidraceiro (2)", names: "Gabriel e Tiago" }]
	}
] */