import React from "react";
import { connect } from "react-redux";
import Register from "views/pages/Register";
import queryString from 'query-string'
import { getInviteData, setInviteError, updateRegisterData } from 'core/redux-store/invite/Actions';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import { signIn } from 'core/redux-store/login/Actions';

class RegisterContainer extends React.Component {
	constructor(props) {
        super(props);
        
        this.onChange = this.onChange.bind(this);
        this.doRegister = this.doRegister.bind(this);

        this.state = {

        }
    }
    
    componentWillMount(){
        const query = queryString.parse(this.props.location.search);

        if(query.invite){
            this.props.dispatch(getInviteData(query.invite)).then(() => {
                this.setState({ email: this.props.email, id: query.invite })
            });
        }
    }

    onChange(field, value) {
		this.setState({ [field]: value });
    }
    
    doRegister(){
        const { id, name, email, password, confirm_password } = this.state;

        if(name && email && password && confirm_password){
            if(password !== confirm_password){
                this.props.dispatch(setInviteError({error: I18n[keyEnum.passwordDoesNotMatch]}))
            } else {
                this.props.dispatch(updateRegisterData({ id, name, email, password })).then(() => {
                    this.props.dispatch(signIn(email, password));    
                });
            }
        } else {
            this.props.dispatch(setInviteError({error: I18n[keyEnum.requiredAll]}))
        }
        
    }

	render() {
        const { email, errorMessage, isLoading } = this.props;

		return (
			<Register
                doRegister={this.doRegister}
                onChange={this.onChange}
                errorMessage={errorMessage}
                isLoading={isLoading}
                email={email}
                {...this.state}
			/> 
		);
	}
}

export default connect(state => ({
    isLoading: state.invite.isLoading,
    email: (state.invite.items && state.invite.items && state.invite.items.email) || undefined,
    errorMessage: state.invite.error
}))(RegisterContainer);
