import React from "react";
import PieChart from "../Charts/PieChart";
import { Card, CardTitle, Col } from "reactstrap";
import { NivoPieChart } from "../Charts/NivoPieChart";

const getNumber = (data, type, count) => {
	let number = data.filter(item => type ? item.scale === type : !item.scale).length
	if (count) {
		return parseFloat(((number / data.length) * 100 || 0).toFixed(2))
	}
	return number > 1 ? `${number} apontamentos` : `${number} apontamento`
}


const GravityCard = ({ data }) => {

	const dataChart = (isPercent = false) => {
		return {
			labels: [`Alto: ${getNumber(data, 3)} `, `Média: ${getNumber(data, 2)} (${getNumber(data, 2, true)}%)`,
			`Baixa: ${getNumber(data, 1)} (${getNumber(data, 1, true)}%)`, `Sem gravidade: ${getNumber(data)} (${getNumber(data, undefined, true)}%)`],
			datasets: [{
				data: [
					isPercent ? getNumber(data, 3, true) : getNumber(data, 3),
					isPercent ? getNumber(data, 2, true) : getNumber(data, 2),
					isPercent ? getNumber(data, 1, true) : getNumber(data, 1),
					isPercent ? getNumber(data, undefined, true) : getNumber(data)
				],
				backgroundColor: [
					'rgba(241, 39, 17, 0.2)',
					'rgba(239, 102, 21, 0.2)',
					'rgba(245, 175, 25, 0.2)',
					'rgba(178, 178, 178, 0.2)',
				],
				borderColor: [
					'rgba(241, 39, 17, 1)',
					'rgba(239, 102, 21, 1)',
					'rgba(245, 175, 25, 1)',
					'rgba(178, 178, 178, 1)',
				],
				borderWidth: 1,
			}],
		}
	}

	const options = {
		tooltips: {
			callbacks: {
				label: function (tooltipItem, data) {
					var label = data.labels[tooltipItem.index] || '';
					return label;
				}
			}
		}
	}

	const dataFormatted = [
		{
			"id": "Alto",
			"label": `Alto (${getNumber(data, 3)})`,
			"value": getNumber(data, 3, true),
			"color": "hsl(39, 99%, 54%)"
		},
		{
			"id": "Médio",
			"label": `Médio (${getNumber(data, 2)})`,
			"value": getNumber(data, 2, true),
			"color": "hsl(22, 87%, 51%)"
		},
		{
			"id": "Baixo",
			"label": `Baixo (${getNumber(data, 1)})`,
			"value": getNumber(data, 1, true),
			"color": "hsl(41, 91%, 52%)"
		},
		{
			"id": "Nenhum",
			"label": `Nenhum (${getNumber(data)})`,
			"value": getNumber(data, 0, true),
			"color": "hsl(356, 70%, 50%)"
		},
		{
			"id": "Positivo",
			"label": `Positivo (${getNumber(data, 4)})`,
			"value": getNumber(data, 4, true),
			"color": "hsl(140, 68%, 39%)"
		},
	]

	return (
		<Col lg="6" md="12">
			<Card style={{ padding: 20, height: 500, width: "100%" }}>
				<div style={{ display: "flex", fontWeight: "bold", fontSize: 20, justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
					{`Impacto - ${data.length} ${data.length > 1 ? "apontamentos" : "apontamento"}`}
				</div>
				{/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Nº de Apontamentos</div> */}
				{/* <PieChart height={300} data={dataChart()} options={options} height={"100%"} /> */}
				<NivoPieChart data={dataFormatted} />
			</Card>
		</Col>
	)
}

export default GravityCard

{/* <div style={{ paddingTop: 5 }}>
	<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>% de Apontamentos</div>
	<PieChart data={dataChart(true)} />
</div> */}

{/* <div>{`Apontamentos sem gravidade: ${getNumber(data)}`}</div>
<div>{`Apontamentos com gravidade baixa: ${getNumber(data, 1)}`}</div>
<div>{`Apontamentos com gravidade média: ${getNumber(data, 2)}`}</div>
<div>{`Apontamentos com gravidade alta: ${getNumber(data, 3)}`}</div> */}

{/* <div>{`% Apontamentos sem gravidade: ${getNumber(data, undefined, true)}%`}</div>
<div>{`% Apontamentos com gravidade baixa: ${getNumber(data, 1, true)}%`}</div>
<div>{`% Apontamentos com gravidade média: ${getNumber(data, 2, true)}%`}</div>
<div>{`% Apontamentos com gravidade alta: ${getNumber(data, 3, true)}%`}</div> */}