import React from "react";
import CustomTable from "../components/CustomTable/CustomTable";
import { Row, Col, Button, Input } from 'reactstrap';
import { MTableEditField, MTableEditRow, MTableActions, MTableFilterRow } from 'material-table';
import { IconButton } from '@material-ui/core';
import { Add, Bookmarks, Bookmark, BookmarkBorder } from '@material-ui/icons';
import Select from 'react-select';
import { Badge } from 'reactstrap';
import { defaultColor } from "../variables/theme";
import { FilterSearch } from "../components/Filter/FilterHooks";
import GenericMaterialTable from "../components/GenericMaterialTable";
import { AddButton, DeleteButton, EditButton, SubtitleButton } from "../components/Button/Button";
import { SelectInput } from "../layoutV2/components/Form";

let defaultSearch;

class Skills extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      change: false,
      search: defaultSearch
    }

    this.onFilterSearch = this.onFilterSearch.bind(this);
    this.getFilteredData = this.getFilteredData.bind(this);
  }

  getIcon(level) {
    switch (level) {
      case 0: //primeiro nível
        return <BookmarkBorder className="icon-bookmark" />;
      // return <Bookmarks className="icon-bookmark" />;
      case 1: //segundo nível
        return <BookmarkBorder className="icon-bookmark two" />;
      // return <Bookmark className="icon-bookmark two" />;
      case 2: //terceiro nível
        return <BookmarkBorder className="icon-bookmark three" />;
    }
  }

  onFilterSearch(_field, search) {
    this.setState({ search });
    defaultSearch = search;
  }

  getLookup() {
    const lookup = {};

    this.props.comboOptions.map(item => {
      lookup[item.value] = item.label;
    })

    return lookup;
  }

  getFilteredData = (data, search) => {
    return data.filter(item => !search || (item.name && search && item.name.toLowerCase().includes(search.toLowerCase())))
  }

  render() {
    const { tags, search } = this.state;
    const { data, isAdmin, network } = this.props;

    return (
      <>
        {/* <div className="content skills-screen skills"> */}
        <div className='content overflowVisible'>
          <GenericMaterialTable
            ref={ref => this.customTable = ref}
            idTable={"skills-list"}
            title={this.props.title}
            onAddClick={this.props.onAddClick}
            headerAdd
            marginZero={true}
            rightComponent={
              <Row className="filter-report" style={{marginRight: 220}}>
                  <Col lg="3" sm="12">
                      <SelectInput
                          onChange={(values) => this.props.setMyItems(values)}
                          value={this.props.myItems}
                          options={[
                              { label: "Meus itens", value: 1 },
                              { label: "Itens do sistema", value: 2 },
                          ]}
                          labelledBy={"Selecionar tipo"}
                          title={"Selecionar tipo"}
                      />
                  </Col>
              </Row>
          }
            wordWrap={true}
            columns={[
              {
                title: 'Nome',
                field: 'name',
                editable: 'always',
                defaultSort: 'asc',
                defaultSearch: true,
                // cellStyle: {
                //   maxWidth: 250,
                //   minWidth: 250
                // },
                // customSort: (a, b) => a.name && a.name.localeCompare(b.name),
                // lookup: this.getLookup(),
                // customFilterAndSearch: (term, rowData) => {
                //   if (Array.isArray(term)) {
                //     return !term || term.length == 0 || rowData.lib && term.includes(rowData.lib.value);
                //   } else {
                //     return !term || rowData.name.toLowerCase().includes(term.toLowerCase());
                //   }
                // },
                // editComponent: props => {
                //   const { rowData } = props;
                //   return (<Row className="edit-custom-row">
                //     <input
                //       level="text"
                //       placeholder="Nome"
                //       className="editing-input-custom"
                //       value={props.value}
                //       onChange={e => {
                //         props.onChange(e.target.value)

                //         if (rowData && e.target.value) {
                //           rowData.name = e.target.value
                //         }
                //       }}
                //     />
                //   </Row>)
                // },
                render: rowData => {
                  // console.log({ rowData, test: rowData && !rowData.tableData.childRows })
                  return (
                    <Row className={`row-user padding-${rowData && rowData.tableData && !rowData.tableData.childRows && rowData.level} label-primary`}>
                      {this.getIcon(rowData.level)}
                      <Col className="details truncated">{rowData.name}</Col>
                      {rowData.lib && <Badge className="lib" pill>{rowData.lib.label}</Badge>}
                    </Row>
                  )
                }
              },
            ]}
            parentChildData={(row, rows) => rows.find(a => !a.isNew && a.id === row.parentId)}
            {...this.props}

            data={this.getFilteredData(this.props.data, this.state.search)}
            actions={[
              rowData => rowData.group ? rowData.group && rowData.level < 2 && ({
                icon: (props) => <AddButton {...props} marginZero />, //'add',
                iconProps: {
                  style: {
                    fontSize: 24,
                    color: defaultColor
                  }
                },
                tooltip: 'Adicionar',
                onClick: (event, rowData) => this.props.onAddChildren(rowData)
              }) : !rowData.group && isAdmin && ({
                icon: (props) => <AddButton {...props} marginZero />, //'add',
                iconProps: {
                  style: {
                    fontSize: 24,
                    color: defaultColor
                  }
                },
                tooltip: 'Adicionar',
                onClick: (event, rowData) => this.props.onAddChildren(rowData)
              })
              ,
              rowData => rowData.level === 1 && ({
                icon: (props) => <SubtitleButton {...props} marginZero />, //'subtitles',
                iconProps: {
                  style: {
                    fontSize: 24,
                    color: defaultColor
                  }
                },
                tooltip: 'Anúncios',
                onClick: (event, rowData) => this.props.onAdvertising(rowData)
              }),

              rowData => rowData.group ? rowData.group && ({
                icon: (props) => <EditButton {...props} marginZero />,//'edit',
                iconProps: {
                  style: {
                    fontSize: 24,
                    color: defaultColor
                  }
                },
                tooltip: 'Editar',
                onClick: (_event, rowData) => this.props.onEditClick(rowData)
              }) : !rowData.group && isAdmin && ({
                icon: (props) => <EditButton {...props} marginZero />,//'edit',
                iconProps: {
                  style: {
                    fontSize: 24,
                    color: defaultColor
                  }
                },
                tooltip: 'Editar',
                onClick: (_event, rowData) => this.props.onEditClick(rowData)
              }),
               rowData => rowData.group ? rowData.group && ({
                 icon: (props) => <DeleteButton {...props} marginZero />,//'edit',
                 iconProps: {
                   style: {
                     fontSize: 24,
                     color: defaultColor
                   }
                 },
                 onClick: (_event, rowData) => this.props.onDeleteClick(rowData),
 
               }) :
                 !rowData.group && isAdmin && ({
                   icon: (props) => <DeleteButton {...props} marginZero />,//'edit',
                   iconProps: {
                     style: {
                       fontSize: 24,
                       color: defaultColor
                     }
                   },
                   onClick: (_event, rowData) => this.props.onDeleteClick(rowData),
 
                 })
               

            ]}
            filterComponent={() => {
              return <div className="filter-container">
                <div className="actions">
                  <FilterSearch onFilterSearch={this.onFilterSearch} value={search} />
                </div>
              </div>
            }}


          /* components={{
            Actions: (item) => {
              return <MTableActions {...item} />
            },
            FilterRow: (item) => {
              this.onFilterChanged = item.onFilterChanged;
              return (
                <tr className="filter-tr">
                  <td colspan="3">
                    <table className="table-filter-row">
                      <MTableFilterRow {...item} />
                    </table>
                  </td>
                </tr>
              )
            }
          }} */
          />
          {/* </div> */}
        </div>
      </>
    );
  }
}

/*
FilterRow: (item) => {
                console.log({item});
                return (
                  <tr className="filter">
                    <td colspan="3">
                      <table className="table-filter-row">
                        <MTableFilterRow {...item} />
                      </table>
                    </td>
                  </tr>
                )
              }
<Picky
                        options={this.props.comboOptions}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Filtrar por tags"
                        filterPlaceholder="Buscar"
                        valueKey="value"
                        labelKey="label"
                        value={[]}
                        multiple={true}
                        includeFilter={true}
                        onChange={values => this.onFilter('tags', values)}
                        dropdownHeight={600}
                      />
components={{
  Actions: (item) => {
    if (item.data || (item.actions && item.actions.length > 1)) {
      return <MTableActions {...item} />
    } else {
      return (
        <div className="actions">
          <Select noOptionsMessage={() => "Não há opções para mostrar"}
            className="react-select primary"
            classNamePrefix="react-select"
            placeholder="Tipo"
            name="multipleSelect"
            closeMenuOnSelect={false}
            // value={groups}
            // isMulti
            // onChange={value => onChange("groups", value)}
            options={normalizeCombo("id", "name", this.props.data)}
          />
          <MTableActions {...item} />
        </div>
      )
    }
  },
}}
*/

/**
render: rowData => !rowData || rowData.isNew ?
<div><div><Input className="editing-input-custom" placeholder="Digite o nome" onChange={(event) => this.props.onValueChange("name", event.target.value)} /></div></div> : (
<Row className="row-user">
<Col className="details truncated">{rowData.name}</Col>
{rowData.level < 2 && <IconButton onClick={() => this.props.onAddChildren(rowData)}>
<Add />
</IconButton>}
</Row>
)
import Picky from 'react-picky';
import 'react-picky/dist/picky.css'; // Include CSS
import TagPicker from '../components/TagPicker/TagPicker';
{rowData.level < 2 && <IconButton onClick={() => this.props.onAddChildren(rowData)}> <Add /></IconButton>}
 */

export default Skills;
