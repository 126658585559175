import React from "react";
import { Card, Col } from "reactstrap";
import { NivoPieChart } from "../Charts/NivoPieChart";

const getNumber = (data, type, count) => {
    let number = data.filter(item => type ? item.status === type : !item.status).length
    if (count) {
        return parseFloat(((number / data.length) * 100 || 0).toFixed(2))
    }
    return number > 1 ? `${number} atividades` : `${number} atividade`
}

const ActivityCard = ({ data }) => {

    const dataFormatted = [
        {
            "id": "Planejado",
            "label": `Planejado (${getNumber(data, 1)})`,
            "value": getNumber(data, 1, true),
            "color": "hsl(0,0%,37%)"
        },
        {
            "id": "Liberado",
            "label": `Liberado (${getNumber(data, 2)})`,
            "value": getNumber(data, 2, true),
            "color": "hsl(207,89%,67%)"
        },
        {
            "id": "Executando",
            "label": `Executando (${getNumber(data, 3)})`,
            "value": getNumber(data, 3, true),
            "color": "hsl(16,82%,63%)"
        },
        {
            "id": "Concluído",
            "label": `Concluído (${getNumber(data, 4)})`,
            "value": getNumber(data, 4, true),
            "color": "hsl(146,52%,62%))",
        },
        {
            "id": "Parado",
            "label": `Parado (${getNumber(data, 5)})`,
            "value": getNumber(data, 5, true),
            "color": "hsl(354,70%,53%)"
        },
        {
            "id": "Impedido",
            "label": `Impedido (${getNumber(data, 6)})`,
            "value": getNumber(data, 6, true),
            "color": "hsl(5,75%,60%)"
        },

    ]

    return (
        <Col lg="6" md="12">
            <Card style={{ padding: 20, height: 500, width: "100%", marginBottom: 10 }}>
                <div style={{ display: "flex", fontWeight: "bold", fontSize: 20, textAlign: 'center', justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
                    {`Estado do cronograma - ${data.length} ${data.length > 1 ? "atividades" : "atividade"}`}
                </div>
                <NivoPieChart data={dataFormatted} />
            </Card>
        </Col>
    )
}

export default ActivityCard;