import { getItem, addItem} from './firebaseFunctions';
import { db } from './firebaseConfig';

export const addConfirmRead = async (item) => {
    return addItem(`alertConfirmRead`, item);
}


export const getConfirmRead = async (item) => {
    
        return getItem(`alertConfirmRead`, item);
   
}