import { doLogin, verifyUser } from "core/database/user";
import { actionsCreator } from "core/utils/redux/Actions";
import ActionCreator from "core/utils/redux/ActionCreator";

const { set } = new ActionCreator("login");

const { actions, functions } = actionsCreator("login", "logout");
export { actions as userActions };

export const groupSelect = set("SET_GROUPSELECT_SUCCESS");
export const setLoginData = set("SET_LOGIN_DATA");

export const signIn = (email, password) => {
	return dispatch => {
		dispatch(functions.getLoginBegin({}))
		return doLogin(email, password).then(response => {
			dispatch(functions.getLoginSuccess({ ...response, loaded: true }));
		}).catch(error => {
			console.log(error);
			dispatch(functions.getLoginError(error.message))
		})
	}
}

export const silentSignIn = (email) => {
	return dispatch => {
		dispatch(functions.getLoginBegin({}))
		return verifyUser({email}).then(response => {
			dispatch(functions.getLoginSuccess({ ...response, loaded: true }));
		}).catch(error => {
			dispatch(logout())
		})
	}
}

export const markAsLoaded = () => {
	return dispatch => {
		dispatch(functions.getLoginSuccess({ loaded: true }));
	}
}

export const verifyUserData = (email) => {
	return dispatch => {
		dispatch(functions.getLoginBegin({}));
		return verifyUser({email}).then(response => {
			dispatch(functions.getLoginSuccess({ ...response, loaded: true }));
		}).catch(error => {
			dispatch(logout())
		})
	}
}

export const logout = () => {
	return dispatch => {
		dispatch(functions.getLogoutSuccess({loaded: true}))
  }
}
export const setErrorMessage = (errorMessage) => {
	return dispatch => {
		dispatch(functions.getLoginError(errorMessage))
  }
}
