import React from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { storage } from "../../core/database/firebaseConfig";
import { OutlineButton } from '../../layoutV2/components/Button';
import { getItem } from '../../core/database/firebaseFunctions';

export const downloadFolderAsZip = async (idWork, nameWork, setIsLoadingZip) => {
    const inspections = await getItem(`Works/${idWork}/inpections`)
    let zip = new JSZip();
    setIsLoadingZip(true)
    for (let i = 0; i < inspections.length; i++) {
        const inspection = inspections[i]
        let img = zip.folder(inspection.name);
        const folderRef = await storage.ref(`Works/${idWork}/inpections/${inspection.id}/images`).listAll().then(response => response.items)
        const imagesFirestore = await getItem(`Works/${idWork}/inpections/${inspection.id}/images`).then(items => {
            return items.map(item => {
                if(!item.url.startsWith("https://")){
                    return;
                }
                const path = `${item.url}`.replace("https://firebasestorage.googleapis.com/v0/b/app-ike.appspot.com/o/", "").replaceAll("%2F", "/").split("?")[0]
                return storage.ref(path);
            }).filter(item => item)
        })
        // const folderRefImages = await storage.ref(`Images/${idWork}/${inspection.id}`).listAll().then(response => response.items)
        const files = [...folderRef, ...imagesFirestore]
        const downloadUrls = await Promise.all(
            files.map((reference) => reference.getDownloadURL().catch((e) => console.log({e})))
        );
        const downloadedFiles = await Promise.all(downloadUrls.map(url => fetch(url).then(res => res.blob())));
        downloadedFiles.forEach((file, i) => file && img.file(files[i].name, file));
    }

    zip.generateAsync({ type: "blob" })
        .then(function (content) {
            saveAs(content, `${nameWork}.zip`);
        });
    setIsLoadingZip(false)
};

export const ButtonExport = ({ id, nameWork, setIsLoadingZip }) => {
    return (
        <OutlineButton label={'Exportar Imagens'} onClick={() => downloadFolderAsZip(id, nameWork, setIsLoadingZip)} />
    )
};