import React, { useState } from 'react';
import Checkbox from 'rc-checkbox';
import { PrimaryButton } from '../layoutV2/components/Button'
import { useSelector } from 'react-redux';
import { updateItem } from '../core/database/firebaseFunctions'
import Alert from '../components/Alert/Alert';
import AdminNavbar from '../components/Navbars/AdminNavbar';
import { setLoginData } from '../core/redux-store/login/Actions';
import { useDispatch } from 'react-redux';

const TermsContainer = (props) => {
	const dispatch = useDispatch()
	const [openButton, setOpenButton] = useState(false)
	const routes = props.location.pathname;
	const user = useSelector(state => state.login)

	const onChangeChecked = () => {
		setOpenButton(!openButton)
	}

	const continueButton = async () => {
		if (user) {
			await updateItem('Users', { id: user.id, term_accepted_date: new Date() })
			await dispatch(setLoginData({ term_accepted_date: new Date() }))
			// Alert.success('Termos aceitos com sucesso!')
			props.history.push("/admin/dashboard");
		}
	}

	return (
		<div className={routes.includes("auth") ? 'div-primary-auth-terms' : 'content div-primary-terms'}>
			{routes.includes("admin") && <AdminNavbar />}
			<div class="entry-content" className={routes.includes("auth") ? 'div-terms-auth' : "div-terms"}>
				<p><strong>TERMO DE USO DO APLICATIVO</strong></p>
				<ol>
					<li><strong> ACEITAÇÃO</strong></li>
				</ol>
				<p>Este é um&nbsp;<strong>CONTRATO</strong>&nbsp;firmado entre você, de agora em diante denominado como&nbsp;<strong>USUÁRIO</strong>, e a <strong>PLANEO Engenharia.</strong></p>
				<p>PLANEO Engenharia de Planejamento e Perícias, empresa brasileira, inscrita no CNPJ sob nº 09.073.899/0001-47, com sede à Av. Mal. Castelo Branco, 65 - 511 - Campinas, São José - SC, 88101-020.</p>
				<p>Este “Termo de Uso do Aplicativo” rege o uso deste aplicativo disponibilizado pela <strong>PLANEO Engenharia de Planejamento e Perícias</strong>&nbsp;para dispositivos móveis nas plataformas Android e iOS.</p>
				<p><strong>SE VOCÊ NÃO CONCORDAR COM ESTES TERMOS, NÃO USE ESTE APLICATIVO</strong>.</p>
				<p>Leia as condições de uso atentamente, pois o uso deste aplicativo significa que você aceitou todos os termos e concorda em cumpri-los. Se você,&nbsp;<strong>USUÁRIO</strong>, for menor de idade ou declarado incapaz em quaisquer aspectos, precisará da permissão de seus pais ou responsáveis que também deverão concordar com estes mesmos termos e condições.</p>
				<ol start="2">
					<li><strong> LICENÇA LIMITADA</strong></li>
				</ol>
				<p>Você recebeu uma licença limitada, não transferível, não exclusiva e revogável para baixar, instalar, executar e utilizar este aplicativo em seu dispositivo. Você reconhece e concorda que a <strong>PLANEO Engenharia de Planejamento e Perícias</strong>&nbsp;concede ao&nbsp;<strong>USUÁRIO</strong>&nbsp;uma licença exclusiva para uso e desta forma não lhe transfere os direitos sobre o produto.</p>
				<p>O aplicativo deverá ser utilizado por você,&nbsp;<strong>USUÁRIO</strong>. A venda, transferência, modificação, engenharia reversa ou distribuição bem como a cópia de textos, imagens ou quaisquer partes nele contido é expressamente proibida.</p>
				<ol start="3">
					<li><strong> ALTERAÇÕES, MODIFICAÇÕES E RESCISÃO</strong></li>
				</ol>
				<p>A&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong>&nbsp;reserva-se no direito de, a qualquer tempo, modificar estes termos seja incluindo, removendo ou alterando quaisquer de suas cláusulas. Tais modificações terão efeito imediato. Após publicadas tais alterações, ao continuar com o uso do aplicativo você terá aceitado e concordado em cumprir os termos modificados.</p>
				<p>A&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong>&nbsp;pode, de tempos em tempos, modificar ou descontinuar (temporária ou permanentemente) a distribuição ou a atualização deste aplicativo.</p>
				<p>A&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong>&nbsp;não é obrigada a fornecer nenhum serviço de suporte para este aplicativo.</p>
				<p>O&nbsp;<strong>USUÁRIO</strong>&nbsp;não poderá responsabilizar a&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong>&nbsp;nem seus diretores, executivos, funcionários, afiliados, agentes, contratados ou licenciadores por quaisquer modificações, suspensões ou descontinuidade do aplicativo.</p>
				<p><strong>CONSENTIMENTO PARA COLETA E USO DE DADOS</strong></p>
				<p>Você concorda que a&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong>&nbsp;pode coletar e usar dados técnicos de seu dispositivo tais como especificações, configurações, versões de sistema operacional, tipo de conexão à internet e afins.</p>
				<p><strong>ISENÇÃO DE GARANTIAS E LIMITAÇÕES DE RESPONSABILIDADE</strong></p>
				<p>Este aplicativo estará em contínuo desenvolvimento e pode conter erros e, por isso, o uso é fornecido “no estado em que se encontra” e sob risco do&nbsp;<strong>USUÁRIO</strong>&nbsp;final. Na extensão máxima permitida pela legislação aplicável a&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong>&nbsp;e seus fornecedores isentam-se de quaisquer garantias e condições expressas ou implícitas incluindo, sem limitação, garantias de comercialização, adequação a um propósito específico, titularidade e não violação no que diz respeito ao aplicativo e qualquer um de seus componentes ou ainda à prestação ou não de serviços de suporte. A&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong>&nbsp;não garante que a operação deste aplicativo seja contínua e sem defeitos.</p>
				<p>Exceto pelo estabelecido neste documento não há outras garantias, condições ou promessas aos aplicativos, expressas ou implícitas, e todas essas garantias, condições e promessas podem ser excluídas de acordo com o que é permitido por lei sem prejuízo à&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong>&nbsp;e seus colaboradores.</p>
				<ol>
					<li>A&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong> declara que não garante, nem assegura que o uso deste aplicativo será ininterrupto ou livre de erros e você concorda que a&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias </strong>poderá remover por períodos indefinidos ou cancelar este aplicativo a qualquer momento sem que você seja avisado.</li>
					<li>A&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong> declara que não garante, nem assegura que este aplicativo esteja livre de perda, interrupção, ataque, vírus, interferência, pirataria ou outra invasão de segurança e isenta-se de qualquer responsabilidade em relação à essas questões. Você é responsável pelo backup do seu próprio dispositivo.</li>
				</ol>
				<p>III. Em hipótese alguma a&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong>, bem como seus diretores, executivos, funcionários, afiliadas, agentes, contratados ou licenciadores responsabilizar-se-ão por perdas ou danos causados pelo uso do aplicativo.</p>
				<ol>
					<li>Os conteúdos de anúncios oferecidos pelo aplicativo são de total responsabilidade de seus idealizadores. A&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong>isenta-se de qualquer responsabilidade direta ou indireta sobre estes conteúdos e o acesso aos mesmos é facultativo ao&nbsp;<strong>USUÁRIO</strong>.</li>
				</ol>
				<p><strong>DA LEI APLICÁVEL</strong></p>
				<p>Este&nbsp;<strong>CONTRATO</strong>&nbsp;será regido, interpretado e se sujeitará às leis brasileiras e, o&nbsp;<strong>USUÁRIO</strong>&nbsp;e a&nbsp;<strong>PLANEO Engenharia de Planejamento e Perícias</strong>&nbsp;desde logo elegem, de forma irrevogável e irretratável, o foro regional de Florianópolis, Estado de Santa Catarina para dirimir quaisquer dúvidas ou controvérsias oriundas deste&nbsp;<strong>CONTRATO</strong>, com a exclusão de qualquer outro, por mais privilegiado que seja.</p>
				<p>&nbsp;</p>
			</div>
			{routes.includes("auth") ? null : <div className="footer-terms">
				<p className='p-terms'><Checkbox checked={openButton} style={{ margin: 5 }} onClick={onChangeChecked} /> Aceitar termos de uso.</p>
				<div className='div-btn'>
					<PrimaryButton disabled={!openButton} label={'Continuar'} onClick={continueButton} className={'v2-primary-btn terms-btn v2-button-text'} />
					<div style={{ flex: 8 }} />
				</div>
			</div>}
		</div >
	)
}

export default TermsContainer;