import React, { useEffect, useState } from "react";
import { SelectGroup } from "../views/SelectGroup";
import { getGroupData } from "../core/redux-store/group/Actions";
import { useDispatch, useSelector } from "react-redux";
import { setLoginData } from "../core/redux-store/login/Actions";
import { fireSQL } from "../core/database/firebaseConfig";

const SelectGroupContainer = (props) => {
    const dispatch = useDispatch()
    const allGroups = useSelector(state => state.group.items)
    const user = useSelector(state => state.login)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        dispatch(getGroupData(user))
        setLoading(false)
    }, [])

    const onLogin = async (group) => {
        if (group.id) {
            await dispatch(setLoginData({ network: group.id, groupData: group }))
            props.history.push("/admin/dashboard");
        }
    }
    
    return (
        <SelectGroup
            {...props}
            allGroups={allGroups} //.filter(group => groups.find(item => group.id === item.workGroup))
            loading={loading}
            onLogin={onLogin}
            user={user}
        />
    );
}

export default SelectGroupContainer;