import React, { Component } from 'react';
import { connect } from 'react-redux';
import WorksCompanyView from '../../views/works/WorksCompanyView';
import { withRouter } from 'react-router-dom';
import CardWorksContainer from './CardWorksContainer';
import { Row } from 'reactstrap';
import WorksEmptyView from '../../views/works/WorksEmptyView';
import { CircularProgress } from '@material-ui/core';
import { Collapse } from 'reactstrap';

function mapStateToProps(state) {
    return {
        //works: state.works.items
    };
}

class WorksCompanyContainer extends Component {

    constructor(props) {
        super(props);

        this.onAddWork = this.onAddWork.bind(this)
        this.onCompanyEdit = this.onCompanyEdit.bind(this)
    }

    onAddWork() {
        const { history, id, name, file } = this.props;
        history.push("/admin/works-details", { idCompany: id });
    }

    onCompanyEdit() {
        const { history, id } = this.props;
        history.push("/admin/company-details", { id });
    }

    render() {
        const { works, name, id, file, collapse } = this.props;
        return (
            <div key={id}>
                <WorksCompanyView
                    onAddWork={this.onAddWork}
                    onCompanyEdit={this.onCompanyEdit}
                    collapse={collapse}
                    {...this.props}
                />
                <Collapse isOpen={collapse}>
                    {!works && <div className="works-progress"><CircularProgress /></div>}
                    {(works && works.length === 0) && <WorksEmptyView message="Não há obras cadastradas para essa empresa" />}
                    <Row>
                        {works && works.map(work => {
                            return <CardWorksContainer company={{ id, name, image: file.url }} {...work} />
                        })}
                    </Row>
                </Collapse>
            </div>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
)(WorksCompanyContainer));