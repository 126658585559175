import React, { useState, useEffect, useMemo } from 'react';
import { ListReport } from '../../components/Report/ListReport';
import { ListGroup, ListGroupItem, Col, Card, Row, UncontrolledTooltip, Button } from 'reactstrap';
import { FilterStep, FilterImpact, FilterInspection, FilterOrdination, FilterDate, FilterPicker } from '../../components/Filter/FilterHooks';
import { normalizeCombo } from '../../core/utils/Utils';
import { PDFModal, onPDFGenerate } from '../../components/PDF/PDFModal';
import LoadingOverlay from 'react-loading-overlay';
import { getInspections, deleteInspection } from '../../core/database/inspections';
import { toDate, toDateFormat } from './InspectionView';
import moment from 'moment';
import SkillsCheck from '../../components/SkillsCheck/SkillsCheck';
import ProblemRecurrency from '../../components/ProblemRecurrency/ProblemRecurrency';
import { useDispatch } from 'react-redux';
import { ModalMessageContainer } from '../../container/Message/ModalMessageContainer';
import { getItem } from '../../core/database/firebaseFunctions';
import { fireSQL } from '../../core/database/firebaseConfig';
import sort from 'fast-sort';
import { useSelector } from 'react-redux';
import { DefaultOrdination, DefaultImpact, DefaultState, DefaultSignal } from '../DefaultFilters'
import { DateRange, SelectInput } from '../../layoutV2/components/Form';
import { MdPlaylistAddCheck, MdFilterList } from 'react-icons/md'
import { HiOutlineDocumentReport } from "react-icons/hi";

let defaultFilterValues = {
    step: [],
    impact: [],
    inspection: [],
    dates: {},
    quality: [],
    ordination: { value: 1, label: "Etapa" },
    showBlankSpaces: false,
};

let count;

const getFilteredData = (data, filterValues, sharedComments) => {
    return data.filter(item => doFilter(item, filterValues, sharedComments))
}

const doFilter = (rowData, filterValues, sharedComments) => {
    let hasSteps = true;

    if (filterValues.step) {
        const arrSteps = Array.isArray(filterValues.step) ? filterValues.step.map(item => {
            return item.value
        }) : [filterValues.step.value]

        // console.log("-> ", filterValues, rowData.step, arrSteps)
        hasSteps = filterValues.step.length == 0 || (rowData.step && arrSteps.includes(rowData.step.id));
    }

    let hasImpact = true;
    if (filterValues.impact) {
        const arrImpacts = Array.isArray(filterValues.impact) ? filterValues.impact.map(item => {
            return item.value
        }) : [filterValues.impact.value]

        // console.log("-> ", filterValues, rowData, arrImpacts)
        hasImpact = filterValues.impact.length == 0 || (rowData.scale && arrImpacts.includes(rowData.scale));
    }

    let hasDates = true;
    if (filterValues.dates && filterValues.dates.startDate && filterValues.dates.endDate) {
        hasDates = moment(toDate(rowData.date)).hours(0).minutes(0).seconds(0).milliseconds(0).diff(moment(filterValues.dates.startDate, "DD-MM-YYYY"), 'days') >= 0 &&
            moment(toDate(rowData.date)).hours(0).minutes(0).seconds(0).milliseconds(0).diff(moment(filterValues.dates.endDate, "DD-MM-YYYY"), 'days') <= 0
    }

    let hasSubjects = true;
    if (filterValues.subject) {
        const arrSubjects = Array.isArray(filterValues.subject) ? filterValues.subject.map(item => {
            return item.value
        }) : [filterValues.subject.value]

        hasSubjects = filterValues.subject.length == 0 || (rowData.subject && arrSubjects.includes(rowData.subject.id));
    }

    let hasUsers = true;
    if (filterValues.users) {
        const arrUsers = Array.isArray(filterValues.users) ? filterValues.users.map(item => {
            return item.value
        }) : [filterValues.users.value]

        const commentIds = sharedComments.filter(item => arrUsers.includes(item.idUser)).map(item => item.idComment);
        hasUsers = filterValues.users.length == 0 || (commentIds.includes(rowData.id));
    }

    let hasSignal = true;
    // let hasTypeImprovement = true;
    if (filterValues.signal) {
        const arrSignal = Array.isArray(filterValues.signal) ? filterValues.signal.map(item => {
            return item.value
        }) : [filterValues.signal.value]

        hasSignal = filterValues.signal.length == 0 || (rowData.signal && (Array.isArray(rowData.signal) ? rowData.signal : [rowData.signal]).filter(item => arrSignal.includes(item)).length === arrSignal.length);
    }

    let hasState = true;
    if (filterValues.state) {
        const arrState = Array.isArray(filterValues.state) ? filterValues.state.map(item => {
            return item.value
        }) : [filterValues.state.value]

        hasState = filterValues.state.length == 0 || arrState.includes(rowData.state);
    }

    return hasSteps && hasImpact && hasDates && hasSubjects && hasUsers && hasSignal && hasState;
}

export const findOne = (haystack, arr) => {
    return arr && arr.some(function (v) {
        return haystack && v && haystack.indexOf(v) >= 0;
    });
};

const getSections = (data, ordination, inspections) => {
    const SECTIONS = [];

    //etapas
    if (ordination && ordination.value === 2) {
        data.map(item => {
            const hasParent = SECTIONS.filter(section => toDateFormat(section.date) === toDateFormat(item.date)).length > 0;
            if (!hasParent) {
                if (item.date) {
                    SECTIONS.push({
                        id: toDateFormat(item.date),
                        date: item.date,
                        name: toDateFormat(item.date),
                        isHeader: true,
                        content: data.filter(data => toDateFormat(item.date) === toDateFormat(data.date))
                    })
                }
            }
        })
        SECTIONS.sort((a, b) => toDate(a.date).getTime() !== toDate(b.date).getTime() ? toDate(a.date).getTime() < toDate(b.date).getTime() ? -1 : 1 : 0);
    } else if(ordination && ordination.value === 3) {
        data.map(item => {
            const hasParent = SECTIONS.filter(section => section.idInspection === item.idInspection).length > 0;
            if (!hasParent) {
                const inspection = inspections && inspections.find(a => a.id === item.idInspection)
                if (inspection) {
                    SECTIONS.push({
                        // ...item.step,
                        id: inspection.id,
                        name: inspection.name,
                        idInspection: item.idInspection,
                        isHeader: true,
                        content: data.filter(data => item.idInspection === data.idInspection)
                    })
                }
            }
        })
    } else {
        data.map(item => {
            const hasParent = SECTIONS.filter(section => section.id && item.step && section.id === item.step.id).length > 0;
            if (!hasParent) {
                if (item.step) {
                    SECTIONS.push({
                        ...item.step,
                        isHeader: true,
                        content: data.filter(data => item.step && data.step && item.step.id === data.step.id)
                    })
                }
            }
        })
        SECTIONS.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
    }
    
    if(ordination && ordination.value !== 2){
        SECTIONS.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
    }

    return SECTIONS;
}

const getSubjects = (sections) => {
    var SUBJECTS = []

    sections.map(section => {
        section.content.map(item => {
            if (item.subject && !SUBJECTS.find(row => item.subject.id === row.id)) {
                SUBJECTS.push(item.subject);
            }
        })
    })

    return SUBJECTS
}

const getUsersByShared = (data) => {
    const ids = [...new Set(data.map(item => item.idUser))];

    return ids.map(id => {
        const user = data.find(item => item.idUser === id);

        return {
            value: id,
            label: user.nmUser
        }
    }).filter(item => item.value && item.label)
}

//await addItem(`SharedComment`, { idUser: item.id, nmUser: item.name, createdAt: new Date, idComment: message.work.id, idWork: message.work.idWork, nmWork: message.work.nmWork });

export const WorkReportView = ({ delComment, onSaveData, data, onSave, onSaveLinkData, onEditImage, onFinish, location, onDataChange, onSaveCustomImage, onSetInspections, skills, delReport }) => {
    const dispatch = useDispatch();
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [selecteds, setSelecteds] = useState([0]);
    const [sections, setSections] = useState([]);
    const [steps, setSteps] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [inspection, setInspection] = useState([]);
    const [filterValues, setFilterValues] = useState(defaultFilterValues);
    const [filter, hasFilter] = useState(false);
    const [stepsInspection, setStepsInspection] = useState([])
    const [recurrencies, setRecurrencies] = useState([]);
    const [downloadUrl, setDownloadUrl] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [sharedComments, setSharedComments] = useState([]);
    const loggedUser = useSelector(state => state.login)

    useEffect(() => {
        if (filterValues.inspection && filterValues.quality) {
            onSetInspections(filterValues.inspection.concat(filterValues.quality));
        }
    }, [filterValues && filterValues.inspection, filterValues && filterValues.quality])

    const onFilter = (field, value) => {
        // console.log({ field, value })
        const values = { ...filterValues, [field]: value }
        setFilterValues(values);
        defaultFilterValues = values;
        if (field === "inspection") {
            onSetInspections(value)
        }
    }

    useEffect(() => {
        setInspection([])
        setFilterValues({
            step: [],
            impact: [],
            inspection: [],
            dates: {},
            ordination: { value: 1, label: "Etapa" },
            showBlankSpaces: false,
        })
        getInspections(location.state.workId).then(items => {
            const groupAuth = loggedUser.groups && loggedUser.groups.find(item => item.details.id === location.state.company.id);
            const groupHasPower = groupAuth && groupAuth.hasPower === true;

            const inspections = items.filter(item => ((item && item.idUser) === loggedUser.id) || loggedUser.isAdmin || groupHasPower)
            setInspection(inspections);
        })
        getItem(`SharedComment`, { idWork: location.state.workId }).then(items => {
            setSharedComments(items);
        })
    }, [location.state && location.state.workId])

    useEffect(() => {
        // console.log({ data, filterValues })
        const newSections = getSections(getFilteredData(data, filterValues, sharedComments), filterValues.ordination, inspection);
        setSections(newSections);
        setStepsInspection(newSections);
        const sections = getSections(data)
        setSteps(sections);
        setSubjects(getSubjects(newSections));
    }, [data, filterValues, inspection])

    const onShare = (data) => {
        const section = sections.find(item => item.id === data.step.id);
        const customizedSections = [{ ...section, content: [data] }]
        onPDFGenerate(dispatch, setDownloadUrl, location.state, customizedSections, [data], setLoadingPdf, setIsOpen, false, [], false, false, [], loggedUser)
    }

    function renderHeader(section, index) {
        let label = section.content.length > 1 ? "apontamentos" : "apontamento"

        const onHeaderClick = () => {
            if (selecteds.includes(index)) {
                setSelecteds(selecteds.filter(item => item !== index));
            } else {
                setSelecteds(selecteds.concat([index]))
            }
        }
        return (
            <ListGroupItem className="report-header" onClick={onHeaderClick}>
                {section.name}
                <span style={{ fontSize: 13, color: "#66615b" }}> ({section.content.length} {label})</span>
            </ListGroupItem>
        );
    };

    function sectionContent(content, index, allItems) {
        // content.sort((a, b) => a.commentId && b.commentId && a.commentId.localeCompare(b.commentId));

        // if (!selecteds.includes(index)) {
        //     count += content.length;
        // }

        // console.log({ content, allItems })

        sort(allItems).asc([
            u => u.step && u.step.name,
            u => toDate(u.date).getTime()
        ]);

        sort(content).asc([
            u => u.step && u.step.id,
            u => toDate(u.date).getTime()
        ]);

        return selecteds.includes(index) && content.map((content, index) => {
            const count = allItems.findIndex(item => item.id === content.id) + 1;

            return (
                <ListGroupItem key={`${content.id}`}>
                    <ListReport
                        count={count}
                        showBlankSpaces={filterValues.showBlankSpaces}
                        key={index}
                        onSaveCustomImage={onSaveCustomImage}
                        data={content}
                        index={index}
                        onSave={onSave}
                        onSaveLinkData={onSaveLinkData}
                        onEditImage={onEditImage}
                        location={location}
                        onDataChange={onDataChange}
                        allData={allItems}//getFilteredData(data, filterValues, sharedComments)
                        impact={DefaultImpact(false).filter(impact => content.scale === impact.value)[0]}
                        delReport={delReport}
                        delComment={delComment}
                        onSaveData={onSaveData}
                        onShare={onShare}
                        sharedComments={sharedComments}
                        ordination={filterValues.ordination}
                        onFinish={onFinish}

                    />
                </ListGroupItem>
            )
        })
    }

    const allItems = sections.map(item => item.content).flat();

    return (
        <div className="list-report">
            <Row className="filter-report">
                <Col lg="3" sm="12">
                    <SelectInput
                        onChange={(values) => onFilter("quality", values)}
                        value={filterValues.quality}
                        options={normalizeCombo("id", "name", inspection.filter(item => item.isQuality))}
                        labelledBy={"Insp. Guiadas"}
                        title={"Insp. Guiadas"}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <SelectInput
                        onChange={(values) => onFilter("inspection", values)}
                        value={filterValues.inspection}
                        options={normalizeCombo("id", "name", inspection.filter(item => !item.isQuality))}
                        labelledBy={"Insp. Livre"}
                        title={"Insp. Livre"}
                    />
                </Col>
                <Button color="primary" id="showSteps" size="sm" className={`${filterValues.showSteps ? "button-alert active" : "button-alert"}`} onClick={() => onFilter("showSteps", !filterValues.showSteps)} >
                    <MdPlaylistAddCheck size={25} color={`${filterValues.showSteps ? "success" : "default"}`} />
                </Button>
                <UncontrolledTooltip placement="top" target="showSteps">
                    Mostrar capa no relatório
                </UncontrolledTooltip>
                <Button id="advancedFilter" size="sm" className={`button-alert`} onClick={() => hasFilter(!filter)} >
                    <MdFilterList size={25} />
                </Button>
                <UncontrolledTooltip placement="top" target="advancedFilter">
                    {filter ? "Esconder filtro avançado" : "Mostrar filtro avançado"}
                </UncontrolledTooltip>
            </Row>
            {filter && <>
                <Row className="filter-report">
                    <Col lg="3" sm="12" >
                        <DateRange
                            onDateChange={onFilter}
                            startDate={filterValues.dates.startDate}
                            endDate={filterValues.dates.endDate}
                        />
                    </Col>
                    <Col lg="3" sm="12">
                        <SelectInput
                            onChange={(values) => onFilter("step", values)}
                            value={filterValues.step}
                            options={normalizeCombo("id", "name", steps)}
                            labelledBy={"Filtrar por etapa"}
                            title={"Filtrar por etapa"}
                        />
                    </Col>
                    <Col lg="3" sm="12">
                        <SelectInput
                            onChange={(values) => onFilter("impact", values)}
                            value={filterValues.impact}
                            options={DefaultImpact()}
                            labelledBy={"Filtrar por impacto"}
                            title={"Filtrar por impacto"}
                            hasSort={false}
                        />
                    </Col>
                    <Col lg="3" sm="12">
                        <SelectInput
                            onChange={(values) => onFilter("ordination", values)}
                            value={filterValues.ordination}
                            options={DefaultOrdination}
                            labelledBy={"Agrupar por"}
                            title={"Agrupar por"}
                            isSingle={true}
                        />
                    </Col>
                </Row>
                <Row className="filter-report last">
                    <Col lg="3" sm="12">
                        <SelectInput
                            onChange={(values) => onFilter("state", values)}
                            value={filterValues.state}
                            options={DefaultState}
                            labelledBy={"Filtrar por estado"}
                            title={"Filtrar por estado"}
                        />
                    </Col>
                    <Col lg="3" sm="12">
                        <SelectInput
                            onChange={(values) => onFilter("subject", values)}
                            value={filterValues.subject}
                            options={normalizeCombo("id", "name", subjects)}
                            labelledBy={"Filtrar por categoria"}
                            title={"Filtrar por categoria"}
                            hasSort={false}
                        />
                    </Col>
                    <Col lg="3" sm="12" >
                        <SelectInput
                            onChange={(values) => onFilter("users", values)}
                            value={filterValues.users}
                            options={getUsersByShared(sharedComments)}
                            labelledBy={"Compartilhado com usuário"}
                            title={"Compartilhado com usuário"}
                        />
                    </Col>
                    <Col lg="3" sm="12">
                        <SelectInput
                            onChange={(values) => onFilter("signal", values)}
                            value={filterValues.signal}
                            options={DefaultSignal}
                            labelledBy={"Filtrar pelo sinalizar"}
                            title={"Filtrar pelo sinalizar"}
                        />
                    </Col>
                </Row>
            </>}
            <Row>
                <PDFModal
                    inspection={inspection}
                    recurrencies={recurrencies}
                    showRecurrency={filterValues.showRecurrency}
                    showSteps={filterValues.showSteps}
                    steps={stepsInspection}
                    showBlankSpaces={filterValues.showBlankSpaces}
                    sections={sections}
                    location={location}
                    setLoadingPdf={setLoadingPdf}
                    loadingPdf={loadingPdf}
                    allData={allItems}
                    downloadUrl={downloadUrl}
                    setDownloadUrl={setDownloadUrl}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    user={loggedUser}
                />
            </Row>
            {filterValues.showSteps && <SkillsCheck sections={sections} onStepsChange={setStepsInspection} location={location} />}
            {filterValues.showRecurrency && <ProblemRecurrency data={data} idCompany={location.state.company.id} onRecurrencies={setRecurrencies} />}
            {sections.length === 0 ?
                <span className="empty-report">
                    <div className="empty-icon">
                        <HiOutlineDocumentReport />
                    </div>
                    Para visualizar ou gerar o relatório selecione um ou mais itens do filtro que possuam dados!
                </span> : <div className="content-report" style={{ padding: 0 }} >
                    {
                        sections.map((section, index) => {
                            return (
                                <ListGroup key={`${section.id}-${section.content.length}`} className="report-group">
                                    {renderHeader(section, index)}
                                    {sectionContent(section.content, index, allItems)}
                                </ListGroup>
                            )
                        })
                    }
                </div>
            }
        </div >
    )
};