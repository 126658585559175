export const primaryColor = "#51cbce"; //
export const successColor = "#6bd098";
export const infoColor = "#003E40";
export const warningColor = "#FDBA4D";
export const dangerColor = "#ef8157";
export const linkDisableColor = "#666666";
export const purpleColor = "#EF4056";
export const defaultColor = "#66615B";

/**
 * 
 * $primary-color:              #51cbce !default;
$success-color:              #6bd098 !default;
$info-color:                 #003E40 !default; // #51bcda !default;
$warning-color:              #FDBA4D !default; //#fbc658 !default;
$danger-color:               #ef8157 !default;
$link-disabled-color:        #666666 !default;
$purple-color:               #EF4056 !default; // #c178c1 !default;
$medium-pale-bg:             #F1EAE0 !default;
$default-color:              #66615B !default;
 */