import React, { Component } from 'react';
import { CardTitle, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { scopeHasPower } from '../../container/UserContainer';

class WorksCompanyView extends Component {
    render() {
        const { id, name, file, collapse, groups, isAdmin } = this.props;
        const hasPower = isAdmin || scopeHasPower(id, groups)

        return (
            <>
                <div key={id} className="works-company" style={{paddingBottom: 5}}>
                    <a 
                        onClick={() => this.props.onToggle(id)}
                        className="btn-toggle"
                    >
                        <i className={collapse ? "nc-icon nc-minimal-up" : "nc-icon nc-minimal-down"} />
                    </a>
                    <img className="company-image" src={file && file.url}/>
                    <CardTitle tag="h4" className="truncated">{name}</CardTitle>
                    {hasPower === true && <>
                        <Button 
                            onClick={this.props.onCompanyEdit}
                            className="btn-icon"
                            color="warning"
                            size="sm"
                            type="button"
                        >
                            <i className="fa fa-edit" />
                        </Button>{" "}
                        <Button 
                            onClick={this.props.onAddWork}
                            className="btn-icon"
                            color="primary"
                            size="sm"
                            type="button"
                        >
                            <i className="nc-icon nc-simple-add" />
                        </Button>{" "}
                    </>}
                </div>
            </>
        );
    }
}

export default connect(state => ({
    isAdmin: state.login.isAdmin,
    groups: state.login.groups
}))(WorksCompanyView);