import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import { toPng } from 'html-to-image';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import ImageEditorRc from 'react-cropper-image-editor';
import 'cropperjs/dist/cropper.css';
import sizeMe from 'react-sizeme';
import { Button } from 'reactstrap';
import { GridList, GridListTile } from '@material-ui/core';
import FileGalleryItem from '../../components/FileGalleryItem/FileGalleryItem';
import { pdfjs } from 'react-pdf';
import { OutlineButton } from '../../layoutV2/components/Button';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PdfView extends Component {

  constructor(props) {
    super(props)

    this.page = React.createRef();

    this.state = {
      numPages: null,
      pageNumber: 1,
      modal: true,
      files: []
    };

    this.createImage = this.createImage.bind(this);
    this.cropImage = this.cropImage.bind(this);
    this.onRemoveItem = this.onRemoveItem.bind(this);
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  goToPrevPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
  goToNextPage = () =>
    this.setState(state => ({ pageNumber: state.pageNumber + 1 }));

  createImage = () => {
    toPng(this.page.current)
      .then((imgUrl) => {
        this.setState({ imgUrl, height: this.page.current.clientHeight })
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }

  getImageEditor() {
    const { width } = this.props.size;
    const { imgUrl, height } = this.state;
    return (
      <ImageEditorRc
        className="editor"
        crossOrigin='true' // boolean, set it to true if your image is cors protected or it is hosted on cloud like aws s3 image server
        src={imgUrl}
        style={{ height }}
        minCanvasWidth={width}
        minCanvasHeight={height}
        zoomable={false}
        guides={false}
        rotatable={false}
        imageName='image name with extension to download'
        saveImage={this.cropImage} // it has to catch the returned data and do it whatever you want
      />
    )
  }

  componentWillReceiveProps(nextProps) {
    const { file } = this.props;

    if (file && nextProps.file) {
      if (file.url !== nextProps.file.url) {
        this.setState({
          numPages: null,
          pageNumber: 1,
          height: undefined
        })
      }
    } else {
      this.setState({
        numPages: null,
        pageNumber: 1,
        height: undefined
      })
    }
  }

  getDocument() {
    const { width } = this.props.size;
    const { pageNumber } = this.state;

    if (!this.props.file) {
      return;
    }

    return (
      <>
        <Document
          file={this.props.file.url}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} width={width} />
        </Document>
      </>
    )
  }

  getPdfControl() {
    const { width } = this.props.size;
    const { pageNumber, numPages, imgUrl, height } = this.state;

    return (
      <div>
        {numPages && <nav className="nav">
          <Button color="primary" disabled={imgUrl || pageNumber === 1} onClick={this.goToPrevPage}><i class="nc-icon nc-minimal-left"></i></Button>
          <p>
            Página {pageNumber} de {numPages}
          </p>
          <Button color="primary" disabled={imgUrl || numPages && pageNumber === numPages} onClick={this.goToNextPage}><i class="nc-icon nc-minimal-right"></i></Button>
        </nav>}
        <div className="pdf-container">
          <div style={{ height }} ref={this.page}>
            {imgUrl ? this.getImageEditor() : this.getDocument()}
          </div>
          {/* {numPages && <Button className="cut-button" color="primary" disabled={imgUrl} onClick={this.createImage}>Cortar Pdf</Button>} */}
          {numPages && <OutlineButton label={"Cortar Pdf"} onClick={this.createImage} disabled={imgUrl} />}
        </div>
        <div className="preview-container">
          <GridList /* cellHeight={160} */ cols={Math.floor(width / 150)} className="tile-grid">
            {this.state.files.map((file) => (
              <GridListTile key={file.url} cols={1} className="tile">
                <FileGalleryItem {...file} hideTile={true} canSelect={false} onRemove={this.onRemoveItem} />
              </GridListTile>
            ))}
          </GridList>
        </div>
      </div>
    );
  }

  onRemoveItem(item) {
    const { files } = this.state;
    this.setState({ files: files.filter(file => file.fileName !== item.fileName) })
  }

  cropImage(dataUrl) {
    const files = this.state.files.concat({ fileName: new Date().toString(), url: dataUrl });
    this.setState({ files, imgUrl: undefined });
    this.props.onChangeFiles(files);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    return this.getPdfControl();
  }
}

export default sizeMe()(PdfView);