import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import Select from 'react-select';
import { normalizeCombo } from '../../core/utils/Utils';
import RichEditorQuill from "../../components/RichEditor/RichEditorQuill";
import { NewModal } from '../../layoutV2/components/Modal/Modal';
import { SelectInput, TextInput } from '../../layoutV2/components/Form';
/*
<Modal isOpen={props.data ? true : false} toggle={props.toggle} style={{ maxWidth: '60%' }}>
                <ModalHeader toggle={props.toggle}></ModalHeader>
                </Modal>
*/
export const AlertView = (props) => {

    // console.log({ props })
    return (
        // <div style={{ maxWidth: 900, margin: "0 auto", display: "block" }}>
        <NewModal
            open={props.data !== undefined}
            toggle={props.toggle}
            onClickConfirm={props.saveData}
            title={"Cronograma"}
            label={"Confirmar"}
            className="report-modal schedule"
        >
            {/* <Label className='details-title-modal'>Criar agendamento</Label> */}
            {/* <ModalBody className='details-wizard'> */}
            {/* <Label className='details-title'>Selecionar pessoas</Label>
            <Select
                noOptionsMessage={() => "Não há opções para mostrar"}
                className="react-select dark-gray zIndexTop top"
                classNamePrefix="react-select"
                placeholder="Selecionar pessoas"
                name="multipleSelect"
                closeMenuOnSelect={true}
                isMulti
                options={props.workUsers}
                value={props.values.users}
                onChange={value => props.onChange("users", value)}
            /> */}
            <SelectInput
                options={props.workUsers}
                title="Selecionar pessoas"
                labelledBy={"Selecionar pessoas"}
                value={props.values.users}
                onChange={value => props.onChange("users", value)}
                isSingle={false}
            />
            {/* <Label className='details-title'>Dias para envio</Label>
            <Input placeholder="Dias" type="number" defaultValue={props.values.days} onChange={(event) => props.onChange("days", event.target.value)} /> */}
            <TextInput
                id="alert-days"
                placeholder="Dias para envio"
                type="number"
                value={props.values.days}
                onChange={(event) => props.onChange("days", event.target.value)}
            />
            {/* <Label className='details-title'>Estado para envio</Label>
                    <Select
                        noOptionsMessage={() => "Não há opções para mostrar"}
                        className="react-select dark-gray"
                        classNamePrefix="react-select"
                        placeholder="Estado para envio"
                        name="multipleSelect"
                        closeMenuOnSelect={true}
                        options={props.defaultState}
                        value={props.values.state}
                        onChange={value => props.onChange("state", value)}
                    /> */}
            {/* <Label className='details-title'>Biblioteca</Label>
            <Select
                className="react-select dark-gray zIndexTop"
                classNamePrefix="react-select"
                placeholder="Itens da biblioteca"
                name="multipleSelect"
                closeMenuOnSelect={true}
                isMulti
                options={normalizeCombo("id", "name", props.libs)}
                // value={props.values.lib}
                onChange={value => props.onChange("lib", value)}
                noOptionsMessage={() => "Não há opções para mostrar"}
                style={{ zIndex: 99999 }}
            /> */}
            <SelectInput
                options={normalizeCombo("id", "name", props.libs)}
                title="Biblioteca"
                labelledBy={"Biblioteca"}
                value={props.values.lib}
                onChange={value => props.onChange("lib", value)}
                isSingle={false}
            />
            {/* <Label className='details-title'>Mensagem</Label> */}
            {/* <Input
                        placeholder="Insira sua descrição"
                        type="textarea"
                        value={props.values.description}
                        onChange={(event) => props.onChange("description", event.target.value)}
                        className='details-input'
                    /> */}
            {/* <div style={{ height: 440 }}> */}
            <RichEditorQuill
                defaultValue={props.values.description || ""}
                onChange={(value) => props.onChange("description", value)}
                placeholder={'Insira aqui sua descrição'}
                height={100}
                fullPageDisabled
            />
            {/* </div> */}
            {/* </ModalBody>
                <ModalFooter>
                    <Button style={{ marginRight: 24 }} className="button-save-company" outline color="success" onClick={props.saveData}>
                        {"Salvar"}
                    </Button>
                </ModalFooter> */}
        </NewModal>
        // </div>
    );
};