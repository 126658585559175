import React, { useRef, useEffect } from 'react';
import { PrimaryButton } from '../../layoutV2/components/Button';
import { PasswordInput, TextInput } from '../../layoutV2/components/Form';

export const CardRegister = ({ history, onChange, errorMessage, doRegister, email, name, password, confirm_password }) => {
    return (
        <div className="card-login">
            <div>
                <img className="v2-logo-ike" src={require("../../assets/img/logo-ike.png")} />
                <h4 className="text-center">Seja bem-vindo</h4>
                <p className="text-center">Para criar sua conta, digite seus dados</p>
                <TextInput
                    onChange={({ target }) => onChange("name", target.value)}
                    value={name}
                    placeholder="Nome"
                    id="name"
                />
                <TextInput
                    onChange={({ target }) => onChange("email", target.value)}
                    value={email}
                    placeholder="E-mail"
                    id="email"
                    error={["O usuário não correponde à nenhuma credencial.", "O endereço de e-mail não é válido."].includes(errorMessage) && errorMessage}
                />
                <PasswordInput
                    onChange={({ target }) => onChange("password", target.value)}
                    value={password}
                    placeholder="Senha"
                    autoComplete="off"
                    id="password"
                />
                <PasswordInput
                    onChange={({ target }) => onChange("confirm_password", target.value)}
                    value={confirm_password}
                    placeholder="Confirmar senha"
                    autoComplete="off"
                    id="confirm_password"
                    error={errorMessage === "Senha incorreta." && errorMessage}
                />
                <PrimaryButton
                    onClick={doRegister}
                    label="Registrar"
                />
                {errorMessage && !["O usuário não correponde à nenhuma credencial.", "O endereço de e-mail não é válido.", "Senha incorreta."].includes(errorMessage) && <p className="v2-text-error text-center">{errorMessage}</p>}
            </div>
            {/* <div className="v2-separator" />
            <p className="layout-center">Ainda não possui uma conta?
            <a
                    className="link"
                    onClick={() => history.push("/auth/recovery")}
                >
                    &nbsp;Cadastre-se agora
            </a>
            </p> */}
        </div>

    )
};

export const CardLogin = ({ history, onChange, errorMessage, doLogin, email, password }) => {
    return (
        <div className="card-login">
            <div>
                <img className="v2-logo-ike" src={require("../../assets/img/logo-ike.png")} />
                <h4 className="text-center">Seja bem-vindo novamente</h4>
                <p className="text-center">Para acessar sua conta, efetue seu login</p>
                <TextInput
                    onChange={({ target }) => onChange("email", target.value)}
                    value={email}
                    placeholder="E-mail"
                    id="email"
                    error={["O usuário não correponde à nenhuma credencial.", "O endereço de e-mail não é válido."].includes(errorMessage) && errorMessage}
                />
                <PasswordInput
                    onChange={({ target }) => onChange("password", target.value)}
                    value={password}
                    placeholder="Senha"
                    autoComplete="off"
                    id="password"
                    error={errorMessage === "Senha incorreta." && errorMessage}
                />
                <PrimaryButton
                    onClick={doLogin}
                    label="Acessar"
                />
                {errorMessage && !["O usuário não correponde à nenhuma credencial.", "O endereço de e-mail não é válido.", "Senha incorreta."].includes(errorMessage) && <p className="v2-text-error text-center">{errorMessage}</p>}
                <a
                    className="link layout-center v2-text-recovery"
                    onClick={() => history.push("/auth/recovery")}
                >
                    Esqueci minha senha
                </a>
            </div>
            {/* <div className="v2-separator" />
            <p className="layout-center">Ainda não possui uma conta?
            <a
                    className="link"
                    onClick={() => history.push("/auth/recovery")}
                >
                    &nbsp;Cadastre-se agora
            </a>
            </p> */}
        </div>

    )
};

export const CardRecovery = ({ history, onChange, errorMessage, submit, email, success }) => {
    return (
        <div className="card-login">
            <div>
                <img className="v2-logo-ike" src={require("../../assets/img/logo-ike.png")} />
                <h4 className="text-center">Esqueci minha senha</h4>
                <p className="text-center">{success ? "Enviamos no seu e-mail as instruções para resetar sua senha." : "Informe seu e-mail cadastrado para receber as instruções"}</p>
                {!success && <TextInput
                    onChange={({ target }) => onChange("email", target.value)}
                    value={email}
                    placeholder="E-mail"
                    id="email"
                    error={errorMessage}
                />}
                {!success && <PrimaryButton
                    onClick={submit}
                    label="Enviar"
                />}
                {/* {(!success && errorMessage) && <p className="v2-text-error text-center">{errorMessage}</p>} */}
                {success && <small>Caso ainda não tenha recebido, verifique sua caixa de spam, ou <a className="link-text" href="#" onClick={submit}>clique aqui</a> para enviar novamente.</small>}
            </div>
            {/* <div className="v2-separator" />
            <p className="layout-center">Ainda não possui uma conta?
                <a
                    className="link"
                    onClick={() => history.push("/auth/recovery")}
                >
                    &nbsp;Cadastre-se agora
                    </a>
            </p> */}
        </div>
    )
};
