import React from 'react';
import { getItemById, addItem, updateItem } from '../../core/database/firebaseFunctions';
import { db, fireSQL } from '../../core/database/firebaseConfig';
import { setLoginData } from '../redux-store/login/Actions';
import { database } from '../../core/database/firebaseConfig';
import moment from 'moment';
import { notReadMessage } from '../../container/ChatContainer';


export const sendNotification = (userId, chatId, userName, message, group = undefined) => {
  let headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: "Basic 'NmY5YjJhZDMtMDMyMi00MDQ5LTgwNzMtMzQ5YmU0MGNlM2Qz'"
  };

  let filters = []
  let ids = [];
  if (!userId || !Array.isArray(userId)) {
    ids.push(userId);
  } else if (Array.isArray(userId)) {
    ids = userId;
  }

  ids.map((id, index) => {
    if (index > 0) {
      filters.push({ "operator": "OR" })
    }
    filters.push({ "field": "tag", "key": "id", "relation": "=", "value": id })
  })
  let endpoint = "https://onesignal.com/api/v1/notifications";
  let params = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      app_id: "59926cca-4b39-4b7b-8ec4-741be124c269",
      filters: filters,
      data: { chatId },
      headings: group ? { en: group.nmGroup, pt: group.nmGroup } : { en: userName, pt: userName },
      subtitle: group ? { en: userName, pt: userName } : { en: "", pt: "" },
      contents: !message ? { en: "Mensagem encaminhada", pt: "Mensagem encaminhada" } : { en: message, pt: message },
      android_group: group ? chatId : [0],
      thread_id: group ? chatId : [0],
      large_icon: "ic_launcher_round",
      ios_badgeType: "Increase",
      ios_badgeCount: 1
    })
  };
  fetch(endpoint, params).then(res => res.json()).then(res => console.log(res));
};


export const verifyAndAddChat = async (work, sender, user) => {
  if (user && user.id) {
    const openChats = await fireSQL.query(`
        SELECT *
        FROM Chats
        WHERE active = true and idWork = '${work.idWork}' and 
        ((idUser = '${user.id}' and idSender = '${sender.id}') OR (idUser = '${sender.id}' and idSender = '${user.id}'))
    `)

    let chatItem = openChats.length > 0 && openChats[0];
    if (!chatItem) {
      chatItem = await getItemById("ChatGroups", user.id);
    }

    if (!chatItem) {
      chatItem = await addItem("Chats", {
        idUser: user.id,
        nmUser: user.name,
        piUser: user.piUser ? user.piUser : null,
        idSender: sender.id,
        idWork: work.idWork,
        nmWork: work.nmWork,
        nmSender: sender.name,
        piSender: sender.piUser ? sender.piUser : null,
        lastMessage: {
          date: new Date()
        }
      });
    }

    return chatItem
  }
}

export const isUnreadMessage = (chatItem, user, type = `Chats`,) => {
  if (chatItem.lastMessage) {
    const users = [chatItem.idUser, chatItem.idSender];

    if (user.id === chatItem.lastMessage.sender) {
      return false;
    }

    if (type === "Chats") {
      if (users.includes(user.id)) {
        return !chatItem.lastMessage.read
      }
    } else {
      return !chatItem.lastMessage.read || (Array.isArray(chatItem.lastMessage.read) && !chatItem.lastMessage.read.includes(user.id))
    }
  }

  return false;
}

export const readMessage = async (chatItem, user, dispatch, type = `Chats`) => {
  /* console.log({ chatItem, user, dispatch, type }) */
  if (isUnreadMessage(chatItem, user, type)) {
    const chat = await getItemById(type, chatItem.id);
    const read = chat.lastMessage && chat.lastMessage.read && Array.isArray(chat.lastMessage.read) ? chat.lastMessage.read : [];
    await updateItem(type, {
      ...chatItem,
      lastMessage: {
        ...chat.lastMessage,
        read: type === "Chats" ? true : read.concat([user.id])
      }
    })

    verifyMessages(dispatch, user);
  }
}

export const verifyMessages = async (dispatch, user) => {
  const notReadChats = await fireSQL.query(`
        SELECT *
        FROM Chats
        WHERE active = true and (idUser = '${user.id}' OR idSender = '${user.id}')
        and \`lastMessage.read\` = false
        and \`lastMessage.sender\` != '${user.id}'
    `);

  const notReadGroups = await fireSQL.query(`
          SELECT *
          FROM ChatGroups
          where active = true and usersGroup CONTAINS '${user.id}'
    `).then(items => {
    return items.filter(item => item.lastMessage.sender && item.lastMessage.sender !== user.id && (!item.lastMessage.read || (Array.isArray(item.lastMessage.read) && !item.lastMessage.read.includes(user.id))))
  });

  dispatch(setLoginData({ unreadCount: notReadGroups.concat(notReadChats).length }));
};

export const sendChatMessage = async (item, message, user, loading, setLoading) => {
  console.log('asdasda', { item, message, user })

  const chatItem = await verifyAndAddChat(message.work, user, item)

  const id = chatItem.id;
  let sendRef = database.ref(`Messages/${id}`);

  const userLogged = {
    name: user.name,
    email: user.email,
    avatar: user.piUser,
    _id: user.id,
  }

  console.log('zzzzz', { id, sendRef, message })
  const messageData = { work: message.work, forward: { sendAt: message.forward.sendAt, image: message.forward.image ? message.forward.image : false, text: message.forward.text, type: message.forward.type ? message.forward.type : null, user: message.forward.user, _id: message.forward._id }, user: { email: user.email, name: user.name, _id: user.id }, sendAt: moment(new Date()).format("YYYY-MM-DD"), createdAt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS"), text: "" };
  if (message.work && message.work.id) {
    await addItem(`SharedComment`, { idUser: item.id, nmUser: item.nmGroup ? user.name : item.name, createdAt: new Date(), idComment: message.work.id, idWork: message.work.idWork, nmWork: message.work.nmWork, text: message.forward.text });
  }

  sendRef.push(messageData);
  notReadMessage(id, message.forward ? "Mensagem encaminhada" : message.text, user.id, chatItem.usersGroup ? "ChatGroups" : undefined);
  if (chatItem.idSender) {
    const idUser = chatItem.idSender !== user.id ? chatItem.idSender : chatItem.idUser;
    const nameUser = chatItem.idSender !== user.id ? chatItem.nmSender : chatItem.nmUser;
    sendNotification(idUser, id, nameUser, message.text)
    setLoading(!loading)
  } else if (chatItem.usersGroup) {
    const users = chatItem.usersGroup.filter(id => id !== user.id)
    sendNotification(users, id, user.name, message.text, chatItem.nmGroup ? { nmGroup: chatItem.nmGroup } : undefined)
    setLoading(!loading)
  }

}

export const observeChat = (table, item, observe) => {
  return db.collection(table).doc(item.id)
  .onSnapshot(
    (querySnapshot) => {
        observe(moment(new Date(item.lastMessage.date.seconds * 1000)).format('DD/MM/YYYY HH:mm:ss') === moment(new Date(querySnapshot.data().lastMessage.date.seconds * 1000)).format('DD/MM/YYYY HH:mm:ss') ? false : true)
      }
      , (error) => { console.log(error) }
    )
}