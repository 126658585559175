import React, { Component, useEffect, useState } from 'react';
import {
    Button,
    Input,
    Col
} from 'reactstrap';
import { Search } from '@material-ui/icons';
import Select from 'react-select';
import { normalizeCombo } from '../../core/utils/Utils';
import sort from 'fast-sort'
import { FilterPicker } from '../../components/Filter/FilterHooks';
import { useSelector } from 'react-redux';
import { fireSQL } from '../../core/database/firebaseConfig';
import { AddNavButton } from '../../layoutV2/components/Button';
import { useHasPowerInGroup } from '../../core/user/hooks';

const WorksToolbarView = ({ onFilter, onCompanyClick, allGroups, adminWorkGroup, user }) => {
    const hasPower = useHasPowerInGroup();
    // const states = [{ value: 0, label: "Todos" }, { value: 1, label: "Executando" }, { value: 2, label: "Finalizado" }];
    // const optionsGroups = sort(normalizeCombo("id", "name", allGroups)).asc(u => u.value)

    return hasPower && <AddNavButton label="Nova Empresa" onClick={onCompanyClick} />
}

export default WorksToolbarView;


    /* <div className="works-toolbar" >
     {user.isAdmin && <div className="filter-works">
            <FilterPicker 
                onFilter={onFilter} 
                field="adminWorkGroup" 
                value={adminWorkGroup} 
                comboOptions={optionsGroups} 
                placeholder="Grupos"
            />
        </div>}
        <Select
            noOptionsMessage={() => "Não há opções para mostrar"}
            className="react-select dark-gray"
            classNamePrefix="react-select"
            placeholder="Estado"
            name="multipleSelect"
            closeMenuOnSelect={true}
            options={states}
            defaultValue={states[0]}
            onChange={data => onFilter("state", data.value)}
        />
        <div className="input-form-group">
            <Input
                placeholder="Pesquisar"
                onChange={e => onFilter("name", e.target.value)}
            />
            <Search className="icon-search" />
        </div>
        <Button className="button-add-fixed btn-round btn-icon" color="primary" onClick={onCompanyClick}>
            <i className="nc-icon nc-simple-add" />
        </Button>
        {hasPower && <Button className="button-add" outline color="primary" onClick={onCompanyClick}>Nova Empresa</Button>} 
    </div >*/