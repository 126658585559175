import React from "react";

// reactstrap components
import {
  Spinner
} from "reactstrap";

class Loader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="loader-page full-page">
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/sucesso.jpg")})`
          }}
        />
          <Spinner color="warning" />
      </div>
    );
  }
}

export default Loader;
