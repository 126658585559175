import { db, firebaseAuth } from './firebaseConfig';
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import EntityEnum from './EntityEnum';
import { getItem, addItem, deleteItem, updateItem, execute } from './firebaseFunctions';
import { normalizeCombo } from 'core/utils/Utils';

export const libReference = (item) => {
    return db.collection("Libs").doc(item.value);
}

export const getSkill = async (fields) => {
    return getItem("Skills", fields).then(skills => {
        const promises = skills.map(async skill => {
            let newSkill = { ...skill };
            if (skill.lib) {
                const lib = await execute(skill.lib);
                newSkill.lib = normalizeCombo("id", "name", lib)[0];
            }
            return newSkill;
        });

        return Promise.all(promises).then(arrItems => {
            const items = [].concat(...arrItems);
            return items;
        });
    });
}

export const getWorkTypes = async ({ network }) => {
    return getItem("Skills", { level: 0 }).then(skills => {
        const arrSort = skills.sort((a, b) => a.name.localeCompare(b.name)).filter(item => item && item.group && item.group.value ? item.group.value === network : { ...item });
        console.log({ arrSort })
        // works.filter(item => item && item.group && item.group.value ? item.group.value === user.network : { ...item })
        return normalizeCombo("id", "name", arrSort);
    });
}


/*
if (scope && scope.value) {
    const table = db.collection("Templates").where("active", "==", true);
    const clauseHasPower = table.where("groups", 'array-contains', { group: groupReference(scope) });

    const templates = await execute(clauseHasPower);

    const promises = templates.map(item => {
        const groups = [];
        item.groups.map(groupItem => {
            if (groupItem && groupItem.group) {
                groups.push(execute(groupItem.group));
            }
        })

        return Promise.all(groups).then(arrGroups => {
            const groups = [].concat(...arrGroups).filter(item => item.active === true);
            const index = templates.findIndex(data => data.id === item.id);
            templates[index].groups = groups;
            return;
        });
    });

    return Promise.all(promises).then(() => templates);
} else {
    throw new Error(I18n[keyEnum.groupFieldRequired]);
}
*/
export const addSkill = async (item) => {
    const formattedLib = item.lib;
    if (formattedLib) {
        item.lib = libReference(formattedLib);
    }
    return addItem("Skills", item).then(res => ({ ...res, lib: formattedLib }));
}

export const deleteSkill = async (id) => {
    return deleteItem("Skills", id);
}

export const updateSkill = async (item) => {
    console.log("updateitem", item.value);
    const formattedLib = item.lib;
    if (formattedLib) {
        item.lib = libReference(formattedLib);
    }
    return updateItem("Skills", item).then(res => ({ ...res, ...item, lib: formattedLib }));
}