import React from "react";
import { Row, Button } from "reactstrap";
import WorksDetailsCard from "./WorksDetailsCard";
import AdminNavbar from '../../../components/Navbars/AdminNavbar';
import { BreadcrumbHeader } from '../../../components/Breadcrumb/Breadcrumb';

const routeData = [
	{
		name: "Início",
		active: true,
		route: "/admin/dashboard"
	},
	{
		name: "Empresas e Obras",
		active: true,
		route: "/admin/works"
	},
	{
		name: "Detalhes"
	}
]

class WorksDetailsView extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		console.log(this.props)
		return (
			<>
				<div className="content" style={this.props.id && { overflowY: "initial" }}>
					{!this.props.hideHeader && <AdminNavbar goBack={this.props.onBack} />}
					{/* <div className="header-details">
						<Button className="come-back" color="default" onClick={this.props.onBack}>
							<span className="btn-label">
								<i className="nc-icon nc-minimal-left" />
							</span>
							Voltar
						</Button>
					</div> */}
					<Row style={{ paddingTop: 30 }}>
						<WorksDetailsCard
							editable={this.props.editable}
							options={this.props.options}
							{...this.props}
						/>
					</Row>
				</div>
			</>
		);
	}
}

export default WorksDetailsView;
