import React, { useState, useEffect } from 'react';
import { CommentReport } from '../views/CommentReport';
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../components/Alert/Alert';
import { addLibData } from "../core/redux-store/lib/Actions";
import { normalizeCombo } from '../core/utils/Utils';
import { getSkillData } from '../core/redux-store/skill/Actions';
import { getSkill } from '../core/database/skill';

let defaultValues = {
    section: 1
};

const CommentReportContainer = ({ data, onSave }) => {
    const dispatch = useDispatch();
    const [values, setValues] = useState(defaultValues)
    const [isOpenModal, setIsOpenModal] = useState(0);
    // const skills = useSelector(state => state.skill.items) || []
    const [skills, setSkills] = useState([])

    function onChange(field, value) {
        setValues({ ...values, [field]: value });
    }

    function saveData() {
        let { name, description } = values;
        try {
            const onDataCompleted = async (response) => {
                onSave(data, "commentId", response.id)
                setIsOpenModal(0)
            }
            dispatch(addLibData({
                name, description, tags: [{ value: "IcjvF32VXhY7Y40fmIV9", label: "Apontamento pré-formatado" }], active: true
            })).then(onDataCompleted)
        } catch (error) {
            Alert.error(error.message);
        }
    };

    function onSaveSelected(selected) {
        try {
            onSave(data, "commentId", selected.id)
            setIsOpenModal(0)
        } catch (error) {
            Alert.error(error.message);
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const skills = await getSkill({ parentId: data.step.id })
        setSkills(skills)
    }

    // console.log({ data, skills, skillsFiltred: skills.filter(skill => skill.parentId === (data && data.step && data.step.id)) })
    return (
        <CommentReport
            values={values}
            saveData={saveData}
            onChange={onChange}
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            onSaveSelected={onSaveSelected}
            data={data}
            skills={normalizeCombo("id", "name", skills.filter(skill => skill.parentId === (data && data.step && data.step.id))).sort((a, b) => a.label && b.label && a.label.localeCompare(b.label))}
        />
    );
}
export default CommentReportContainer;