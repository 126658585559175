import { getItem, addItem, deleteItem, updateItem, getItemsByFilter, execute } from './firebaseFunctions';
import { db } from './firebaseConfig';

export const getCompanies = async ({ groups, isAdmin, workGroup }) => {
    if (isAdmin) {
        return getItem(`Company`);
    } else {
        const groupIds = groups ? groups.map(item => item.details.id) : [];
        return getItemsByFilter(`Company`, groupIds, "id", workGroup)
    }
}

export const addCompany = async (item) => {
    return addItem(`Company`, item);
}

export const deleteCompany = async (id) => {
    return deleteItem(`Company`, id);
}

export const updateCompany = async (item) => {
    return updateItem(`Company`, item);
}
export const getCompany = async (id) => {
    const table = db.collection("Company").doc(id)
    return execute(table).then(items => {
        return items[0];
    });
}
