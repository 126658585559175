import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Button,
    FormGroup
} from 'reactstrap';
import { Creatable } from "react-select";
import { getUserData } from 'core/redux-store/user/Actions';
import Alert from '../../components/Alert/Alert';
import RichEditorQuill from '../../components/RichEditor/RichEditorQuill';
import { Spinner } from 'reactstrap';
import { NewModal } from '../../layoutV2/components/Modal/Modal';
import { CreatableInput } from '../../layoutV2/components/Form';

export const messageHtml = (description) => {
    return `
        <div>
            ${description}
        </div>
    `
}

export const sendEmailMessage = (emails, sender, replyTo, attachmentUrl, subject, description) => {
    //http://localhost:5000/app-ike/us-central1/api3/    
    fetch("https://us-central1-app-ike.cloudfunctions.net/api3/sendEmail", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            emails, sender, replyTo, subject, html: messageHtml(description), attachments: [{ path: attachmentUrl[0] }]
        })
    })
}

export const ModalMessageContainer = ({ isOpen, setIsOpen, loadingPdf, downloadUrl, company }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.login);
    const users = useSelector(state => state.user.items || []);
    const [selected, setSelected] = useState();
    const [message, setMessage] = useState("");
    const [visible, setVisible] = useState();

    useEffect(() => {
        setMessage("");
        setSelected();
    }, [])

    useEffect(() => {
        dispatch(getUserData({ value: company.id }));
    }, [company && company.id])

    const toggle = () => {
        setVisible(false)
        setMessage("");
        // setIsOpen(false);
        setSelected();
    }

    const sendByEmail = () => {
        if (selected && selected.length > 0) {
            sendEmailMessage(selected.map(item => item.email || item.value).join(","), `"Ike - ${user.name}" <${user.email}>`, user.email, downloadUrl, `Mensagem de ${user.name}`, message)
            Alert.success("Mensagem enviada com sucesso!")
            toggle();
        } else {
            Alert.error("Selecione um ou mais usuários para enviar por email!")
        }
        // setIsOpen(false)
    }

    useEffect(() => {
        if (isOpen) {
            setVisible(true)
        }
    }, [isOpen])

    useEffect(() => {
        if (!visible) {
            setIsOpen(false);
        }
    }, [visible])
    console.log({ downloadUrl })
    return (
        <NewModal
            open={isOpen}
            toggle={toggle}
            onClickConfirm={!loadingPdf && sendByEmail}
            title={"Relatório"}
            className="alert-modal"
            onClickCancel={toggle}
        >
            {loadingPdf ? <div className="empty-modal-pdf">
                <Spinner color="warning" />
                <span>Aguarde alguns instantes enquanto processamos seu relatório!</span>
            </div> : <>
                <div className="badge-pdf">
                    <a href={downloadUrl} target="_blank">
                        <img className="download-pdf" src={require('assets/img/pdf.svg')} />
                        <p>Visualizar relatório</p>
                    </a>
                </div>
                <Row>
                    <Col md="12" style={{ position: "relative", marginBottom: 10 }}>
                        {visible && <RichEditorQuill
                            className="Mensagem"
                            defaultValue={message}
                            height={250}
                            onChange={(value) => {
                                setMessage(value);
                            }}
                            placeholder={'Mensagem'}
                        />}
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <FormGroup>
                            {/* <Creatable noOptionsMessage={() => "Não há opções para mostrar"}
                                className="react-select primary"
                                classNamePrefix="react-select"
                                placeholder="Selecione usuários para compartilhar"
                                name="multipleSelect"
                                closeMenuOnSelect={false}
                                value={selected}
                                isMulti
                                onChange={setSelected}
                                options={users.map(item => ({ ...item, value: item.id, label: item.name }))}
                            /> */}
                            <CreatableInput
                                title={"Usuários para compartilhar"}
                                labelledBy={"Usuários para compartilhar"}
                                value={selected}
                                onChange={setSelected}
                                options={users.map(item => ({ ...item, value: item.id, label: item.name }))}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>}
        </NewModal>
    )
}


// <Modal isOpen={isOpen} toggle={toggle} style={{width: "720px", maxWidth: "100vw"}}>
//     <ModalHeader toggle={toggle}>Relatório</ModalHeader>
//     <ModalBody>
{/* </ModalBody>
            <ModalFooter>
                <Button className="cancel-button-modal" color="link" onClick={() => setIsOpen(false)}>Cancelar</Button>
                 {!loadingPdf && <Button color="secondary" onClick={sendByEmail}>Enviar por email</Button>}
            </ModalFooter>
        </Modal > */}