import { getSkill, addSkill, deleteSkill, updateSkill} from "core/database/skill";
import ActionCreator from 'core/utils/redux/ActionCreator';

const { actions, defaultReducer, get, add, remove, update } = new ActionCreator("skill");
export { actions, defaultReducer };

export const getSkillData = get(getSkill);
export const addSkillData = add(addSkill);
export const updateSkillData = update(updateSkill);
export const deleteSkillData = remove(deleteSkill);
