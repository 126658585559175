import React, { useEffect, useState } from 'react';
import { storage } from '../../core/database/firebaseConfig';
import { addItem, deleteItem, getItem } from '../../core/database/firebaseFunctions';
import { imageNameUtils } from '../../core/utils/Utils';
import { FileItem } from '../../layoutV2/components/FileItem';

// import { Container } from './styles';
//dataReference={`Libs/${id}/images`}
const FileContainer = ({ disabled, dataReference, onFileChange, defaultSelect = false, accept, title, style, inspection = false, showFiles = true, files, largeDropzone = false }) => {
    const [state, setState] = useState({
        files: [],
        uploadInProgress: [],
        isGallery: false,
        selectAll: false
    })

    useEffect(() => {
        getItem(dataReference, {}).then(files => {
            files = files.map(file => {
                const defaultItem = defaultSelect && defaultSelect.filter(item => item.url === file.url)[0];
                if (defaultItem) {
                    file.isSelected = true;
                } else {
                    file.isSelected = false;
                }
                return file;
            })

            setState(state => ({
                ...state,
                files: files.map(item => ({ ...item, isSelected: false }))
            }));
        });
    }, [])

    useEffect(() => {
        setState(state => ({ ...state, files }))
    }, [files])

    const onStateChanged = (snapshot, uniqueFilename, filename) => {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setState(state => {
            console.log({ state })
            const uploadIndex = state.uploadInProgress && state.uploadInProgress.findIndex(item => item.uniqueFilename === uniqueFilename);
            if (uploadIndex >= 0) {
                state.uploadInProgress[uploadIndex] = { ...state.uploadInProgress[uploadIndex], percentage };
                return {
                    ...state,
                    // files: state.files.filter(item => item.uniqueFilename !== uniqueFilename),
                    uploadInProgress: state.uploadInProgress
                }
            } else {
                return {
                    ...state,
                    // files: state.files.filter(item => item.uniqueFilename !== uniqueFilename),
                    uploadInProgress: state.uploadInProgress.concat({ filename, uniqueFilename, percentage: percentage > 0 ? percentage - 1 : percentage }),
                }
            }
        });
    }

    const onAddItem = (item) => {
        const { url, filename, uniqueFilename } = item;

        addItem(dataReference, { ...item, time: new Date() }).then(data => {
            setState(state => {
                const newFiles = state.files.concat({ id: data.id, url, filename, uniqueFilename, time: new Date() });
                if (onFileChange) {
                    onFileChange(newFiles)
                }
                return {
                    ...state,
                    files: newFiles,
                    uploadInProgress: state.uploadInProgress.filter(item => item.uniqueFilename !== uniqueFilename),
                }
            });
        });
    }

    const onRemoveItem = (item) => {
        return deleteItem(dataReference, item.id).then(() => {

            setState(state => {
                const newFiles = state.files.filter(file => file.id !== item.id);
                if (onFileChange) {
                    onFileChange(newFiles)
                }
                return {
                    ...state,
                    files: newFiles,
                }
            });
        });
    }

    const onPreviewDrop = async (files) => {
        files.forEach(file => {
            const reader = new FileReader();
            const filename = file.name;
            const uniqueFilename = imageNameUtils(filename);
            const storageRef = storage.ref(dataReference).child(uniqueFilename);
            const task = storageRef.put(file);
            task.on("state_changed", (snapshot) => {
                onStateChanged(snapshot, uniqueFilename, filename);
            });
            task.then(() => {
                storageRef.getDownloadURL().then((url) => {
                    onAddItem({ url, filename, uniqueFilename });
                });
            })

            reader.readAsDataURL(file);
        })
    }

    return <>
        <FileItem
            onSave={!disabled && onPreviewDrop}
            {...state}
            accept={accept}
            onRemoveItem={!disabled && onRemoveItem}
            title={title}
            style={style}
            inspection={inspection}
            showFiles={showFiles}
            disabled={disabled}
            largeDropzone={largeDropzone}
        />
    </>;
}

export default FileContainer;