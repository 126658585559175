import React, { useState } from "react";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import { BreadcrumbHeader } from "../components/Breadcrumb/Breadcrumb";
import QualityStepper from "../components/QualityStepper/QualityStepper";
import { QualityConfiguration } from "./works/QualityConfiguration";
import { BadgeStatus } from "./Schedule/ScheduleList";
import { toDate, toDateFormat } from "./works/InspectionView";
import { Button } from "reactstrap";
import FileSaver from "file-saver";
import { getActivity } from "../core/database/activity";
import { getInspectionModel } from "../core/database/inspectionModel";
import sort from 'fast-sort'
import lodash from 'lodash'
import { SaveButton } from "../layoutV2/components/Button";
import { ExportButton } from "../components/Button/Button";
import { DateRange, SelectInput } from "../layoutV2/components/Form";
import { Row, Col } from "reactstrap";
import { FilterQuality } from "../layoutV2/components/FilterQuality";
import I18n from 'core/I18n/I18n';
import keyEnum from 'core/I18n/KeyEnum';
import Alert from '../components/Alert/Alert';

const data = (state) => {
  return [
    {
      name: "Início",
      active: true,
      route: "/admin/dashboard"
    },
    {
      name: "Empresas e Obras",
      route: "/admin/works",
      active: true,
    },
    {
      name: state.work.name,
      route: "/admin/works-inspection",
      active: true,
      params: { ...state }
    },
    {
      name: "Qualidade"
    }
  ]
}

const dtInspections = [
  {
    "activityUID": "19",
    "asks": [
      {
        "id": "3247a7759dd55f7dd63600d7eb713daf",
        "value": true,
        "type": 2,
        "name": "Conferir projeto",
        "condition": "A | A | A",
        "obs": ""
      },
      {
        "id": "fe49848a758e5cf84cefede11340f186",
        "value": true,
        "name": "Conferir bitolas",
        "type": 2,
        "condition": "A | A | A",
        "obs": ""
      },
      {
        "id": "77ce85ccf19967c889a8b66ebcbc6174",
        "value": false,
        "type": 2,
        "name": "Conferir posicionamento dos quadros",
        "condition": "R | R | A",
        "obs": "F2) Teste"
      },
      {
        "value": true,
        "id": "84fb6eaafe40a28099943933d9f9b659",
        "name": "Guias",
        "type": 2,
        "condition": "A | A | A",
        "obs": ""
      },
      {
        "id": "21d2aeeb64d9eac4dd7ca8afc62d5c72",
        "value": false,
        "type": 2,
        "name": "Limpeza",
        "condition": "R | A | A",
        "obs": "F1) Testando"
      }
    ],
    "condition": "Sim",
    "formattedDate": "23/11/2020 13:42",
    "id": "O3CR92OGlFwHED5Jva8j",
    "idUser": "8vERk7ngoGF2E2WLPpd2",
    "modelId": "8JiyL54az4N31gd3Z0ii",
    "modelName": "Inspeção Final",
    "referenceLocal": "Teste",
    "referenceLocalId": "KncdRsUFbWZ8M7uye5M2",
    "stepLabel": "01. Serviços Iniciais",
    "team": [
      {
        "functionName": "Mestre de Obra",
        "quantity": "2",
        "names": "Teste",
        "function": {
          "label": "Mestre de Obra",
          "value": 1
        }
      }
    ],
    "teamTotal": "Mestre de Obra (2), F2) Mestre (3)",
    "quantity": "20",
    "unity": "Unid",
    "quantityTotal": "20 Unid, F3) 15 Unid"
  },
  {
    "activityUID": "15",
    "asks": [
      {
        "id": "3247a7759dd55f7dd63600d7eb713daf",
        "value": true,
        "type": 2,
        "name": "Projeto",
        "condition": "A | A | A",
        "obs": ""
      },
      {
        "id": "fe49848a758e5cf84cefede11340f186",
        "value": false,
        "name": "Bitolas",
        "type": 2,
        "condition": "R | A | A",
        "obs": ""
      },
      {
        "id": "77ce85ccf19967c889a8b66ebcbc6174",
        "value": false,
        "type": 2,
        "name": "Posicionamento dos quadros",
        "condition": "R | R | A",
        "obs": ""
      },
      {
        "value": false,
        "id": "84fb6eaafe40a28099943933d9f9b659",
        "name": "Guias",
        "type": 2,
        "condition": "R | R | R",
        "obs": ""
      },
      {
        "id": "21d2aeeb64d9eac4dd7ca8afc62d5c72",
        "value": true,
        "type": 2,
        "name": "Limpeza",
        "condition": "A | A | A",
        "obs": ""
      }
    ],
    "condition": "Sim",
    "formattedDate": "23/11/2020 13:42",
    "id": "O3CR92OGlFwHED5Jva8j",
    "idUser": "8vERk7ngoGF2E2WLPpd2",
    "modelId": "8JiyL54az4N31gd3Z0i",
    "modelName": "Inspeção",
    "referenceLocal": "Testes",
    "referenceLocalId": "KncdRsUFbWZ8M7uye5M",
    "stepLabel": "01. Serviços",
    "team": [
      {
        "functionName": "Mestre",
        "quantity": "2",
        "names": "Teste",
        "function": {
          "label": "Mestre",
          "value": 1
        }
      }
    ],
    "teamTotal": "Mestre (2)",
    "quantity": "20",
    "unity": "Unid",
    "quantityTotal": "20 Unid, F5) 25 Unid"
  }
]

const getOrderedAttributeListA = (array, name) => {
  let asks = lodash.groupBy(array.map((item, index) => Array.isArray(item[name]) ? item[name].map(ask => ({ ...ask, index })) : ({ ...item[name], index })).flat(), (asks) => typeof asks === "string" ? asks : `${asks.id}`);
  const allAsks = Object.keys(asks).map(key => asks[key]);
  sort(allAsks).asc(u => u.index)
  return allAsks.flat();
}

const getOrderedAttributeList = (array, name, result) => {
  let asks = lodash.groupBy(array.map((item, index) => Array.isArray(item[name]) ? item[name].map(ask => ({ ...ask, index })) : ({ ...item[name], index })).flat(), (asks) => typeof asks === "string" ? asks : `${asks.id}`);
  console.log({ asks })
  const allAsks = Object.keys(asks).map(key => asks[key]);
  sort(allAsks).asc(u => u.index)
  return lodash.groupBy(allAsks.flat(), result);
}

const emptyOrItem = (data, valueReturn = "X", index = "") => {
  if (!data || data === "" || data.replace(" ", "") === "") {
    return valueReturn
  } else {
    return index ? `${index}) ${data}` : data;
  }
}

/* const max = {}
test.map(item => {
  console.log({ item })
  if (max[item.functionName]) {
    console.log("dentro do primeiro if", item.quantity, max[item.functionName], item.quantity > max[item.functionName])
    if (item.quantity > max[item.functionName]) {
      max[item.functionName] = item.quantity
      console.log("dentro do segundo if", max)
    }
  } else {
    max[item.functionName] = item.quantity
  }
}) */
const getNovoArray = (array, type) => {
  // const modelAndLocal = [...new Set(inspections.map(item => `${item.modelId}-${item.referenceLocalId}`))];
  const arrayGroup = lodash.groupBy(array, (item) => type === 1 ? `${item.modelId}` : `${item.modelId}-${item.referenceLocalId}`)

  const novoArray = []

  Object.keys(arrayGroup).map(modelAndLocal => {
    const arrayInspection = arrayGroup[modelAndLocal];
    sort(arrayInspection).asc(u => toDate(u.date));

    // console.log({ arrayInspection })
    const activityUID = arrayInspection.map(item => item.activityUID)[0];
    const stepLabel = arrayInspection.map(item => item.stepLabel)[0];
    const referenceLocal = arrayInspection.map(item => item.referenceLocal)[0];
    const modelName = arrayInspection.map(item => item.modelName)[0];
    const formattedDate = arrayInspection.map(item => item.formattedDate)[0];
    const condition = arrayInspection.map(item => item.condition)[0];
    // const quantityTotal = arrayInspection.map(item => emptyOrItem(item.quantityTotal)).join(" | ");
    // const teamTotal = arrayInspection.map(item => emptyOrItem(item.teamTotal)).join(" | ");
    // console.log({ arrayInspection })
    const maxTeam = {}
    arrayInspection.map(inspection => {
      // console.log({ item })
      inspection.team.map(item => {
        if (maxTeam[item.functionName]) {
          // console.log("dentro do primeiro if", item.quantity, max[item.functionName], item.quantity > max[item.functionName])
          if (item.quantity > maxTeam[item.functionName]) {
            maxTeam[item.functionName] = item.quantity
            // console.log("dentro do segundo if", max)
          }
        } else {
          maxTeam[item.functionName] = item.quantity
        }
      })
    })
    const teamTotal = Object.keys(maxTeam).map(key => `${key} (${maxTeam[key]})`).join(" | ");
    // console.log({ teamTotal })

    const maxQuantity = {}
    arrayInspection.map((item, index) => {
      // console.log({ item })
      if (item.quantityTotal) {
        if (maxQuantity[item.quantityTotal]) {
          // console.log("dentro do primeiro if", item.quantity, max[item.functionName], item.quantity > max[item.functionName])
          maxQuantity[item.quantityTotal] = `${maxQuantity[item.quantityTotal]}, ${index + 1}`
        } else {
          maxQuantity[item.quantityTotal] = `${index + 1}`
        }
      }
    })
    // console.log({ maxQuantity })
    const quantityTotal = Object.keys(maxQuantity).map(key => `${maxQuantity[key]}) ${key}`).join(" | ");

    // const askIndex = getOrderedAttributeList(arrayInspection, "asks", "index");
    const asks = getOrderedAttributeList(arrayInspection, "asks", "id");

    const newAsks = []

    Object.keys(asks).map(id => {
      const array = asks[id];

      let condition = [];
      let obsIds = [];
      let obs = [];

      for (let index = 0; index < arrayInspection.length; index++) {
        const item = array.find(item => item.index === index);

        obsIds.push(emptyOrItem(item && item.obsIds, "", index + 1));
        condition.push(emptyOrItem(item && item.condition, "N"));
        obs.push(emptyOrItem(item && item.obs, "", index + 1));
        console.log({ obsIds, condition, obs })
      }

      newAsks.push({
        id,
        name: array[0].name,
        condition: condition.filter(item => item).join(" | "),
        obs: obs.filter(item => item).join(" | "),
        obsIds: obsIds.filter(item => item).join(" | ")
      })
    })

    novoArray.push({
      activityUID, stepLabel, referenceLocal, modelName, formattedDate, condition, teamTotal, quantityTotal, asks: newAsks
    })
  })

  return novoArray;
}

const Quality = (props) => {
  const [reload, setReload] = useState(false)
  console.log({ props })

  const exportPDF = async () => {
    try {
      // let url = "http://localhost:5000/app-ike/us-central1/api3/report-quality"
      let url = "https://us-central1-app-ike.cloudfunctions.net/api3/report-quality"

      const { work, workId, company, companyId } = props && props.state
      // const checklist = await getItem(`Checklist`, {
      //   workId, companyId, UID: props.UID
      // })

      let activity = []
      if (props && props.work && props.work.noSchedule) {
        const model = props.inspections.map(item => ({ id: item.modelId }))
        activity = [{ UID: props.UID, workId, companyId: companyId || company.id, status: 3, model }]
      } else {
        activity = await getActivity({ UID: props.UID, workId, companyId: companyId || company.id });
      }

      if (activity.length > 0 && activity[0] && activity[0].model) {
        const promises = []
        activity[0].model.map(item => {
          promises.push(getInspectionModel({ fields: { id: item.value } }))
        })
        const models = await Promise.all(promises).then(items => items.flat()).catch(() => ([]))

        const initial = props.inspections.filter(item => item.type === 1).map(inspection => {
          const model = models.find(item => item.id === inspection.modelId)
          if (model) {
            const checklist = model.asks.filter(item => item.type === 1)
            const newAsks = checklist.map(item => inspection.asks.find(row => row.id === item.id) ? ({ ...inspection.asks.find(row => row.id === item.id), ...item }) : item).map(item => {
              return { ...item, condition: item.value === true ? "A" : item.value === false ? "R" : item.value === -1 ? "NA" : "", obsIds: item.obsIds && item.obsIds.length > 0 ? item.obsIds.map(row => `#${toDateFormat(row.obsIdDate, "DDMMYYHHmm")}`).join(", ") : "" }
            }).filter(item => item.value === true || item.value === false || item.value === -1)
            // console.log("initial", { newAsks })
            // const referenceLocal = props.allReferenceLocal.find(item => item.id === inspection.referenceLocalId)
            // const teamTotal = inspection.team.map(item => `${item.functionName} ${item.quantity}`).join(", ")
            const quantityTotal = inspection.quantity && inspection.unity ? `${inspection.quantity} ${inspection.unity}` : undefined
            return { ...inspection, quantityTotal, formattedDate: toDateFormat(inspection.date, "DD/MM/YYYY HH:mm"), stepLabel: inspection.step.label, modelName: model.name, /* referenceLocal: referenceLocal.name, */ asks: newAsks, condition: newAsks.filter(item => item.condition === "Reprovado(a)").length > 0 ? "Não" : "Sim" }
          } else {
            throw new Error(inspection.modelId)
          }
        })
        sort(initial).asc(u => u.date)
        // console.log({ initial })

        // let initialChecklist = checklist.length > 0 && checklist[0].asks.filter(item => item.type === 1)
        // initialChecklist = initialChecklist.map(item => initialInspection.asks.find(row => row.id === item.id) ?
        //   ({ ...initialInspection.asks.find(row => row.id === item.id), ...item }) : item)
        //   .map(item => { return { ...item, condition: item.value ? "Aprovado(a)" : "Reprovado(a)" } })

        const inspections = props.inspections.filter(item => item.type === 2).map(inspection => {
          const model = models.find(item => item.id === inspection.modelId)
          if (model) {
            // console.log({ model })
            const checklist = model && model.asks.filter(item => item.type === 2)
            // console.log({ checklist, inspection })
            const newAsks = checklist.map(item => inspection.asks.find(row => row.id === item.id) ? ({ ...inspection.asks.find(row => row.id === item.id), ...item }) : item).map(item => {
              return { ...item, condition: item.value === true ? "A" : item.value === false ? "R" : item.value === -1 ? "NA" : "", obsIds: item.obsIds && item.obsIds.length > 0 ? item.obsIds.map(row => `#${toDateFormat(row.obsIdDate, "DDMMYYHHmm")}`).join(", ") : "" }
            }).filter(item => item.value === true || item.value === false || item.value === -1)
            // console.log({ newAsks })
            const referenceLocal = props.allReferenceLocal.find(item => item.id === inspection.referenceLocalId)
            // const teamTotal = inspection.team.map(item => `${item.functionName} ${item.quantity}`).join(", ")
            // console.log({ max })
            const quantityTotal = inspection.quantity && inspection.unity ? `${inspection.quantity} ${inspection.unity}` : undefined
            // console.log({ teamTotal })
            return { ...inspection, quantityTotal, modelName: model.name, referenceLocal: referenceLocal && referenceLocal.name, asks: newAsks, stepLabel: inspection.step.label, formattedDate: toDateFormat(inspection.date, "DD/MM/YYYY HH:mm"), condition: newAsks.filter(item => item.condition === "Reprovado(a)").length > 0 ? "Não" : "Sim" }
          } else {
            throw new Error(inspection.modelId)
          }
        })
        sort(inspections).asc(u => u.date)
        // console.log({ inspections })

        // const bodyInspection = props.inspections.filter(item => item.type === 2)
        // let inspectionsChecklist = checklist.length > 0 && checklist[0].asks.filter(item => item.type === 2)
        // let inspections = bodyInspection.map(inspection => ({
        //   ...inspection,
        //   asks:
        //     inspectionsChecklist.map(item =>
        //       inspection.asks.find(row => row.id === item.id) ?
        //         ({ ...inspection.asks.find(row => row.id === item.id), ...item }) :
        //         item
        //     ).map(item => ({ obs: !item.value && !item.obs ? "Não respondido" : "", ...item, condition: item.value === true ? "A" : item.value === false ? "R" : "", obsIdDate: item.obsIdDate ? `#${toDateFormat(item.obsIdDate, "DDMMYYHHmm")}` : "" }))
        // }))
        // sort(inspections).asc(u => u.date)

        const real_start = inspections[0] && inspections[0].real_date_start ? inspections[0].real_date_start : ""
        const real_end = inspections[inspections.length - 1] && inspections[inspections.length - 1].real_date_start && activity[0].status === 4 ? inspections[inspections.length - 1].real_date_start : "Não concluído"

        // console.log({ initialInspection, inspections, initialChecklist })

        const novoarrayinitial = getNovoArray(initial, 1);
        const novoarray = getNovoArray(inspections, 2);
        // console.log({ novoarray, novoarrayinitial, initial, inspections })

        // const novoarray = []
        // inspections.map((item, index) => {
        //   if (novoarray.filter(row => row.modelId === item.modelId && row.referenceLocalId === item.referenceLocalId).length > 0) {
        //     const indexItemArray = novoarray.findIndex(row => row.modelId === item.modelId && row.referenceLocalId === item.referenceLocalId)
        //     console.log({ indexItemArray, novoarray, item })
        //     novoarray[indexItemArray] = {
        //       ...novoarray[indexItemArray],
        //       // novos valores que ele deve ter
        //       /* asks: item.asks.map(ask => {
        //         return novoarray[indexItemArray].asks.find(row => {
        //           return ask.id === row.id ? ({ ...ask, condition: `${row.condition} | ${ask.condition}` }) : ask
        //         })
        //       }), */
        //     }
        //     console.log({ itemNovo: novoarray[indexItemArray] })
        //   } else {
        //     console.log("passou no else")
        //     novoarray.push(item)
        //   }
        // })

        // console.log({
        //   dtInspections,
        //   novoarray
        // })

        fetch(url, {
          method: "POST",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            work: work.name,
            name: props.Name,
            startDate: toDateFormat(props.Start) ? toDateFormat(props.Start) : "Não definido",
            endDate: toDateFormat(props.Finish) ? toDateFormat(props.Finish) : "Não definido",
            real_start,
            real_end,
            initial: novoarrayinitial,
            inspections: novoarray,
            noSchedule: props && props.work && props.work.noSchedule || false,
          })
        }).then(function (response) {
          return response.blob();
        }).then(function (blob) {
          FileSaver.saveAs(blob, 'qualidade.pdf');
        })
      }
    } catch (error) {
      if (typeof error.message === "string") {
        const model = await getInspectionModel({ fields: { id: error.message } })
        // console.log({ model })
        if (model && model.length > 0) {
          Alert.error(`${model.length > 1 ? "Os modelos" : "O modelo"} ${model.map(item => item.name).join(", ")} ${model.length > 1 ? "são obrigatórios" : "é obrigatório"} e não está selecionado, verifique novamente!`)
        } else {
          Alert.error("Existe um modelo obrigatório que não está selecionado, verifique os modelos novamente!")
        }
      } else {
        Alert.error("Ocorreu um erro, verifique os modelos e as inspeções.")
      }
    }
  }

  console.log('bbbb', { props })

  return (
    <div className="content lib-list">
      {/* <AdminNavbar>
        <BreadcrumbHeader data={data(props.state)} />
      </AdminNavbar> */}
      <AdminNavbar goBack={() => props.history.goBack()} />
      <FilterQuality {...props} />
      <div className="quality-stepper">
        <div className="quality-header" style={{ display: "flex", /* boxShadow: "0px 12px 20px rgb(31 77 155 / 7%)", marginBottom: 10, */ minHeight: 70, borderBottom: "1px solid #B6C2D6"/* , paddingBottom: 5 */ }}>
          <div style={{ flex: 1, alignSelf: "center" }}>
            <div className="quality-move-badge" style={{ display: "flex", alignItems: "flex-start", justifyContent: "center"/* , marginTop: 10 */ }}>
              <span className="name-step">{props.Name}</span>
              <BadgeStatus style={{ marginLeft: 10 }} {...props} />
              <div style={{ flex: 1 }}></div>
            </div>
            {props.Start && props.Finish && <span className="date-step">{`${toDateFormat(props.Start)} - ${toDateFormat(props.Finish)}`}</span>}
          </div>
          {props.isResponsible && (props.work && !props.work.noSchedule) && <div style={{ alignSelf: "center" }}>
            <QualityConfiguration {...props} setReload={setReload} reload={reload} />
          </div>}
          <div className="button-quality-export">
            {props.inspections.length > 0 && <SaveButton className="btn-save-quality" onClick={() => exportPDF()} label="Exportar" />}
          </div>
          <div className="button-quality-export mobile">
            {props.inspections.length > 0 && <ExportButton onClick={() => exportPDF()} />}
          </div>
          {/* <Button className="button-schedule-screen" onClick={() => exportPDF()}>
              Exportar
              </Button>} */}
        </div>
        <QualityStepper {...props} setReload={setReload} reload={reload} />
      </div>
    </div>
  );
}

export default Quality;