import React from "react";
import {
	Row,
	Col,
	Button,
	Card,
	CardHeader,
	CardTitle,
	FormGroup,
	Form,
	Input,
	CardSubtitle,
	Label
} from "reactstrap";
import Select from "react-select";
import FileSimpleContainer from "../../../container/FileSimpleContainer";
import Dialog from 'react-bootstrap-dialog'
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import { SelectInput, TextInput } from '../../../layoutV2/components/Form/index'
import { OutlineButton, SaveButton } from "../../../layoutV2/components/Button";
import ModalAlert from "../../../layoutV2/components/Modal/ModalAlert";
import { connect } from "react-redux";
import { ButtonExport } from '../../../components/ButtonExport/ButtonExport'
import Spinner from "reactstrap/lib/Spinner";
import { ButtonMetadata } from "../../../components/ButtonMetadata";

class WorksDetailsCard extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			isLoadingZip: false,
		}

		this.detailsForm = this.detailsForm.bind(this);
		this.confirmDelete = this.confirmDelete.bind(this);
		this.dialog = React.createRef();
		this.setIsLoadingZip = this.setIsLoadingZip.bind(this);

	}

	componentDidMount() {
		Dialog.setOptions({
			defaultOkLabel: 'Sim',
			defaultCancelLabel: 'Cancelar',
			primaryClassName: 'btn-success'
		})
	}
	setIsLoadingZip(value = false) {
		this.setState({ isLoadingZip: value })
	}

	confirmDelete() {
		// this.dialog.current.show({
		// 	title: 'Obra',
		// 	body: 'Deseja mesmo remover esta obra? Ao realizar esta ação o item não poderá mais ser acessado e a ação não poderá ser revertida.',
		// 	actions: [
		// 		Dialog.CancelAction(),
		// 		Dialog.OKAction(() => {
		// 			 this.props.onDelete();
		// 		})
		// 	],
		// 	bsSize: 'small',
		// })

		ModalAlert.danger({
			title: "Obra",
			text: "Deseja mesmo remover esta obra? Ao realizar esta ação o item não poderá mais ser acessado e a ação não poderá ser revertida.",
			onConfirm: this.props.onDelete
		})
	}

	detailsForm() {
		const {
			id, onChange, saveData, name, type,
			file, onFileChange, companyUsers = [], users = [],
			state, workTypes, workType, address, responsible, regProf,
			qualityResponsible, qualityUsers, hideTeam, hideProduction, user, idCompany, company, noSchedule, email
		} = this.props;
		const types = [{ value: 1, label: "Vistoria" }, { value: 2, label: "Perícia" }];
		const states = [{ value: 1, label: "Executando" }, { value: 2, label: "Finalizado" }]
		const selectedValue = state ? states.filter(item => item.value === state) : states[0];
		const workTypeSelected = workTypes && workTypes.filter(item => item.value === workType);
		const { isLoadingZip } = this.state

		return (
			// <CardHeader>
			<>

				{/* <Row>
					<Col sm="12" className="header-col header-card-works">
						<CardTitle className="title-works" tag="h4">Obra</CardTitle>
						{id && <Button onClick={this.confirmDelete} className="btn-link btn-danger btn-delete-works">
							<i class="material-icons">delete_outline</i>
							Remover
						</Button>}
					</Col>
				</Row> */}


				{isLoadingZip ? <div className="empty-xlsx">
					<Spinner color="warning" />
					<span>Aguarde alguns minutos enquanto geramos seu arquivo!</span>
				</div> :
					<>
						<div className="v2-company-details">
							<div className="header-company work">
								<Col className="col-img">
									<FileSimpleContainer
										circle={true}
										file={file}
										onFileChange={onFileChange}
										single={true}
										dataReference={`Images/Works/data`}
										mini
									/>
								</Col>
								<Col className="col-info">
									<Row style={{ display: "flex", flexDirection: "row" }}>
										<Col className="work-name" md="9" sm="12" >
											<TextInput
												id="work-name"
												placeholder="Nome da obra"
												type="text"
												value={name}
												onChange={(event) => onChange("name", event.target.value)}
											/>
										</Col>
										<Col className="work-status" md="3" sm="12">
											<SelectInput
												options={states}
												title="Status"
												labelledBy={"Status"}
												value={selectedValue}
												onChange={data => onChange("state", data.value)}
												isSingle={true}
											/>
										</Col>
									</Row>
									<div className="work-address">
										<TextInput
											id="work-address"
											placeholder="Endereço"
											type="text"
											value={address}
											onChange={(event) => onChange("address", event.target.value)}
										/>
									</div>
								</Col>
							</div>
						</div>
						<Row>
							{/* <Col md="6" sm="12">

								<TextInput
									id="work-responsible"
									placeholder="Responsável Técnico"
									type="text"
									value={responsible}
									onChange={(event) => onChange("responsible", event.target.value)}
								/>
							</Col> */}
							{/* <Col md="6" sm="12">
								<TextInput
									id="work-regProf"
									placeholder="Registro Profissional"
									type="text"
									value={regProf}
									onChange={(event) => onChange("regProf", event.target.value)}
								/>
							</Col> */}
							<Col>
								<SelectInput
									options={workTypes}
									title="Tipo da obra"
									labelledBy={"Tipo da obra"}
									value={workTypeSelected}
									onChange={data => onChange("workType", data.value)}
									isSingle={true}
								/>
							</Col>
						{/* </Row>
						<Row> */}
						</Row>
						<Row>
							<Col>
								<SelectInput
									options={types}
									title="Tipo do serviço"
									labelledBy={"Tipo do serviço"}
									value={type}
									onChange={value => onChange("type", value)}
									isSingle={true}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<SelectInput
									options={id ? companyUsers : companyUsers.filter(item => item.value !== email) || []}
									title="Emails de interessados da obra"
									labelledBy={"Emails de interessados da obra"}
									value={users}
									onChange={value => onChange("users", value)}
									isSingle={false}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<SelectInput
									options={id ? companyUsers : companyUsers.filter(item => item.value !== email) || []}
									title="Responsáveis"
									labelledBy={"Responsáveis"}
									value={qualityResponsible}
									onChange={value => onChange("qualityResponsible", value)}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<SelectInput
									options={id ? companyUsers : companyUsers.filter(item => item.value !== email) || []}
									title="Visualizadores"
									labelledBy={"Visualizadores"}
									value={qualityUsers}
									onChange={value => onChange("qualityUsers", value)}
								/>
							</Col>
						</Row>
						<Row className="row-quality">
							<Col sm="3" className="header-col header-card-works" style={{ alignSelf: "center" }}>
								<CardSubtitle className="title-works">Qualidade</CardSubtitle>
							</Col>
							<Col sm="3" className="header-col header-card-works">
								<p style={{ marginTop: 5, marginBottom: 5 }}>
									<label>
										<Checkbox
											checked={hideTeam}
											onChange={(event) => onChange("hideTeam", event.target.checked)}
										/>
										&nbsp; Ocultar equipe
									</label>
									&nbsp;&nbsp;
								</p>
							</Col>
							<Col sm="3" className="header-col header-card-works">
								<p style={{ marginTop: 5, marginBottom: 5 }}>
									<label>
										<Checkbox
											checked={hideProduction}
											onChange={(event) => onChange("hideProduction", event.target.checked)}
											style={{ width: 20, height: 20 }}
										/>
										&nbsp; Ocultar produção
									</label>
									&nbsp;&nbsp;
								</p>
							</Col>
						</Row>
						<Row className="row-quality">
							<div style={{ paddingLeft: 15 }}/* sm="3" className="header-col header-card-works" */>
								<p style={{ marginTop: 5, marginBottom: 5 }}>
									<label>
										<Checkbox
											checked={noSchedule}
											onChange={(event) => onChange("noSchedule", event.target.checked)}
											style={{ width: 20, height: 20 }}
										/>
										{/* 		&nbsp; */} Usar ficha de inspeção sem cronograma
									</label>
									{/* &nbsp;&nbsp; */}
								</p>
							</div>
						</Row>
						{/* <Row>
					<Col sm="12">
						<FormGroup>
							<Input placeholder="Nome" type="text" defaultValue={name} onChange={(event) => onChange("name", event.target.value)} />
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col sm="12">
						<FormGroup>
							<Input placeholder="Endereço" type="text" defaultValue={address} onChange={(event) => onChange("address", event.target.value)} />
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col sm="6">
						<FormGroup>
							<Input placeholder="Responsável técnico" type="text" defaultValue={responsible} onChange={(event) => onChange("responsible", event.target.value)} />
						</FormGroup>
					</Col>
					<Col sm="6">
						<FormGroup>
							<Input placeholder="Registro Profissional" type="text" defaultValue={regProf} onChange={(event) => onChange("regProf", event.target.value)} />
						</FormGroup>
					</Col>
				</Row> */}
						{/* <Row>
					<Col md="4">
						<FormGroup>
							<Select
								noOptionsMessage={() => "Não há opções para mostrar"}
								className="react-select dark-gray"
								classNamePrefix="react-select"
								placeholder="Tipo da obra"
								name="multipleSelect"
								closeMenuOnSelect={true}
								options={workTypes}
								value={workTypeSelected}
								onChange={data => onChange("workType", data.value)}
							/>
						</FormGroup>
					</Col>
					<Col md="4">
						<FormGroup>
							<Select
								noOptionsMessage={() => "Não há opções para mostrar"}
								className="react-select dark-gray"
								classNamePrefix="react-select"
								placeholder="Tipo do serviço"
								name="multipleSelect"
								closeMenuOnSelect={true}
								options={types}
								value={type}
								onChange={value => onChange("type", value)}
							/>
						</FormGroup>
					</Col>
					<Col md="4">
						<FormGroup>
							<Select
								noOptionsMessage={() => "Não há opções para mostrar"}
								className="react-select dark-gray"
								classNamePrefix="react-select"
								placeholder="Estado do serviço"
								name="multipleSelect"
								closeMenuOnSelect={true}
								options={states}
								value={selectedValue}
								onChange={data => onChange("state", data.value)}
							/>
						</FormGroup>
					</Col>
				</Row> */}
						{/* <Row>
					<Col md="12">
						<FormGroup>
							<Select
								className="react-select dark-gray"
								classNamePrefix="react-select"
								placeholder="Emails de interessados da obra"
								name="multipleSelect"
								closeMenuOnSelect={true}
								value={users}
								isMulti
								options={companyUsers}
								onChange={value => onChange("users", value)}
								// formatCreateLabel={label => `Criar ${label}`}
								noOptionsMessage={() => "Não há opções para mostrar"}
							/>
						</FormGroup>
					</Col>
				</Row> */}
						{/* <Row>
					<Col md="12">
						<FormGroup>
							<Select
								className="react-select dark-gray"
								classNamePrefix="react-select"
								placeholder="Responsáveis"
								name="multipleSelect"
								closeMenuOnSelect={true}
								value={qualityResponsible}
								isMulti
								options={companyUsers}
								onChange={value => onChange("qualityResponsible", value)}
								// formatCreateLabel={label => `Criar ${label}`}
								noOptionsMessage={() => "Não há opções para mostrar"}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<FormGroup>
							<Select
								className="react-select dark-gray"
								classNamePrefix="react-select"
								placeholder="Visualizadores"
								name="multipleSelect"
								closeMenuOnSelect={true}
								value={qualityUsers}
								isMulti
								options={companyUsers}
								onChange={value => onChange("qualityUsers", value)}
								// formatCreateLabel={label => `Criar ${label}`}
								noOptionsMessage={() => "Não há opções para mostrar"}
							/>
						</FormGroup>
					</Col>
				</Row> */}
						<Row>
							<Col sm="12" className="header-footer" style={user && user.isAdmin ? { display: "flex", justifyContent: 'space-between' } : { display: "flex", justifyContent: 'flex-end' }}>
								<div>
									{user && user.isAdmin || user.hasPower ? <ButtonExport id={id} nameWork={name} setIsLoadingZip={this.setIsLoadingZip} /> : <div />}
									{/* {user && user.isAdmin ? <ButtonMetadata id={id} nameWork={name} /> : <div />} */}
								</div>
								<div>
									{id && <OutlineButton label="Excluir" onClick={this.confirmDelete} />}
									{/* <Button className="button-save-company" style={{ backgroundColor: "#2A3F92" }} onClick={saveData}>{"Salvar"}</Button> */}
									<SaveButton onClick={saveData} />
								</div>
							</Col>
						</Row> </>}
				{/* </CardHeader> */}

			</>

		);
	}

	render() {
		return (
			// <Col className="card-works-details" md="12" lg="10">
			<>
				{/* <Card className="card-tasks"> */}
				<div className="card-tasks" style={{ marginTop: 10 }}>
					{this.detailsForm()}
				</div>
				{/* </Card> */}
				<Dialog ref={this.dialog} />
			</>
			// </Col>
		);
	}
}

/*
<Row>
	<Col sm="12">
		<FormGroup>
			<Input placeholder="Descrição" type="textarea" value={description} onChange={(event) => onChange("description", event.target.value)} />
		</FormGroup>
	</Col>
</Row>
*/

/* export default WorksDetailsCard; */
export default connect(state => ({
	user: state.login,
	company: state.company,
	email: state.login.email,
	/* loggedIn: state.login.loggedIn,
	errorMessage: state.login.error */
}))(WorksDetailsCard);