import { db } from './firebaseConfig';
import { getItemTable, getItem, addItem, deleteItem, updateItem, execute, executeAndFormat } from './firebaseFunctions';

const getTableLib = async (table) => {
    return executeAndFormat(table, [], [{ key: "tags", idField: "tag", name: 'label' }], () => { }).then(libs => {
        return libs.map(lib => {
            if (lib.tags) {
                lib.tags = lib.tags.sort((a, b) => {
                    return a.value.localeCompare(b.value);
                });

                lib.tagsSort = lib.tags.map(tag => {
                    return tag.value[0];
                })
            }
            return lib;
        })
    });
}

export const getLib = async (fields) => {
    const table = db.collection("Libs").doc(fields.id)
    // return execute(table);
    return executeAndFormat(table, [], [{ key: "tags", idField: "tag", name: 'label' }], () => { }).then(libs => {
        return libs.map(lib => {
            if (lib.tags) {
                lib.tags = lib.tags.sort((a, b) => {
                    return a.value.localeCompare(b.value);
                });

                lib.tagsSort = lib.tags.map(tag => {
                    return tag.value[0];
                })
            }
            return lib;
        })
    });
}

export const getLibTags = async () => {
    const table = db.collection("Tags").doc("Libs").collection("values").where("active", "==", true);
    return execute(table).then(comboTags => {
        if (comboTags) {
            return { comboTags: comboTags.map(tag => ({ value: tag.id, label: tag.label })) }
        } else {
            return { comboTags: [] };
        }
    });
}

export const getLibs = async (fields) => {
    const promises = []
    if (fields && fields.tag) {
        if(fields.isAdmin){
            promises.push(getTableLib(getItemTable("Libs", {}).where("tags", 'array-contains', { tag: tagReference({ value: fields.tag }) })))
        } else {
            promises.push(getTableLib(getItemTable("Libs", { isPublic: true }).where("tags", 'array-contains', { tag: tagReference({ value: fields.tag }) })))
            promises.push(getTableLib(getItemTable("Libs", { workGroup: fields.workGroup }).where("tags", 'array-contains', { tag: tagReference({ value: fields.tag }) })))
        }
    } else {
        promises.push(getTableLib(getItemTable("Libs", fields)))
        promises.push(getTableLib(getItemTable("Libs", { isPublic: true })))
    }
    return Promise.all(promises).then(items => {
        return items.flat()
    })
}

export const tagReference = (item) => {
    return db.collection(`Tags/Libs/values`).doc(item.value);
}

export const addLib = async (item) => {
    const tags = [];
    if (item.tags) {
        item.tags.map(item => {
            tags.push({ tag: tagReference(item) })
        })
    }
    return addItem("Libs", { ...item, tags });
}

export const deleteLib = async (id) => {
    return deleteItem("Libs", id);
}

export const updateLib = async (item) => {
    const tags = [];
    if (item.tags) {
        item.tags.map(item => {
            tags.push({ tag: tagReference(item) })
        })
    }
    return updateItem("Libs", { ...item, tags }).then(data => {
        return { ...data, tags: item.tags }
    });
}