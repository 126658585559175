import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import { Reducers } from './Reducers';
import logger from "redux-logger";
import { setLoginData } from "./login/Actions";

let store;
let persist;
export const getStore = () => store;
export const getPersist = () => persist;
export default function configureStore() {
    const enhancer = compose(
        applyMiddleware(
            thunk
            ,logger
        )
    );
    store = createStore(Reducers, enhancer);

    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
          module.hot.accept('./Reducers', () => {
            store.replaceReducer(Reducers);
          });
        }
    }

    persist = persistStore(store, {}, () => {
      store.dispatch(setLoginData({rehydrated: true}))
    });
    return store;
}