import React from 'react';
import { DeleteButton } from '../../../components/Button/Button';
import ReactDropZone from 'components/ReactDropZone/ReactDropZone';
import { confirmAlert } from 'react-confirm-alert';
import ModalAlert from '../Modal/ModalAlert';

const delComment = async (removeEvent, file) => {
    /* confirmAlert({
        title: 'Deseja remover este item?',
        message: 'Ao remover este item não será mais exibido, esta ação não é reversível, deseja mesmo remover?',
        buttons: [
            {
                label: 'Sim',
                onClick: (e) => {
                    removeEvent(file)
                }
            },
            {
                label: 'Não'
            }
        ]
    }); */
    ModalAlert.danger({
        title: "Deseja remover este item?",
        text: "Ao remover este item não será mais exibido, esta ação não é reversível, deseja mesmo remover?",
        onConfirm: () => removeEvent(file)
    })
}

/*

Componente utilizado para anexar arquivos

*/
export const FileItem = ({ disabled, files, title, onSave, accept, onRemoveItem, style, inspection, showFiles = true, largeDropzone = false }) => {
    return <>
        <ReactDropZone
            // accept="image/*"
            accept={accept}
            onDrop={onSave}
            disabled={disabled}
            fullChildren={true}
        >
            <div className={`add-files ${inspection && "inspection"} ${largeDropzone && "large-dropzone"}`} style={style}>
                <div >
                    <h4>{title || (disabled ? "Arquivos" : "Adicionar arquivos")}</h4>
                    {!disabled && <a className="link">Selecione ou arraste aqui</a>}
                </div>
                {showFiles && files && <div>
                    {files.map((file) => <UploadedFile {...file} onRemoveItem={onRemoveItem} disabled={disabled} />)}
                </div>}
            </div>
        </ReactDropZone>
    </>
}

export const UploadedFile = (props) => {

    let url = props.url;

    if (/\.pdf|PDF$/.test(props.filename)) {
        url = "https://firebasestorage.googleapis.com/v0/b/app-ike.appspot.com/o/pdf.svg?alt=media&token=294f3718-3ed0-436b-8235-c2cb2da1061d";
    } else if (/\.doc|docx|DOC|DOCX$/.test(props.filename)) {
        url = "https://firebasestorage.googleapis.com/v0/b/app-ike.appspot.com/o/doc.svg?alt=media&token=f73d3867-9d4f-4c69-8c58-602cc4300fe2";
    } else if (/\.xml|XML|xls|XLS$/.test(props.filename)) {
        url = "https://firebasestorage.googleapis.com/v0/b/app-ike.appspot.com/o/xls.svg?alt=media&token=e6362f2d-fd01-4ae0-a2d3-af903464dc20"
    }
    // console.log({ props })
    // const url = "https://img.freepik.com/fotos-gratis/desenhos-de-construcao-arquitetonica-torcidos-em-um-rolo-projetos-de-construcao-em-papel_99433-171.jpg?size=626&ext=jpg"
    return <div className="uploaded-file" href={props.url} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(props.url, "_blank");
    }}>
        <div className="img" style={{ background: `url('${url}')` }} />
        <label>{props.filename}</label>
        {!props.disabled && <DeleteButton onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            delComment(props.onRemoveItem, props)
        }} />}
    </div>
}