import { getActivity, getAllActivity, addActivity, deleteActivity, updateActivity } from "core/database/activity";
import ActionCreator from 'core/utils/redux/ActionCreator';

const { actions, defaultReducer, get, add, remove, update } = new ActionCreator("activity");
export { actions, defaultReducer };

export const getActivityData = get(getActivity);
export const getAllActivityData = get(getAllActivity)
export const addActivityData = add(addActivity);
export const updateActivityData = update(updateActivity);
export const deleteActivityData = remove(deleteActivity);
