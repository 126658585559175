import React from 'react';
import Check from '../assets/img/check.gif'

const AnimationView = ({ history }) => (
    <div className='div-full'>
        <div className={'div-collumn'}>
            <img src={Check} className='img' />
            <span >Visualização foi confirmada com sucesso!</span>
            <a className='text-login' onClick={() => history.push("/auth/login")}>Ir para o login</a>
        </div>
    </div>
    )

export default AnimationView;