import React from "react";

class ScopeContainer extends React.Component {
    
    constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.onLocationChange(this.props.location);
		this.pathname = this.props.location.pathname;
		this.unlisten = this.props.history.listen((location) => {
			if(this.pathname === location.pathname){
				this.onLocationChange(location);
			}
		});
	}

	onLocationChange(location) {
        
    }

	componentWillUnmount() {
		if(this.unlisten){
			this.unlisten();
		}
    }
    
    onScopeChange(scope) {

    }

    componentWillReceiveProps(nextProps) {
        const { scope } = this.props;
        if (nextProps.scope != scope) {
            this.onScopeChange(nextProps.scope)
        }
    }

    render() {
        return (
            <></>
        );
    }
};

export default ScopeContainer;
