import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Row, Col, Spinner } from 'reactstrap';
import { ListReport } from '../../components/Report/ListReport';
import { getItem } from '../../core/database/firebaseFunctions';
import { NewModal } from '../../layoutV2/components/Modal/Modal';
import { DefaultImpact } from '../DefaultFilters';
import { toDateFormat } from './InspectionView';

export const QualityInspectionDetails = (props) => {
    const { item } = props
    const [open, setOpen] = useState(undefined);
    const [data, setData] = useState()

    const toggle = () => {
        setOpen(undefined)
        setData()
    }

    useEffect(() => {
        loadData()
    }, [open])

    const loadData = async () => {
        if (open && open.obsId) {
            const data = await getItem("WorkData", { id: open.obsId })
            if (data && data[0]) {
                let newData;
                const images = await getItem(`Works/${data[0].idWork}/inpections/${data[0].idInspection}/images`);
                newData = {
                    // activityUID, date, description, id, idcompany, idinspection, idwork, images, localization, scale, signal e step no item
                    ...data[0],
                    images: data[0].images.map(image => image.url && (image.url.includes("file://") || image.url.includes("ph://") || image.url.includes("content://")) ? images.find(item => item.id === image.id) : image),
                    inspectionId: data[0].idInspection,
                    section: data[0].section || 1,
                }
                if (data && data[0] && data[0].solvedImages) {
                    newData['solvedImages'] = data[0].solvedImages.map(image => image.url && (image.url.includes("file://") || image.url.includes("ph://") || image.url.includes("content://")) ? images.find(item => item.id === image.id) : image)
                }
                if (newData && newData.id) {
                    setData(newData)
                }
            }
        }
    }

    /* const loadData = () => {
        if (inspections) {
            const promises = [];
            inspections.map(inspection => {
                promises.push(getLinkedImagesInspection(location.state.workId, inspection.value).then(async items => {
                    const images = await getItem(`Works/${location.state.workId}/inpections/${inspection.value}/images`);
                    return items.map(item => ({
                        // activityUID, date, description, id, idcompany, idinspection, idwork, images, localization, scale, signal e step no item
                        ...item,
                        images: item.images.map(image => image.url && (image.url.includes("file://") || image.url.includes("ph://") || image.url.includes("content://")) ? images.find(item => item.id === image.id) : image),
                        inspectionId: inspection.value,
                        section: item.section || 1,
                    }))
                }))
            })
            Promise.all(promises).then(items => {
                let allItems = [];

                items.map(itemData => {
                    allItems = allItems.concat(itemData);
                })

                allItems = allItems.sort(function (a, b) {

                    if (a.step && b.step && a.step.name > b.step.name) return 1;
                    if (a.step && b.step && a.step.name < b.step.name) return -1;

                    if (a.commentId > b.commentId) return 1;
                    if (a.commentId < b.commentId) return -1;

                    if (a.date > b.date) return 1;
                    if (a.date < b.date) return -1;

                    return 0;
                });

                setData(allItems);
            });
        }
    } */

    // console.log({ props, data })
    return (
        <>
            {item.obsIdDate && <a style={{ marginLeft: 5, color: "#ef6d2e", cursor: "pointer" }} onClick={() => setOpen(item)}>#{toDateFormat(item.obsIdDate, "DDMMYYHHmm")}</a>}
            {item.obsIds && item.obsIds.length > 0 && item.obsIds.map(row => {
                return (
                    <a style={{ marginLeft: 5, color: "#ef6d2e", cursor: "pointer" }} onClick={() => setOpen(row)}>
                        #{toDateFormat(row.obsIdDate, "DDMMYYHHmm")}
                    </a>
                )
            })}
            <NewModal
                open={open !== undefined}
                toggle={toggle}
                title={"Detalhes"}
                onClickCancel={toggle}
                outlineLabel="Voltar"
                className="report-modal modal-quality-details"
            >
                {data && open !== undefined ? <ListReport
                    count={0}
                    addCount={() => { }}
                    showBlankSpaces={false}
                    key={1}
                    onSaveCustomImage={() => { }}
                    data={data}
                    index={1}
                    onSave={() => { }}
                    onSaveLinkData={() => { }}
                    onEditImage={() => { }}
                    location={{ state: props.state }}
                    onDataChange={() => { }}
                    allData={[data]}
                    impact={DefaultImpact(false).filter(impact => data.scale === impact.value)[0]}
                    delReport={() => { }}
                    delComment={() => { }}
                    onSaveData={() => { }}
                    onShare={() => { }}
                    sharedComments={[]}
                    ordination={[]}
                    onFinish={() => { }}
                    isQualityModal={true}
                /> : <Spinner color="warning" className="spinner-details" style={{ display: "flex" }} />}
            </NewModal>
        </>
    );
};

/*
            <Modal isOpen={open !== undefined} toggle={toggle} style={{ maxWidth: '60%' }}>
                <ModalHeader toggle={toggle}>Detalhes</ModalHeader>
                <ModalBody className='details-wizard' style={{ padding: 0, paddingBottom: 30 }}>
                    {data && open !== undefined ? <ListReport
                        count={0}
                        addCount={() => { }}
                        showBlankSpaces={false}
                        key={1}
                        onSaveCustomImage={() => { }}
                        data={data}
                        index={1}
                        onSave={() => { }}
                        onSaveLinkData={() => { }}
                        onEditImage={() => { }}
                        location={{ state: props.state }}
                        onDataChange={() => { }}
                        allData={[data]}
                        impact={DefaultImpact(false).filter(impact => data.scale === impact.value)[0]}
                        delReport={() => { }}
                        delComment={() => { }}
                        onSaveData={() => { }}
                        onShare={() => { }}
                        sharedComments={[]}
                        ordination={[]}
                        onFinish={() => { }}
                        isQualityModal={true}
                    /> : <Spinner color="warning" className="spinner-details" style={{ display: "flex" }} />}
                </ModalBody>
                <ModalFooter>
                    <Button style={{ marginRight: 24 }} small className="button-save-company" outline color="info">
                        {"Voltar"}
                    </Button>
                </ModalFooter>
                </Modal>
*/