import React from 'react';
import { IconButton } from '@material-ui/core';
import { Clear, Check, Edit, DeleteOutlined } from '@material-ui/icons'
//import { FiTrash} from "react-icons/fi";
import { BiEditAlt, BiTrashAlt, BiPrinter, BiImageAdd, BiEdit } from "react-icons/bi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import { MdMailOutline, MdSubtitles, MdAddAlert, MdViewQuilt, MdUndo, MdPeopleOutline } from 'react-icons/md'
import { RiLayout4Line } from 'react-icons/ri'
import { TiBell, TiKeyboard } from 'react-icons/ti'
import { IoMdRemoveCircleOutline, IoMdSettings } from 'react-icons/io'
import { IoShareSocial } from 'react-icons/io5'
import { GrConfigure } from 'react-icons/gr'
import { FiTool } from 'react-icons/fi';

export const CancelButton = ({ onClick, marginZero = false }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"}`}>
		<Clear />
	</IconButton>
)

export const ConfirmButton = ({ onClick, marginZero = false }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"}`}>
		<Check />
	</IconButton>
)

export const DeleteButton = ({ onClick, marginZero = false, className = "" }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"} ${className && className}`}>
		<BiTrashAlt />
	</IconButton>
)

export const ExportButton = ({ onClick, marginZero = false, className = "" }) => (
	<IconButton onClick={onClick} className={`table-button btn-export-quality ${marginZero && "margin"} ${className && className}`}>
		<BiPrinter />
	</IconButton>
)

export const EditButton = ({ onClick, marginZero = false, className = "" }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"} ${className && className}`}>
		<BiEditAlt />
	</IconButton>
)

export const EditGalleryButton = ({ onClick, marginZero = false }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"}`}>
		<BiEdit />
	</IconButton>
)

export const ViewButton = ({ onClick, marginZero = false }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"}`}>
		<BsEye />
	</IconButton>
)

export const AddButton = ({ onClick, marginZero = false }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"}`}>
		<AiOutlinePlusCircle />
	</IconButton>
)

export const RemoveButton = ({ onClick, marginZero = false }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"}`}>
		<IoMdRemoveCircleOutline />
	</IconButton>
)

export const ViewImagesButton = ({ onClick, marginZero = false, className = "" }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"} ${className && className}`}>
		<BiImageAdd />
	</IconButton>
)

export const EmailButton = ({ onClick, marginZero = false }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"}`}>
		<MdMailOutline />
	</IconButton>
)

export const SubtitleButton = ({ onClick, marginZero = false }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"}`}>
		<TiKeyboard />
	</IconButton>
)

export const AddAlertButton = ({ onClick, marginZero = false, active, iconBlue = false }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"} ${active && "active"} ${iconBlue && "iconBlue"}`}>
		<TiBell />
	</IconButton>
)

export const ViewQuiltButton = ({ onClick, marginZero = false }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"}`}>
		<RiLayout4Line />
	</IconButton>
)

export const ShareButton = ({ onClick, marginZero = false }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"}`}>
		<IoShareSocial />
	</IconButton>
)

export const UndoButton = ({ onClick, marginZero = false, className = "" }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"} ${className && className}`}>
		<MdUndo />
	</IconButton>
)

export const SettingsButton = ({ onClick, marginZero = false, className = "" }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"} ${className && className}`}>
		<FiTool />
	</IconButton>
)

export const PeopleButton = ({ onClick, marginZero = false, className = "" }) => (
	<IconButton onClick={onClick} className={`table-button ${marginZero && "margin"} ${className && className}`}>
		<MdPeopleOutline />
	</IconButton>
)

export const RadioButton = ({ text = '', onChange, value }) => (
    <div className="div-input">
        <input type='radio' className="check-box-input" onChange={onChange} value={value} name='radio' />
        <text className='check-text' >{text}</text>
    </div>
)