import React, { useState } from 'react';
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import FileGalleryContainer from '../../container/FileGalleryContainer';
import { SaveButton } from '../../layoutV2/components/Button';

export const AdvDetails = (props) => {
    const [selectedFiles, setSelectedFiles] = useState([]);

    const onSelectionChange = (files) => {
        setSelectedFiles(files);
        props.setHasChange(true);
    }

    const { saveAdv } = props;
    return (
        // <Col className="card-lib-details" md="12" lg="10">
        <div className="card-tasks">
            {/* <CardBody> */}
            <div className="category editable">
                <FileGalleryContainer defaultSelect={props.location.state.images} onSelectionChange={onSelectionChange} isMultipleSelect={true} accept="image/*" dataReference={`Adv/${props.location.state.id}/images`} canSelect={true} />
            </div>
            <SaveButton onClick={() => saveAdv(selectedFiles)} style={{ float: "right", marginTop: 25 }} />
            {/* <Button color="success" className="save-button" outline onClick={() => saveAdv(selectedFiles)}>{"Salvar"}</Button> */}
            {/* </CardBody> */}
        </div>
        // </Col>
    );
};