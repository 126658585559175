import React, { useEffect, useState } from "react";
import { NewModal } from "../../layoutV2/components/Modal/Modal";
import { SelectInput, TextInput } from "../../layoutV2/components/Form";
import { useSelector } from 'react-redux';
import { getWork, getWorks } from "../../core/database/works";
import sort from 'fast-sort';
import { getAllUsers, getUsers } from "../../core/database/user";
import { fireSQL } from "../../core/database/firebaseConfig";
import { RadioButton } from "../Button/Button";
import { sendChatMessage } from '../../core/communication/PushNotification';
import Alert from "../Alert/Alert";

export const ModalShare = ({ open, setOpen, channel, title = 'Compartilhar', type = 'Users', setIsOpen, setReload, reload }) => {
    const [work, setWork] = useState({})
    const [works, setWorks] = useState([])
    const user = useSelector(state => state.login);
    const groups = useSelector(state => state.login.groups);
    const [users, setUsers] = useState([])
    const [selected, setSelected] = useState([]);
    const [selectedWork, setSelectedWork] = useState([]);

    const toggle = () => {
        setOpen(false)
        setIsOpen(false)
        setSelected([])
        setSelectedWork([])
    }

    useEffect(() => {
        loadWorks()
        if (channel && channel.idWork) {
            setSelectedWork([{ value: channel.idWork, label: channel.nmWork }])
        }
    }, [channel, open])

    useEffect(() => {
        loadUsers()
    }, [works, selectedWork])

    const loadWorks = async () => {
        if (channel && channel.idWork) {
            const work = await getWork(channel.idWork);
            setWork(work)
        }
        let works = await getWorks({ filter: {}, groups, isAdmin: user.isAdmin, email: user.email })
        sort(works).asc(u => u.name)
        setWorks(works)
    }

    const loadUsers = async () => {
        const promises = []
        let newUsers

        if (user.isAdmin) {
            promises.push(getAllUsers())
        } else if (channel && channel.idWork) {
            promises.push(getUsers({ value: work.idCompany }))
        } else if (channel && !channel.idWork) {
            const groupsIds = groups.map(group => group.details && group.details.id).filter(item => item)
            groupsIds.map(idCompany => {
                promises.push(getUsers({ value: idCompany }))
            })
        }

        promises.push(fireSQL.query(`
            SELECT *
            FROM ChatGroups
            where active = true and usersGroup CONTAINS '${user.id}'
        `))

        newUsers = await Promise.all(promises).then(items => items.flat())
        newUsers = newUsers.filter(item => item.id !== user.id)


        const includeWork = works.find(work => {
            const array = selectedWork.map(item => item.value)

            return array.includes(work.id)
        })

        let users = newUsers.filter(user => user.nmGroup || (includeWork && includeWork.users && (includeWork.users.find(item => user.email === item.value)))).map(item => ({ ...item, value: item.id, label: item.name }))
        setUsers(users)
    }

    const sendByChat = async () => {

        if (selected && selected.length > 0) {
            const sendUsers = users.filter(item => {
                const arrayIds = selected.map(row => row.id)
                return arrayIds.includes(item.id)
            }
            );
            const promises = [];
            const sendWork = work.id ? work : await getWork(selectedWork[0].id);
            console.log({ sendUsers, sendWork, work })

            for (let index = 0; index < sendUsers.length; index++) {
                const send = sendUsers[index];
                promises.push(sendChatMessage(send, {
                    forward: { ...open },
                    work: sendWork ? {
                        ...sendWork,
                        date: sendWork.date ? sendWork.date : new Date(),
                        idWork: sendWork.id,
                        nmWork: sendWork.name,
                    } : {},
                }, user, reload, setReload))
            }
            toggle();
            Alert.success("Mensagem enviada com sucesso!");

        }
    }

    return (
        <>
            <NewModal
                open={open}
                toggle={toggle}
                onClickConfirm={sendByChat}
                title={title}
                label={'Compartilhar'}
            >
                <SelectInput
                    options={works.map(item => ({ ...item, value: item.id, label: item.name }))}
                    title="Projetos"
                    labelledBy={"Projetos"}
                    value={selectedWork}
                    onChange={value => setSelectedWork(value)}
                />
                <SelectInput
                    options={type === 'Users' ? users.filter(item => item.email).map(item => ({ ...item, value: item.id, label: item.name })) : users.filter(item => item.nmGroup).map(item => ({ ...item, value: item.id, label: item.nmGroup }))}
                    title={type === 'Users' ? "Usuários" : 'Grupos'}
                    labelledBy={type === 'Users' ? "Usuários" : 'Grupos'}
                    value={selected}
                    onChange={value => setSelected(value)}
                    isDisabled={type === 'Users' && selectedWork && selectedWork.length === 0 ? true : false}
                />
            </NewModal>
        </>
    )
}

