import { combineReducers } from 'redux';
import { createTransform, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import login from './login/Reducers';
import invite from './invite/Reducers';
import group from './group/Reducers';
import user from './user/Reducers';
import evaluation from './evaluation/Reducers';
import skill from './skill/Reducers';
import lib from './lib/Reducers';
import projectLib from './projectLib/Reducers';
import tag from './tag/Reducers';
import company from './company/Reducers';
import works from './works/Reducers';
import inspectionModel from './inspectionModel/Reducers';
import activity from './activity/Reducers';

// import { stringify, parse } from 'flatted';

// import JSOG from 'jsog'

// export const transformCircular = createTransform(
//     (inboundState, key) => JSOG.encode(inboundState),
//     (outboundState, key) => JSOG.decode(outboundState),
// )

// export const transformCircular = createTransform(
//     (inboundState, key) => stringify(inboundState),
//     (outboundState, key) => parse(outboundState),
// )

const loginConfig = {
	key: 'login',
	storage,
	blacklist: ["loaded", "groups"],//["email", "password", "scope", "network"]
};

export const Reducers = combineReducers({
	login: persistReducer(loginConfig, login),
	invite,
	group,
	user,
	evaluation,
	skill,
	lib,
	tag,
	company,
	works,
	projectLib,
	inspectionModel,
	activity
})