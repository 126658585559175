import React, { useEffect, useState, useMemo } from 'react';
import { ScheduleView } from '../../views/works/ScheduleView';
import { getAlerts } from '../../core/database/alert';
import { addTasks, getTasks, getWork, updateTasks } from '../../core/database/works';
import { getItem, updateItem } from '../../core/database/firebaseFunctions';
import { getQualityInspection } from '../../core/database/qualityInspection';
import ModalAlert from '../../layoutV2/components/Modal/ModalAlert';
import { useSelector } from 'react-redux';
import moment from 'moment';

const defaultState = [
    {
        value: 1,
        label: "Planejado",
        color: "#A4A4A4"
    },
    {
        value: 2,
        label: "Liberado",
        // color: "#088A08"
    },
    {
        value: 3,
        label: "Executando",
        // color: "#ef6d2e"
    },
    {
        value: 4,
        label: "Concluido"
    },
    {
        value: 5,
        label: "Parado"
    },
    {
        value: 6,
        label: "Impedido"
    }
]

const formatData = (data, alerts) => {
    return data.map(item => {
        const alert = alerts.find(alert => alert.UID === item.UID);

        if (alert) {
            item['alert'] = alert
        }

        return item;
    })
}

let defaultData = {}

export const ScheduleContainer = (props) => {
    const { workId, work } = props.location.state
    const [id, setId] = useState();
    const [data, setData] = useState(defaultData[workId] || []);
    const [alerts, setAlerts] = useState([]);
    const [tasks, setTasks] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [reload, setReload] = useState(true);
    const [reloadData, setReloadData] = useState(true);
    const [workData, setWorkData] = useState({});
    const user = useSelector(state => state.login);

    const loadData = async () => {
        if (workId) {
            const tasks = await getTasks(props.location, props.company)
            const activities = await getItem("Activity", { workId })
            const workData = await getWork(workId)
            setWorkData(workData)

            // console.log({ tasks, activities })
            if (workData.noSchedule) {
                const dataSchedule = [{
                    Name: `Fichas de inspeção ${workData.name}`,
                    UID: "-1",
                    // Start: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    // Finish: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    OutlineNumber: "0.0",
                }]

                const formattedData = dataSchedule.map(item => ({ ...item, tableData: data.find(a => a.UID === item.UID) ? data.find(a => a.UID === item.UID).tableData : {} }))
                setData(formattedData)
                defaultData[workId] = formattedData;
            } else if (tasks && tasks.length > 0 && activities) {
                const newData = tasks[0].data.map(task => activities.find(activity => activity.UID === task.UID) ? { ...task, ...activities.find(activity => activity.UID === task.UID) } : task)
                // console.log({ newData })

                if (newData) {
                    const formattedData = newData.map(item => ({ ...item, tableData: data.find(a => a.UID === item.UID) ? data.find(a => a.UID === item.UID).tableData : {} }))
                    setId(tasks[0].id);
                    console.log({ newData })
                    setData(formattedData)
                    defaultData[workId] = formattedData;
                    // setData(data)
                }
            }
        }
        // .then(items => {
        //     if (items && items[0]) {
        //     }
        // })
    }
    console.log({ data })
    useEffect(() => {
        loadData()
    }, [reloadData])

    useEffect(() => {
        loadAlerts()
    }, [reload]);

    const loadAlerts = async () => {
        const alerts = await getAlerts(props.location, props.company)
        setAlerts(alerts)
        //.then(alerts => setAlerts(alerts))
    }

    const onDataChanged = (items) => {
        const tasks = items.map(({ UID, Name, Start, Finish, OutlineNumber }) => ({ UID: UID || "", Name: Name || "", Start: Start || "", Finish: Finish || "", OutlineNumber: OutlineNumber || "" }));
        setTasks(tasks);

        if (data && data.length > 0) {
            setIsOpenModal(true);
        } else {
            addTasks(props.location, { data: tasks }, props.company).then(() => setData(tasks));
        }
    }

    const onConfirm = () => {
        updateTasks(props.location, { id, data: tasks }, props.company).then(() => setData(tasks));
    }

    const restartWarn = (text) => {
        ModalAlert.warn({
            title: "Impossível reiniciar inspeção",
            text,
            hideConfirm: true
        })
    }

    const updateActivity = async ({ id }) => {
        await updateItem("Activity", { id, status: 1 })
        setReloadData(reload => !reload)
    }

    const onClickRestart = async (rowData, index) => {
        const work = await getWork(workId)
        if (work && work.qualityResponsible && work.qualityResponsible.filter(item => item.value === user.email).length > 0) {
            if ([2, 3].includes(index) && rowData && rowData.UID && rowData.workId) {
                const inspections = await getQualityInspection({ activityUID: rowData.UID, workId: rowData.workId }) || []
                if (inspections && inspections.length === 0) {
                    ModalAlert.danger({
                        title: "Deseja reiniciar essa inspeção?",
                        text: "Ao reiniciar, sua inspeção retornará ao estado de Planejada. Esta ação não é reversível, portanto, você teria de recomeçar.",
                        onConfirm: () => updateActivity(rowData)
                    })
                } else {
                    restartWarn(`Há itens nessa inspeção que não podem ser excluídos automaticamente.`)
                }
            } else {
                restartWarn(`Apenas inspeções liberadas e em execução podem ser reabertas.`)
            }
        } else {
            restartWarn(`Apenas responsáveis pela qualidade podem reabrir atividades.`)
        }
    }

    return <ScheduleView
        title={"Cronogramas"}
        data={data}
        defaultState={defaultState}
        setData={setData}
        onDataChanged={onDataChanged}
        onConfirm={onConfirm}
        isOpenModal={isOpenModal}
        user={user}
        setIsOpenModal={setIsOpenModal}
        setReload={setReload}
        reload={reload}
        alerts={alerts}
        onClickRestart={onClickRestart}
        workData={workData}
        {...props}
    />;
};