import React, { useRef, useState } from 'react';
import { toDateFormatted } from '../../core/utils/Utils';
import ImageDraggableLayout from '../DragDrop/ImageDraggableLayout';
import RichEditorQuill from '../RichEditor/RichEditorQuill';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { WorkModal } from '../WorkModal/WorkModal';
import { ShowComment } from './ShowComment';
import { ModalSharedComment } from './ModalSharedComment';
import { confirmAlert } from 'react-confirm-alert';
import { primaryColor } from '../../variables/theme';
import { SignalReport } from './SignalReport';
import ModalAlert from '../../layoutV2/components/Modal/ModalAlert';
import { AddButton, DeleteButton, RemoveButton, ShareButton } from '../Button/Button';
import { Badge } from 'reactstrap';
import { OutlineButton } from '../../layoutV2/components/Button';

// const Alpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "U", "V", "W", "X", "Y", "Z"];

export const formatComments = (data, index, description) => {
    data[index] = {
        ...data[index],
        description,
    }
    return data;
}

export const formatSectionComments = (data, index, section) => {
    // console.log({ data, index, section })
    data[index] = {
        ...data[index],
        section,
    }
    // console.log({ data })
    return data;
}

export const ListReport = ({ sharedComments, delComment, count, allData, onFinish, addCount, showBlankSpaces, data, index, ordination, onSaveData, onSaveLinkData, onShare, onSaveCustomImage, onSave, onEditImage, location, onDataChange, impact, delReport, isQualityModal = false }) => {
    const page = useRef(null);

    const locationChanged = {
        state: { workId: location && location.state && location.state.workId, id: data && data.inspectionId }
    }

    const shareds = sharedComments.filter(item => item.idComment === data.id && item.nmUser);

    const confirmRemove = (item) => {
        ModalAlert.danger({
            title: "Deseja remover esta descrição?",
            text: "Ao remover, sua descrição não será mais exibida no relatório. Esta ação não é reversível, deseja mesmo remover?",
            onConfirm: () => onSave(data, "commentItems", data.commentItems.filter(a => a.id !== item.id))
        })
    }

    const addSection = (verification, save, description = false) => {
        if (verification) {
            ModalAlert.warn({
                title: "Número máximo de seções",
                text: "Você pode acrescentar no máximo 2 seções que equivalem ao tamanho de uma página!",
                hideConfirm: true
            })
        } else {
            save()
        }
    }

    const removeSection = (verification, save, description = false) => {
        if (verification) {
            ModalAlert.warn({
                title: "Número mínimo de seções",
                text: `${description ? "Sua descrição" : "Seu apontamento"} deve conter no mínimo 1 seção, para que possa ser apresentado na página!`,
                hideConfirm: true
            })
        } else {
            save()
        }
    }

    return (
        <>
            <div className={`content-report`} style={{ height: 510 * (data && data.section), overflow: "hidden" }} id={`${count}-step`} key={`${data && data.id}-step`}>
                <div className={showBlankSpaces ? "report-auto-section report-data" : `report-middle-section report-data`}>
                    <div className="list-margin headerText">
                        {isQualityModal ? <>
                            ‎{toDateFormatted(data && data.date && data.date._seconds ? new Date(data && data.date && data.date._seconds * 1000) : data && data.date)}
                            {impact && <> - <span style={{ color: impact.color, marginRight: 10 }}>{`​​  ${impact.label}`}</span></>}
                        </> : <>
                            <div className="list-margin headerText index">‏{`${count}‏‏‎`}</div>
                            {`‏‏‎ ‎-‏‏‎ ‎${toDateFormatted(data.date)} `}
                            {impact && <><span className="hide-mobile" style={{ color: impact.color, marginRight: 10 }}>{`​​  ${impact.label}`}</span></>}
                            <div className="hide-mobile">
                                {(shareds && shareds.length > 0) && <ModalSharedComment shareds={shareds} />}
                                <DeleteButton onClick={() => delReport(data)} />
                                <WorkModal content={data} location={locationChanged} hasButton={true} onSaveData={onSaveData} />
                                <ShareButton onClick={() => onShare(data)} />
                            </div>
                        </>}
                        <div style={{ flex: 1 }} />
                        {data && data.signal && data.signal.length > 0 && data.signal.map(signal => <SignalReport signal={signal} count={count} typeImprovement={data.typeImprovement} />)}
                        {isQualityModal ? <>
                            <span style={{ backgroundColor: data.state === 1 ? "#6bd098" : "#fbc658", color: "white", padding: 5, paddingRight: 15, paddingLeft: 15, borderRadius: 3, lineHeight: "1.35em", fontSize: "0.8571em", textTransform: "uppercase" }}>{data.state === 1 ? "Finalizado" : "Pendente"}</span>
                        </> : data && data.state === 1 ? <Badge color="success" onClick={() => onFinish(data)}>Finalizado</Badge> : <Badge color="info" onClick={() => onFinish(data)}>Pendente</Badge>}
                    </div>
                    <div className="sectionHeight">
                        <div className="list-margin bottom">
                            <RichEditorQuill
                                className="localization"
                                defaultValue={data.localization}
                                toolbarInline={true}
                                onChange={(value) => onSave(data, "localization", value)}
                                placeholder={'Localização'}
                                disabled={isQualityModal}
                            />
                        </div>
                        <div ref={page}>
                            <ImageDraggableLayout
                                location={location}
                                edit={true}
                                disabled={isQualityModal || data.images.length > 3}
                                data={data}
                                onSaveLayout={onSaveLinkData}
                                onEditImage={onEditImage}
                                onDataChange={onDataChange}
                                onSaveCustomImage={onSaveCustomImage}
                                // disabled={isQualityModal}
                                isComment={isQualityModal}
                            />
                        </div>
                        <div className="list-margin bottom">
                            <RichEditorQuill
                                className="apoint"
                                defaultValue={data.description}
                                toolbarInline={true}
                                onChange={(text) => onSave(data, "description", text)}
                                placeholder={'Insira aqui suas observações'}
                                disabled={isQualityModal}
                            />
                        </div>
                        {(data && data.solvedDescription || data && data.solvedImages && data.solvedImages.length > 0) && <div ref={page}>
                            <ImageDraggableLayout
                                location={location}
                                edit={true}
                                disabled={isQualityModal || (data.solvedImages && data.solvedImages.length > 3)}
                                data={{ solvedImages: [], ...data }}
                                field='solvedImages'
                                onSaveLayout={onSaveLinkData}
                                onEditImage={onEditImage}
                                onDataChange={onDataChange}
                                onSaveCustomImage={onSaveCustomImage}
                                // disabled={isQualityModal}
                                isComment={isQualityModal}
                            />
                        </div>}
                        {(data && data.solvedDescription || data && data.solvedImages && data.solvedImages.length > 0) && <div className="list-margin bottom">
                            <RichEditorQuill
                                className="resolution"
                                defaultValue={data.solvedDescription}
                                toolbarInline={true}
                                onChange={(text) => onSave(data, "solvedDescription", text)}
                                placeholder={'Insira aqui a sua resolução'}
                                disabled={isQualityModal}
                            />
                        </div>}
                    </div>
                </div>
            </div>
            {!isQualityModal && <div className={`content-section`}>
                <OutlineButton className="hide-mobile" onClick={() => onSave(data, "commentItems", data.commentItems ? data.commentItems.concat({ id: new Date().getTime(), section: 1 }) : [{ id: new Date().getTime(), section: 1 }])}
                    label={<>
                        <i style={{ fontSize: 15 }} class="material-icons">
                            add
                        </i>
                        {"Adicionar descrição ao apontamento"}
                    </>} />
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <span className="section-span">Seções</span>
                    <div className="section-button">
                        <AddButton onClick={() => addSection(data.section === 2, () => onSave(data, "section", data.section === 2 ? data.section : data.section + 1))} />
                        <RemoveButton onClick={() => removeSection(data.section === 1, () => onSave(data, "section", data.section === 1 ? 1 : data.section - 1))} />
                    </div>
                </div>
            </div>}
            <div className={`content-section report-data-comment commentItems`}>
                {data.commentItems && data.commentItems.map((item, index) => {
                    return (
                        <div key={item.id} className="list-margin bottom" style={{ position: "relative", overflow: "hidden", maxHeight: (540 * item.section) + 55, height: (540 * item.section) + 55, width: "100%" }}>
                            <style dangerouslySetInnerHTML={{
                                __html: `
                                .apoint-${index + 1} .fr-wrapper::before{
                                    content: 'Descrição';
                                } `
                            }} />
                            <div style={{ maxHeight: 530 * item.section, height: 530 * item.section, overflow: "hidden", paddingTop: 10 }}>
                                <RichEditorQuill
                                    className={`apoint-${index + 1}`}
                                    defaultValue={item.description}
                                    toolbarInline={true}
                                    height={520 * item.section}
                                    // commentItem
                                    onChange={(text) => onSave(data, "commentItems", formatComments(data.commentItems, index, text))}
                                    placeholder={'Insira aqui suas observações'}
                                />
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 5 }}>
                                <OutlineButton onClick={() => confirmRemove(item)} label="Remover" />
                                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                                    <span className="section-span">Seções</span>
                                    <div className="section-button">
                                        <AddButton onClick={() => addSection(item.section === 2, () => onSave(data, "commentItems", formatSectionComments(data.commentItems, index, item.section === 2 ? 2 : item.section + 1)), true)} />
                                        <RemoveButton onClick={() => removeSection(item.section === 1, () => onSave(data, "commentItems", formatSectionComments(data.commentItems, index, item.section === 1 ? 1 : item.section - 1)), true)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {!isQualityModal && <ShowComment
                data={data}
                allData={allData}
                onSave={onSave}
                count={count}
                delComment={delComment}
                showBlankSpaces={showBlankSpaces}
                ordination={ordination}
            />}
        </>
    );
};