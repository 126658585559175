import React from "react";
import { connect } from "react-redux";
import { LibContainerComponent } from "../LibContainer";
import { getProjectLibData, updateProjectLibData, deleteProjectLibData } from "../../core/redux-store/projectLib/Actions";
import { getSkillData } from "../../core/redux-store/skill/Actions";

class WorkProjectDetailsContainer extends LibContainerComponent {

	static defaultProps = {
		showToolbar: false,
		isProject: true,
		isDetails: false,
		hideHistory: true,
		filter: {},
		headerAdd: true
	}

	constructor(props) {
		super(props);

		this.onSelectionChange = this.onSelectionChange.bind(this);
	}

	onRowDelete(oldData) {
		return this.props.dispatch(deleteProjectLibData(oldData.id));
	}

	onRowUpdate(newData, oldData) {
		return this.props.dispatch(updateProjectLibData(newData));
	}

	componentWillMount() {
		// if(!this.props.items || this.props.items.length === 0){
		// this.props.dispatch(getLibTagsData());
		// console.log(this.props.filter);
		// }
		const { workId } = this.props.filter;
		this.props.dispatch(getProjectLibData({ workId }));
		this.props.dispatch(getSkillData({ level: 1 }));
	}

	onSelectionChange(files) {
		this.props.onSelectionChange(files);
	}

}

export default connect(state => ({
	email: state.login.email,
	isLoading: state.projectLib.isLoading,
	items: state.projectLib.items,
	comboTags: state.projectLib.comboTags,
	errorMessage: state.projectLib.error,
	skills: state.skill.items,
	isAdmin: state.login.isAdmin,
	groups: state.login.groups
}))(WorkProjectDetailsContainer);