import React from "react";
import { Nav, Collapse, Badge } from "reactstrap";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";

import logo from "assets/img/logo-branca.png";
import { NavLink } from "../NavLink/NavLink";
import { connect } from 'react-redux';
import { getScopeHasPower } from 'core/utils/Utils';
import ScopeContainer from 'container/ScopeContainer';
import { withRouter } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Roles } from "../Group/ModalNewGroup";
import { useUserType } from "../../core/user/hooks";

var ps;

// this function creates the NavLinks and collapses that appear in the sidebar (left menu)
const CreateNavLinks = (props) => {
  const { hasPower } = useUserType();
  const { routes, hasFullPower, groupData, state, setState, getCollapseInitialState, activeRoute } = props
  // const { hasPower, hasFullPower, groupData } = this.props;
  // document.body.classList.toggle("sidebar-mini");
  return routes.filter(item => !item.hidden && item.layout === "/admin" && (!item.hasFullPower || item.hasFullPower && item.hasFullPower === hasFullPower) && (!item.hasPower || item.hasPower && item.hasPower === hasPower)).map((prop, key) => {
    if (prop.redirect) {
      return null;
    }
    if (prop.collapse) {
      var st = {};
      st[prop["state"]] = !state[prop.state];
      return (
        <li
          className={getCollapseInitialState(prop.views) ? "active" : ""}
          key={key}
        >
          <a
            data-toggle="collapse"
            aria-expanded={state[prop.state]}
            onClick={e => {
              e.preventDefault();
              setState(st);
            }}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>
                  {prop.name}
                  <b className="caret" />
                </p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">
                  {prop.name}
                  <b className="caret" />
                </span>
              </>
            )}
          </a>
          <Collapse isOpen={state[prop.state]}>
            <ul className="nav">
              {/* {createNavLinks({ routes: prop.views, })} */}
              </ul>
          </Collapse>
        </li>
      );
    }
    return (
      groupData && groupData.roles && Array.isArray(groupData.roles) && !groupData.roles.includes(Roles.Biblioteca) && prop.name === 'Biblioteca' ? <div /> : <li className={activeRoute(prop.layout + prop.path)} key={key}>
        <NavLink to={prop.layout + prop.path} activeClassName="">
          {prop.iconComponent !== undefined ? (
            <>
              {prop.iconComponent}
              <p>{prop.name}</p>
            </>
          ) : (
            <>
              <span className="sidebar-mini-icon">{prop.mini}</span>
              <span className="sidebar-normal">{prop.name}</span>
            </>
          )}
        </NavLink>
      </li>
    );
  });
};

class Sidebar extends ScopeContainer {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // toggleSidemini = () => {
  //   var windowWidth = window.innerWidth;
  //   if (windowWidth < 961) {
  //     document.body.classList.toggle("sidebar-mini");
  //   }
  // };

  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      /*ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });*/
    }
  }

  render() {
    const { user, scope, hasPower, hasFullPower, groupData } = this.props;

    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={"primary"}
      >
        <div className="logo">
          <a onClick={this.props.handleMiniClick}>
            <i class="material-icons menu-adminnav">
              menu
            </i>
            <i class="material-icons arrow-adminnav">
              arrow_back_ios
            </i>
          </a>
          <a
            className="simple-text"
          >
            <div className="logo-img">
              <img src={logo} />
            </div>
          </a>
        </div>

        <div className="sidebar-wrapper" ref="sidebar">
          <Nav>
          {/* routes, hasPower, hasFullPower, groupData, state, setState, getCollapseInitialState, activeRoute */}
            <CreateNavLinks routes={this.props.routes} hasPower={true} hasFullPower={hasFullPower} groupData={groupData} state={this.state} setState={this.setState} getCollapseInitialState={this.getCollapseStates} activeRoute={this.activeRoute}/>
            {/* {this.createNavLinks(this.props.routes)} */}
            </Nav>
        </div>
      </div>
    );
  }
}

const mapToProps = state => ({
  hasFullPower: state.login.isAdmin,
  hasPower: state.login.hasPower,
  scope: state.login.scope,
  name: state.login.name,
  groupData: state.login.groupData
})

export default withRouter(connect(mapToProps)(Sidebar));

// {hasPower ? <Badge pill className="admin">Admin</Badge> : <Badge pill className="member">Membro</Badge>}